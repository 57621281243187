import { state } from "@src/state";
export const selectIsSourcesOpen = () => state.meeting.studio.sources.isPanelOpen;
export const selectIsObjectPropertiesOpen = () => state.meeting.studio.sources.isObjectPropertiesPanelOpen;
export const selectStudioSources = () => {
    return state.meeting.studio.sources.list;
};
export const selectStudioActiveSourceId = () => {
    return state.meeting.studio.sources.activeSourceId;
};
export const selectStudioActiveSources = () => {
    const activeSceneId = state.meeting.studio.scenes.activeSceneId.get();
    return state.meeting.studio.sources.list[activeSceneId];
};
export const selectStudioActiveSource = () => {
    const activeSceneId = state.meeting.studio.scenes.activeSceneId.get();
    const activeSourceId = state.meeting.studio.sources.activeSourceId.get();
    return state.meeting.studio.sources.list[activeSceneId][activeSourceId];
};
export const selectStudioSourceById = (id) => {
    const activeSceneId = state.meeting.studio.scenes.activeSceneId.get();
    return state.meeting.studio.sources.list[activeSceneId][id];
};
