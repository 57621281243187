import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Tag } from "@ting/ting-ui-components";
import { CrownOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import "./premiumTag.scss";
const cn = classNameFactory("premium-tag");
export const PremiumTag = () => {
    const { t } = useTranslation(["common"]);
    return (_jsx(Tag, { size: "medium", type: "brand", rounded: "small", fill: true, startIcon: _jsx(CrownOutlinedIcon, {}), className: cn(""), children: t("premium") }));
};
