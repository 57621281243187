import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@ting/ting-ui-components";
import { HandOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { toggleRaisingHand } from "@src/controller";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomSelfPeer } from "@src/selectors";
import { useBuildMobile } from "@src/hooks/breakpoints";
export const HandController = () => {
    const selfPeer = useSubscribe(selectRoomSelfPeer());
    const isMobile = useBuildMobile();
    return (_jsx(Button, { v2: true, icon: _jsx(HandOutlinedIcon, { height: "20px", width: "20px" }), onClick: toggleRaisingHand, size: isMobile ? "big" : "medium", round: true, type: selfPeer?.raisedHandAt ? "button" : "neutral", variant: selfPeer?.raisedHandAt || isMobile ? "primary" : "ghost" }));
};
