import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Screen } from "@src/components/Studio/Screen";
import { Scenes } from "@src/components/Studio/Scenes";
import { Sources } from "@src/components/Studio/Sources";
import { Mixer } from "@src/components/Studio/Mixer";
import { PeerAudioList } from "@src/components/PeerAudioList";
import { resize } from "@src/utils/resize";
import { classNameFactory } from "@src/utils/dom";
import { selectIsMixerOpen, selectIsScenesOpen, selectIsSourcesOpen, selectStudioSavedState, } from "@src/selectors/studio";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { restoreStudioData } from "@src/controller";
import "./studio.scss";
const cn = classNameFactory("studio");
export const Studio = () => {
    const isScenesOpen = useSubscribe(selectIsScenesOpen());
    const isSourcesOpen = useSubscribe(selectIsSourcesOpen());
    const isMixerOpen = useSubscribe(selectIsMixerOpen());
    const savedState = useSubscribe(selectStudioSavedState());
    const [toolsHeight, setToolsHeight] = useState(60);
    const studioRef = useRef(null);
    const resizeToastBox = (event, element) => {
        resize(event, 0, toolsHeight, 0, 0, element).subscribe(result => {
            if (result.height > 10 && result.height < 90) {
                setToolsHeight(result.height);
            }
            return result;
        });
    };
    const mouseDownHandler = (event) => {
        if (studioRef.current) {
            resizeToastBox(event, studioRef.current);
        }
        event.stopPropagation();
    };
    useEffect(() => {
        if (savedState) {
            restoreStudioData();
        }
    }, [savedState]);
    return (_jsxs("main", { className: cn(""), ref: studioRef, children: [_jsx("section", { className: cn("screen"), children: _jsx(Screen, {}) }), (isScenesOpen || isSourcesOpen || isMixerOpen) && (_jsxs(_Fragment, { children: [_jsx("button", { type: "button", className: cn("div"), onMouseDown: mouseDownHandler }), _jsxs("section", { className: cn("tools"), style: {
                            height: `${100 - toolsHeight}%`,
                        }, children: [isScenesOpen && _jsx(Scenes, {}), isSourcesOpen && _jsx(Sources, {}), isMixerOpen && _jsx(Mixer, {})] })] })), _jsx(PeerAudioList, {})] }));
};
