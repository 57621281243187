import { interval } from "rxjs";
import logger from "loglevel";
import { AudioMixer } from "./audioMixer";
import configs from "@src/configs";
import { SourceKind, } from "@src/domain/Studio/types";
import { ENV } from "@src/env";
export class StudioMerger {
    constructor() {
        Object.defineProperty(this, "canvas", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "gl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "program", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "debug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "height", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRendering", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "result", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoStreams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioStreams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioCtx", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioDestination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoSyncDelayNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rxIntervalSub", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "checkSupport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const canvas = document.createElement("canvas");
                if (!("AudioContext" in window && "createMediaElementSource" in AudioContext.prototype)) {
                    logger.error("AudioContext is not supported is this browser");
                    return false;
                }
                if (!canvas.captureStream) {
                    logger.error("Canvas is not supported is this browser");
                    return false;
                }
                if (!canvas.getContext("webgl2")) {
                    logger.error("WebGL is not supported is this browser");
                    return false;
                }
                return true;
            }
        });
        Object.defineProperty(this, "translatePositionToVertices", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (positions, flipX = false, flipY = false) => {
                const { width, height } = this.canvas;
                const [posX, posY, posW, posH] = positions
                    ? [positions.dx, positions.dy, positions.dw, positions.dh]
                    : [(10 / 100) * width, (10 / 100) * height, (20 / 100) * width, (20 / 100) * height];
                const pixelX = 2 / width;
                const pixelY = 2 / height;
                const x1 = pixelX * posX - 1;
                const x2 = pixelX * (posX + posW) - 1;
                const y1 = pixelY * (height - posY) - 1;
                const y2 = pixelY * (height - (posY + posH)) - 1;
                // Positions are like this:
                // FlipX & FlipY:  bottom-right (x1, y2), top-right (x1, y1), bottom-left (x2, y2), top-left (x2, y1),
                if (flipX && flipY)
                    return new Float32Array([x2, y2, x2, y1, x1, y2, x1, y1]);
                // FlipX:  top-right (x2, y1), bottom-right (x2, y2), top-left (x1, y1), bottom-left (x1, y2)
                if (flipX)
                    return new Float32Array([x2, y1, x2, y2, x1, y1, x1, y2]);
                // FlipY:  bottom-left (x1, y2), top-left (x1, y1), bottom-right (x2, y2), top-right (x2, y1),
                if (flipY)
                    return new Float32Array([x1, y2, x1, y1, x2, y2, x2, y1]);
                // Normal: top-left (x1, y1), bottom-left (x1, y2), top-right (x2, y1), bottom-right (x2, y2)
                return new Float32Array([x1, y1, x1, y2, x2, y1, x2, y2]);
            }
        });
        Object.defineProperty(this, "createVideo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (stream) => {
                const video = document.createElement("video");
                video.muted = true;
                video.style.position = "absolute";
                video.style.top = "0";
                video.style.left = "0";
                video.style.opacity = "0";
                video.srcObject = stream;
                video.play().catch(err => {
                    logger.error("Merger failed to add stream", err);
                });
                return video;
            }
        });
        if (!this.checkSupport())
            return;
        this.debug = !!window?.localStorage?.getItem("wglDebug") || false;
        this.width = configs.studio.defaultOutput.width;
        this.height = configs.studio.defaultOutput.height;
        this.fps = configs.studio.defaultOutput.fps;
        this.isRendering = false;
        this.videoStreams = new Map();
        this.audioStreams = new Map();
        this.canvas = document.createElement("canvas");
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        // https://registry.khronos.org/webgl/specs/latest/1.0/#5.2
        this.gl = this.canvas.getContext("webgl2", {
            // If set to true, the context will have an alpha (transparency) channel.
            // Defaults to true.
            alpha: false,
            // If set to true, the color channels in the framebuffer will be stored premultipled by the alpha channel to improve performance. If the value is true the page compositor will assume the drawing buffer contains colors with premultiplied alpha.
            // This flag is ignored if the alpha flag is false.
            // Defaults to true
            premultipliedAlpha: false,
            // If set to true, the context will attempt to perform antialiased rendering if possible.
            // Defaults to true.
            antialias: true,
            // If set to false, the buffer will be cleared after rendering. If you wish to use canvas.toDataURL(), you will either need to draw to the canvas immediately before calling toDataURL(), or set preserveDrawingBuffer to true to keep the buffer available after the browser has displayed the buffer (at the cost of increased memory use).
            // Defaults to false.
            preserveDrawingBuffer: false,
            // * high-performance: Indicates a request for a GPU configuration that prioritizes rendering performance over power consumption.
            // Developers are encouraged to only specify this value if they believe it is absolutely necessary, since it may significantly decrease battery life on mobile devices.
            // Implementations may decide to initially respect this request and, after some time, lose the context and restore a new context ignoring the request.
            // * low-power: Indicates a request for a GPU configuration that prioritizes power saving over rendering performance.
            // Generally, content should use this if it is unlikely to be constrained by drawing performance; for example, if it renders only one frame per second, draws only relatively simple geometry with simple shaders, or uses a small HTML canvas element. Developers are encouraged to use this value if their content allows, since it may significantly improve battery life on mobile devices.
            // powerPreference: 'high-performance',
            // powerPreference: 'low-power',
            powerPreference: "default",
            // If the value is true, then the user agent may optimize the rendering of the canvas to reduce the latency, as measured from input events to rasterization, by desynchronizing the canvas paint cycle from the event loop, bypassing the ordinary user agent rendering algorithm, or both.
            // Insofar as this mode involves bypassing the usual paint mechanisms, rasterization, or both, it might introduce visible tearing artifacts.
            // desynchronized: true,
        });
        this.prepareWebGL(); // Setup WebGL
        this.result = this.canvas.captureStream(this.fps);
    }
    prepareWebGL() {
        // Create shaders
        const vertexShaderSource = `#version 300 es
        layout(location=0) in vec2 aPosition;
        layout(location=1) in vec2 aTextCoords;
        out vec2 vTextCoords;
        void main() {
            vTextCoords = aTextCoords;
            gl_Position = vec4(aPosition, 0.0, 1.0);
        }
    `;
        const fragmentShaderSource = `#version 300 es
        precision mediump float;
        uniform sampler2D uSampler;
        in vec2 vTextCoords;
        out vec4 fragColor;
        void main() {
            fragColor = texture(uSampler, vTextCoords);
        }
    `;
        // Set viewport
        this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
        this.canvas.addEventListener("webglcontextlost", (e) => {
            logger.error(e);
        }, false);
        this.program = this.gl.createProgram();
        // Add shader Source, Compile, and Attach to Program
        // Vertex shader
        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        this.gl.shaderSource(vertexShader, vertexShaderSource);
        this.gl.compileShader(vertexShader);
        this.gl.attachShader(this.program, vertexShader);
        // Fragment shader
        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        this.gl.shaderSource(fragmentShader, fragmentShaderSource);
        this.gl.compileShader(fragmentShader);
        this.gl.attachShader(this.program, fragmentShader);
        // Link program
        this.gl.linkProgram(this.program);
        if (this.debug) {
            const debugInfo = this.gl.getExtension("WEBGL_debug_renderer_info");
            const vendor = this.gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            const renderer = this.gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            logger.log(`DEBUG_INFO: Vendor: ${vendor}, Renderer: ${renderer}`, debugInfo);
            if (!this.gl.getShaderParameter(vertexShader, this.gl.COMPILE_STATUS))
                logger.error(`ERROR compiling vertex shader!`, this.gl.getShaderInfoLog(vertexShader));
            if (!this.gl.getShaderParameter(fragmentShader, this.gl.COMPILE_STATUS))
                logger.error(`ERROR compiling fragment shader!`, this.gl.getShaderInfoLog(fragmentShader));
            if (!this.gl.getProgramParameter(this.program, this.gl.LINK_STATUS))
                logger.error("Error linking program:", this.gl.getProgramInfoLog(this.program));
        }
        // Attach program
        this.gl.useProgram(this.program);
        // Bind vertexBuffer
        // const pos = 1.0;
        // const vertexBuffer = this.gl.createBuffer();
        // const vertexBufferData = new Float32Array([-pos, pos, -pos, -pos, pos, pos, pos, -pos]);
        // this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexBuffer);
        // this.gl.bufferData(this.gl.ARRAY_BUFFER, vertexBufferData, this.gl.STATIC_DRAW);
        // this.gl.vertexAttribPointer(0, 2, this.gl.FLOAT, false, 0, 0);
        // this.gl.enableVertexAttribArray(0);
        // Bind texCoordsBuffer
        const texCoordsBuffer = this.gl.createBuffer();
        const texCoordsBufferData = new Float32Array([0, 1, 0, 0, 1, 1, 1, 0]);
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordsBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, texCoordsBufferData, this.gl.STATIC_DRAW);
        this.gl.vertexAttribPointer(1, 2, this.gl.FLOAT, false, 0, 0);
        this.gl.enableVertexAttribArray(1);
        // Bind texture
        const texture = this.gl.createTexture();
        this.gl.uniform1i(this.gl.getUniformLocation(this.program, "uSampler"), 0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.MIRRORED_REPEAT);
        // this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
        // this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.MIRRORED_REPEAT);
        // Flip the picture
        this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, true);
    }
    sortStreams() {
        this.videoStreams = new Map([...this.videoStreams].sort((a, b) => a[1].index - b[1].index));
    }
    backgroundAudioHack() {
        const source = this.audioCtx.createConstantSource();
        const gainNode = this.audioCtx.createGain();
        gainNode.gain.value = 0.001; // required to prevent popping on start
        source.connect(gainNode);
        gainNode.connect(this.audioCtx.destination);
        source.start();
    }
    setupConstantNode() {
        const gain = this.audioCtx.createGain(); // gain node prevents quality drop
        const constantAudioNode = this.audioCtx.createConstantSource();
        gain.gain.value = 0;
        constantAudioNode.start();
        constantAudioNode.connect(gain);
        gain.connect(this.videoSyncDelayNode);
    }
    addAudioTrack() {
        // Setup Audio
        this.audioCtx = new AudioContext();
        this.audioDestination = this.audioCtx.createMediaStreamDestination();
        this.videoSyncDelayNode = this.audioCtx.createDelay(5.0); // delay node for video sync
        this.videoSyncDelayNode.connect(this.audioDestination);
        // HACK for wowza #7, #10
        this.setupConstantNode();
        // stop browser from throttling timers by playing almost-silent audio
        this.backgroundAudioHack();
        this.result.addTrack(this.audioDestination.stream.getAudioTracks()[0]); // Add audio
        AudioMixer.instance.setAudioContext(this.audioCtx);
    }
    glDraw() {
        try {
            this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
            this.gl.clearDepth(this.gl.getParameter(this.gl.DEPTH_CLEAR_VALUE));
            this.gl.clearColor(0, 0, 0, 0);
            if (!this.isRendering) {
                // if (!this.videoStreams?.size) {
                // this.isRendering = false;
                if (this.rxIntervalSub?.unsubscribe)
                    this.rxIntervalSub.unsubscribe();
                return;
            }
            // this.gl.clearColor(0, 0, 0, 1.0);
            this.videoStreams.forEach(({ source, vertices }) => {
                if (source instanceof HTMLVideoElement && source.readyState < 3)
                    return;
                const vertexBuffer = this.gl.createBuffer();
                this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexBuffer);
                this.gl.bufferData(this.gl.ARRAY_BUFFER, vertices, this.gl.STATIC_DRAW);
                this.gl.vertexAttribPointer(0, 2, this.gl.FLOAT, false, 2 * 4, 0);
                this.gl.enableVertexAttribArray(0);
                this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, source instanceof HTMLVideoElement ? source.videoWidth : source.width, source instanceof HTMLVideoElement ? source.videoHeight : source.height, 0, this.gl.RGB, this.gl.UNSIGNED_BYTE, source);
                this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
                this.gl.deleteBuffer(vertexBuffer);
            });
        }
        catch (err) {
            logger.error(err);
        }
    }
    start() {
        this.isRendering = true;
        this.rxIntervalSub = interval(1000 / this.fps).subscribe(() => {
            this.glDraw();
        });
    }
    createRetrievalScreenImage() {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 10 * 1.777777;
        canvas.height = 10;
        ctx.fillStyle =
            "#" +
                Math.floor(Math.random() * 16777215)
                    .toString(16)
                    .padStart(6, "0")
                    .toUpperCase();
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const picture = canvas.toDataURL("image/png");
        const image = document.createElement("img");
        image.src = picture;
        return image;
    }
    addVideoSource(source) {
        let visualSource = source.source;
        if (source.source instanceof MediaStream) {
            visualSource = this.createVideo(source.source);
        }
        this.videoStreams.set(source.id, {
            index: source.index || this.videoStreams.size,
            id: source.id,
            source: visualSource,
            vertices: "positions" in source
                ? this.translatePositionToVertices(source.positions, source.flipX, source.flipY)
                : new Float32Array([]),
        });
    }
    addAudioSource(source) {
        const volume = 0.1;
        const audioSource = this.audioCtx.createMediaStreamSource(source.source);
        const audioOutput = this.audioCtx.createGain();
        const gainNode = audioSource.context.createGain();
        audioOutput.gain.value = 1;
        gainNode.gain.setValueAtTime(volume, audioSource.context.currentTime);
        audioSource.connect(gainNode);
        gainNode.connect(audioOutput);
        audioOutput.connect(this.videoSyncDelayNode);
        this.audioStreams.set(source.id, { audioSource, audioOutput });
        AudioMixer.instance.addMediaStreamSource(source.id, source.source, gainNode);
    }
    addSource(source) {
        // Attach audio track to merge result if it's not added yet
        if (!this.result?.getAudioTracks().length)
            this.addAudioTrack();
        if (source.isRetrieval) {
            this.videoStreams.set(source.id, {
                index: source.index || this.videoStreams.size,
                id: source.id,
                source: this.createRetrievalScreenImage(),
                vertices: "positions" in source ? this.translatePositionToVertices(source.positions) : new Float32Array([]),
            });
        }
        else if (source.source instanceof MediaStream && source.source.getVideoTracks().length) {
            this.addVideoSource(source);
        }
        else if (source.source instanceof MediaStream && source.source.getAudioTracks().length) {
            this.addAudioSource(source);
        }
        else if ([SourceKind.IMAGE_FILE, SourceKind.VIDEO_FILE].includes(source.sourceKind)) {
            this.addVideoSource(source);
            if (source.source instanceof HTMLVideoElement) {
                source.source.play();
            }
        }
        if (!this.isRendering)
            this.start();
    }
    removeStream(streamId) {
        // Remove audio source
        if (this.audioStreams.size) {
            const audioStream = this.audioStreams.get(streamId);
            if (audioStream) {
                if (audioStream.audioOutput)
                    audioStream.audioOutput.disconnect(this.videoSyncDelayNode);
                AudioMixer.instance.removeStream(streamId);
                this.audioStreams.delete(streamId);
            }
        }
        // Remove visual source
        const stream = this.videoStreams.get(streamId);
        if (stream) {
            if (stream.source) {
                if (stream.source instanceof HTMLVideoElement) {
                    stream.source.srcObject = null;
                }
                stream.source.remove();
                stream.source = null;
            }
            this.videoStreams.delete(streamId);
        }
        // Clear canvas if there is no stream to draw
        // This will remove last frame drew into canvas
        if (!this.videoStreams.size) {
            setTimeout(() => {
                if (this.gl) {
                    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
                    this.gl.clearDepth(this.gl.getParameter(this.gl.DEPTH_CLEAR_VALUE));
                }
            }, 50);
        }
    }
    getStreams() {
        const streams = [];
        this.videoStreams.forEach((stream) => streams.push(Object.freeze(stream)));
        return Object.freeze(streams);
    }
    updateIndex(mediaStream, index) {
        const id = typeof mediaStream === "string" ? mediaStream : mediaStream.id;
        const stream = this.videoStreams.get(id);
        if (stream) {
            this.videoStreams.set(id, {
                ...stream,
                index,
            });
            this.sortStreams();
        }
    }
    updatePosition(streamId, positions, flipX, flipY) {
        const stream = this.videoStreams.get(streamId);
        if (stream) {
            this.videoStreams.set(streamId, {
                ...stream,
                vertices: this.translatePositionToVertices(positions, flipX, flipY),
            });
        }
    }
    setOutputSize(width, height) {
        this.width = width;
        this.height = height;
        this.canvas.setAttribute("width", width.toString());
        this.canvas.setAttribute("height", height.toString());
        this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
    }
    getAudioContext() {
        return this.audioCtx;
    }
    destroy() {
        for (const [id] of this.videoStreams) {
            this.removeStream(id);
        }
        for (const [id] of this.audioStreams) {
            this.removeStream(id);
        }
        this.videoStreams = new Map();
        this.audioStreams = new Map();
        this.isRendering = false;
        if (this.audioCtx) {
            this.audioCtx.close();
            this.audioCtx = null;
            this.audioDestination = null;
            this.videoSyncDelayNode = null;
        }
        this.rxIntervalSub = null;
        this.result.getTracks().forEach(track => {
            track.stop();
            this.result.removeTrack(track);
        });
    }
}
Object.defineProperty(StudioMerger, "instance", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: new StudioMerger()
});
if (ENV.IS_DEV)
    window.studioMerger = StudioMerger.instance;
