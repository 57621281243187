import { jsx as _jsx } from "react/jsx-runtime";
import { useLayoutEffect, useRef } from "react";
import { PeerFrame } from "../PeerFrame";
import { selectRoomPeers } from "@src/selectors";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import "./galleryView.scss";
function calculatePeerFrameWidth(NoOfPeers, containerBoundingRect) {
    let bestWidth = 0;
    // i represents No of peers per each row
    for (let i = 1; i <= NoOfPeers; i += 1) {
        // TODO: 8 is the gap between frames
        // if we fit the height to screen height
        const height = (containerBoundingRect.height - (NoOfPeers - 1) * 8) / Math.ceil(NoOfPeers / i);
        const respectiveWidth = (height * 16) / 9;
        const totalWidth = respectiveWidth * i + (i - 1) * 8;
        if (totalWidth < containerBoundingRect.width && respectiveWidth > bestWidth) {
            bestWidth = respectiveWidth;
        }
        // if we fit width to screen width
        const width = (containerBoundingRect.width - (i - 1) * 8) / i;
        const respectiveHeight = (width * 9) / 16;
        const NoOfRows = Math.ceil(NoOfPeers / i);
        const totalHeight = respectiveHeight * NoOfRows + (NoOfRows - 1) * 8;
        if (totalHeight < containerBoundingRect.height && width > bestWidth) {
            bestWidth = width;
        }
    }
    return [Math.floor(bestWidth), Math.floor((bestWidth * 9) / 16)];
}
const cn = classNameFactory("gallery-view");
export const GalleryView = () => {
    const peers = useSubscribe(selectRoomPeers(), true);
    const peersLength = Object.keys(peers).length;
    const contRef = useRef();
    useLayoutEffect(() => {
        function handlePFWidth(rect) {
            const [width, height] = calculatePeerFrameWidth(peersLength, rect ?? contRef.current.getBoundingClientRect());
            Array.from(contRef.current.children).forEach(child => {
                const childStyle = child.style;
                childStyle.width = `${width}px`;
                childStyle.height = `${height}px`;
            });
        }
        handlePFWidth();
        const contElement = contRef.current;
        const observer = new ResizeObserver(entries => {
            for (const entry of entries) {
                handlePFWidth(entry.contentRect);
            }
        });
        observer.observe(contElement);
        return () => {
            observer.disconnect();
        };
    }, [peersLength]);
    return (_jsx("section", { className: cn(""), ref: contRef, children: Object.values(peers).map(peer => {
            return _jsx(PeerFrame, { peerId: peer.peerId }, peer.peerId);
        }) }));
};
