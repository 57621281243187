import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Checkbox, Tag, Text } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import "./checkboxDropDown.scss";
const cn = classNameFactory("checkbox-dropdown");
export const CheckboxDropDown = ({ name, peer, checked, disabled, onChange, children }) => {
    const [open, setOpen] = useState(!disabled && checked);
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("toggler"), children: [_jsx(Checkbox, { name: name, disabled: disabled, checked: !disabled && checked, onChange: val => {
                            setOpen(val.target.checked);
                            onChange(val.target.checked);
                        } }), _jsx("label", { htmlFor: name, children: _jsx(Text, { size: "p1", fontWeight: "semiBold", children: `${peer.name}'s ${name.slice(0, 1).toLocaleUpperCase()}${name.slice(1)}` }) }), disabled && (_jsx(Tag, { size: "medium", type: "default", rounded: "big", fill: false, className: cn("not-available"), children: "Not Available" }))] }), _jsx("div", { className: cn("content", { open }), children: children })] }));
};
