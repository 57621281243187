import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNameFactory } from "@src/utils/dom";
import { PanelController } from "@src/components/Studio/PanelController";
import { Separator } from "@src/components/ControlBar/Separator/Separator";
import { setMixerOpen, setScenesOpen, setSourcesOpen } from "@src/controller";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectIsMixerOpen, selectIsScenesOpen, selectIsSourcesOpen } from "@src/selectors/studio";
import "./studioController.scss";
const cn = classNameFactory("studio-controller");
export const StudioController = () => {
    const isScenePanelOpen = useSubscribe(selectIsScenesOpen());
    const isSourcePanelOpen = useSubscribe(selectIsSourcesOpen());
    const isMixerPanelOpen = useSubscribe(selectIsMixerOpen());
    return (_jsxs("div", { className: cn(""), children: [_jsx(Separator, {}), _jsx(PanelController, { leftActions: {
                    scenesToggle: {
                        toggled: !isScenePanelOpen,
                        toggleByVariant: true,
                        action: () => {
                            setScenesOpen(!isScenePanelOpen);
                        },
                    },
                    sourcesToggle: {
                        toggled: !isSourcePanelOpen,
                        toggleByVariant: true,
                        action: () => {
                            setSourcesOpen(!isSourcePanelOpen);
                        },
                    },
                    mixerToggle: {
                        toggled: !isMixerPanelOpen,
                        toggleByVariant: true,
                        action: () => {
                            setMixerOpen(!isMixerPanelOpen);
                        },
                    },
                } })] }));
};
