import { nanoid } from "nanoid";
import { SourceAlignment, SourceKind, SourceReorder, SourceType, } from "@src/domain/Studio/types";
import { selectActiveScenesID, selectIsObjectPropertiesOpen, selectStudioActiveScene, selectStudioActiveSource, selectStudioActiveSources, selectStudioSourceById, } from "@src/selectors/studio";
import { AudioMixer, StudioMerger } from "@src/services/studioService";
import { calcStudioSourcePosition } from "@src/utils/calcStudioSourcePosition";
export class StudioSources {
    constructor() {
        Object.defineProperty(this, "isPanelOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isObjectPropertiesPanelOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeSourceId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "list", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isPanelOpen = true;
        this.list = {};
    }
    setPanelOpen(isOpen) {
        this.isPanelOpen.set(isOpen);
    }
    setObjectPropertiesPanelOpen(isOpen) {
        this.isObjectPropertiesPanelOpen.set(isOpen);
    }
    activate(sourceId) {
        const activeSceneId = selectActiveScenesID().peek();
        this.activeSourceId.set(sourceId);
        Object.keys(this.list[activeSceneId].peek()).forEach((id) => {
            this.list[activeSceneId][id].assign({ active: id === sourceId });
        });
    }
    activateNextPossibleSource(sourceId) {
        const activeSceneId = selectActiveScenesID().peek();
        const sourcesId = Object.keys(this.list[activeSceneId]);
        if (sourcesId.length > 0) {
            const lastIndex = sourcesId.indexOf(sourceId);
            this.activate(lastIndex === 0 ? sourcesId[lastIndex + 1] : sourcesId[lastIndex - 1]);
        }
        else {
            this.activeSourceId.set(undefined);
        }
    }
    addSoundSource(index, source, sceneId) {
        const activeSceneId = selectActiveScenesID().peek();
        const soundSource = {
            ...source,
            index,
            volume: 10,
        };
        // Add source to state
        this.list[sceneId || activeSceneId][source.id].set(soundSource);
        // Add stream to studio-merger
        StudioMerger.instance.addSource(soundSource);
    }
    addVisualSource(index, source, sceneId) {
        const activeSceneId = selectActiveScenesID().peek();
        const activeScene = selectStudioActiveScene()?.peek();
        const visualSource = {
            ...source,
            index,
            positions: calcStudioSourcePosition(activeScene, source),
        };
        // Add source to state
        this.list[sceneId || activeSceneId][source.id].set(visualSource);
        // Add stream to studio-merger
        StudioMerger.instance.addSource(visualSource);
    }
    mapSource(source) {
        const activeSceneId = selectActiveScenesID().peek();
        const mappedSource = {
            ...source,
            isRetrieval: false,
        };
        // Exit source from retrieval mode
        this.list[activeSceneId][source.id].set(mappedSource);
        // Add stream to studio-merger
        StudioMerger.instance.addSource(mappedSource);
    }
    addSource(source, sceneId) {
        const activeSceneId = selectActiveScenesID().peek();
        const sources = selectStudioActiveSources()?.peek();
        const isOPPanelOpen = selectIsObjectPropertiesOpen()?.peek();
        const activeSources = this.list[activeSceneId].peek();
        const index = sources ? Object.keys(sources).length : 0;
        if (source.sourceType === SourceType.VISUAL) {
            this.addVisualSource(index, source, sceneId);
            // Add Screen Stream audio track
            if (source.source instanceof MediaStream && source.source.getAudioTracks().length) {
                const { sourceLabel, sourceName, isRemote, isRetrieval, audioSettings } = source;
                const screenSoundSource = source.source.clone();
                // Remove video track
                screenSoundSource.removeTrack(screenSoundSource.getVideoTracks()[0]);
                this.addSoundSource(index + 1, {
                    id: screenSoundSource.id,
                    mute: false,
                    volume: 10,
                    isRemote,
                    isRetrieval,
                    sourceLabel,
                    sourceName,
                    sourceKind: SourceKind.AUDIO_INPUT,
                    sourceType: SourceType.SOUND,
                    source: screenSoundSource,
                    settings: audioSettings,
                });
            }
        }
        if (source.sourceType === SourceType.SOUND) {
            this.addSoundSource(index, source, sceneId);
        }
        if (isOPPanelOpen === undefined || (activeSources && !Object.keys(activeSources).length)) {
            this.setObjectPropertiesPanelOpen(true);
        }
        if (!sceneId)
            this.activate(source.id);
    }
    deleteSource(sourceId, sourceSceneId) {
        const activeSceneId = selectActiveScenesID().peek();
        const sources = selectStudioActiveSources()?.peek();
        const source = sources && sources[sourceId]?.source;
        const sceneId = sourceSceneId || activeSceneId;
        // Disable MediaStream Tracks
        if (source instanceof MediaStream) {
            source.getTracks().forEach((track) => {
                track.enabled = false;
                track.stop();
            });
        }
        // Remove source from StudioMerger
        StudioMerger.instance.removeStream(sourceId);
        // Activate next source
        if (!sourceSceneId || sourceSceneId === activeSceneId) {
            this.activateNextPossibleSource(sourceId);
        }
        // Remove source from state
        this.list[sceneId][sourceId].delete();
        // Delete sceneId key from list if it contains with no source
        if (!Object.keys(this.list[sceneId].peek()).length) {
            this.list[sceneId].delete();
        }
    }
    renameSource(sourceId, sourceName) {
        const activeSceneId = selectActiveScenesID().peek();
        this.list[activeSceneId][sourceId].assign({ sourceName });
    }
    switchSource(source) {
        const oldSource = selectStudioActiveSource().peek();
        let newSource = { ...source, index: oldSource.index };
        if (source.sourceType === SourceType.VISUAL) {
            const { positions, positionY, positionX } = oldSource;
            newSource = { ...newSource, positions, positionY, positionX };
        }
        this.addSource(newSource);
        this.deleteSource(oldSource.id);
    }
    duplicateSource(sourceId) {
        let id = nanoid();
        let newSource;
        let positions = {};
        const source = selectStudioSourceById(sourceId).peek();
        // All Camera, Audio, Screen, and Peer media sources
        if (source.source instanceof MediaStream) {
            newSource = source.source.clone();
            id = newSource.id;
        }
        // Source Files
        if ([SourceKind.IMAGE_FILE, SourceKind.VIDEO_FILE, SourceKind.AUDIO_FILE].includes(source.sourceKind)) {
            newSource = source.source.cloneNode();
        }
        // Positions
        if (source.sourceType === SourceType.VISUAL) {
            const { positionX, positionY, positions: pos } = source;
            positions = {
                positionX: positionX + 5,
                positionY: positionY + 5,
                positions: {
                    ...pos,
                    dx: pos.dx + 5,
                    dy: pos.dy + 5,
                },
            };
        }
        const sourceName = source.sourceName.replace(/\(copy\)/g, "").trim();
        this.addSource({
            ...source,
            id,
            source: newSource,
            sourceName: `${sourceName} (copy)`,
            ...positions,
        });
    }
    updateSourceSize(sourceId, width, height) {
        const activeSceneId = selectActiveScenesID().peek();
        const activeScene = selectStudioActiveScene()?.peek();
        const activeSources = selectStudioActiveSources()?.peek();
        const visualSource = activeSources[sourceId];
        // Update source size in state
        this.list[activeSceneId][sourceId].assign({
            width: +width.toFixed(2),
            height: +height.toFixed(2),
        });
        // Update source size in StudioMerger
        StudioMerger.instance.updatePosition(sourceId, calcStudioSourcePosition(activeScene, visualSource), visualSource.flipX, visualSource.flipY);
    }
    updateSourcePosition(sourceId, x, y) {
        const activeSceneId = selectActiveScenesID().peek();
        const activeScene = selectStudioActiveScene()?.peek();
        const activeSources = selectStudioActiveSources()?.peek();
        const visualSource = activeSources[sourceId];
        const positionX = +x.toFixed(2);
        const positionY = +y.toFixed(2);
        const positions = calcStudioSourcePosition(activeScene, {
            ...visualSource,
            positionX,
            positionY,
        });
        // Update source positions in state
        this.list[activeSceneId][sourceId].assign({ positionX, positionY, positions });
        // Update source size in StudioMerger
        setTimeout(() => StudioMerger.instance.updatePosition(sourceId, positions, visualSource.flipX, visualSource.flipY), 50);
    }
    updateSourceFlip(sourceId, flipX, flipY) {
        const activeSceneId = selectActiveScenesID().peek();
        const activeScene = selectStudioActiveScene()?.peek();
        const activeSources = selectStudioActiveSources()?.peek();
        const positions = calcStudioSourcePosition(activeScene, activeSources[sourceId]);
        // Update source positions in state
        this.list[activeSceneId][sourceId].assign({ flipX, flipY });
        // Update source size in StudioMerger
        setTimeout(() => StudioMerger.instance.updatePosition(sourceId, positions, flipX, flipY), 50);
    }
    setSourceFillCanvas(sourceId) {
        this.updateSourcePosition(sourceId, 0, 0);
        this.updateSourceSize(sourceId, 100, 100);
    }
    setSourceStretchToCanvas(sourceId) {
        const activeSceneId = selectActiveScenesID().peek();
        const source = this.list[activeSceneId][sourceId].peek();
        this.updateSourcePosition(sourceId, 0, source.positionY);
        this.updateSourceSize(sourceId, 100, source.height);
    }
    updateSourceVolume(sourceId, volume) {
        const activeSceneId = selectActiveScenesID().peek();
        this.list[activeSceneId][sourceId].assign({ volume });
        // Change Audio volume in AudioMixer
        AudioMixer.instance.updateVolume(sourceId, volume);
    }
    muteSource(sourceId, mute) {
        const activeSceneId = selectActiveScenesID().peek();
        const source = selectStudioActiveSource().peek();
        // Set source mute
        this.list[activeSceneId][sourceId].assign({ mute });
        // Mute source audio inside mixer
        AudioMixer.instance.updateVolume(sourceId, mute ? 0 : source.volume);
    }
    sortSourcesIndexOrder() {
        // Sort sources by index
        const activeSceneId = selectActiveScenesID().peek();
        const sources = this.list[activeSceneId].peek();
        const entries = Object.entries(sources);
        const newSort = entries.sort(([, a], [, b]) => {
            return b.index - a.index;
        });
        this.list[activeSceneId].set(Object.fromEntries(newSort));
        // Update merger indexes
        Object.values(this.list[activeSceneId].peek()).map((source) => {
            StudioMerger.instance.updateIndex(source.id, source.index);
        });
    }
    updateSourceIndexOrder(sourceId, type) {
        const activeSceneId = selectActiveScenesID().peek();
        const sources = this.list[activeSceneId].peek();
        const source = this.list[activeSceneId][sourceId].peek();
        const sourceIndex = source.index;
        const altSource = Object.entries(sources).find(([, x]) => {
            if (type === SourceReorder.FORWARD)
                return x.index === source.index + 1;
            if (type === SourceReorder.BACKWARD)
                return x.index === source.index - 1;
            if (type === SourceReorder.FRONT)
                return x.index === Object.keys(sources).length - 1;
            return x.index === 0;
        });
        if (altSource) {
            this.list[activeSceneId][sourceId].index.set(altSource[1].index);
            this.list[activeSceneId][altSource[0]].index.set(sourceIndex);
        }
        this.sortSourcesIndexOrder();
    }
    adjustSourcePosition(sourceId, action) {
        const activeSceneId = selectActiveScenesID().peek();
        const source = this.list[activeSceneId][sourceId].peek();
        const { width, height, positionX, positionY } = source;
        let [x, y] = [0, 0];
        switch (action) {
            case SourceAlignment.HORIZONTAL_LEFT:
                [x, y] = [0, positionY];
                break;
            case SourceAlignment.HORIZONTAL_RIGHT:
                [x, y] = [100 - width, positionY];
                break;
            case SourceAlignment.HORIZONTAL_CENTER:
                [x, y] = [50 - width / 2, positionY];
                break;
            case SourceAlignment.VERTICAL_TOP:
                [x, y] = [positionX, 0];
                break;
            case SourceAlignment.VERTICAL_BOTTOM:
                [x, y] = [positionX, 100 - height];
                break;
            case SourceAlignment.VERTICAL_CENTER:
                [x, y] = [positionX, 50 - height / 2];
                break;
            default:
                break;
        }
        this.updateSourcePosition(sourceId, x, y);
    }
    lockSource(sourceId, lock) {
        const activeSceneId = selectActiveScenesID().peek();
        this.list[activeSceneId][sourceId].assign({ lock });
    }
    hideSource(sourceId, hidden) {
        const activeSceneId = selectActiveScenesID().peek();
        const source = selectStudioActiveSource().peek();
        // Update source state
        this.list[activeSceneId][sourceId].assign({ hidden });
        // Add/Remove from StudioMerger
        if (hidden) {
            StudioMerger.instance.removeStream(sourceId);
        }
        else
            StudioMerger.instance.addSource(source);
    }
}
