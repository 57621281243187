import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
import { DCMessageType } from "@src/domain/RTCConnection/types";
import { PeerRoles } from "@src/domain/Peer/types";
import { selectHostPeer, selectSelfPeerRole } from "@src/selectors";
export const DCMessageBuilder = {
    BuildPeerStatusMessage(peer) {
        const { isMicOn, isCamOn } = peer.peerStreamSettings;
        const { peerId, name } = peer;
        return new DCMessage({
            from: { peerId, name },
            to: "all",
            type: DCMessageType.PEER_STREAM_STATUS,
            payload: { peerId, isMicOn, isCamOn },
        });
    },
    BuildLeaveRoomMessage(peer) {
        return new DCMessage({
            from: { peerId: peer.peerId, name: peer.name },
            to: "all",
            type: DCMessageType.PEER_LEFT,
            payload: { peerId: peer.peerId },
        });
    },
    BuildEndCallMessage(peer) {
        return new DCMessage({
            from: { peerId: peer.peerId, name: peer.name },
            to: "all",
            type: DCMessageType.CALL_ENDED,
            payload: {},
        });
    },
    BuildStopRaisedHandMessage(selfPeer) {
        return new DCMessage({
            from: { peerId: selfPeer.peerId, name: selfPeer.name },
            to: "all",
            type: DCMessageType.STOP_RISING_HAND,
            payload: { peerId: selfPeer.peerId },
        });
    },
    BuildRaisedHandMessage(selfPeer) {
        return new DCMessage({
            from: { peerId: selfPeer.peerId, name: selfPeer.name },
            to: "all",
            type: DCMessageType.RISING_HAND,
            payload: { peerId: selfPeer.peerId, time: new Date().getTime() },
        });
    },
    BuildMissingTrackMessage(peer, targetPeer, isAudioMissing, isVideoMissing) {
        const isSelfSuperNode = selectSelfPeerRole().peek() === PeerRoles.SUPER_NODE;
        const hostPeerId = selectHostPeer().peerId.peek();
        const to = isSelfSuperNode ? [targetPeer.peerId] : [hostPeerId];
        return new DCMessage({
            from: { peerId: peer.peerId, name: peer.name },
            to,
            type: DCMessageType.MISSING_TRACK,
            payload: {
                peerId: targetPeer.peerId,
                audio: isAudioMissing,
                video: isVideoMissing,
            },
        });
    },
    BuildMissingMetaMessage(peer, meta, senderPeer) {
        return new DCMessage({
            from: { peerId: peer.peerId, name: peer.name },
            type: DCMessageType.POSSIBLE_MISSING_META,
            to: [senderPeer.peerId],
            payload: {
                meta,
            },
        });
    },
    BuildCandidaSelectMessage(peer, candidatePeer, to) {
        return new DCMessage({
            from: { peerId: peer.peerId, name: peer.name },
            to: to ? [to] : "all",
            type: DCMessageType.SELECT_CANDIDATE,
            payload: {
                peerId: candidatePeer.peerId,
            },
        });
    },
};
