import { ENV } from "@src/env";
export class AudioMixer {
    constructor() {
        Object.defineProperty(this, "audioContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mediaStreamStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
    }
    setAudioContext(audioContext) {
        if (this.audioContext instanceof AudioContext) {
            this.audioContext.close().then(() => {
                this.audioContext = audioContext;
            });
        }
        else
            this.audioContext = audioContext;
    }
    getAudioContext() {
        return this.audioContext;
    }
    addMediaStreamSource(streamId, stream, gainNode) {
        const foundedStreamNode = this.mediaStreamStore.get(streamId);
        const node = {};
        if (foundedStreamNode !== undefined) {
            return foundedStreamNode;
        }
        node.mediaStream = this.audioContext.createMediaStreamSource(stream);
        node.analyser = this.audioContext.createAnalyser();
        node.analyser.fftSize = 256;
        node.audioData = new Uint8Array(node.analyser.frequencyBinCount);
        node.gainNode = gainNode;
        node.mediaStream.connect(node.analyser);
        this.mediaStreamStore.set(streamId, node);
        return node;
    }
    getAudioVolumeInDecibels(streamId) {
        const node = this.mediaStreamStore.get(streamId);
        if (node) {
            node.analyser.getByteFrequencyData(node.audioData);
            // Calculate the average amplitude of the audio signal
            let sum = 0;
            node.audioData.forEach(value => {
                sum += value;
            });
            const averageAmplitude = sum / node.analyser.frequencyBinCount;
            // Convert the average amplitude to dB
            return Math.floor(20 * Math.log10(averageAmplitude / 255));
        }
        return null;
    }
    getMediaStreamSource(streamId) {
        return this.mediaStreamStore.get(streamId);
    }
    removeStream(streamId) {
        const streamNode = this.mediaStreamStore.get(streamId);
        if (streamNode) {
            streamNode.mediaStream.mediaStream.getTracks().forEach(track => {
                track.stop();
            });
            streamNode.mediaStream.disconnect(streamNode.analyser);
            streamNode.mediaStream = null;
            streamNode.analyser = null;
            streamNode.audioData = null;
            streamNode.gainNode = null;
            this.mediaStreamStore.delete(streamId);
        }
    }
    updateVolume(streamId, volume) {
        const context = AudioMixer.instance.getAudioContext();
        const mediaSource = AudioMixer.instance.getMediaStreamSource(streamId);
        if (context && mediaSource) {
            mediaSource.gainNode.gain.setValueAtTime(volume / 100, context.currentTime);
        }
    }
}
Object.defineProperty(AudioMixer, "instance", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: new AudioMixer()
});
if (ENV.IS_DEV)
    window.audioMixer = AudioMixer.instance;
