import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { PanelController } from "../PanelController";
import { classNameFactory } from "@src/utils/dom";
import { StudioMerger } from "@src/services/studioService";
import { SourceFrameList } from "@src/components/Studio/Screen/components/SourceFrameList";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectIsMixerOpen, selectIsObjectPropertiesOpen, selectIsScenesOpen, selectIsScreenFullscreen, selectIsSourcesOpen, selectStudioActiveSourceId, selectStudioActiveSources, selectStudioScenes, } from "@src/selectors/studio";
import { deleteStudioSource, duplicateStudioSource, setMixerOpen, setObjectPropertiesOpen, setScenesOpen, setScreenFullscreen, setSourceHidden, setSourceLock, setSourcesOpen, } from "@src/controller";
import { SourceType } from "@src/domain/Studio/types";
import { debounce } from "@src/utils/helpers/debounce";
import "./screen.scss";
const cn = classNameFactory("screen");
export const Screen = () => {
    const screenFullscreen = useSubscribe(selectIsScreenFullscreen());
    const isScenesPanelOpen = useSubscribe(selectIsScenesOpen());
    const isSourcesPanelOpen = useSubscribe(selectIsSourcesOpen());
    const isMixerPanelOpen = useSubscribe(selectIsMixerOpen());
    const activeSourceId = useSubscribe(selectStudioActiveSourceId());
    const scenes = useSubscribe(selectStudioScenes());
    const sources = useSubscribe(selectStudioActiveSources());
    const source = activeSourceId && sources && sources[activeSourceId];
    const isObjectPropertiesOpen = useSubscribe(selectIsObjectPropertiesOpen());
    const canvasRatio = StudioMerger.instance?.canvas?.width / StudioMerger?.instance?.canvas.height || 1.77777;
    const screenRef = useRef(null);
    const canvasContainerRef = useRef(null);
    const [screenSize, setScreenSize] = useState({
        width: 0,
        height: 0,
    });
    const handleScreenSizeChanged = useCallback((entries) => {
        if (entries.length) {
            const { contentRect: { width, height }, } = entries[0];
            debounce(() => {
                setScreenSize(width / canvasRatio > height
                    ? { width: height * canvasRatio, height }
                    : { width: width, height: width / canvasRatio });
            }, 50)();
        }
    }, [canvasRatio]);
    useEffect(() => {
        const observer = new ResizeObserver(handleScreenSizeChanged);
        if (screenRef?.current) {
            observer.observe(screenRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [screenRef, handleScreenSizeChanged, scenes]);
    useEffect(() => {
        const screen = canvasContainerRef.current;
        if (screen) {
            const canvas = screen.querySelector("canvas");
            if (!canvas)
                screen.append(StudioMerger.instance.canvas);
        }
    }, [canvasContainerRef, scenes]);
    useEffect(() => {
        if (!isScenesPanelOpen && !isSourcesPanelOpen && !isMixerPanelOpen) {
            setScreenFullscreen(true);
        }
    }, [isMixerPanelOpen, isScenesPanelOpen, isSourcesPanelOpen]);
    return (_jsxs("section", { className: cn(""), children: [_jsx("div", { className: cn("actions"), children: activeSourceId && source && (_jsx(PanelController, { direction: "column", leftActions: {
                        objectPropertiesToggle() {
                            setObjectPropertiesOpen(!isObjectPropertiesOpen);
                        },
                        separator: true,
                        duplicate() {
                            duplicateStudioSource(source.id);
                        },
                        lockSourceToggle: {
                            toggled: source.lock,
                            sourceType: SourceType.VISUAL,
                            action: locked => {
                                setSourceLock(activeSourceId, locked);
                            },
                        },
                        hideSourceToggle: {
                            toggled: source.hidden,
                            sourceType: SourceType.VISUAL,
                            action: hidden => {
                                setSourceHidden(activeSourceId, hidden);
                            },
                        },
                        delete() {
                            deleteStudioSource(activeSourceId);
                        },
                    } })) }), _jsx("div", { ref: screenRef, className: cn("container"), children: _jsx("div", { ref: canvasContainerRef, id: "studioScreen", className: cn("canvas"), style: { width: screenSize.width, height: screenSize.height }, children: _jsx("div", { className: cn("frame-wrapper"), children: _jsx(SourceFrameList, {}) }) }) }), _jsx("div", { className: cn("actions"), children: _jsx(PanelController, { direction: "column", leftActions: {
                        fullscreenToggle: {
                            toggled: !isScenesPanelOpen && !isSourcesPanelOpen && !isMixerPanelOpen,
                            action: () => {
                                setScreenFullscreen(!screenFullscreen);
                                setScenesOpen(screenFullscreen);
                                setSourcesOpen(screenFullscreen);
                                setMixerOpen(screenFullscreen);
                            },
                        },
                    }, rightActions: {
                        redo: () => {
                            // console.log("redo");
                        },
                        undo: () => {
                            // console.log("undo");
                        },
                    } }) })] }));
};
