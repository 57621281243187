import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button, Text, useSnackBar } from "@ting/ting-ui-components";
import { AddOutlinedIcon, CloseCircleOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import configs from "@src/configs";
import "./streamThumbnail.scss";
const cn = classNameFactory("field-thumbnail");
export const StreamThumbnail = ({ secondaryThumbnailFile, onChange }) => {
    const showSnackBar = useSnackBar();
    const { t } = useTranslation("studio");
    const [selectedFileForUpload, setSelectedFileForUpload] = useState(secondaryThumbnailFile);
    const { getRootProps, getInputProps } = useDropzone({
        accept: { "image/jpeg": [], "image/png": [] },
        maxSize: configs.images.maxUploadStreamThumbSize,
        onDropAccepted: (files) => {
            setSelectedFileForUpload(files[0]);
            onChange(files[0]);
        },
        onDropRejected: files => {
            showSnackBar({ message: files[0].errors[0].message });
        },
    });
    const handleClickRemoveSelectedFile = () => {
        setSelectedFileForUpload(null);
        onChange(null);
    };
    return (_jsx("div", { className: cn("input-group"), children: _jsxs("div", { className: cn(""), children: [_jsx("div", { children: _jsx(Text, { className: cn("label"), size: "body-small", type: "main", fontWeight: "bold", children: t("stream.setThumbnail") }) }), _jsx("div", { children: _jsxs(Text, { className: cn("caption"), size: "small", type: "sub", children: ["You can change your thumbnail once the stream is over", t("stream.setThumbnailCaption")] }) }), _jsx("div", { className: cn("selector"), children: selectedFileForUpload ? (_jsx("button", { type: "button", className: cn("selected"), onClick: () => {
                            onChange(selectedFileForUpload);
                        }, children: _jsx("img", { src: URL.createObjectURL(secondaryThumbnailFile || selectedFileForUpload), alt: "thumbnail" }) })) : (_jsxs("div", { ...getRootProps(), children: [_jsx("input", { ...getInputProps() }), _jsxs("button", { type: "button", className: cn("dd-box"), onClick: () => {
                                    onChange(selectedFileForUpload);
                                }, children: [_jsx(AddOutlinedIcon, { width: 20, height: 20 }), _jsx(Text, { size: "body-small", type: "main", fontWeight: "bold", children: t("stream.addThumbnail") })] })] })) }), _jsx("div", { className: cn("actions"), children: selectedFileForUpload && (_jsx(Button, { v2: true, size: "small", type: "neutral", variant: "ghost", className: cn("submit"), icon: _jsx(CloseCircleOutlinedIcon, { width: 20, height: 20 }), onClick: handleClickRemoveSelectedFile, children: t("stream.removeThumbnail") })) })] }) }));
};
