import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Badge, Button, Text } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import "./listRow.scss";
const cn = classNameFactory("list-row");
export const ListRow = props => {
    const { onClick, icon: Icon, title, postElement, indicator } = props;
    return (_jsx(Fragment, { children: _jsxs("div", { className: cn(""), onClick: onClick, children: [_jsx(Button, { v2: true, icon: _jsx(Icon, { width: "24px", height: "24px" }), round: true, type: "neutral", size: "medium" }), _jsx(Text, { size: "p2", fontWeight: "medium", className: cn("title"), children: title }), indicator && _jsx(Badge, { badgeContent: indicator, color: "grey", className: cn("indicator") }), _jsx("div", { className: cn("post-elem"), children: postElement })] }) }));
};
