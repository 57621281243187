import { useState, useEffect } from "react";
export function useDebounceValue(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        const callNow = !timerId;
        if (callNow) {
            setDebouncedValue(value);
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [value, delay]);
    return debouncedValue;
}
