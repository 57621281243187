import { jsx as _jsx } from "react/jsx-runtime";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomPeers, selectRoomSelfPeer } from "@src/selectors";
import { PeerAudio } from "@src/components/PeerAudio";
export const PeerAudioList = () => {
    const peers = useSubscribe(selectRoomPeers());
    const selfPeer = useSubscribe(selectRoomSelfPeer());
    if (peers && Object.values(peers).length) {
        return Object.values(peers).map(peer => {
            if (peer.peerId !== selfPeer.peerId) {
                return _jsx(PeerAudio, { peerId: peer.peerId }, peer.peerId);
            }
            return null;
        });
    }
    return null;
};
