import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import logger from "loglevel";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { FormTextField, Button } from "@ting/ting-ui-components";
import * as Yup from "yup";
import { RoomInfoBox } from "./RoomInfoBox";
import { useDisplayNameOptions, DisplayNameSelect } from "@src/components/DisplayName";
import { classNameFactory } from "@src/utils/dom";
import { Header, HeaderSizes } from "@src/components/Header";
import { useBuildMobile } from "@src/hooks/breakpoints";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectMeetingUser, selectRoom, selectRoomTitle, selectRoomUISettings, selectSelfName, selectUser, } from "@src/selectors";
import { ConnectionStatus } from "@src/services/PeerCommunicator/types";
import { RoomStatus, RoomType } from "@src/domain/Room";
import { fetchRoomInfo, joinRoom, startRoom } from "@src/controller";
import { routePaths } from "@src/routes/routes";
import { fetchCompactRoomInfo } from "@src/controller/meeting/lobby";
import configs from "@src/configs";
const cn = classNameFactory("lobby-info");
const schema = Yup.object().shape({
    displayName: Yup.string().required(),
});
export const LobbyInfo = () => {
    const { t } = useTranslation(["room", "common"]);
    const history = useHistory();
    const isMobile = useBuildMobile();
    const room = useSubscribe(selectRoom(), true);
    const user = useSubscribe(selectUser());
    const meetName = useSubscribe(selectRoomTitle());
    const selfName = selectSelfName().peek();
    const { roomId } = useParams();
    const p2pConnectionStatus = useSubscribe(selectRoomUISettings().p2pConnectionStatus);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [displayNameValues, setVerifiedName] = useDisplayNameOptions();
    const isJoinMode = room && room.status === RoomStatus.joining;
    const isSchedule = room.type === RoomType.public;
    const isLoggedIn = !!user;
    const [isLoadingRoomInfo, setIsLoadingRoomInfo] = useState(true);
    const [compactRoomInfo, setCompactRoomInfo] = useState();
    useEffect(() => {
        if (!isJoinMode)
            return;
        let intervalId;
        let isStale = false;
        async function fetch() {
            if (room?.slug) {
                const roomRes = await fetchRoomInfo(room?.slug);
                if (isStale)
                    return;
                if (roomRes.publicId) {
                    const compactRoomInfo = await fetchCompactRoomInfo(roomRes.publicId);
                    if (isStale)
                        return;
                    setCompactRoomInfo(compactRoomInfo);
                }
                else if (!roomRes.publicId) {
                    setCompactRoomInfo(null);
                }
                setIsLoadingRoomInfo(false);
            }
            intervalId = setTimeout(fetch, configs.app.scheduleLobbyPollingInterval);
        }
        fetch();
        return () => {
            isStale = true;
            clearTimeout(intervalId);
        };
    }, [room?.slug, isJoinMode]);
    const startRoomHandler = async (displayName, meetingName) => {
        await startRoom(displayName, meetingName);
        history.push(routePaths.ROOM(roomId));
    };
    const joinRoomHandler = async (displayName) => {
        await joinRoom(displayName);
        history.push(routePaths.ROOM(roomId));
    };
    const onFormSubmit = async (formValues) => {
        try {
            setSubmitLoading(true);
            const displayName = isLoggedIn
                ? displayNameValues[formValues.displayName]
                : formValues.displayName;
            if (isJoinMode)
                await joinRoomHandler(displayName);
            else
                await startRoomHandler(displayName, formValues.meetingName);
        }
        catch (e) {
            logger.error(e);
        }
        finally {
            setSubmitLoading(false);
        }
    };
    const initName = isLoggedIn
        ? (Object.entries(displayNameValues).find(v => v[1] === selfName)?.[0] ??
            "tingName")
        : "";
    const initialValues = {
        meetingName: meetName,
        displayName: initName,
    };
    const submitBtnLoading = p2pConnectionStatus !== ConnectionStatus.CONNECTED || submitLoading;
    return (_jsxs("div", { className: cn(""), children: [!isMobile && (_jsx(Header, { size: HeaderSizes.big, className: cn("big-screen-header", { schedule: isJoinMode }), children: isJoinMode ? t("room:join-a-meeting") : t("common:instant-meeting") })), isJoinMode && _jsx(RoomInfoBox, { compactRoomInfo: compactRoomInfo, isLoading: isLoadingRoomInfo }), _jsx(Formik, { initialValues: initialValues, onSubmit: onFormSubmit, validationSchema: schema, children: ({ handleSubmit, setFieldValue, values }) => (_jsxs(_Fragment, { children: [_jsx(FormTextField, { v2: true, name: "meetingName", label: t("room:meeting-name"), maxLength: 100, size: "large", className: cn("input"), disabled: isJoinMode }), isLoggedIn ? (_jsx(DisplayNameSelect, { value: values.displayName, options: displayNameValues, name: "displayName", onChange: value => setFieldValue("displayName", value), className: cn("input"), onChangeVerifiedName: setVerifiedName })) : (_jsx(FormTextField, { v2: true, value: values.displayName, name: "displayName", label: t("display-name"), placeholder: t("display-name"), size: "large", className: cn("input"), onChange: e => {
                                // TODO: add appropriate service for setting this
                                selectMeetingUser().displayName.set(e.target.value);
                                setFieldValue("displayName", e.target.value);
                            }, stateText: "This is a required field." })), _jsx(Button, { className: cn("submit"), v2: true, size: "big", type: "brand", isLoading: submitBtnLoading, disabled: isJoinMode && !compactRoomInfo, onClick: () => {
                                handleSubmit();
                            }, children: t(getSubmitButtonText(isJoinMode, isSchedule)) })] })) })] }));
};
function getSubmitButtonText(isJoinMode, isSchedule) {
    if (isJoinMode && isSchedule) {
        return "room:join-schedule-meeting";
    }
    else if (isJoinMode && !isSchedule) {
        return "room:join-instant-meeting";
    }
    else if (!isJoinMode && isSchedule) {
        return "room:start-schedule-meeting";
    }
    else if (!isJoinMode && !isSchedule) {
        return "room:start-instant-meeting";
    }
}
