import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { AccountOutlinedIcon, BlockOutlinedIcon, LoadingOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useState } from "react";
import { CameraFrame } from "../CameraFrame";
import { classNameFactory } from "@src/utils/dom";
import { ToggleWithContent } from "@src/components/ToggleWithContent";
import { selectIsBlurBackground, selectIsCamOn } from "@src/selectors";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { toggleBlurBackground } from "@src/controller/userMedia";
import "./EffectsController.scss";
const cn = classNameFactory("effects-controller");
const BlurIcon = () => (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "4", height: "4", viewBox: "0 0 4 4", fill: "none", children: _jsx("circle", { cx: "2", cy: "2", r: "2", fill: "currentColor" }) }));
export const EffectsController = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation(["common", "room"]);
    const isBlurBackground = useSubscribe(selectIsBlurBackground());
    const isCamOn = useSubscribe(selectIsCamOn());
    const handleBlur = async (isBlur) => {
        setIsLoading(true);
        await toggleBlurBackground(isBlur);
        setIsLoading(false);
    };
    const disabled = !isCamOn || isLoading;
    const handleOffBlur = !disabled ? () => handleBlur(false) : undefined;
    const handleOnBlur = !disabled ? () => handleBlur(true) : undefined;
    return (_jsxs("div", { className: cn(""), children: [_jsx("div", { className: cn("camera-frame"), children: _jsx(CameraFrame, {}) }), _jsxs("div", { className: cn("section"), children: [_jsx(Text, { size: "h4", fontWeight: "semiBold", children: t("effects") }), _jsx(ToggleWithContent, { label: t("room:flip-camera-view") })] }), _jsxs("div", { className: cn("section"), children: [_jsx(Text, { size: "h4", fontWeight: "semiBold", children: t("room:background-effects") }), _jsxs("div", { children: [_jsxs("div", { className: cn("blur-header"), children: [_jsx(Text, { size: "p2", children: t("room:blur-background") }), isLoading && _jsx(LoadingOutlinedIcon, { width: 20, height: 20 })] }), _jsxs("div", { className: cn("blur-wrapper"), children: [_jsx("div", { role: "button", "aria-label": "Disable blur background", tabIndex: 0, className: cn("blur-item", { active: !isBlurBackground }), "aria-disabled": disabled, onClick: handleOffBlur, onKeyDown: handleOffBlur, children: _jsx(BlockOutlinedIcon, { width: 24, height: 24 }) }), _jsxs("div", { role: "button", "aria-label": "Enable blur background", tabIndex: 0, className: cn("blur-item", {
                                            active: isBlurBackground,
                                        }), "aria-disabled": disabled, onClick: handleOnBlur, onKeyDown: handleOnBlur, children: [_jsx("span", { className: cn("blur-icon"), children: [...Array(12)].map((_, index) => (_jsx(BlurIcon, {}, index))) }), _jsx(AccountOutlinedIcon, { width: 24, height: 24 })] })] })] })] })] }));
};
