import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { ThreeDotsIcon } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { classNameFactory } from "@src/utils/dom";
import { SourceIcon } from "@src/components/Studio/Sources/components/SourceIcon";
import { SourceType } from "@src/domain/Studio/types";
import { DropDown } from "@src/components/DropDown";
import { activateSource, deleteStudioSource, duplicateStudioSource, renameStudioSource, setObjectPropertiesOpen, setSourceHidden, setSourceLock, } from "@src/controller";
import { RenameModal } from "@src/components/Studio/RenameModal/RenameModal";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectIsObjectPropertiesOpen } from "@src/selectors/studio";
import "./source.scss";
const cn = classNameFactory("source");
export const Source = ({ source }) => {
    const { t } = useTranslation(["common", "studio"]);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isObjectPropertiesOpen = useSubscribe(selectIsObjectPropertiesOpen());
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: cn({ active: source.active, hidden: source.hidden, open: isMenuOpen }), children: [_jsxs("button", { className: cn("handler"), title: source.sourceName, onClick: () => {
                            activateSource(source.id);
                        }, children: [_jsx(SourceIcon, { kind: source.sourceKind, width: 20, height: 20 }), _jsx("span", { className: cn("name"), children: source.sourceName })] }), _jsx("div", { className: cn("actions"), children: _jsx(DropDown, { direction: "bottom-left", toggleIcon: _jsx(ThreeDotsIcon, { height: "18px", width: "18px" }), onOpen: setIsMenuOpen, items: [
                                {
                                    label: t("studio:objectProperties"),
                                    onClick: () => {
                                        setObjectPropertiesOpen(!isObjectPropertiesOpen);
                                    },
                                },
                                {
                                    label: t("studio:rename"),
                                    onClick: () => {
                                        setIsRenameModalOpen(true);
                                    },
                                },
                                {
                                    label: t("studio:duplicate"),
                                    divider: true,
                                    onClick: () => {
                                        duplicateStudioSource(source.id);
                                    },
                                },
                                ...(source.sourceType === SourceType.VISUAL
                                    ? [
                                        {
                                            label: t("studio:hide"),
                                            onClick: () => {
                                                setSourceHidden(source.id, !source.hidden);
                                            },
                                        },
                                        {
                                            label: t("studio:lock"),
                                            onClick: () => {
                                                setSourceLock(source.id, !source.lock);
                                            },
                                        },
                                    ]
                                    : []),
                                {
                                    label: t("delete"),
                                    type: "danger",
                                    divider: true,
                                    onClick: () => {
                                        deleteStudioSource(source.id);
                                    },
                                },
                            ] }) })] }), _jsx(RenameModal, { name: source.sourceLabel, rename: "source", isOpen: isRenameModalOpen, onRename: name => {
                    renameStudioSource(source.id, name);
                    setIsRenameModalOpen(false);
                }, onClose: () => setIsRenameModalOpen(false) })] }));
};
