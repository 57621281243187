import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Tab, Tabs } from "@ting/ting-ui-components";
import { ChatBody } from "../ChatBody";
import { ChatFooter } from "../ChatFooter";
import { Header, HeaderSizes } from "@src/components/Header";
import { ModalMeetHeader } from "@src/components/ModalMeetHeader";
import { classNameFactory } from "@src/utils/dom";
import "./chatModal.scss";
const cn = classNameFactory("chat-modal");
export const ChatModal = props => {
    const { isOpen, onClose } = props;
    const { t } = useTranslation(["common", "room"]);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, fullscreen: true, hideCloseButton: true, containerClassName: cn(""), children: [_jsx(ModalMeetHeader, {}), _jsx(Header, { onClose: onClose, filled: true, marginSize: HeaderSizes.small, children: t("chat") }), _jsx(Tabs, { value: activeTabIndex, onChange: idx => setActiveTabIndex(idx), variant: "secondary", children: _jsx(Tab, { children: t("room:meeting-chat") }) }), activeTabIndex === 0 && (_jsxs(Fragment, { children: [_jsx(ChatBody, {}), _jsx(ChatFooter, { options: false })] }))] }));
};
