import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, SimpleArrowLeftIcon } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import "./dropDown.scss";
const cn = classNameFactory("dropdown");
export const DropDown = ({ toggleIcon, direction, onOpen, items }) => {
    const { t } = useTranslation("common");
    const [isOpen, setIsOpen] = useState(false);
    const [subItems, setSubItems] = useState(null);
    const [subMenuClass, setSubMenuClass] = useState(undefined);
    const [subItemClass, setSubItemClass] = useState(undefined);
    const mainMenu = useCallback(() => {
        return items.map((item) => (_jsx("li", { className: cn("li", { divider: item.divider }), children: _jsx(Button, { v2: true, size: "small", type: item.type ? item.type : "neutral", variant: "ghost", onClick: (event) => {
                    if (item.submenu) {
                        setSubItems(item.submenu);
                        setSubMenuClass(item.subMenuClass);
                        setSubItemClass(item.subItemClass);
                    }
                    else if (item.onClick)
                        item.onClick(event);
                }, children: _jsxs("div", { className: cn("item-content"), children: [item.startIcon, _jsx("span", { children: item.label }), item.endIcon] }) }) }, item.label)));
    }, [items]);
    const subMenu = useCallback(() => {
        return (_jsxs(_Fragment, { children: [_jsx("li", { className: cn("back"), children: _jsx(Button, { v2: true, size: "small", type: "neutral", variant: "ghost", className: cn("back-action"), onClick: () => {
                            setIsOpen(true);
                            setSubItems(null);
                            setSubMenuClass(undefined);
                            setSubItemClass(undefined);
                        }, children: _jsxs("div", { className: cn("item-content"), children: [_jsx(SimpleArrowLeftIcon, { height: "24px", width: "24px" }), _jsx("span", { children: t("back") })] }) }) }), subItems?.map((item) => {
                    return (_jsx("li", { className: cn(`subitem ${subItemClass || ""}`), children: _jsx(Button, { v2: true, size: "small", type: item.type ? item.type : "neutral", variant: "ghost", onClick: (event) => {
                                if (item.onClick)
                                    item.onClick(event);
                            }, children: _jsx("div", { className: cn("item-content"), children: item.content }) }) }, item.id));
                })] }));
    }, [subItems]);
    useEffect(() => {
        const closeDropDown = (e) => {
            if (!e.target.closest(`[class^=${cn("")}]`)) {
                setIsOpen(false);
                if (onOpen)
                    onOpen(false);
            }
        };
        window.addEventListener("click", closeDropDown);
        return () => {
            window.removeEventListener("click", closeDropDown);
        };
    }, []);
    return (_jsxs("div", { className: cn(""), children: [_jsx(Button, { v2: true, size: "small", type: "neutral", variant: "ghost", icon: toggleIcon, className: cn("toggler"), onClick: () => {
                    setIsOpen(!isOpen);
                    onOpen(!isOpen);
                } }), _jsx("div", { className: cn("content", { [direction]: true, open: isOpen }), children: _jsx("ul", { className: subMenuClass, children: subItems ? subMenu() : mainMenu() }) })] }));
};
DropDown.defaultProps = {
    direction: "bottom-right",
};
