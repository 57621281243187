var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { injectable } from "inversify";
import { MessageType } from "@ting/ting-ui-components";
import { AudioType, playAudioNotification } from "../AudioNotifier";
import { DCMessageType } from "@src/domain/RTCConnection";
import { addChatMessage, addUnreadMessage } from "@src/controller";
import { addPollAnswer, setActivePoll } from "@src/controller/poll";
import { debounce } from "@src/utils/helpers/debounce";
let ChatService = class ChatService {
    constructor() {
        Object.defineProperty(this, "_soundMaker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._soundMaker = debounce(this.makeMessageSound, 4000, true);
    }
    makeMessageSound() {
        playAudioNotification(AudioType.Message);
    }
    onMessage(message) {
        switch (message.type) {
            case DCMessageType.CHAT:
                addChatMessage(message.payload);
                if (message.payload.type === MessageType.CHAT) {
                    addUnreadMessage(message.payload);
                    this._soundMaker();
                }
                break;
            case DCMessageType.POLL_QUESTION:
                setActivePoll(message.payload);
                this._soundMaker();
                break;
            case DCMessageType.POLL_ANSWER:
                addPollAnswer(message.payload);
                break;
            case DCMessageType.FILE:
                addChatMessage(message.payload);
                break;
        }
    }
};
ChatService = __decorate([
    injectable(),
    __metadata("design:paramtypes", [])
], ChatService);
export { ChatService };
