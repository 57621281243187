import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useRef, useState, useCallback } from "react";
import { ToasterPortal } from "./ToasterPortal";
export const ToasterContext = createContext(() => ({}));
export const ToasterProvider = ({ children, position = "top-center" }) => {
    const lastIndexRef = useRef(0);
    const [toasts, setToasts] = useState([]);
    const showToaster = useCallback(({ type = "neutral", size = "medium", icon, message, duration = 3000, className }) => {
        lastIndexRef.current += 1;
        const id = lastIndexRef.current;
        const close = () => setToasts(nsBars => nsBars.filter(toastBar => toastBar.id !== id));
        if (duration) {
            setTimeout(close, duration);
        }
        setToasts(snBars => [
            ...snBars,
            {
                id,
                type,
                size,
                message,
                className,
                icon,
                close,
                onClose: close,
            },
        ]);
    }, []);
    return (_jsxs(ToasterContext.Provider, { value: showToaster, children: [children, _jsx(ToasterPortal, { toasts: toasts, position: position })] }));
};
