import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SettingsOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useState } from "react";
import { Button, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { ControllerButtonSizes } from "../types";
import { classNameFactory } from "@src/utils/dom";
import { AudioVideoSettings } from "@src/components/AudioVideoSettings";
import "./settingsController.scss";
const cn = classNameFactory("settings-controller");
export const SettingsController = ({ withText = false, size = ControllerButtonSizes.medium, withBg = false, }) => {
    const { t } = useTranslation(["common"]);
    const [openSettingModal, setOpenSettingModal] = useState(false);
    return (_jsxs("div", { className: cn(""), children: [_jsx(Button, { v2: true, size: size, type: "neutral-inverted", variant: withBg ? "primary" : "ghost", startIcon: _jsx(SettingsOutlinedIcon, { width: 24, height: 24 }), round: true, onClick: () => setOpenSettingModal(true) }), withText && (_jsx(Text, { size: "body-medium", fontWeight: "regular", children: t("settings") })), _jsx(AudioVideoSettings, { open: openSettingModal, onClose: () => setOpenSettingModal(false) })] }));
};
