var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from "inversify";
import { format } from "date-fns";
import { DaysOfWeek, Recurrences } from "./enums";
let CalendarUtils = class CalendarUtils {
    createCalendarEvent(event) {
        const description = this.createCalendarDescription(event.link);
        const startDateTimeISO = this.buildDateTime(event.startDate, event.startTime).toISOString();
        const endDateTimeISO = this.buildDateTime(event.endDate, event.endTime).toISOString();
        const timeZone = event.timeZone;
        let recurrence = undefined;
        if (event.recurrence !== Recurrences.none) {
            recurrence = [
                this.createRecurrenceRule({
                    monthlyOccursOn: event.monthlyOccursOn,
                    recurrence: event.recurrence,
                    recurrenceEndDate: event.recurrenceEndDate,
                    weeklyOccursOn: event.weeklyOccursOn,
                }),
            ];
        }
        return {
            summary: event.title,
            location: event.link,
            description,
            attendees: event.attendees,
            start: {
                dateTime: startDateTimeISO,
                timeZone,
            },
            end: {
                dateTime: endDateTimeISO,
                timeZone,
            },
            recurrence,
        };
    }
    createCalendarDescription(link) {
        return `You have been invited to a scheduled meeting
          <br>
          <br>
          Meeting Link: 
          <a href=${link}>${link}<a>`;
    }
    buildDateTime(date, time) {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const hours = time.getHours();
        const minutes = time.getMinutes();
        const seconds = time.getSeconds();
        return new Date(year, month, day, hours, minutes, seconds);
    }
    createRecurrenceRule({ recurrence, recurrenceEndDate, weeklyOccursOn, monthlyOccursOn }) {
        let freq;
        let byDay;
        let byMonthDay;
        switch (recurrence) {
            case Recurrences.daily:
                freq = "DAILY";
                break;
            case Recurrences.weekly:
                freq = "WEEKLY";
                byDay = weeklyOccursOn.map(day => this.convertDay(day)).join(",");
                break;
            case Recurrences.monthly:
                freq = "MONTHLY";
                byMonthDay = monthlyOccursOn;
                break;
            default:
                throw new Error("unexpected recurrence");
        }
        const endDate = format(recurrenceEndDate, "yyyyMMdd");
        let rule = `RRULE:FREQ=${freq};INTERVAL=1;UNTIL=${endDate}`;
        if (byDay)
            rule += `;BYDAY=${byDay}`;
        if (byMonthDay)
            rule += `;BYMONTHDAY=${byMonthDay}`;
        return rule;
    }
    convertDay(day) {
        switch (day) {
            case DaysOfWeek.Fri:
                return "FR";
            case DaysOfWeek.Mon:
                return "MO";
            case DaysOfWeek.Sat:
                return "SA";
            case DaysOfWeek.Sun:
                return "SU";
            case DaysOfWeek.Thu:
                return "TH";
            case DaysOfWeek.Tue:
                return "TU";
            case DaysOfWeek.Wed:
                return "WE";
            default:
                throw new Error("invalid Input");
        }
    }
};
CalendarUtils = __decorate([
    injectable()
], CalendarUtils);
export { CalendarUtils };
