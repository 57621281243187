import { useEffect, useState } from "react";
import { getStreamInputDataApi } from "@src/controller";
export const useGetStreamInputData = (input) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        const controller = new AbortController();
        const fetch = async () => {
            setLoading(true);
            getStreamInputDataApi(input, controller.signal)
                .then(data => {
                setData(data);
                setLoading(false);
            })
                .catch(error => {
                setError(error?.message || true);
                setLoading(false);
            });
        };
        if (!loading && !data)
            void fetch();
    }, [data, input, loading]);
    return { data, error };
};
