import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
import { Any } from "./google/protobuf/any";
import { Status } from "./status";
// @generated message type with reflection information, may provide speed optimized methods
class ApiResponse$Type extends MessageType {
    constructor() {
        super("ApiResponse", [
            { no: 1, name: "status", kind: "message", T: () => Status },
            { no: 2, name: "payload", kind: "message", T: () => Any },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Status status */ 1:
                    message.status = Status.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* google.protobuf.Any payload */ 2:
                    message.payload = Any.internalBinaryRead(reader, reader.uint32(), options, message.payload);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* Status status = 1; */
        if (message.status)
            Status.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Any payload = 2; */
        if (message.payload)
            Any.internalBinaryWrite(message.payload, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApiResponse
 */
export const ApiResponse = new ApiResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RTCEventRequest$Type extends MessageType {
    constructor() {
        super("RTCEventRequest", [{ no: 1, name: "payload", kind: "scalar", T: 9 /*ScalarType.STRING*/ }]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.payload = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string payload */ 1:
                    message.payload = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string payload = 1; */
        if (message.payload !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.payload);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RTCEventRequest
 */
export const RTCEventRequest = new RTCEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RTCEventResponse$Type extends MessageType {
    constructor() {
        super("RTCEventResponse", [{ no: 1, name: "isFine", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.isFine = false;
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isFine */ 1:
                    message.isFine = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bool isFine = 1; */
        if (message.isFine !== false)
            writer.tag(1, WireType.Varint).bool(message.isFine);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RTCEventResponse
 */
export const RTCEventResponse = new RTCEventResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinRoomRequest$Type extends MessageType {
    constructor() {
        super("JoinRoomRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "corepassId", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "avatarURL", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "peerStreamSettings", kind: "message", T: () => PeerStreamSettings },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* optional string corepassId */ 2:
                    message.corepassId = reader.string();
                    break;
                case /* optional string avatarURL */ 3:
                    message.avatarURL = reader.string();
                    break;
                case /* PeerStreamSettings peerStreamSettings */ 4:
                    message.peerStreamSettings = PeerStreamSettings.internalBinaryRead(reader, reader.uint32(), options, message.peerStreamSettings);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* optional string corepassId = 2; */
        if (message.corepassId !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.corepassId);
        /* optional string avatarURL = 3; */
        if (message.avatarURL !== undefined)
            writer.tag(3, WireType.LengthDelimited).string(message.avatarURL);
        /* PeerStreamSettings peerStreamSettings = 4; */
        if (message.peerStreamSettings)
            PeerStreamSettings.internalBinaryWrite(message.peerStreamSettings, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JoinRoomRequest
 */
export const JoinRoomRequest = new JoinRoomRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PeerStreamSettings$Type extends MessageType {
    constructor() {
        super("PeerStreamSettings", [
            { no: 1, name: "isCamOn", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "isMicOn", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "isScreenOn", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.isCamOn = false;
        message.isMicOn = false;
        message.isScreenOn = false;
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isCamOn */ 1:
                    message.isCamOn = reader.bool();
                    break;
                case /* bool isMicOn */ 2:
                    message.isMicOn = reader.bool();
                    break;
                case /* bool isScreenOn */ 3:
                    message.isScreenOn = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bool isCamOn = 1; */
        if (message.isCamOn !== false)
            writer.tag(1, WireType.Varint).bool(message.isCamOn);
        /* bool isMicOn = 2; */
        if (message.isMicOn !== false)
            writer.tag(2, WireType.Varint).bool(message.isMicOn);
        /* bool isScreenOn = 3; */
        if (message.isScreenOn !== false)
            writer.tag(3, WireType.Varint).bool(message.isScreenOn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PeerStreamSettings
 */
export const PeerStreamSettings = new PeerStreamSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Peer$Type extends MessageType {
    constructor() {
        super("Peer", [
            { no: 1, name: "peerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "corepassId", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "avatarURL", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "peerStreamSettings", kind: "message", T: () => PeerStreamSettings },
            { no: 7, name: "raisedHandAt", kind: "scalar", opt: true, T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.peerId = "";
        message.name = "";
        message.role = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string peerId */ 1:
                    message.peerId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string role */ 3:
                    message.role = reader.string();
                    break;
                case /* optional string corepassId */ 4:
                    message.corepassId = reader.string();
                    break;
                case /* optional string avatarURL */ 5:
                    message.avatarURL = reader.string();
                    break;
                case /* PeerStreamSettings peerStreamSettings */ 6:
                    message.peerStreamSettings = PeerStreamSettings.internalBinaryRead(reader, reader.uint32(), options, message.peerStreamSettings);
                    break;
                case /* optional int64 raisedHandAt */ 7:
                    message.raisedHandAt = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string peerId = 1; */
        if (message.peerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.peerId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string role = 3; */
        if (message.role !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.role);
        /* optional string corepassId = 4; */
        if (message.corepassId !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.corepassId);
        /* optional string avatarURL = 5; */
        if (message.avatarURL !== undefined)
            writer.tag(5, WireType.LengthDelimited).string(message.avatarURL);
        /* PeerStreamSettings peerStreamSettings = 6; */
        if (message.peerStreamSettings)
            PeerStreamSettings.internalBinaryWrite(message.peerStreamSettings, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* optional int64 raisedHandAt = 7; */
        if (message.raisedHandAt !== undefined)
            writer.tag(7, WireType.Varint).int64(message.raisedHandAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Peer
 */
export const Peer = new Peer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinRoomResponse$Type extends MessageType {
    constructor() {
        super("JoinRoomResponse", [
            { no: 1, name: "peers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Peer },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "mode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "createdAt", kind: "message", T: () => Timestamp },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.peers = [];
        message.title = "";
        message.mode = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Peer peers */ 1:
                    message.peers.push(Peer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string mode */ 3:
                    message.mode = reader.string();
                    break;
                case /* google.protobuf.Timestamp createdAt */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Peer peers = 1; */
        for (let i = 0; i < message.peers.length; i++)
            Peer.internalBinaryWrite(message.peers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string mode = 3; */
        if (message.mode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.mode);
        /* google.protobuf.Timestamp createdAt = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message JoinRoomResponse
 */
export const JoinRoomResponse = new JoinRoomResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompactRoomInfoRequest$Type extends MessageType {
    constructor() {
        super("CompactRoomInfoRequest", [{ no: 1, name: "corepassId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.corepassId = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string corepassId */ 1:
                    message.corepassId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string corepassId = 1; */
        if (message.corepassId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.corepassId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompactRoomInfoRequest
 */
export const CompactRoomInfoRequest = new CompactRoomInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompactPeer$Type extends MessageType {
    constructor() {
        super("CompactPeer", [
            { no: 1, name: "peerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "corepassId", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "avatarURL", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.peerId = "";
        message.name = "";
        message.role = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string peerId */ 1:
                    message.peerId = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string role */ 3:
                    message.role = reader.string();
                    break;
                case /* optional string corepassId */ 4:
                    message.corepassId = reader.string();
                    break;
                case /* optional string avatarURL */ 5:
                    message.avatarURL = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string peerId = 1; */
        if (message.peerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.peerId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string role = 3; */
        if (message.role !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.role);
        /* optional string corepassId = 4; */
        if (message.corepassId !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.corepassId);
        /* optional string avatarURL = 5; */
        if (message.avatarURL !== undefined)
            writer.tag(5, WireType.LengthDelimited).string(message.avatarURL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompactPeer
 */
export const CompactPeer = new CompactPeer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompactRoomInfoResponse$Type extends MessageType {
    constructor() {
        super("CompactRoomInfoResponse", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "mode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "compactPeers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompactPeer },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.title = "";
        message.mode = "";
        message.compactPeers = [];
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string mode */ 2:
                    message.mode = reader.string();
                    break;
                case /* repeated CompactPeer compactPeers */ 3:
                    message.compactPeers.push(CompactPeer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string mode = 2; */
        if (message.mode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.mode);
        /* repeated CompactPeer compactPeers = 3; */
        for (let i = 0; i < message.compactPeers.length; i++)
            CompactPeer.internalBinaryWrite(message.compactPeers[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CompactRoomInfoResponse
 */
export const CompactRoomInfoResponse = new CompactRoomInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeSupernodeRequest$Type extends MessageType {
    constructor() {
        super("ChangeSupernodeRequest", [{ no: 1, name: "peerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.peerId = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string peerId */ 1:
                    message.peerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string peerId = 1; */
        if (message.peerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.peerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChangeSupernodeRequest
 */
export const ChangeSupernodeRequest = new ChangeSupernodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeSupernodeResponse$Type extends MessageType {
    constructor() {
        super("ChangeSupernodeResponse", [{ no: 1, name: "isFine", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.isFine = false;
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isFine */ 1:
                    message.isFine = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bool isFine = 1; */
        if (message.isFine !== false)
            writer.tag(1, WireType.Varint).bool(message.isFine);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChangeSupernodeResponse
 */
export const ChangeSupernodeResponse = new ChangeSupernodeResponse$Type();
