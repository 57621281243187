var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject, injectable } from "inversify";
import { HttpClient } from "../HttpClient/HttpClient";
import configs from "@src/configs";
import { TYPES } from "@src/ioc.type";
let TingApiService = class TingApiService {
    constructor(authorizerService) {
        Object.defineProperty(this, "tingAxiosInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.tingAxiosInstance = new HttpClient(configs.app.tingAPI, authorizerService);
    }
    async getCategories({ cancel }) {
        return this.tingAxiosInstance.get(`/videos/categories`, { signal: cancel });
    }
    async getLanguages({ cancel }) {
        return this.tingAxiosInstance.get(`/videos/languages`, { signal: cancel });
    }
    async getPrivacies({ cancel }) {
        return this.tingAxiosInstance.get(`/videos/privacies`, { signal: cancel });
    }
    async getLicenses({ cancel }) {
        return this.tingAxiosInstance.get(`/videos/licences`, { signal: cancel });
    }
    async getVideoPlayLists({ cancel }) {
        return this.tingAxiosInstance.get(`/video-playlists`, { signal: cancel });
    }
    async getVideoChannels({ cancel, tingUser }) {
        return this.tingAxiosInstance.get(`/accounts/${tingUser.account.name}/video-channels?withStats=false&start=0&count=15`, {
            signal: cancel,
        });
    }
    async getVideoDetails({ cancel, uuid }) {
        return this.tingAxiosInstance.get(`/videos/${uuid}`, {
            signal: cancel,
        });
    }
    async uploadUserAvatar(avatarFile) {
        const formData = new FormData();
        formData.append("avatarfile", avatarFile);
        return this.tingAxiosInstance.post("/users/me/avatar/pick", formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    }
    async uploadChannelAvatar(channelHandle, avatarFile) {
        const formData = new FormData();
        formData.append("avatarfile", avatarFile);
        return this.tingAxiosInstance.post(`/video-channels/${channelHandle}/avatar/pick`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
    async signUp({ profileName, userName, termsChecked, channelName, channelHandle }) {
        return this.tingAxiosInstance.post("/users/register", {
            username: userName,
            displayName: profileName,
            terms: termsChecked,
            channel: {
                displayName: channelName,
                name: channelHandle,
            },
        });
    }
    async startStream({ title, channelId, category, privacy, thumbnailFile }) {
        const formData = new FormData();
        formData.append("name", title);
        formData.append("description", `Live Stream: ${title}`);
        formData.append("channelId", `${channelId?.value || ""}`);
        formData.append("category", `${category?.value || ""}`);
        formData.append("privacy", `${privacy?.value || 1}`);
        formData.append("thumbnailfile", thumbnailFile);
        return this.tingAxiosInstance.postForm(`/videos/new-livestream`, formData);
    }
    async stopStream(uuid) {
        return this.tingAxiosInstance.postForm(`/videos/newLive/finished?liveID=${uuid}`);
    }
    async sendStreamChunk(uuid, data) {
        return this.tingAxiosInstance.postForm(`/videos/newLive/chunks?liveID=${uuid}`, data);
    }
    async sendChatMessage(text, time, videoId) {
        return this.tingAxiosInstance.post(`/videos/newLive/chat`, { text, time, videoId });
    }
    async getOtherUserInfo(name) {
        return this.tingAxiosInstance.get(`/accounts/${name}`);
    }
    async getMe() {
        return this.tingAxiosInstance.get(`${configs.app.tingAPI}/users/me`);
    }
};
TingApiService = __decorate([
    injectable(),
    __param(0, inject(TYPES.Authorizer)),
    __metadata("design:paramtypes", [Object])
], TingApiService);
export { TingApiService };
