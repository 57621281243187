import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
let selfieSegmentation;
export class BlurFilter {
    constructor() {
        Object.defineProperty(this, "blurProcess", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 640
        });
        Object.defineProperty(this, "height", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 360
        });
    }
    setResolution(width, height) {
        this.width = width;
        this.height = height;
    }
    async apply(stream, isBlur) {
        const canvasElement = document.createElement("canvas");
        const videoElement = document.createElement("video");
        canvasElement.id = "canvas-blur";
        videoElement.id = "video-blur";
        videoElement.autoplay = true;
        videoElement.width = this.width;
        videoElement.height = this.height;
        canvasElement.width = this.width;
        canvasElement.height = this.height;
        const canvasCtx = canvasElement.getContext("2d");
        if (isBlur) {
            this.blurProcess = true;
            if (stream.getAudioTracks().length) {
                stream.removeTrack(stream.getAudioTracks()[0]);
            }
            videoElement.srcObject = stream;
            selfieSegmentation = new SelfieSegmentation({
                locateFile: file => {
                    return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1/${file}`;
                },
            });
            selfieSegmentation.setOptions({ modelSelection: 1 });
            selfieSegmentation.onResults((results) => {
                canvasCtx.save();
                canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
                canvasCtx.globalCompositeOperation = "destination-atop";
                canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);
                canvasCtx.filter = "blur(12px)";
                canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
                canvasCtx.restore();
            });
            await selfieSegmentation.initialize();
            const segmentationLoop = () => {
                if (this.blurProcess) {
                    selfieSegmentation.send({ image: videoElement });
                    const time = setTimeout(() => {
                        segmentationLoop();
                        clearTimeout(time);
                    }, 1000 / 60);
                }
            };
            segmentationLoop();
            const canvasCapture = canvasElement.captureStream(60);
            return canvasCapture;
        }
        else {
            canvasElement.remove();
            videoElement.srcObject = null;
            videoElement.remove();
            this.blurProcess = false;
            selfieSegmentation?.reset();
            selfieSegmentation?.close();
            return;
        }
    }
}
