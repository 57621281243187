import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Text, ThreeDotsIcon } from "@ting/ting-ui-components";
import { ChatBody } from "../ChatBody";
import { ChatFooter } from "../ChatFooter";
import { classNameFactory } from "@src/utils/dom";
import "./ChatFrame.scss";
const cn = classNameFactory("chat-frame");
export const ChatFrame = () => {
    return (_jsxs("div", { className: cn(""), children: [_jsxs("header", { className: cn("header"), children: [_jsx(Text, { size: "p1", fontWeight: "semiBold", children: "Meeting Chat" }), _jsx(Button, { v2: true, icon: _jsx(ThreeDotsIcon, { width: 18, height: 18 }), size: "small", type: "neutral", variant: "primary" })] }), _jsx(ChatBody, {}), _jsx(ChatFooter, {})] }));
};
