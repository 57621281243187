import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from "react";
import { Text } from "@ting/ting-ui-components";
import { PeerFrame } from "../PeerFrame";
import { PeerScreen } from "../PeerScreen";
import { selectRoomPeers } from "@src/selectors";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import "./screenShareView.scss";
const cn = classNameFactory("screen-view");
export const ScreenShareView = () => {
    const peersObj = useSubscribe(selectRoomPeers());
    const peers = Object.values(peersObj);
    const peerScreenShare = Object.values(peers).filter(peer => peer.peerStreamSettings.isScreenOn && peer.screenStream);
    const contRef = useRef();
    const [numOfPeersFit, setNumOfPeersFit] = useState(0);
    useLayoutEffect(() => {
        const handlePeersFitToShow = (rect) => {
            if (!rect?.width)
                return;
            const { width } = rect || {};
            setNumOfPeersFit(Math.floor(width / 208));
        };
        handlePeersFitToShow();
        const observer = new ResizeObserver(entries => {
            for (const entry of entries)
                handlePeersFitToShow(entry.contentRect);
        });
        observer.observe(contRef.current);
        return () => {
            observer.disconnect();
        };
    }, [peers]);
    return (_jsxs("section", { className: cn(""), children: [_jsxs("div", { ref: contRef, className: cn("peers"), children: [(numOfPeersFit >= peers.length ? peers : peers.slice(0, numOfPeersFit)).map(peer => {
                        return _jsx(PeerFrame, { peerId: peer.peerId }, peer.peerId);
                    }), numOfPeersFit < peers.length ? (_jsx("div", { className: cn("others"), children: _jsxs(Text, { size: "body-medium", fontWeight: "bold", children: ["+", peers.length - numOfPeersFit, " Others"] }) })) : null] }), _jsx("div", { className: cn("screen"), children: _jsx(PeerScreen, { peerId: peerScreenShare[0].peerId }) })] }));
};
