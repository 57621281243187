import { state } from "@src/state";
export function setSourcesOpen(isOpen) {
    state.meeting.studio.sources.setPanelOpen(isOpen);
}
export function setObjectPropertiesOpen(isOpen) {
    state.meeting.studio.sources.setObjectPropertiesPanelOpen(isOpen);
}
export function activateSource(id) {
    state.meeting.studio.sources.activate(id);
}
export function mapStudioSource(source) {
    state.meeting.studio.sources.mapSource(source);
}
export function addStudioSource(source, sceneId) {
    state.meeting.studio.sources.addSource(source, sceneId);
}
export function switchStudioSource(source) {
    state.meeting.studio.sources.switchSource(source);
}
export function deleteStudioSource(sourceId, sceneId) {
    state.meeting.studio.sources.deleteSource(sourceId, sceneId);
}
export function duplicateStudioSource(id) {
    state.meeting.studio.sources.duplicateSource(id);
}
export function renameStudioSource(id, name) {
    state.meeting.studio.sources.renameSource(id, name);
}
export function updateSourceSize(sourceId, width, height) {
    state.meeting.studio.sources.updateSourceSize(sourceId, width, height);
}
export function updateSourcePosition(sourceId, positionX, positionY) {
    state.meeting.studio.sources.updateSourcePosition(sourceId, positionX, positionY);
}
export function adjustSourcePosition(sourceId, action) {
    state.meeting.studio.sources.adjustSourcePosition(sourceId, action);
}
export function updateSourceFlip(sourceId, flipX, flipY) {
    state.meeting.studio.sources.updateSourceFlip(sourceId, flipX, flipY);
}
export function setSourceFillCanvas(sourceId) {
    state.meeting.studio.sources.setSourceFillCanvas(sourceId);
}
export function setSourceStretchToCanvas(sourceId) {
    state.meeting.studio.sources.setSourceStretchToCanvas(sourceId);
}
export function updateSourceVolume(sourceId, volume) {
    state.meeting.studio.sources.updateSourceVolume(sourceId, volume);
}
export function muteSource(sourceId, mute) {
    state.meeting.studio.sources.muteSource(sourceId, mute);
}
export function updateSourceIndexOrder(sourceId, type) {
    state.meeting.studio.sources.updateSourceIndexOrder(sourceId, type);
}
export function setSourceLock(sourceId, lock) {
    state.meeting.studio.sources.lockSource(sourceId, lock);
}
export function setSourceHidden(sourceId, hidden) {
    state.meeting.studio.sources.hideSource(sourceId, hidden);
}
