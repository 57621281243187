import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSource } from "@src/selectors/studio";
import { classNameFactory } from "@src/utils/dom";
import { SourceType } from "@src/domain/Studio/types";
import { AudioVolumeMeter } from "@src/components/AudioVolumeMeter";
import { updateSourceVolume } from "@src/controller";
import "./sourceFields.scss";
const cn = classNameFactory("source-field");
export const SourceAudioVolumeMeterField = () => {
    const { t } = useTranslation("studio");
    const source = useSubscribe(selectStudioActiveSource());
    if (source && source.sourceType === SourceType.SOUND) {
        return (_jsx("div", { className: cn(""), children: _jsxs("div", { className: cn("audio-volume"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("volume") }), _jsx("div", { className: cn("audio-volume-meter"), children: _jsx(AudioVolumeMeter, { type: "STUDIO", streamId: source.id, volume: source.volume, onVolumeChanged: (volume) => {
                                updateSourceVolume(source.id, volume);
                            } }) })] }) }));
    }
    return null;
};
