import { EventEmitter } from "events";
export class IRTCConnectionEventEmitter {
    constructor() {
        Object.defineProperty(this, "em", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new EventEmitter()
        });
    }
    // matches EventEmitter.on
    on(event, listener) {
        return this.em.on(event, listener);
    }
    // matches EventEmitter.off
    off(event, listener) {
        return this.em.off(event, listener);
    }
    // matches EventEmitter.emit
    emit(event, args) {
        return this.em.emit(event, args);
    }
}
