export var ImageValidationResult;
(function (ImageValidationResult) {
    ImageValidationResult["INVALID_SIZE"] = "invalidSize";
    ImageValidationResult["INVALID_FORMAT"] = "invalidFormat";
    ImageValidationResult["VALID"] = "valid";
})(ImageValidationResult || (ImageValidationResult = {}));
const validateImageUpload = (img, maxSize = 1024 * 1024, // in bytes, equals to 1MB
validFormats = ["png", "jpg", "jpeg"]) => {
    if (img?.size > maxSize) {
        return ImageValidationResult.INVALID_SIZE;
    }
    if (!validFormats.includes(img?.type.slice(6, img?.type.length))) {
        return ImageValidationResult.INVALID_FORMAT;
    }
    return ImageValidationResult.VALID;
};
export default validateImageUpload;
