import Libp2p from "libp2p";
import WebRTCStar from "libp2p-webrtc-star";
import filters from "libp2p-websockets/src/filters";
import Mplex from "libp2p-mplex";
import { NOISE } from "libp2p-noise";
import PeerId from "peer-id";
import logger from "loglevel";
import { libp2pIceServers } from "./constants";
export async function createNode(p) {
    const nodesPeerId = p || (await PeerId.create());
    return Libp2p.create({
        peerId: nodesPeerId,
        addresses: {
            // Add the signaling server address, along with our PeerId to our multiaddrs list
            // libp2p will automatically attempt to dial to the signaling server so that it can
            // receive inbound connections from other peers
            listen: ["/dns4/webrtc.ting.tube/tcp/443/wss/p2p-webrtc-star"],
        },
        modules: {
            transport: [WebRTCStar],
            connEncryption: [NOISE],
            streamMuxer: [Mplex],
        },
        config: {
            peerDiscovery: {
                autoDial: false,
            },
            transport: {
                WebRTCStar: {
                    filter: filters.all,
                    listenerOptions: {
                        config: {
                            iceServers: libp2pIceServers,
                        },
                    },
                },
            },
        },
    }).then(node => {
        logger.info("this node peerID", node.peerId.toB58String());
        node.connectionManager.on("peer:connect", connection => {
            logger.debug("peer:connect", "Connection established to:", connection.remotePeer.toB58String()); // Emitted when a peer has been found
        });
        node.on("peer:discovery", peerId => {
            // No need to dial, autoDial is on
            logger.debug("peer:Discovered:", peerId.toB58String());
        });
        node.peerStore.on("change:multiaddrs", ({ peerId, multiaddrs }) => {
            if (peerId.equals(node.peerId)) {
                logger.info("address changes", multiaddrs.toString(), peerId.toB58String());
            }
        });
        return node;
    });
}
