import { useState, useEffect } from "react";
import { useDebounce } from "./useDebounce";
export var Breakpoint;
(function (Breakpoint) {
    Breakpoint[Breakpoint["XS"] = 0] = "XS";
    Breakpoint[Breakpoint["SM"] = 576] = "SM";
    Breakpoint[Breakpoint["MD"] = 768] = "MD";
    Breakpoint[Breakpoint["LG"] = 992] = "LG";
    Breakpoint[Breakpoint["XL"] = 1200] = "XL";
    Breakpoint[Breakpoint["XXL"] = 1440] = "XXL";
    Breakpoint[Breakpoint["XXXL"] = 1800] = "XXXL";
    Breakpoint[Breakpoint["XXXXL"] = 2140] = "XXXXL";
})(Breakpoint || (Breakpoint = {}));
const findBreakpoint = () => [Breakpoint.XS, Breakpoint.SM, Breakpoint.MD, Breakpoint.LG, Breakpoint.XL, Breakpoint.XXL].reduce((previous, current) => {
    if (current > window.innerWidth) {
        return previous;
    }
    return Math.max(current, previous);
}, Breakpoint.XS);
export const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState(findBreakpoint());
    const handleResize = useDebounce(() => setBreakpoint(findBreakpoint()), 200);
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);
    return breakpoint;
};
export const useBreakpointUp = (minSize) => {
    const breakpoint = useBreakpoint();
    return minSize <= breakpoint;
};
export const useBreakpointDown = (maxSize) => {
    const breakpoint = useBreakpoint();
    return maxSize > breakpoint;
};
export const useBreakpointBetween = (minSize, maxSize) => {
    const breakpoint = useBreakpoint();
    return minSize <= breakpoint && maxSize > breakpoint;
};
export const useMobile = () => useBreakpointDown(Breakpoint.MD);
export const useBuildMobile = () => process.env.DEVICE === "mobile";
