import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTransition } from "react-transition-state";
import { leaveRoom } from "@src/controller";
import { selectRoomUISettings, selectSelfPeerRole } from "@src/selectors";
import { classNameFactory } from "@src/utils/dom";
import { ControlBar } from "@src/components/ControlBar";
import { Chat } from "@src/components/Chat";
import { SideNavigation } from "@src/components/SideNavigation";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectIsChatOpen, selectIsChatToggle } from "@src/selectors/chat";
import { selectIsObjectPropertiesOpen } from "@src/selectors/studio";
import { LiveChat } from "@src/components/LiveChat";
import { ObjectProperties } from "@src/components/Studio";
import { selectIsLiveChatOpen, selectIsLiveChatToggle } from "@src/selectors/live-chat";
import { useDebounceValue } from "@src/hooks/useDebounceValue";
import "./meetingLayout.scss";
const cn = classNameFactory("main");
const TRANSITION_TIME = 500;
const INVITE_PARTICIPANT_OVERLAY_DELAY_TIME = 2000;
export const MeetingLayout = props => {
    const isChatOpen = useSubscribe(selectIsChatOpen());
    const isChatToggle = useSubscribe(selectIsChatToggle());
    const isLiveChatOpen = useSubscribe(selectIsLiveChatOpen());
    const isLiveChatToggle = useSubscribe(selectIsLiveChatToggle());
    const [meetingChatState, toggleMeetingChat] = useTransition({
        timeout: TRANSITION_TIME,
        preEnter: true,
        initialEntered: isChatOpen && isChatToggle,
    });
    const [viewContainerState, toggleViewContainer] = useTransition({
        timeout: TRANSITION_TIME,
        preEnter: true,
        initialEntered: isChatOpen && isChatToggle,
    });
    const peerRole = useSubscribe(selectSelfPeerRole());
    const isObjectPropertiesOpen = useSubscribe(selectIsObjectPropertiesOpen());
    const debouncedIsObjectPropertiesOpen = useDebounceValue(isObjectPropertiesOpen, TRANSITION_TIME);
    const { children } = props;
    const isStudioRoute = useRouteMatch({
        path: "/room/:roomId/studio",
        strict: true,
        sensitive: true,
    });
    useEffect(() => {
        const closeTabCallback = () => {
            leaveRoom();
        };
        window.addEventListener("beforeunload", closeTabCallback);
        return () => {
            window.removeEventListener("beforeunload", closeTabCallback);
        };
    }, [peerRole]);
    useEffect(() => {
        const isOpen = Boolean((isChatOpen && isChatToggle) ||
            (isLiveChatOpen && isLiveChatToggle) ||
            (isObjectPropertiesOpen && Boolean(isStudioRoute)));
        toggleMeetingChat(isOpen);
        toggleViewContainer(isOpen);
    }, [isChatOpen, isChatToggle, isLiveChatOpen, isLiveChatToggle, isObjectPropertiesOpen, isStudioRoute]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            selectRoomUISettings().showInviteModal.set(true);
        }, INVITE_PARTICIPANT_OVERLAY_DELAY_TIME);
        return () => {
            clearTimeout(timeout);
        };
    }, []);
    return (_jsxs("main", { className: cn(""), children: [_jsxs("section", { className: `${cn("view-container")} ${viewContainerState.status}`, children: [_jsx("section", { className: cn("view"), children: children }), _jsx("section", { className: cn("control"), children: _jsx(ControlBar, {}) })] }), _jsxs("section", { className: `${cn("chat-container")} ${meetingChatState.status}`, children: [isChatOpen && isChatToggle && _jsx(Chat, {}), isLiveChatOpen && isLiveChatToggle && _jsx(LiveChat, {}), (debouncedIsObjectPropertiesOpen || isObjectPropertiesOpen) && (_jsx(ObjectProperties, { isOpen: isObjectPropertiesOpen && Boolean(isStudioRoute) }))] }), _jsx("aside", { className: cn("side-navbar"), children: _jsx(SideNavigation, {}) })] }));
};
