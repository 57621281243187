const generateKey = () => crypto.subtle.generateKey({ name: "AES-GCM", length: 256 }, true, ["encrypt", "decrypt"]);
const encrypt = (key, data, iv) => crypto.subtle.encrypt({ name: "AES-GCM", iv }, key, data);
const decrypt = (key, data, iv) => crypto.subtle.decrypt({ name: "AES-GCM", iv }, key, data);
const toBuffer = (ab) => {
    const buf = Buffer.alloc(ab.byteLength);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; i += 1) {
        buf[i] = view[i];
    }
    return buf;
};
const toArrayBuffer = (buf) => {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; i += 1) {
        view[i] = buf[i];
    }
    return ab;
};
const strToUnit8 = (str) => {
    return Uint8Array.from(str.split(","), c => Number(c));
};
const encryptData = async (file) => {
    const iv = crypto.getRandomValues(new Uint8Array(16)); // Generate a 16 byte long initialization vector
    try {
        const data = await file.arrayBuffer();
        const key = await generateKey();
        const encryptedData = await encrypt(key, data, iv);
        const exportedKey = await crypto.subtle.exportKey("jwk", key);
        return await Promise.resolve({
            key: JSON.stringify(exportedKey),
            data: toBuffer(encryptedData),
            iv: iv.toString(),
        });
    }
    catch (e) {
        return Promise.reject(e);
    }
};
const decryptData = async (file, key, fileType, iv) => {
    try {
        const encryptedData = await file.arrayBuffer();
        const importedKey = await crypto.subtle.importKey("jwk", JSON.parse(key), { name: "AES-GCM", length: 256 }, true, [
            "encrypt",
            "decrypt",
        ]);
        const data = await decrypt(importedKey, encryptedData, strToUnit8(iv));
        return await Promise.resolve(new Blob([data], { type: fileType }));
    }
    catch (e) {
        return Promise.reject(e);
    }
};
export const Encryption = {
    encryptData,
    generateKey,
    decryptData,
    toArrayBuffer,
    toBuffer,
    encrypt,
    decrypt,
};
