import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button, FormTextField, Modal } from "@ting/ting-ui-components";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { classNameFactory } from "@src/utils/dom";
import { Header } from "@src/components/Header";
import "./renameModal.scss";
const cn = classNameFactory("rename-scene");
export const RenameModal = ({ name, isOpen, rename, onRename, onClose }) => {
    const { t } = useTranslation(["common", "studio"]);
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, shouldCloseOnEsc: true, shouldCloseOnOverlayClick: true, hideCloseButton: true, children: [_jsx(Header, { isModal: true, onClose: onClose, children: t(`studio:${rename}Rename`) }), _jsx("div", { className: cn(""), children: _jsx(Formik, { initialValues: { name }, onSubmit: ({ name }) => {
                        onRename(name);
                    }, validationSchema: Yup.object().shape({
                        name: Yup.string()
                            .min(1, "Please enter minimum 1 character")
                            .max(40, "You can enter maximum 40 character")
                            .required("* Required"),
                    }), children: ({ handleSubmit }) => (_jsxs(Form, { children: [_jsx("div", { className: cn("container"), children: _jsx(FormTextField, { v2: true, name: "name", label: t(`studio:${rename}Name`), placeholder: t(`studio:${rename}Name`) }) }), _jsxs("div", { className: cn("actions"), children: [_jsx(Button, { v2: true, type: "neutral", theme: "primary", variant: "secondary", size: "small", onClick: () => onClose(), children: t("cancel") }), _jsx(Button, { v2: true, type: "brand", theme: "primary", size: "small", onClick: handleSubmit, children: t("rename") })] })] })) }) })] }));
};
