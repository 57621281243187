import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Tab, Tabs } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import "./modalTabs.scss";
const cn = classNameFactory("modal-tabs");
export const ModalTabs = ({ list }) => {
    const [tabIndex, setTabIndex] = useState(0);
    return (_jsxs("div", { className: cn(""), children: [_jsx(Tabs, { value: tabIndex, onChange: idx => setTabIndex(idx), variant: "secondary", children: list.map(({ label }) => (_jsx(Tab, { className: cn("tab"), children: label }, label))) }), list[tabIndex].content] }));
};
