import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { FormCheckbox, FormSelectField, Text, DatePicker } from "@ting/ting-ui-components";
import { ChevronDownFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { RecurringMeetingScheduler } from "../RecurringMeetingScheduler";
import { recurrenceOptions } from "./constant";
import { DaysOfWeek, Recurrences } from "@src/services/Calendar/enums";
import { classNameFactory } from "@src/utils/dom";
import { getDaysInMonth } from "@src/utils/date";
import "./RecurringMeeting.scss";
const cn = classNameFactory("recurring-meeting");
export const RecurringMeeting = () => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation(["common", "room"]);
    const daysOfMonth = useMemo(() => {
        const days = getDaysInMonth(values.recurrenceEndDate.getMonth(), values.recurrenceEndDate.getFullYear()).map(day => {
            const _day = day.getDate().toString();
            return {
                label: _day,
                value: _day,
            };
        });
        return days || [];
    }, [values.recurrenceEndDate]);
    const selectedRecurrence = values?.recurrence;
    return (_jsxs("div", { className: cn(""), children: [_jsx(FormSelectField, { size: "large", label: t("room:recurring-meeting"), name: "recurrence", options: recurrenceOptions, value: { value: values.recurrence, label: t(values.recurrence) }, setFieldValue: (name, value) => setFieldValue(name, value.value), className: cn("input") }), selectedRecurrence === Recurrences.monthly && (_jsxs("div", { className: cn("monthly"), children: [_jsx(FormSelectField, { size: "large", label: t("room:occurs-on") + ":", name: "monthlyOccursOn", options: daysOfMonth, value: { value: values.monthlyOccursOn, label: values.monthlyOccursOn }, setFieldValue: (name, value) => setFieldValue(name, value.value) }), _jsx(Text, { size: "p2", children: t("room:day-of-the-month") })] })), selectedRecurrence === Recurrences.weekly && (_jsxs("div", { className: cn("weekly"), children: [_jsx(Text, { size: "p2", fontWeight: "medium", children: t("room:recurs-on") }), _jsx("div", { className: cn("days-of-week"), children: Object.keys(DaysOfWeek).map(day => (_jsx(FormCheckbox, { name: `weeklyOccursOn-${day}`, value: day, size: "big", checked: values.weeklyOccursOn.includes(day), disabled: values.weeklyOccursOn.length === 1 && values.weeklyOccursOn.includes(day), onChange: e => {
                                if (e.target.checked) {
                                    setFieldValue("weeklyOccursOn", [...values.weeklyOccursOn, day]);
                                }
                                else {
                                    setFieldValue("weeklyOccursOn", values.weeklyOccursOn.filter(v => v !== day));
                                }
                            }, children: _jsx(Text, { size: "p2", fontWeight: "medium", children: t(day.toLocaleLowerCase()) }) }, day))) })] })), selectedRecurrence !== Recurrences.none && (_jsxs(_Fragment, { children: [_jsx(DatePicker, { label: t("room:end-by") + ":", className: cn("end-by"), name: "recurrenceEndDate", placeholder: "yyyy/mm/dd", selected: values.recurrenceEndDate, onChange: (date) => setFieldValue("recurrenceEndDate", date), calendarIcon: _jsx(ChevronDownFilledIcon, { width: "24px", height: "24px" }) }), _jsx(RecurringMeetingScheduler, { endTime: values.endTime, monthlyOccursOn: values.monthlyOccursOn, recurrence: selectedRecurrence, startDate: values.startDate, startTime: values.startTime, weeklyOccursOn: values.weeklyOccursOn })] }))] }));
};
