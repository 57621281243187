import { MessageType } from "@ting/ting-ui-components";
import { Poll } from "./Poll";
import { selectRoomSelfPeer } from "@src/selectors";
import { chatSettings } from "@src/configs/chat";
export class Chat {
    constructor(isOpen = false, isToggle = false) {
        Object.defineProperty(this, "isOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isToggle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeTabIndex", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "messageIdToScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "messages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "poll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unReadMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "recievedMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "repliedMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isOpen = isOpen;
        this.isToggle = isToggle;
        this.activeTabIndex = 0;
        this.messages = [];
        this.poll = new Poll();
        this.recievedMessages = [];
        this.unReadMessages = [];
        this.messageIdToScroll = null;
    }
    setChatOpen(isOpen) {
        this.isOpen.set(isOpen);
    }
    setChatToggle(toggle) {
        this.isToggle.set(toggle);
    }
    setActiveTabIndex(tabIndex) {
        this.activeTabIndex.set(tabIndex);
    }
    setMessageIdToScroll(messageId) {
        this.messageIdToScroll.set(messageId);
    }
    addChatMessage(msg) {
        const peer = selectRoomSelfPeer().get();
        let messageIdx = -1;
        const chatUser = {
            displayName: peer?.name || "Guest",
            userName: peer?.name || chatSettings.guestCorePassID,
            avatarUrl: peer?.avatarURL,
            corepassId: peer?.corepassId || chatSettings.guestCorePassID,
        };
        switch (msg.type) {
            case MessageType.CHAT:
            case MessageType.FILE:
                if (!this.messages.some(m => m.id.get() === msg.id)) {
                    this.messages.push({ ...msg, viewer: chatUser });
                }
                break;
            case MessageType.REACTION:
                messageIdx = this.messages.get().findIndex(m => m.id === msg.messageId);
                if (messageIdx !== -1) {
                    this.messages.set([
                        ...this.messages.get().slice(0, messageIdx),
                        { ...this.messages[messageIdx].get(), reactions: msg.reactions, viewer: chatUser },
                        ...this.messages.get().slice(messageIdx + 1),
                    ]);
                }
                break;
            case MessageType.HIDE_MESSAGE: {
                this.messages.set(this.messages.get().filter(m => m.id !== msg.messageId));
            }
        }
    }
    addUnreadMessage(msg) {
        this.recievedMessages.push(msg);
        this.unReadMessages.push(msg);
    }
    setUnreadMessages(msgs) {
        this.unReadMessages.set(msgs);
    }
    setRecievedMessages(msgs) {
        this.recievedMessages.set(msgs);
    }
    clearMessages() {
        this.messages.set([]);
    }
    setRepliedMessage(msg) {
        this.repliedMessage.set(msg);
    }
    removeRepliedMessage() {
        this.repliedMessage.set(undefined);
    }
}
