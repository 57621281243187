import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TvOutlinedIcon, TvFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { useState } from "react";
import { Button, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { ControllerButtonSizes } from "../types";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomSelfPeer } from "@src/selectors";
import { toggleScreenOn } from "@src/controller/userMedia";
import "./screenController.scss";
const cn = classNameFactory("screen-controller");
export const ScreenController = ({ withText = false, size = ControllerButtonSizes.medium }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation(["room"]);
    // TODO: fix this when reactive state fixed
    // const isScreenOn = useSubscribe(selectIsScreenOn());
    const selfPeer = useSubscribe(selectRoomSelfPeer());
    const isScreenOn = selfPeer?.peerStreamSettings?.isScreenOn;
    const changeScreenOn = async () => {
        try {
            setIsLoading(true);
            await toggleScreenOn(!isScreenOn);
        }
        finally {
            setIsLoading(false);
        }
    };
    return (_jsxs("div", { className: cn(""), children: [_jsx("div", { className: cn("buttons"), children: _jsx(Button, { v2: true, onClick: changeScreenOn, size: size, disabled: isLoading, variant: isScreenOn ? "primary" : "ghost", type: "neutral-inverted", startIcon: isScreenOn ? _jsx(TvFilledIcon, { width: 24, height: 24 }) : _jsx(TvOutlinedIcon, { width: 24, height: 24 }), round: true }) }), withText && (_jsx(Text, { size: "body-medium", fontWeight: "regular", children: isScreenOn ? t("screen-on") : t("screen-off") }))] }));
};
