export class SelfStream {
    constructor() {
        Object.defineProperty(this, "stream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new MediaStream()
        });
        Object.defineProperty(this, "videoTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    enableDisableAudioTrack(enabled) {
        if (this.audioTrack.peek()) {
            this.audioTrack.enabled.set(enabled);
        }
    }
    enableDisableVideoTrack(enabled) {
        if (this.videoTrack.peek()) {
            this.videoTrack.enabled.set(enabled);
        }
    }
    addTrack(track) {
        if (track.kind === "audio") {
            return this.addAudioTrack(track);
        }
        else if (track.kind === "video") {
            return this.addVideoTrack(track);
        }
    }
    addAudioTrack(track) {
        if (track && track.kind === "audio") {
            this.removePreviousTracks("audio");
            this.stream.peek().addTrack(track);
            this.audioTrack.set(track);
            // TODO: onaddtrack can only have one cb unlike addEventListener
            // so right now we only use it in one place but this could potentially lead to bugs
            this.stream.peek().onaddtrack?.(new MediaStreamTrackEvent("addtrack", { track }));
        }
    }
    removeAudioTrack() {
        if (!this.audioTrack.peek())
            return;
        this.stream.peek().removeTrack(this.audioTrack.peek());
        this.audioTrack.peek().stop();
        this.stream.peek().onremovetrack?.(new MediaStreamTrackEvent("removetrack", { track: this.audioTrack.peek() }));
        this.audioTrack.set(null);
    }
    addVideoTrack(track) {
        if (track && track.kind === "video") {
            this.removePreviousTracks("video");
            this.stream.peek().addTrack(track);
            this.videoTrack.set(track);
            this.stream.peek().onaddtrack?.(new MediaStreamTrackEvent("addtrack", { track }));
        }
    }
    removeVideoTrack() {
        if (!this.videoTrack.peek())
            return;
        this.stream.peek().removeTrack(this.videoTrack.peek());
        this.videoTrack.peek().stop();
        this.stream.peek().onremovetrack?.(new MediaStreamTrackEvent("removetrack", { track: this.videoTrack.peek() }));
        this.videoTrack.set(null);
    }
    removePreviousTracks(type) {
        const stream = this.stream.peek();
        let tracks;
        if (type === "audio") {
            tracks = stream.getAudioTracks();
        }
        else if (type === "video") {
            tracks = stream.getVideoTracks();
        }
        for (const track of tracks) {
            stream.removeTrack(track);
            track.stop();
        }
    }
    clear() {
        this.removePreviousTracks("video");
        this.removePreviousTracks("audio");
    }
}
