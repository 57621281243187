export function getDaysInMonth(month, year) {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}
export function addHoursToTime(baseTime, hoursToAdd) {
    const updatedTime = new Date(baseTime);
    updatedTime.setHours(updatedTime.getHours() + hoursToAdd);
    return updatedTime;
}
