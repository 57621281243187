import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { MessageType, Text, GroupFilesItem } from "@ting/ting-ui-components";
import { FilesOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import classNames from "classnames";
import { groupFilesBySender } from "./utils";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectChatMessages } from "@src/selectors/chat";
import { classNameFactory } from "@src/utils/dom";
import { ChatMessageItem } from "@src/components/ChatMessageItem";
import { FilesFooter } from "@src/components/Files/FilesFooter";
import { FilesSortValuesEnum } from "@src/components/Files/types";
import "@src/components/Chat/ChatBody/chatBody.scss";
import "./FilesBody.scss";
const cn = classNameFactory("chat-body");
const cnFilesBody = classNameFactory("files-body");
export const FilesBody = ({ sortType }) => {
    const messages = useSubscribe(selectChatMessages());
    const fileMessages = messages.filter(message => message.type === MessageType.FILE);
    const groupedFilesData = useMemo(() => {
        if (sortType === "group") {
            return groupFilesBySender(fileMessages);
        }
    }, [sortType]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: cn(""), children: fileMessages.length ? (_jsxs("ul", { className: classNames(cn("messages-list"), cnFilesBody("files-list")), children: [sortType === FilesSortValuesEnum.newest && (_jsx(_Fragment, { children: fileMessages.map(msg => (_jsx(ChatMessageItem, { msg: msg, isFile: true }, msg.id))) })), sortType === FilesSortValuesEnum.group && (_jsx(_Fragment, { children: groupedFilesData.map(groupedFiles => (_jsx(GroupFilesItem, { sender: groupedFiles.sender, children: groupedFiles.files.map(file => (_jsx(ChatMessageItem, { msg: file, isFile: true, isGrouped: true }, file.id))) }, groupedFiles.id))) }))] })) : (_jsxs("div", { className: cn("not-active-state"), children: [_jsx("div", { className: cn("chat-icon"), children: _jsx(FilesOutlinedIcon, { width: "20px", height: "20px" }) }), _jsx(Text, { size: "p2", fontWeight: "semiBold", children: "No files yet" }), _jsx(Text, { size: "p2", children: "Files you will send to your meeting chat will appear here" })] })) }), _jsx(FilesFooter, {})] }));
};
