import { improvedLocalStorage } from "./ImprovedStorage";
import { proxify, MemoryStorage } from "./memoryStorage";
let TingLocalStorage;
if (improvedLocalStorage) {
    TingLocalStorage = improvedLocalStorage;
}
else {
    const instanceLocalStorage = new MemoryStorage();
    TingLocalStorage = proxify(instanceLocalStorage);
}
export { TingLocalStorage };
