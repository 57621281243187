import * as Sentry from "@sentry/react";
import logger from "loglevel";
export function logWithFingerprint(message, fingerprint, context) {
    Sentry.withScope(function (scope) {
        if (fingerprint.length > 0) {
            scope.setFingerprint(fingerprint);
        }
        Sentry.captureMessage(message, { contexts: { custom: context } });
    });
    logger.info(message, context);
}
export function errWithFingerprint(exception, fingerprint, context) {
    Sentry.withScope(function (scope) {
        if (fingerprint.length > 0) {
            scope.setFingerprint(fingerprint);
        }
        Sentry.captureException(exception, context);
    });
}
