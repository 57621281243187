var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import EventEmitter from "events";
import { inject, injectable } from "inversify";
import { TYPES } from "@src/ioc.type";
import { PeerRoles } from "@src/domain/Peer";
import { PeerConnectionEvents, RTCConnectionManager, SuperNodeRTCConnectionManager, } from "@src/domain/RTCConnection";
import { selectRoomSelfPeer } from "@src/selectors";
let RTCConnectionService = class RTCConnectionService extends EventEmitter {
    constructor(peerCommunicator) {
        super();
        Object.defineProperty(this, "_rtcConnectionManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_peerCommunicator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._peerCommunicator = peerCommunicator;
    }
    createRtcConnectionManager() {
        const selfPeer = selectRoomSelfPeer().get(); // this._peerService.getSelfPeer(); cant inject PeerService due circular deps
        const isSupernode = selfPeer.role === PeerRoles.SUPER_NODE;
        this._rtcConnectionManager = isSupernode
            ? new SuperNodeRTCConnectionManager(this._peerCommunicator, selfPeer)
            : new RTCConnectionManager(this._peerCommunicator, selfPeer);
        this.assignListeners();
    }
    assignListeners() {
        this._rtcConnectionManager.on(PeerConnectionEvents.ReceivedDCMessage, (...args) => this.emit(PeerConnectionEvents.ReceivedDCMessage, ...args));
        this._rtcConnectionManager.on(PeerConnectionEvents.ReceivedTrack, (...args) => this.emit(PeerConnectionEvents.ReceivedTrack, ...args));
        this._rtcConnectionManager.on(PeerConnectionEvents.ConnectionFailed, (...args) => this.emit(PeerConnectionEvents.ConnectionFailed, ...args));
    }
    init() {
        this.createRtcConnectionManager();
    }
    close() {
        this._rtcConnectionManager?.close();
    }
    reinitiate() {
        this.close();
        this.createRtcConnectionManager();
    }
    addConnectionTo(peer) {
        this._rtcConnectionManager.addConnectionTo(peer);
    }
    removeConnectionFrom(peer) {
        this._rtcConnectionManager.removeConnectionFrom(peer);
    }
    sendMessage(message) {
        this._rtcConnectionManager.sendMessage(message);
    }
    addScreenTracksToPCs(stream) {
        this._rtcConnectionManager.addScreenTracksToPCs(stream);
    }
    removeScreenTracksFromPCs(stream) {
        this._rtcConnectionManager.removeScreenTracksFromPCs(stream);
    }
    setSendingStream(stream) {
        this._rtcConnectionManager.setSendingStream(stream);
    }
    getPeerConnectionState(peerId) {
        return this._rtcConnectionManager.getPeerConnectionState(peerId);
    }
};
RTCConnectionService = __decorate([
    injectable(),
    __param(0, inject(TYPES.PeerCommunicator)),
    __metadata("design:paramtypes", [Object])
], RTCConnectionService);
export { RTCConnectionService };
