import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import { ModalTabs } from "../ModalTabs";
import { AddSourceContext } from "../../Context";
import { AddPeerSource } from "../AddSource";
export const SelectSourceRemoteTabList = () => {
    const { selectedSource } = useContext(AddSourceContext);
    const { source } = selectedSource;
    return (_jsx(ModalTabs, { list: [
            {
                label: source.name,
                content: _jsx(AddPeerSource, {}),
            },
        ] }));
};
