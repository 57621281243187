export const defaultSelfStreamSettings = {
    isCamOn: true,
    isMicOn: true,
    isScreenOn: false,
    audioOutput: undefined,
    audioInput: undefined,
    videoInput: undefined,
    aspectRatio: 1.7777777778,
    audioInputError: undefined,
    videoInputError: undefined,
    videoInputResolution: undefined,
    screenInputResolution: undefined,
    echoCancellation: true,
    autoGainControl: false,
    noiseSuppression: true,
    volume: 100,
    isBlurBackground: false,
};
