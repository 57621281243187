import { useState } from "react";
import { useInterval } from "./useInterval";
function getTimeFromSeconds(secs) {
    const totalSeconds = Math.ceil(secs);
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
    };
}
function getSecondsFromPrevTime(prevTime) {
    const now = new Date().getTime();
    const milliSecondsDistance = now - prevTime.getTime();
    if (milliSecondsDistance > 0) {
        const val = milliSecondsDistance / 1000;
        return Math.round(val);
    }
    return 0;
}
export function useNewTimer({ defaultDate }) {
    const [prevTime] = useState(defaultDate ?? new Date());
    const [seconds, setSeconds] = useState(getSecondsFromPrevTime(prevTime));
    useInterval(() => {
        setSeconds(getSecondsFromPrevTime(prevTime));
    }, 1000);
    return {
        ...getTimeFromSeconds(seconds),
    };
}
