import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Text } from "@ting/ting-ui-components";
import { format } from "date-fns";
import { getNextWeeklyMeetingDate, getNextMonthlyMeetingDate } from "./utils";
import { Recurrences } from "@src/services/Calendar/enums";
import { classNameFactory } from "@src/utils/dom";
import "./RecurringMeetingScheduler.scss";
const cn = classNameFactory("recurring-meeting-scheduler");
const dayAbbreviations = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
};
export const RecurringMeetingScheduler = ({ recurrence, startDate, startTime: startTimeProp, endTime: endTimeProp, monthlyOccursOn, weeklyOccursOn, }) => {
    const { t } = useTranslation(["common", "room"]);
    const startTime = format(startTimeProp, "HH:mm");
    const endTime = format(endTimeProp, "HH:mm");
    return (_jsxs("div", { className: cn(""), children: [recurrence === Recurrences.daily && (_jsxs(_Fragment, { children: [_jsx(Text, { size: "p2", children: t("room:repeat-every-day-on", {
                            startDate: format(startDate, "iiii"),
                            startTime,
                            endTime,
                        }) }), _jsxs(Text, { size: "p2", children: [t("room:next-meeting-will-happen"), _jsxs(Text, { children: ["\u00A0", t("room:tomorrow-at", {
                                        startTime,
                                        endTime,
                                    })] })] })] })), recurrence === Recurrences.weekly && (_jsxs(_Fragment, { children: [_jsx(Text, { size: "p2", children: t("room:repeat-every-week-on", {
                            days: weeklyOccursOn.map(_day => dayAbbreviations[_day]),
                            startTime,
                            endTime,
                        }) }), _jsxs(Text, { size: "p2", children: [t("room:next-meeting-will-happen"), _jsxs(Text, { children: ["\u00A0", `${getNextWeeklyMeetingDate(weeklyOccursOn, startDate)} ${t("at")} ${startTime} - ${endTime}`] })] })] })), recurrence === Recurrences.monthly && (_jsxs(_Fragment, { children: [_jsx(Text, { size: "p2", children: t("room:repeat-every-month-on", {
                            month: monthlyOccursOn,
                            startTime,
                            endTime,
                        }) }), _jsxs(Text, { size: "p2", children: [t("room:next-meeting-will-happen"), _jsxs(Text, { children: ["\u00A0", `${getNextMonthlyMeetingDate(Number(monthlyOccursOn), startDate)} ${t("at")} ${startTime} - ${endTime}`] })] })] }))] }));
};
