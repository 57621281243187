import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomPeer } from "@src/selectors";
export const PeerAudio = ({ peerId }) => {
    const audioRef = useRef(null);
    const { name, peerStream } = useSubscribe(selectRoomPeer(peerId));
    useEffect(() => {
        if (peerStream instanceof MediaStream) {
            audioRef.current.srcObject = peerStream;
        }
    }, [peerStream]);
    return (
    /* eslint-disable-next-line jsx-a11y/media-has-caption */
    _jsx("audio", { autoPlay: true, id: `PeerAudio_${name || peerId}`, ref: audioRef }));
};
