import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { Button, Text, Tooltip } from "@ting/ting-ui-components";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HorizontalOrderOutlinedIcon, ObsFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { StartStreamModal } from "./components/StartStreamModal";
import { classNameFactory } from "@src/utils/dom";
import { studioStopStream } from "@src/controller";
import { useTimer } from "@src/hooks/useTimer";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioIsLiveON, selectStudioLiveStartTime, selectStudioSources } from "@src/selectors/studio";
import { routePaths } from "@src/routes/routes";
import { selectRoomSelfPeer, selectRoomSlug, selectUser } from "@src/selectors";
import { WarnModal } from "@src/components/WarnModal";
import { useToaster } from "@src/components/Toaster";
import { PeerRoles } from "@src/domain/Peer/types";
import "./streamController.scss";
const cn = classNameFactory("stream-controller");
export const StreamController = () => {
    const { t } = useTranslation(["common", "studio"]);
    const history = useHistory();
    const user = useSubscribe(selectUser());
    const roomId = useSubscribe(selectRoomSlug());
    const selfPeer = useSubscribe(selectRoomSelfPeer());
    const sources = useSubscribe(selectStudioSources());
    const isLiveON = useSubscribe(selectStudioIsLiveON());
    const liveStartTime = useSubscribe(selectStudioLiveStartTime());
    const [startTime, stopTimer] = useTimer(isLiveON, liveStartTime);
    const [openLiveModal, setOpenLiveModal] = useState(false);
    const [createSceneModal, setCreateSceneModal] = useState(false);
    const showToast = useToaster();
    const goLiveRef = useRef();
    const isStudioRoute = useRouteMatch({
        path: "/room/:roomId/studio",
        strict: true,
        sensitive: true,
    });
    const handleGoLive = () => {
        if (!Object.keys(sources).length) {
            setCreateSceneModal(true);
        }
        else if (isLiveON) {
            showToast({ type: "success", message: t("studio:liveHasEnded") });
            studioStopStream();
            stopTimer();
        }
        else {
            setOpenLiveModal(true);
        }
    };
    return (_jsxs("div", { className: cn(""), children: [isStudioRoute && (_jsxs("div", { className: cn("live"), children: [_jsxs(Text, { type: "sub", size: "body-medium", className: cn("live-text live-duration"), children: [_jsx(ObsFilledIcon, { width: 15, height: 15 }), _jsx("span", { children: t("live") }), "|", _jsx("span", { children: startTime })] }), _jsx(Button, { v2: true, forwardedRef: goLiveRef, disabled: !user, size: "medium", type: isLiveON ? "danger" : "brand", isLoading: openLiveModal, onClick: handleGoLive, children: isLiveON ? t("studio:stopLive") : t("studio:goLive") }), !user && (_jsx(Tooltip, { arrow: true, target: goLiveRef.current, placement: "top-end", children: t("studio:warning.needLoginToGoLive") }))] })), !isStudioRoute && selfPeer?.role === PeerRoles.SUPER_NODE && (_jsx(Button, { v2: true, size: "medium", type: "brand", onClick: () => {
                    history.push(routePaths.STUDIO(roomId));
                }, children: t("studio:setUpStream") })), _jsx(WarnModal, { isOpen: createSceneModal, title: "Set up your scene first", caption: "Before you start streaming your meeting, you have to set up the layout. You can choose one of our pre-made layouts or start with creating your own.", icon: _jsx(HorizontalOrderOutlinedIcon, { width: 24, height: 24 }), onClose: () => {
                    setCreateSceneModal(false);
                } }), openLiveModal && (_jsx(StartStreamModal, { onClose: () => {
                    setOpenLiveModal(false);
                } }))] }));
};
