import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from "classnames";
import { Button, CloseCrossIcon, Text } from "@ting/ting-ui-components";
import { AttentionOutlinedIcon, CheckmarkCircleOutlinedIcon, EmojiOutlinedIcon, InfoOutlinedIcon, LockOutlinedIcon, StopOutlinedIcon, } from "@ting/ting-ui-components/dist/icons";
import "./Toast.scss";
const icons = {
    neutral: (size) => _jsx(LockOutlinedIcon, { width: size, height: size }),
    brand: (size) => _jsx(EmojiOutlinedIcon, { width: size, height: size }),
    info: (size) => _jsx(InfoOutlinedIcon, { width: size, height: size }),
    success: (size) => _jsx(CheckmarkCircleOutlinedIcon, { width: size, height: size }),
    attention: (size) => _jsx(AttentionOutlinedIcon, { width: size, height: size }),
    danger: (size) => _jsx(StopOutlinedIcon, { width: size, height: size }),
    corepass: (size) => _jsx(CheckmarkCircleOutlinedIcon, { width: size, height: size }),
};
export const Toast = ({ type, size, icon, message, className = "", close, onClose }) => {
    return (_jsxs("div", { className: cn(`toast toast-${type} toast-${size}`, className), children: [_jsxs("div", { className: "content", children: [icon ? icon : icons[type](size === "small" ? 20 : 24), _jsx(Text, { size: size === "small" ? "body-medium" : "body-large", children: message })] }), _jsx(Button, { v2: true, className: "closeBtn", size: size === "medium" ? "small" : "extraSmall", type: type, theme: "primary", variant: "primary", onClick: () => {
                    if (onClose)
                        onClose();
                    close();
                }, icon: _jsx(CloseCrossIcon, {}) })] }));
};
