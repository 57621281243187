import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { classNameFactory } from "@src/utils/dom";
const cn = classNameFactory("stream-form");
export const StepActions = ({ step, setStep, onClose }) => {
    const { t } = useTranslation("common");
    const { isSubmitting, handleSubmit, values, validateForm, setFieldError } = useFormikContext();
    const handleStep = async () => {
        const fieldErrors = await validateForm(values);
        let errorFound = false;
        for (const err in fieldErrors) {
            if (step === 1 && ["title", "category"].includes(err)) {
                setFieldError(err, fieldErrors[err]);
                errorFound = true;
            }
            else if (step === 2) {
                setFieldError(err, fieldErrors[err]);
                errorFound = true;
            }
        }
        if (errorFound)
            return;
        if (step === 1)
            setStep(2);
        if (step === 2)
            handleSubmit();
    };
    const StepIndicator = () => {
        return (_jsxs("div", { className: cn("indicator"), children: [_jsx("span", { className: cn("indicator-step", { active: step === 1 }), children: "1" }), _jsx("span", { className: cn("indicator-step", { active: step === 2 }), children: "2" })] }));
    };
    if (step === 1) {
        return (_jsxs("div", { className: cn("actions"), children: [_jsx(StepIndicator, {}), _jsx(Button, { v2: true, type: "neutral", variant: "primary", size: "medium", onClick: onClose, className: cn("cancel"), children: t("cancel") }), _jsx(Button, { v2: true, type: "neutral", variant: "primary", size: "medium", onClick: handleStep, className: cn("continue"), children: t("continue") })] }));
    }
    return (_jsxs("div", { className: cn("actions"), children: [_jsx(Button, { v2: true, type: "neutral", variant: "primary", size: "medium", onClick: () => setStep(1), className: cn("back"), children: t("back") }), _jsx(Button, { v2: true, type: "brand", variant: "primary", size: "medium", onClick: handleStep, disabled: isSubmitting, isLoading: isSubmitting, className: cn("continue"), children: t("studio:goLive") })] }));
};
