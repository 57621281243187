import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { Text } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
const cn = classNameFactory("create-room-card");
export const CreateRoomCard = props => {
    const { className: classNameProps, selected = false, title, icon, tags, description, onClick } = props;
    const className = classNames(cn({ selected }), classNameProps);
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    _jsx("div", { onClick: onClick, className: className, children: _jsxs("div", { className: cn("content"), children: [_jsxs("div", { className: cn("header"), children: [_jsx("div", { className: cn("icon-wrapper"), children: icon }), _jsx("div", { className: cn("tags-wrapper"), children: tags?.map((tag, i) => {
                                return (_jsx("div", { className: "tag", children: tag }, i));
                            }) })] }), _jsx("div", { className: cn("title-wrapper"), children: _jsx(Text, { size: "p2", fontWeight: "medium", className: cn("title"), children: title }) }), _jsx("div", { className: cn("description-wrapper"), children: _jsx(Text, { size: "body-medium", fontWeight: "regular", type: "sub", className: cn("description"), children: description }) })] }) }));
};
