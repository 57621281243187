import { fromEvent, Subject } from "rxjs";
export const resize = (event, boxWidth, boxHeight, positionX, positionY, frameElement) => {
    let [currentWidth, currentHeight] = [boxWidth, boxHeight];
    let [recentX, recentY] = [event.screenX, event.screenY];
    const subject = new Subject();
    const source = fromEvent(document, "mousemove");
    const subscription = source.subscribe((e) => {
        const [newX, newY] = [e.screenX, e.screenY];
        const width = currentWidth + ((newX - recentX) / frameElement.offsetWidth) * 100;
        const height = currentHeight + ((newY - recentY) / frameElement.offsetHeight) * 100;
        if (width + positionX <= 100) {
            currentWidth = width;
            recentX = newX;
        }
        if (height + positionY <= 100) {
            currentHeight = height;
            recentY = newY;
        }
        subject.next({ width: currentWidth, height: currentHeight });
    });
    window.addEventListener("mouseup", () => {
        if (subscription) {
            subscription.unsubscribe();
            subject.complete();
        }
    });
    return subject;
};
export const resizeWithAspectRatio = (event, boxWidth, boxHeight, positionX, positionY, frameElement, aspectRatio = 16 / 9) => {
    let currentWidth = boxWidth;
    let currentHeight = (boxHeight * frameElement.offsetHeight) / 100;
    let recentY = event.screenY;
    const subject = new Subject();
    const subscription = fromEvent(document, "mousemove").subscribe((e) => {
        const newY = e.screenY;
        const height = currentHeight + newY - recentY;
        const width = currentHeight * aspectRatio;
        if (positionY + (height / frameElement.offsetHeight) * 100 <= 100 &&
            positionX + (width / frameElement.offsetWidth) * 100 <= 100) {
            currentWidth = width;
            currentHeight = height;
            recentY = newY;
        }
        recentY = newY;
        subject.next({
            width: (currentWidth / frameElement.offsetWidth) * 100,
            height: (currentHeight / frameElement.offsetHeight) * 100,
        });
    });
    window.addEventListener("mouseup", () => {
        if (subscription) {
            subscription.unsubscribe();
            subject.complete();
        }
    });
    return subject;
};
export const independentResize = (event) => {
    const recentX = event.screenX;
    const recentY = event.screenY;
    const subject = new Subject();
    const subscription = fromEvent(document, "mousemove").subscribe((e) => {
        const width = e.screenX - recentX;
        const height = e.screenY - recentY;
        subject.next({ width, height });
    });
    window.addEventListener("mouseup", () => {
        if (subscription) {
            subscription.unsubscribe();
            subject.complete();
        }
    });
    return subject;
};
