import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { routePaths } from "../routes";
import { classNameFactory } from "@src/utils/dom";
import { GalleryView } from "@src/components/GalleryView";
import { ScreenShareView } from "@src/components/ScreenShareView";
import { PeerAudioList } from "@src/components/PeerAudioList";
import { selectRoom, selectRoomPeers } from "@src/selectors";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { FullScreenLoading } from "@src/components/FullScreenLoading";
import "./room.scss";
const cn = classNameFactory("room");
export const Room = () => {
    const peers = useSubscribe(selectRoomPeers());
    const hasScreenShare = peers && Object.values(peers).find(p => p.peerStreamSettings.isScreenOn && p.screenStream);
    const history = useHistory();
    const { roomId } = useParams();
    // Redirect the user to lobby. happens when user want's to join by directly using the room link
    useEffect(() => {
        if (!selectRoom().peek())
            history.push(routePaths.LOBBY(roomId));
    });
    if (!selectRoom().peek())
        return _jsx(FullScreenLoading, {});
    return (_jsxs("main", { className: cn(""), children: [hasScreenShare ? _jsx(ScreenShareView, {}) : _jsx(GalleryView, {}), _jsx(PeerAudioList, {})] }));
};
