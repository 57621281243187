import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, MessageType, SelectBox, Text, TextField, ToggleButton, } from "@ting/ting-ui-components";
import { AddOutlinedIcon, CloseOutlinedIcon, TrashOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { FieldArray, Formik, Form, getIn } from "formik";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useTransition } from "react-transition-state";
import { classNameFactory } from "@src/utils/dom";
import { selectRoomSelfPeer } from "@src/selectors";
import { useBuildMobile } from "@src/hooks/breakpoints";
import "./CreatePoll.scss";
const cn = classNameFactory("create-poll");
const validationSchema = Yup.object().shape({
    question: Yup.string().min(2, "Too Short!").max(150, "Too Long!").required("Required"),
    options: Yup.array()
        .of(Yup.object().shape({
        value: Yup.string().min(0, "Too Short!").max(100, "Too Long!").required("Required"),
        id: Yup.string(),
    }))
        .min(2, "You need at least two options"),
    pollDuration: Yup.object().shape({
        value: Yup.string(),
        id: Yup.string(),
    }),
    showVoteResultsTo: Yup.object().shape({
        value: Yup.string(),
        id: Yup.string(),
    }),
});
export const CreatePoll = ({ handleClose, onCreate, isOpen }) => {
    const [createPollModalState, toggleCreatePollModalState] = useTransition({ timeout: 500 });
    const [isMulti, setIsMulti] = useState(false);
    const formikRef = useRef(null);
    const isMobile = useBuildMobile();
    useEffect(() => {
        if (isOpen) {
            formikRef.current.resetForm();
        }
        toggleCreatePollModalState(isOpen);
    }, [isOpen]);
    return (_jsx("div", { className: `${cn("")} ${createPollModalState.status}`, children: _jsxs("div", { className: `${cn("content")} ${createPollModalState.status}`, children: [_jsxs("header", { className: cn("header"), children: [_jsx(Text, { size: "h4", fontWeight: "semiBold", children: isMobile ? "Polls" : "Create New Poll" }), _jsx(Button, { v2: true, icon: _jsx(CloseOutlinedIcon, { height: "20px", width: "20px" }), size: "small", type: "neutral", onClick: handleClose })] }), _jsx(Formik, { innerRef: formikRef, onSubmit: values => {
                        const peer = selectRoomSelfPeer().get();
                        const pollData = {
                            type: MessageType.POLL_QUESTION,
                            id: nanoid(),
                            title: values.question,
                            options: values.options,
                            timeToAnswer: Number(values.pollDuration.value) * 1000,
                            creator: { peerId: peer.peerId, name: peer.name },
                            time: new Date().toISOString(),
                            isMulti,
                        };
                        onCreate(pollData);
                        handleClose();
                    }, initialValues: {
                        question: "",
                        options: [
                            { id: nanoid(), value: "" },
                            { id: nanoid(), value: "" },
                        ],
                        pollDuration: { label: "Use default (2 mins)", value: "120" },
                        showVoteResultsTo: { label: "Everybody", value: "0" },
                    }, validationSchema: validationSchema, children: ({ handleChange, handleBlur, setFieldValue, values, touched, errors }) => (_jsxs(Form, { className: cn("form"), children: [_jsx("section", { className: cn("section"), children: _jsx(TextField, { v2: true, label: "Your Question (required)", maxLength: 150, name: "question", placeholder: "", size: "medium", state: touched.question && Boolean(errors.question) ? "error" : "normal", value: values.question, onChange: handleChange, onBlur: handleBlur, stateText: touched.question && errors.question }) }), _jsx("section", { className: cn("section"), children: _jsx(FieldArray, { name: "options", children: ({ remove, push }) => (_jsxs(_Fragment, { children: [values.options.map((option, index) => {
                                                const error = getIn(errors, `options.${index}`);
                                                const touch = getIn(touched, `options.${index}`);
                                                return index <= 1 ? (_jsx(TextField, { v2: true, value: option.value, label: `Option ${index + 1} (required)`, maxLength: 150, name: `options.${index}`, placeholder: "", size: "medium", onChange: e => setFieldValue(`options.${index}`, { ...option, value: e.target.value }), state: touch && error?.value ? "error" : "normal", stateText: touch && error?.value, onBlur: handleBlur }, index)) : (_jsxs("div", { className: cn("new-option"), children: [_jsx(TextField, { value: option.value, v2: true, label: `Option ${index + 1}`, maxLength: 150, name: `options.${index}`, placeholder: "", size: "medium", onChange: e => setFieldValue(`options.${index}`, { ...option, value: e.target.value }), state: touch && error?.value ? "error" : "normal", stateText: touch && error?.value, onBlur: handleBlur }, index), _jsx(Button, { v2: true, icon: _jsx(TrashOutlinedIcon, { height: "20px", width: "20px" }), size: "medium", type: "neutral", onClick: () => remove(index) })] }, index));
                                            }), _jsx(Button, { htmlType: "button", v2: true, startIcon: _jsx(AddOutlinedIcon, { height: "20px", width: "20px" }), size: "medium", type: "neutral", className: cn("add-more-btn"), onClick: () => push({ id: nanoid(), value: "" }), children: "Add Option" })] })) }) }), _jsxs("section", { className: cn("section"), children: [_jsx(Text, { size: "p1", fontWeight: "semiBold", children: "Poll Settings" }), _jsxs("div", { className: cn("settings-option"), children: [_jsx(Text, { size: "body-medium", children: "Poll duration" }), _jsx(SelectBox, { type: "text", name: "pollDuration", options: [
                                                    { label: "Until the meeting ends", value: Number.POSITIVE_INFINITY },
                                                    { label: "15 seconds", value: "15" },
                                                    { label: "30 seconds", value: "30" },
                                                    { label: "60 seconds", value: "60" },
                                                    { label: "Use default (2 mins)", value: "120" },
                                                ], size: "small", value: values.pollDuration, onChange: value => setFieldValue("pollDuration", value) })] }), _jsxs("div", { className: cn("settings-option"), children: [_jsx(Text, { size: "body-medium", children: "Show vote results to" }), _jsx(SelectBox, { name: "showVoteResultsTo", options: [
                                                    { label: "Everybody", value: "0" },
                                                    { label: "Only to Host", value: "1" },
                                                    { label: "Only to Host and Moderators", value: "2" },
                                                ], size: "small", value: values.showVoteResultsTo, onChange: value => setFieldValue("showVoteResultsTo", value) })] }), _jsxs("div", { className: cn("settings-option"), children: [_jsx(Text, { size: "body-medium", children: "Allow selecting multiple options" }), _jsx(ToggleButton, { onClick: () => setIsMulti(!isMulti) })] })] }), _jsx("div", { className: cn("footer"), children: _jsx(Button, { v2: true, type: "brand", variant: "primary", children: "Create Poll Now" }) })] })) })] }) }));
};
