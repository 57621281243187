import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { Button, Poll, Text } from "@ting/ting-ui-components";
import { AddOutlinedIcon, PollsOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { CreatePoll } from "../CreatePoll/CreatePoll";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { classNameFactory } from "@src/utils/dom";
import { selectActivePoll, selectActivePollTime, selectPolls } from "@src/selectors/poll";
import "./pollBody.scss";
const cn = classNameFactory("poll-body");
export const PollBody = ({ onCreate, onAnswer }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isCreatePollOpen, setIsCreatePollOpen] = useState(false);
    const polls = useSubscribe(selectPolls());
    const activePoll = useSubscribe(selectActivePoll());
    const activePollTime = useSubscribe(selectActivePollTime());
    useEffect(() => {
        if (!activePoll)
            setIsSubmitted(false);
    }, [activePoll]);
    const handleCreatePoll = (poll) => {
        setSelectedOptions([]);
        onCreate(poll);
    };
    const handleSubmitAnswer = () => {
        setIsSubmitted(true);
        onAnswer(selectedOptions);
    };
    return (_jsxs(Fragment, { children: [_jsx(CreatePoll, { isOpen: isCreatePollOpen, handleClose: () => setIsCreatePollOpen(false), onCreate: handleCreatePoll }), Boolean(polls.length) || isCreatePollOpen ? (_jsxs("div", { className: cn("polls-container"), children: [_jsxs("div", { className: cn("polls-list"), children: [polls
                                .filter(p => p.id !== activePoll?.id)
                                .map(p => {
                                const answersMap = p.answers.reduce((acc, val) => {
                                    val.content.answerIds?.forEach(id => {
                                        acc[id] = acc[id] ? acc[id] + 1 : 1;
                                    });
                                    return acc;
                                }, {});
                                const pollResults = p.options.map(option => ({ ...option, count: answersMap[option.id] || 0 }));
                                return (_jsx(Poll, { poll: p, pollResults: pollResults, selectedOptions: p.userSelectedOptions, isSubmitted: true, isActive: false, options: p.options }, p.id));
                            }), activePoll && (_jsx(Poll, { isMulti: activePoll.isMulti, pollTimer: activePollTime, selectedOptions: selectedOptions, isSubmitted: isSubmitted, poll: activePoll, isActive: true, options: activePoll.options, onChange: setSelectedOptions, handleSubmit: handleSubmitAnswer, handleEdit: () => setIsSubmitted(false) }))] }), !activePoll && (_jsx(Button, { className: cn("add-poll-btn"), v2: true, variant: "primary", type: "brand", round: true, size: "big", startIcon: _jsx(AddOutlinedIcon, { width: 24, height: 24 }), onClick: () => setIsCreatePollOpen(true) }))] })) : (_jsxs("div", { className: cn("not-active-state"), children: [_jsx("div", { className: cn("poll-icon"), children: _jsx(PollsOutlinedIcon, { width: "20px", height: "20px" }) }), _jsx(Text, { size: "p2", fontWeight: "semiBold", children: "No polls yet" }), _jsx(Text, { size: "p2", children: "Create a poll that other participants can respond to" }), _jsx(Button, { className: cn("add-poll-btn"), v2: true, variant: "primary", type: "brand", round: true, size: "big", startIcon: _jsx(AddOutlinedIcon, { width: 24, height: 24 }), onClick: () => setIsCreatePollOpen(true) })] }))] }));
};
