/* eslint-disable @typescript-eslint/ban-ts-comment */
import logger from "loglevel";
import { MediaTrackType } from "../SelfStreamService";
import { RTCConnectionState } from "../RTCConnection";
import { RoomStatus } from "@src/domain/Room/types";
import configs from "@src/configs";
import { PeerRoles } from "@src/domain/Peer";
import { DCMessageType, IMediaTrackType, PeerConnectionEvents, } from "@src/domain/RTCConnection";
import { selectHostPeer, selectRoom, selectRoomPeer, selectRoomPeers, selectRoomSelfPeer } from "@src/selectors";
import { errWithFingerprint, logWithFingerprint } from "@src/utils/sentryHelper";
import { DCMessageBuilder } from "@src/controller/helpers";
import { MediaTrackMetaData } from "@src/domain/RTCConnection/MediaTrackMetaData";
// TODO: handle hasAudio
export class MissingTrackService {
    constructor(rtcm) {
        Object.defineProperty(this, "interval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // for each peer we keep a few variables to track the status of their track
        Object.defineProperty(this, "peersInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rtcConnectionManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.rtcConnectionManager = rtcm;
        // fill the required variables for each peer
        const peers$ = selectRoomPeers();
        const initialPeers = Object.values(peers$.peek() ?? {});
        this.peersInfo = new Map();
        for (const peer of initialPeers) {
            this.addPeer(peer);
        }
        // listen for new peers and add it's corresponding variables to the list
        peers$.onChange(({ value: peers }) => {
            if (!peers)
                return;
            Object.entries(peers).forEach(([pid, peer]) => {
                if (this.peersInfo.has(pid))
                    return;
                this.addPeer(peer);
            });
        });
        this.rtcConnectionManager.on(PeerConnectionEvents.ReceivedDCMessage, (message) => {
            switch (message.type) {
                case DCMessageType.PEER_STREAM_STATUS: {
                    const streamStatus = message.payload;
                    const peerInfo = this.peersInfo.get(streamStatus.peerId);
                    if (!peerInfo)
                        throw new Error("Peer info in missing track does not exist");
                    peerInfo.shouldveReceivedAudioTrack = peerInfo.shouldveReceivedAudioTrack || streamStatus.isMicOn;
                    peerInfo.shouldveReceivedVideoTrack = streamStatus.isCamOn;
                    break;
                }
                case DCMessageType.MISSING_TRACK: {
                    const payload = message.payload;
                    this.handleMissingTrackMessage(message.from.peerId, payload.peerId, payload.audio, payload.video);
                    break;
                }
                case DCMessageType.POSSIBLE_MISSING_META: {
                    const payload = message.payload;
                    this.handleMissingMeta(payload.meta);
                }
            }
        });
        this.interval = setInterval(this.checkForMissingTracks.bind(this), configs.missingTrack.localCheckInterval);
    }
    stop() {
        clearInterval(this.interval);
        this.peersInfo = new Map();
    }
    addPeer(peer) {
        this.peersInfo.set(peer.peerId, {
            shouldveReceivedAudioTrack: peer.peerStreamSettings.isMicOn,
            shouldveReceivedVideoTrack: peer.peerStreamSettings.isCamOn,
            createdAt: new Date(),
        });
    }
    checkForMissingTracks() {
        try {
            if (selectRoom().status.peek() !== RoomStatus.active)
                return;
            const peers = selectRoomPeers().peek();
            for (const peer of Object.values(peers ?? {})) {
                if (peer.peerId === selectRoomSelfPeer().peerId.peek())
                    continue;
                this.checkTracksOfPeer(peer);
            }
        }
        catch (err) {
            errWithFingerprint(err, ["missingTrack"]);
        }
    }
    checkTracksOfPeer(peer) {
        const missingTrackInfo = this.peersInfo.get(peer.peerId);
        const peerStream = peer.peerStream;
        const selfPeer = selectRoomSelfPeer().peek();
        const hostPeer = selectHostPeer().peek();
        // don't check if tracks are not yet sent
        if (!missingTrackInfo.shouldveReceivedAudioTrack && !missingTrackInfo.shouldveReceivedVideoTrack)
            return;
        // wait a little bit for recently added tracks to be added
        if (new Date().getTime() - missingTrackInfo?.createdAt.getTime() < configs.missingTrack.timeToWaitAfterJoin)
            return;
        const at = peerStream?.getAudioTracks()[0];
        let isAudioMissing = (!at || at.readyState !== "live") && missingTrackInfo.shouldveReceivedAudioTrack;
        if (isAudioMissing) {
            isAudioMissing = this.findTrackLocally(peer, MediaTrackType.audio);
        }
        const vt = peerStream?.getVideoTracks()[0];
        let isVideoMissing = (!vt || vt.readyState !== "live") &&
            missingTrackInfo.shouldveReceivedVideoTrack &&
            peer.peerStreamSettings.isCamOn;
        if (isVideoMissing) {
            isVideoMissing = this.findTrackLocally(peer, MediaTrackType.video);
        }
        if (isAudioMissing || isVideoMissing) {
            logWithFingerprint(`Could not find the missing track asking corresponding node to send it again`, ["missingTrack", hostPeer.peerId], {
                peerName: peer.name,
                selfPeer: selfPeer.name,
                isSuperNode: selfPeer.role === PeerRoles.SUPER_NODE,
                isAudioMissing,
                isVideoMissing,
            });
            const message = DCMessageBuilder.BuildMissingTrackMessage(selfPeer, peer, isAudioMissing, isVideoMissing);
            this.rtcConnectionManager.sendMessage(message);
        }
    }
    findTrackLocally(peer, trackKind) {
        const hostPeer = selectHostPeer().peek();
        const selfPeer = selectRoomSelfPeer().peek();
        try {
            const rtcConnectionManager = this.rtcConnectionManager;
            logWithFingerprint(`There exists a missing ${trackKind} track from ${peer.name}`, ["missingTrack", hostPeer.peerId], {
                peerName: peer.name,
                selfPeer: selfPeer.name,
                isSuperNode: selfPeer.role === PeerRoles.SUPER_NODE,
            });
            const types = trackKind === MediaTrackType.audio
                ? [IMediaTrackType.Audio, IMediaTrackType.ForwardedAudio]
                : [IMediaTrackType.Video, IMediaTrackType.ForwardedVideo];
            const metaValidator = (meta) => meta.peerId === peer.peerId && types.includes(meta.type);
            const foundMeta = rtcConnectionManager["metaStore"].find(metaValidator);
            let found;
            for (const [, pc] of rtcConnectionManager["pcMap"]) {
                found = pc.remoteTracks.find(remote => remote.stream.id === foundMeta?.streamId && remote.track.kind === foundMeta?.kind);
                if (found)
                    break;
            }
            if (found) {
                logWithFingerprint(`Found the missing ${trackKind} track locally`, ["missingTrack", hostPeer.peerId], {
                    trackKind: found?.track?.kind,
                    enabled: found?.track?.enabled,
                    muted: found?.track?.muted,
                    readyState: found?.track?.readyState,
                    found,
                    peerName: peer.name,
                    selfPeer: selfPeer.name,
                    isSuperNode: selfPeer.role === PeerRoles.SUPER_NODE,
                });
            }
            if (found && found?.track?.readyState === "live") {
                logWithFingerprint(`going to dispatch receivedRemoteTrack action`, ["missingTrack", hostPeer.peerId], {
                    selfPeer: selfPeer.name,
                    trackKind: found?.track?.kind,
                    peerName: peer.name,
                    isSuperNode: selfPeer.role === PeerRoles.SUPER_NODE,
                });
                try {
                    rtcConnectionManager.emit(PeerConnectionEvents.ReceivedTrack, {
                        peerId: peer.peerId,
                        trackType: foundMeta.type,
                        track: found.track,
                        stream: found.stream,
                    });
                }
                catch (e) {
                    logger.error(e);
                }
                return false;
            }
        }
        catch (err) {
            errWithFingerprint(err, ["missingTrack", hostPeer.peerId], {
                in: "find tracks locally",
            });
        }
        return true;
    }
    handleMissingTrackMessage(senderPeerId, targetPeerId, audio, video) {
        const hostPeerId = selectHostPeer().peerId.peek();
        const targetPeer = selectRoomPeer(targetPeerId).peek();
        const senderPeer = selectRoomPeer(senderPeerId).peek();
        const selfPeer = selectRoomSelfPeer().peek();
        const isSuperNode = selfPeer.role === PeerRoles.SUPER_NODE;
        const rtcConnectionManager = this.rtcConnectionManager;
        try {
            logWithFingerprint("Handling Missing Track", ["missingTrack", hostPeerId], {
                senderPeer: senderPeer.name,
                targetPeer: targetPeer.name,
                selfPeer: selfPeer.name,
                isSuperNode,
                audio,
                video,
            });
            if (isSuperNode) {
                const targetStream = targetPeer.peerStream;
                const targetAudioTrack = targetStream?.getAudioTracks()[0];
                const targetVideoTrack = targetStream?.getVideoTracks()[0];
                const isAudioTrackValid = targetAudioTrack && targetAudioTrack?.readyState !== "ended";
                if (audio)
                    logWithFingerprint("Target audio track in requestee", ["missingTrack", hostPeerId], {
                        senderPeer: senderPeer.name,
                        targetPeer: targetPeer.name,
                        selfPeer: selfPeer.name,
                        isSuperNode,
                        hasTargetTrack: targetAudioTrack,
                        muted: targetAudioTrack?.muted,
                        readyState: targetAudioTrack?.readyState,
                    });
                let shouldAskTargetForAudio = true;
                if (targetAudioTrack && audio && isAudioTrackValid) {
                    this.sendTrackAgain(senderPeer, targetPeer, targetAudioTrack, targetStream, "audio", {
                        senderPeer: senderPeer.name,
                        targetPeer: targetPeer.name,
                        selfPeer: selfPeer.name,
                        isSuperNode: isSuperNode.toString(),
                        hostPeerId,
                    });
                    shouldAskTargetForAudio = false;
                }
                const isVideoTrackValid = targetVideoTrack && targetVideoTrack?.muted === false && targetVideoTrack?.readyState !== "ended";
                if (video)
                    logWithFingerprint("Target video track in requestee", ["missingTrack", hostPeerId], {
                        senderPeer: senderPeer.name,
                        targetPeer: targetPeer.name,
                        selfPeer: selfPeer.name,
                        isSuperNode,
                        hasTargetTrack: targetVideoTrack,
                        muted: targetVideoTrack?.muted,
                        readyState: targetVideoTrack?.readyState,
                    });
                let shouldAskTargetForVideo = true;
                if (targetVideoTrack && video && isVideoTrackValid) {
                    this.sendTrackAgain(senderPeer, targetPeer, targetVideoTrack, targetStream, "video", {
                        senderPeer: senderPeer.name,
                        targetPeer: targetPeer.name,
                        selfPeer: selfPeer.name,
                        hostPeerId,
                        isSuperNode: isSuperNode.toString(),
                    });
                    shouldAskTargetForVideo = false;
                }
                if (shouldAskTargetForAudio || shouldAskTargetForVideo) {
                    // TODO: fingerprint should include requester
                    logWithFingerprint("couldn't find target track asking the target peer", ["missingTrack", hostPeerId], {
                        senderPeer: senderPeer.name,
                        targetPeer: targetPeer.name,
                        selfPeer: selfPeer.name,
                        isSuperNode: isSuperNode.toString(),
                    });
                    if (rtcConnectionManager["pcMap"].get(targetPeer.peerId).connState === RTCConnectionState.CONNECTED) {
                        rtcConnectionManager.sendMessage(DCMessageBuilder.BuildMissingTrackMessage(selfPeer, targetPeer, audio && !targetAudioTrack, video && !targetVideoTrack));
                    }
                    else {
                        logWithFingerprint("target peer is not connected to us", ["missingTrack", hostPeerId], {
                            senderPeer: senderPeer.name,
                            targetPeer: targetPeer.name,
                            selfPeer: selfPeer.name,
                            isSuperNode,
                        });
                    }
                }
            }
            else {
                const stream = selfPeer.peerStream;
                const audioTrack = stream?.getAudioTracks()[0];
                const videoTrack = stream?.getVideoTracks()[0];
                logWithFingerprint("going to set track for the missing track", ["missingTrack", hostPeerId], {
                    senderPeer: senderPeer.name,
                    targetPeer: targetPeer.name,
                    audioTrack,
                    videoTrack,
                });
                rtcConnectionManager.setSendingStream(stream);
                //check if meta is available for the track
                stream.getTracks().forEach(t => {
                    const meta = rtcConnectionManager["metaStore"].find(stream.id, t.kind);
                    if (!meta)
                        rtcConnectionManager["metaStore"].add(MediaTrackMetaData.buildFromTrack(t, stream, selfPeer.peerId, t.kind === "audio" ? IMediaTrackType.Audio : IMediaTrackType.Video));
                });
                for (const [, pc] of rtcConnectionManager["pcMap"]) {
                    //@ts-ignore
                    pc["npc"].negotiate();
                }
            }
        }
        catch (err) {
            errWithFingerprint(err, ["missingTrack", hostPeerId], {
                senderPeer: senderPeer.name,
                targetPeer: targetPeer.name,
                selfPeer: selfPeer.name,
                isSuperNode,
                audio,
                video,
            });
        }
    }
    // this only happens on super node
    sendTrackAgain(senderPeer, targetPeer, track, stream, kind, context) {
        const selfPeer = selectRoomSelfPeer().peek();
        const rtcConnectionManager = this.rtcConnectionManager;
        let senderHasMeta = rtcConnectionManager["metaStore"].find(meta => {
            return stream.id === meta.streamId && meta.kind === kind;
        });
        logWithFingerprint(`handle missing track-> sendTrackAgain: found Meta? ${Boolean(senderHasMeta)}`, ["missingTrack", context.hostPeerId], {
            ...context,
            meta: senderHasMeta,
        });
        if (!senderHasMeta) {
            senderHasMeta = MediaTrackMetaData.buildFromTrack(track, stream, targetPeer.peerId, kind === "audio" ? IMediaTrackType.ForwardedAudio : IMediaTrackType.ForwardedVideo);
            rtcConnectionManager["metaStore"].add(senderHasMeta);
        }
        try {
            const npc = rtcConnectionManager["pcMap"].get(senderPeer.peerId)["npc"];
            // @ts-ignore
            const sender = npc?._senderMap?.get(track)?.get(stream);
            const isConnected = npc?.connected && sender?.transport?.state === "connected";
            if (sender)
                logWithFingerprint(`status of the already sent track`, ["missingTrack", context.hostPeerId], {
                    ...context,
                    pcState: isConnected,
                });
            if (sender && isConnected) {
                logWithFingerprint(`going to send a missing meta message`, ["missingTrack", context.hostPeerId], {
                    ...context,
                });
                // so the most probable cause is that the senderPeer does not have the metadata but does have track
                rtcConnectionManager.sendMessage(DCMessageBuilder.BuildMissingMetaMessage(selfPeer, senderHasMeta, senderPeer));
            }
            else if (sender && !isConnected) {
                const info = {
                    ...context,
                    pcState: isConnected,
                    sender,
                    transport: sender.transport,
                    senderState: sender.transport?.state,
                    // @ts-ignore
                    isNegotiating: npc._isNegotiating,
                };
                logWithFingerprint(`the requested track does exist on pc but there seems to be a problem with connection itself`, ["missingTrack", context.hostPeerId], info);
                // @ts-ignore
                if (npc._isNegotiating) {
                    logWithFingerprint(`we are already negotiating not doing it again`, ["missingTrack", context.hostPeerId], info);
                }
                else {
                    logWithFingerprint(`let us renegotiate with the peer`, ["missingTrack", context.hostPeerId], info);
                    // @ts-ignore
                    npc.negotiate();
                }
            }
            else if (!sender) {
                logWithFingerprint("there are no senders available for this track. Adding the track to connection", ["missingTrack", context.hostPeerId], context);
                // let's see if we have the track in the forwarding list
                // and add it to the list if it is not in there
                const trackInList = rtcConnectionManager["forwardedTrackList"].find(({ track: t }) => track === t);
                if (!trackInList) {
                    rtcConnectionManager["forwardedTrackList"].push({ track, stream });
                }
                npc.addTrack(track, stream);
                logWithFingerprint("successfully resent the missing track", ["missingTrack", context.hostPeerId], context);
            }
        }
        catch (err) {
            errWithFingerprint(err, ["missingTrack", context.hostPeerId], context);
        }
    }
    handleMissingMeta(incomingMeta) {
        const hostPeer = selectHostPeer().peek();
        const selfPeer = selectRoomSelfPeer().peek();
        const metaOwnerPeer = selectRoomPeer(incomingMeta.peerId).peek();
        try {
            const rtcConnectionManager = this.rtcConnectionManager;
            logWithFingerprint("handle missing meta", ["missingTrack", hostPeer.peerId], {
                peerName: selfPeer.name,
                metaOwnerName: metaOwnerPeer.name,
                meta: incomingMeta,
            });
            // first let's see if we already have the meta
            const foundMeta = rtcConnectionManager["metaStore"].find(m => m.streamId === incomingMeta.streamId && m.kind === incomingMeta.kind);
            logWithFingerprint(`have we found the meta locally? ${Boolean(foundMeta)}`, ["missingTrack", hostPeer.peerId], {
                peerName: selfPeer.name,
                metaOwnerName: metaOwnerPeer.name,
                meta: incomingMeta,
                foundMeta,
            });
            // then let's see if we have a track related to this meta
            const peerWithConnection = selfPeer === hostPeer ? metaOwnerPeer : hostPeer;
            const streamTrack = rtcConnectionManager["pcMap"]
                .get(peerWithConnection.peerId)["npc"].remoteTracks.find(remote => remote.stream.id === incomingMeta?.streamId && remote.track.kind === incomingMeta?.kind);
            logWithFingerprint(`have we found any tracks related to this meta? ${Boolean(streamTrack)}`, ["missingTrack", hostPeer.peerId], {
                peerName: selfPeer.name,
                metaOwnerName: metaOwnerPeer.name,
                meta: incomingMeta,
                streamTrack: streamTrack?.track,
                foundTrack: streamTrack?.track?.id,
                foundStream: streamTrack?.stream?.id,
            });
            // if we do have the track then add the meta and emit track
            if (streamTrack) {
                rtcConnectionManager["metaStore"].add(incomingMeta);
                rtcConnectionManager.emit(PeerConnectionEvents.ReceivedTrack, {
                    peerId: incomingMeta.peerId,
                    trackType: incomingMeta.type,
                    track: streamTrack.track,
                    stream: streamTrack.stream,
                });
            }
        }
        catch (err) {
            errWithFingerprint(err, ["missingTrack", hostPeer.peerId], {
                selfPeer: selfPeer.name,
                metaOwner: metaOwnerPeer.name,
                incomingMeta,
                isSuperNode: selfPeer.role === PeerRoles.SUPER_NODE,
            });
        }
    }
}
