import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalTabs } from "../ModalTabs";
import { SourceList } from "../SourceList";
import { PeerList } from "../PeerList";
import { LocalStream } from "@src/services/localStream";
import { SourceKind } from "@src/domain/Studio/types";
export const SelectSourceTabList = () => {
    const { t } = useTranslation(["common", "studio"]);
    const [mediaList, setMediaList] = useState([]);
    useEffect(() => {
        if (!mediaList?.length) {
            LocalStream.getMediaList().then(mediaList => {
                setMediaList(mediaList);
            });
        }
    }, [mediaList]);
    if (mediaList?.length) {
        return (_jsx(ModalTabs, { list: [
                {
                    label: t("audio"),
                    content: (_jsx(SourceList, { list: mediaList.filter((media) => media.sourceKind === SourceKind.AUDIO_INPUT) })),
                },
                {
                    label: t("video"),
                    content: (_jsx(SourceList, { list: mediaList.filter((media) => {
                            return media.sourceKind === SourceKind.VIDEO_INPUT;
                        }) })),
                },
                {
                    label: t("display"),
                    content: (_jsx(SourceList, { list: mediaList.filter((media) => {
                            return (SourceKind.DISPLAY_CAPTURE === media.sourceKind ||
                                SourceKind.WINDOW_CAPTURE === media.sourceKind ||
                                SourceKind.BROWSER_CAPTURE === media.sourceKind);
                        }) })),
                },
                {
                    label: t("media"),
                    content: (_jsx(SourceList, { list: mediaList.filter((media) => {
                            return (SourceKind.IMAGE_FILE === media.sourceKind ||
                                SourceKind.VIDEO_FILE === media.sourceKind ||
                                SourceKind.AUDIO_FILE === media.sourceKind);
                        }) })),
                },
                {
                    label: t("participants"),
                    content: _jsx(PeerList, {}),
                },
            ] }));
    }
    return null;
};
