import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { SlideDown } from "@src/components/SlideDown";
import { classNameFactory } from "@src/utils/dom";
import { PanelController } from "@src/components/Studio/PanelController";
import { adjustSourcePosition, setSourceFillCanvas, setSourceStretchToCanvas, updateSourceIndexOrder, } from "@src/controller";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSource } from "@src/selectors/studio";
import { SourceAlignment, SourceReorder } from "@src/domain/Studio/types";
import "./alignmentSlideDown.scss";
const cn = classNameFactory("alignment-slide");
export const AlignmentSlideDown = () => {
    const { t } = useTranslation("studio");
    const source = useSubscribe(selectStudioActiveSource());
    const { id } = source;
    return (_jsx(SlideDown, { title: t("alignmentAndLayout"), content: _jsxs("div", { className: cn(""), children: [_jsx("div", { className: cn("alignment"), children: _jsx(PanelController, { className: "alignment-actions", leftActions: {
                            alignHorizontalLeft: () => adjustSourcePosition(id, SourceAlignment.HORIZONTAL_LEFT),
                            alignHorizontalCenter: () => adjustSourcePosition(id, SourceAlignment.HORIZONTAL_CENTER),
                            alignHorizontalRight: () => adjustSourcePosition(id, SourceAlignment.HORIZONTAL_RIGHT),
                            separator: true,
                            alignVerticalTop: () => adjustSourcePosition(id, SourceAlignment.VERTICAL_TOP),
                            alignVerticalCenter: () => adjustSourcePosition(id, SourceAlignment.VERTICAL_CENTER),
                            alignVerticalBottom: () => adjustSourcePosition(id, SourceAlignment.VERTICAL_BOTTOM),
                        } }) }), _jsxs("div", { className: cn("group"), children: [_jsxs("div", { className: cn("order"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("order") }), _jsx(PanelController, { className: "order-actions", leftActions: {
                                        bringForward: () => updateSourceIndexOrder(id, SourceReorder.FORWARD),
                                        bringToFront: () => updateSourceIndexOrder(id, SourceReorder.FRONT),
                                        sendBackward: () => updateSourceIndexOrder(id, SourceReorder.BACKWARD),
                                        sendToBack: () => updateSourceIndexOrder(id, SourceReorder.BACK),
                                    } })] }), _jsxs("div", { className: cn("layout"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("layout") }), _jsx(PanelController, { className: "layout-actions", leftActions: {
                                        fillCanvas: () => setSourceFillCanvas(id),
                                        stretchToCanvas: () => setSourceStretchToCanvas(id),
                                    } })] })] })] }) }));
};
