import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, RPopover, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { classNameFactory } from "@src/utils/dom";
import { routePaths } from "@src/routes/routes";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomSelfPeer, selectRoomSlug } from "@src/selectors";
import { PeerRoles } from "@src/domain/Peer/types";
import { setLiveChatToggle } from "@src/controller/live-chat/chat";
import { setChatToggle } from "@src/controller";
import "./navigator.scss";
const cn = classNameFactory("navigator");
export const Navigator = () => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const roomId = useSubscribe(selectRoomSlug());
    const selfPeer = useSubscribe(selectRoomSelfPeer());
    const isStudioRoute = useRouteMatch({
        path: "/room/:roomId/studio",
        strict: true,
        sensitive: true,
    });
    return (_jsxs("div", { className: cn(""), children: [_jsx(Button, { v2: true, size: "small", type: !isStudioRoute?.isExact ? "brand" : "neutral", variant: !isStudioRoute?.isExact ? "tertiary" : "ghost", onClick: () => {
                    history.push(routePaths.ROOM(roomId));
                    setChatToggle(true);
                    setLiveChatToggle(false);
                }, children: t("meeting") }), selfPeer?.role === PeerRoles.SUPER_NODE ? (_jsx(Button, { v2: true, size: "small", type: isStudioRoute?.isExact ? "brand" : "neutral", variant: isStudioRoute?.isExact ? "tertiary" : "ghost", onClick: () => {
                    history.push(routePaths.STUDIO(roomId));
                    setChatToggle(false);
                    setLiveChatToggle(true);
                }, children: t("studio") })) : (_jsx(RPopover, { zIndex: 99, showOnHover: true, placement: "top-start", trigger: _jsx(Button, { v2: true, size: "small", type: "neutral", variant: "ghost", disabled: true, children: t("studio") }), 
                // todo Fix remove hard coded style after Popover tooltip prop has been implemented
                content: _jsx("span", { style: { backgroundColor: "#212b3a", padding: 8, borderRadius: 8 }, children: _jsx(Text, { size: "body-medium", children: t("studio:warning.studioAccess") }) }) }))] }));
};
