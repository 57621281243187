import { jsx as _jsx } from "react/jsx-runtime";
import { AdjustOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useTranslation } from "react-i18next";
import { PanelController } from "../PanelController";
import { Panel } from "../Panel";
import { SourceType } from "@src/domain/Studio/types";
import { AudioSource } from "@src/components/Studio/Mixer/components";
import { updateSourceVolume } from "@src/controller";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectActiveScenesID, selectStudioSources } from "@src/selectors/studio";
export const Mixer = () => {
    const { t } = useTranslation("studio");
    const activeSceneId = useSubscribe(selectActiveScenesID());
    const sources = useSubscribe(selectStudioSources());
    const activeSources = sources && activeSceneId && sources[activeSceneId];
    return (_jsx(Panel, { header: _jsx(PanelController, { title: t("mixer"), titleSize: "small", icon: _jsx(AdjustOutlinedIcon, { height: "24px", width: "24px" }), rightActions: {
                settings: () => {
                    // console.log("settings mixer action");
                },
            } }), children: activeSources &&
            Object.entries(activeSources).map(([id, source]) => {
                if (source.sourceType === SourceType.SOUND) {
                    return (_jsx(AudioSource, { source: source, onVolumeChanged: (volume) => {
                            updateSourceVolume(source.id, volume);
                        } }, id));
                }
                return null;
            }) }));
};
