import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, FormTextField, Modal, PositionType, Text } from "@ting/ting-ui-components";
import { Header, HeaderSizes } from "@src/components/Header";
import { classNameFactory } from "@src/utils/dom";
import { useBuildMobile } from "@src/hooks/breakpoints";
import "./nameAuthenticationModal.scss";
const cn = classNameFactory("name-auth-modal");
const initialValues = {
    firstName: "",
    lastName: "",
    saveData: false,
};
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("* Required"),
    lastName: Yup.string().required("* Required"),
});
export const NameAuthenticationModal = props => {
    const { isOpen, onClose } = props;
    const { t } = useTranslation(["common", "room"]);
    const isBuildMobile = useBuildMobile();
    const onFormSubmit = (formValues) => {
        // eslint-disable-next-line no-console
        console.log(formValues);
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, hideCloseButton: true, containerClassName: cn(""), position: isBuildMobile ? PositionType.BOTTOM : PositionType.CENTER, children: [_jsx(Header, { onClose: onClose, filled: true, isModal: true, size: isBuildMobile ? HeaderSizes.small : HeaderSizes.medium, children: t("room:name-authentication") }), _jsx(Formik, { initialValues: initialValues, onSubmit: onFormSubmit, validationSchema: validationSchema, children: ({ handleSubmit, errors, touched }) => (_jsxs("div", { className: cn("content"), children: [_jsx(Text, { size: "p2", type: "sub", children: t("room:name-authentication-description") }), _jsx(FormTextField, { v2: true, name: "firstName", label: t("first-name"), size: "large", className: cn("input"), state: errors?.firstName && touched?.firstName ? "error" : undefined, stateText: touched?.firstName ? errors?.firstName : undefined }), _jsx(FormTextField, { v2: true, name: "lastName", label: t("last-name"), size: "large", className: cn("input"), state: errors?.lastName && touched?.lastName ? "error" : undefined, stateText: touched?.lastName ? errors?.lastName : undefined }), _jsxs("div", { className: cn("checkbox"), children: [_jsx(Checkbox, { name: "saveData", size: "big" }), _jsx(Text, { size: "p2", fontWeight: "medium", children: t("room:save-for-future-meeting") })] }), _jsx(Button, { v2: true, disabled: Boolean(errors?.firstName || errors?.lastName), type: "brand", size: "big", className: cn("submit"), onClick: handleSubmit, children: t("authenticate") })] })) })] }));
};
