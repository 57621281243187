import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { AddSourceContext } from "./Context";
import { AddSourceContainer } from "./components";
export const AddSourceModal = ({ isOpen, onClose }) => {
    const [step, setStep] = useState(1);
    const [selected, setSelected] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    return (_jsx(AddSourceContext.Provider, { value: {
            isOpen,
            onClose,
            step,
            setStep,
            selected,
            setSelected,
            selectedSource,
            setSelectedSource,
        }, children: _jsx(AddSourceContainer, {}) }));
};
