import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@ting/ting-ui-components";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomTitle } from "@src/selectors";
import { TimerController } from "@src/components/ControlBar/TimerController";
import { classNameFactory } from "@src/utils/dom";
import "./modalMeetHeader.scss";
const cn = classNameFactory("modal-meet-header");
export const ModalMeetHeader = () => {
    const roomTitle = useSubscribe(selectRoomTitle());
    return (_jsxs("div", { className: cn(""), children: [_jsx("div", { className: cn("indicator") }), _jsx(Text, { size: "body-medium", fontWeight: "semiBold", className: cn("title"), children: roomTitle }), _jsx(TimerController, {})] }));
};
