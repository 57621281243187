import { useState, useEffect, useRef } from "react";
export const useTimer = (isOn, date) => {
    const interval = useRef();
    const [startTime, setStartTime] = useState("00:00:00");
    useEffect(() => {
        if (!isOn) {
            setStartTime("00:00:00");
            return;
        }
        interval.current = setInterval(() => {
            const diff = Math.abs(new Date().getTime() - date.getTime());
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const hoursString = hours.toString().padStart(2, "0");
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const minutesString = minutes.toString().padStart(2, "0");
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);
            const secondsString = seconds.toString().padStart(2, "0");
            setStartTime(`${hoursString}:${minutesString}:${secondsString}`);
        }, 1000);
        return () => {
            if (interval.current)
                clearInterval(interval.current);
        };
    }, [interval, date, isOn]);
    const stopTimer = () => {
        setStartTime("00:00:00");
        if (interval.current)
            clearInterval(interval.current);
    };
    return [startTime, stopTimer];
};
