export function debounce(func, delay, immediate = false) {
    let timeoutId;
    return function (...args) {
        const callNow = immediate && !timeoutId;
        // Clear the existing timeout
        clearTimeout(timeoutId);
        // Set a new timeout with the specified delay
        timeoutId = setTimeout(() => {
            timeoutId = null;
            if (!immediate) {
                func(...args);
            }
        }, delay);
        if (callNow) {
            func(...args);
        }
    };
}
