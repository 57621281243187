import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import { Button, Modal } from "@ting/ting-ui-components";
import getCroppedImg from "@src/utils/helpers/cropImage";
import { classNameFactory } from "@src/utils/dom";
import "./CropImage.scss";
const cn = classNameFactory("crop-modal");
export const CropImage = ({ onSave, image, showGrid = false, cropShape = "rect", aspect = 1, isOpen = true, 
// eslint-disable-next-line @typescript-eslint/no-empty-function
onClose = () => { }, }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixelsValue, setCroppedAreaPixelsValue] = useState(null);
    const { t } = useTranslation("common");
    const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsValue(croppedAreaPixels);
    }, []);
    const onCroppedImageSave = useCallback(async () => {
        const croppedImage = await getCroppedImg(image, croppedAreaPixelsValue);
        onSave(croppedImage);
    }, [croppedAreaPixelsValue, image, onSave]);
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, children: _jsxs("div", { className: cn(""), children: [_jsx("div", { className: cn("container"), children: _jsx(Cropper, { image: image, crop: crop, zoom: zoom, aspect: aspect, cropShape: cropShape, showGrid: showGrid, onCropChange: setCrop, onCropComplete: onCropComplete, onZoomChange: setZoom }) }), _jsx("div", { className: cn("submit"), children: _jsx(Button, { theme: "primary", onClick: onCroppedImageSave, children: t("save") }) })] }) }));
};
