import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MessageType, Tab, Tabs, Text, } from "@ting/ting-ui-components";
import { ChatOutlinedIcon, PollsOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { nanoid } from "nanoid";
import { PollFrame } from "../Poll/PollFrame";
import { LiveChatFrame } from "./ChatFrame";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectLiveChat } from "@src/selectors/live-chat";
import { selectUser } from "@src/selectors";
import { selectStudioIsLiveON } from "@src/selectors/studio";
import { LiveChatWebTorrentUtil } from "@src/services/WebtorrentService";
import { setLiveChatActiveTabIndex, setLiveChatOpen } from "@src/controller/live-chat/chat";
import { chatSettings } from "@src/configs/chat";
import { addPollAnswer, setActivePoll } from "@src/controller/poll";
import { PanelController } from "@src/components/Studio";
import { selectActivePoll } from "@src/selectors/poll";
import "./liveChat.scss";
const cn = classNameFactory("live-chat");
export const LiveChat = () => {
    const user = useSubscribe(selectUser());
    const chatState = useSubscribe(selectLiveChat());
    const isLiveOn = useSubscribe(selectStudioIsLiveON());
    const activePoll = useSubscribe(selectActivePoll());
    const handleAnswerPoll = (selectedIds) => {
        const from = {
            displayName: user?.account.displayName,
            userName: user?.username,
            avatarUrl: user?.account.avatar
                ? `${user?.account.avatar?.path}`
                : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
            corepassId: user?.corepassId || chatSettings.guestCorePassID,
        };
        const pollAnswer = {
            id: nanoid(),
            type: MessageType.POLL_ANSWER,
            from,
            content: {
                pollId: activePoll.id,
                answerIds: selectedIds,
            },
            time: new Date().toISOString(),
            viewer: from,
        };
        LiveChatWebTorrentUtil.Instance.sendNewLiveChatMessage(pollAnswer);
        addPollAnswer(pollAnswer, user);
    };
    const handleCreatePoll = (poll) => {
        setActivePoll(poll);
        LiveChatWebTorrentUtil.Instance.sendNewLiveChatMessage(poll);
    };
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("header"), children: [_jsx(PanelController, { title: "Live Stream", titleSize: "large", rightActions: {
                            close: () => {
                                setLiveChatOpen(false);
                            },
                        } }), _jsxs(Tabs, { value: chatState.activeTabIndex, onChange: idx => setLiveChatActiveTabIndex(idx), variant: "secondary", children: [_jsx(Tab, { className: cn("tab"), children: _jsx(ChatOutlinedIcon, { width: "20px", height: "20px" }) }), _jsx(Tab, { className: cn("tab"), children: _jsx(PollsOutlinedIcon, { width: "20px", height: "20px" }) })] })] }), user && isLiveOn ? (_jsxs("div", { className: cn("content"), children: [chatState.activeTabIndex === 0 && _jsx(LiveChatFrame, {}), chatState.activeTabIndex === 1 && _jsx(PollFrame, { onAnswer: handleAnswerPoll, onCreate: handleCreatePoll })] })) : (_jsxs("div", { className: cn("not-active-state"), children: [_jsx("div", { className: cn("chat-icon"), children: _jsx(ChatOutlinedIcon, { width: "20px", height: "20px" }) }), _jsx(Text, { size: "p2", children: "Live chat is not active" }), _jsx(Text, { size: "p2", children: "Live chat for this stream has not been activated" })] }))] }));
};
