import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { fromEvent } from "rxjs";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import { LockOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { Button, Text } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import { resize, resizeWithAspectRatio } from "@src/utils/resize";
import { StudioMerger } from "@src/services/studioService";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveScene } from "@src/selectors/studio";
import { activateSource, mapRetrievalSource, updateSourcePosition, updateSourceSize } from "@src/controller";
import "./sourceFrame.scss";
const cn = classNameFactory("source-frame");
export const SourceFrame = ({ source }) => {
    const { t } = useTranslation("studio");
    const scene = useSubscribe(selectStudioActiveScene());
    const nodeRef = useRef();
    const [aspectRatio, setAspectRatio] = useState(source.ratio);
    const [keepOriginalAspectRatio, setKeepOriginalAspectRatio] = useState(true);
    let resizeSubscription;
    useEffect(() => {
        const keyDownTrack = fromEvent(document, "keydown").subscribe(event => {
            if (event instanceof KeyboardEvent && event.key === "Shift") {
                setKeepOriginalAspectRatio(false);
            }
        });
        const keyUpTrack = fromEvent(document, "keyup").subscribe(event => {
            if (event instanceof KeyboardEvent && event.key === "Shift") {
                setKeepOriginalAspectRatio(true);
            }
        });
        return () => {
            keyDownTrack.unsubscribe();
            keyUpTrack.unsubscribe();
        };
    });
    // Set aspect ratio
    useEffect(() => {
        if (source?.source instanceof MediaStream && source.source.getVideoTracks().length) {
            const [track] = source.source.getVideoTracks();
            const { aspectRatio, width, height } = track.getSettings() || {};
            const aspect = aspectRatio || width / height;
            if (aspect)
                setAspectRatio(aspect);
        }
        else if (source?.source instanceof HTMLImageElement) {
            setTimeout(() => {
                setAspectRatio(source.height / source.width + 1);
            }, 0);
        } // todo add Video source condition
        return () => {
            if (resizeSubscription)
                resizeSubscription.unsubscribe();
        };
    }, [setAspectRatio, resizeSubscription, aspectRatio, source]);
    // Update source position
    const updatePosition = (data) => {
        if (!source.active)
            return;
        const x = (data.x / scene.width) * 100;
        const y = (data.y / scene.height) * 100;
        updateSourcePosition(source.id, x, y);
    };
    // Update source size
    const resizeBox = (event) => {
        const { canvas } = StudioMerger.instance;
        const { offsetWidth: offsetW, offsetHeight: offsetH } = canvas;
        const { width: w, height: h, positionX: x, positionY: y } = source;
        const resizeHandler = keepOriginalAspectRatio
            ? resizeWithAspectRatio(event, w, h, (x / offsetW) * 100, (y / offsetH) * 100, canvas, aspectRatio || 1)
            : resize(event, w, h, (x / offsetW) * 100, (y / offsetH) * 100, canvas);
        return resizeHandler.subscribe(result => {
            const { width, height } = result;
            const w = width < 0 ? 0 : width > 100 ? 100 : width;
            const h = height < 0 ? 0 : height > 100 ? 100 : height;
            updateSourceSize(source.id, Math.round(w), Math.round(h));
        });
    };
    const mouseDownHandler = (event) => {
        if (resizeSubscription) {
            resizeSubscription.unsubscribe();
        }
        resizeSubscription = resizeBox(event);
        event.stopPropagation();
    };
    const handleFrameClick = () => {
        if (!source.active) {
            activateSource(source.id);
        }
    };
    if (source.isRetrieval) {
        return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        _jsxs("div", { className: cn({ retrieval: true, active: source.active }), style: {
                width: `${source.width}%`,
                height: `${source.height}%`,
                left: `${source.positionX}%`,
                top: `${source.positionY}%`,
                zIndex: source.active ? 100 : source.index,
            }, onClick: handleFrameClick, children: [_jsxs(Text, { size: "small", type: "sub", children: [t(`mapSource.${source.sourceKind}`), " missing!"] }), _jsx(Button, { v2: true, size: "small", type: "brand", variant: "primary", onClick: () => mapRetrievalSource(source), children: "Map source" })] }));
    }
    if (source.lock) {
        return (_jsx("button", { className: cn({ lock: source.lock, active: source.active }), style: {
                width: `${source.width}%`,
                height: `${source.height}%`,
                left: `${source.positionX}%`,
                top: `${source.positionY}%`,
                zIndex: source.index,
            }, onClick: handleFrameClick, children: _jsx(LockOutlinedIcon, { width: 20, height: 20 }) }));
    }
    return (_jsx(Draggable, { nodeRef: nodeRef, cancel: ".content-is-editable", position: {
            x: (source.positionX * scene.width) / 100,
            y: (source.positionY * scene.height) / 100,
        }, scale: 1, onDrag: (_, data) => updatePosition(data), children: _jsx("div", { ref: nodeRef, className: cn({ active: source.active }), style: {
                width: `${source.width}%`,
                height: `${source.height}%`,
                left: `${source.positionX}%`,
                top: `${source.positionY}%`,
                zIndex: source.index,
            }, onClick: handleFrameClick, children: source.active && _jsx("button", { className: cn("resizer"), onMouseDown: mouseDownHandler }) }) }));
};
