import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton, RadioButtonGroup, Text } from "@ting/ting-ui-components";
import { nanoid } from "nanoid";
import { AddSourceContext } from "../../Context";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioSources } from "@src/selectors/studio";
import { classNameFactory } from "@src/utils/dom";
import { SourceIcon } from "@src/components/Studio/Sources/components/SourceIcon";
import { EmptyState } from "@src/components/Studio/Sources/components/AddSourceModal/components/EmptyState";
import "./addExistingSource.scss";
const cn = classNameFactory("existing-sources");
export const AddExistingSource = () => {
    const { t } = useTranslation("studio");
    const { selectedSource, setSelectedSource } = useContext(AddSourceContext);
    const sources = useSubscribe(selectStudioSources());
    const list = Object.keys(sources)
        .map(sceneId => {
        const sceneSource = sources[sceneId];
        return Object.values(sceneSource).filter((source) => {
            return source.sourceKind === selectedSource.source.sourceKind;
        });
    })
        .flat();
    if (list.length) {
        return (_jsx("div", { className: cn(""), children: _jsx(RadioButtonGroup, { name: "boz", className: cn("list"), onChange: e => {
                    const selected = list.filter((source) => source.id === e.target.value)[0];
                    let newSource = selected.source;
                    if (selected.source instanceof MediaStream) {
                        newSource = selected.source.clone();
                    }
                    setSelectedSource({
                        type: "local",
                        source: {
                            ...selected,
                            id: newSource instanceof MediaStream ? newSource.id : nanoid(),
                            source: newSource,
                        },
                    });
                }, children: list.map(source => (_jsx(RadioButton, { value: source.id, className: cn("source"), children: _jsxs("label", { htmlFor: source.id, className: cn("source-label"), children: [_jsx(SourceIcon, { kind: source.sourceKind }), _jsx(Text, { size: "body-medium", fontWeight: "medium", children: source.sourceName })] }) }, source.id))) }) }));
    }
    return (_jsx(EmptyState, { title: t("studio:sourceNotFound", {
            source: t(`studio:sourceKind.${selectedSource.source.sourceKind}`),
        }) }));
};
