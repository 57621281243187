import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Button, Text } from "@ting/ting-ui-components";
import { ChatOutlinedIcon, ChevronDownFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { selectChatMessages, selectMessageIdToScroll, selectUnreadMessages } from "@src/selectors/chat";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { setUnreadMessages } from "@src/controller";
import { classNameFactory } from "@src/utils/dom";
import { smoothScroll } from "@src/utils/helpers/smooth-scroll";
import { useDebounce } from "@src/hooks/useDebounce";
import { ChatMessageItem } from "@src/components/ChatMessageItem";
import "./chatBody.scss";
const cn = classNameFactory("chat-body");
export const ChatBody = () => {
    const messages = useSubscribe(selectChatMessages());
    const unReadMessages = useSubscribe(selectUnreadMessages());
    const messageIdToScroll = useSubscribe(selectMessageIdToScroll());
    const [isTouched, setIsTouched] = useState(false);
    const messagesListRef = useRef(null);
    const scrollDownRef = useRef(0);
    const chatMessagesRef = useRef({});
    const adjustScroll = (force) => {
        const messagesList = messagesListRef.current;
        if (!messagesList) {
            return;
        }
        const { clientHeight, scrollHeight } = messagesList;
        const scrollDown = scrollDownRef.current;
        const scrollTop = isTouched && !force ? Math.max(0, scrollDown - clientHeight) : scrollHeight - clientHeight;
        smoothScroll(messagesList, { top: scrollTop, left: 0 });
    };
    useEffect(adjustScroll, [messages, isTouched]);
    const handleScroll = useDebounce(() => {
        const messagesList = messagesListRef.current;
        if (!messagesList) {
            return;
        }
        const lastScrollDown = scrollDownRef.current;
        const { scrollTop, clientHeight, scrollHeight } = messagesList;
        const scrollDown = scrollTop + clientHeight;
        scrollDownRef.current = scrollDown;
        if (!isTouched && scrollDown < lastScrollDown) {
            setIsTouched(true);
        }
        else if (isTouched && scrollHeight - scrollDown < 10) {
            setIsTouched(false);
        }
    }, 300);
    useEffect(() => {
        const messagesList = messagesListRef.current;
        if (messagesList) {
            messagesList.addEventListener("scroll", handleScroll);
            return () => {
                messagesList.removeEventListener("scroll", handleScroll);
            };
        }
    }, [handleScroll, isTouched]);
    useEffect(() => {
        const messagesList = messagesListRef.current;
        if (!messagesList) {
            return;
        }
        const elements = Array.from(messagesList.children);
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && unReadMessages.length) {
                    const targetId = entry.target.dataset.id;
                    if (unReadMessages.find(m => m.id === targetId)) {
                        setUnreadMessages(unReadMessages.filter(m => m.id !== targetId));
                    }
                }
            });
        }, { root: messagesList, threshold: 0, rootMargin: "10px" });
        elements.forEach(element => observer.observe(element));
        return () => {
            observer.disconnect();
        };
    }, [unReadMessages.length, unReadMessages]);
    useEffect(() => {
        if (messageIdToScroll) {
            const messagesList = messagesListRef.current;
            const messageIdElem = chatMessagesRef.current[messageIdToScroll];
            if (messageIdElem) {
                const messagesListPositions = messagesList.getBoundingClientRect();
                const messageIdElemPosition = messageIdElem.getBoundingClientRect();
                const scrollTo = messageIdElemPosition.top - messagesListPositions.top + messagesList.scrollTop;
                smoothScroll(messagesList, { top: scrollTo, left: 0 });
            }
        }
    }, [messageIdToScroll]);
    return (_jsxs("div", { className: cn(""), children: [messages.length ? (_jsx("ul", { className: cn("messages-list"), ref: messagesListRef, children: messages
                    .map(msg => ({ ...msg, reactions: msg.reactions ? msg.reactions : [] }))
                    .map(msg => (_jsx(ChatMessageItem, { ref: ref => (chatMessagesRef.current[msg.id] = ref), msg: msg }, msg.id))) })) : (_jsxs("div", { className: cn("not-active-state"), children: [_jsx("div", { className: cn("chat-icon"), children: _jsx(ChatOutlinedIcon, { width: "20px", height: "20px" }) }), _jsx(Text, { size: "p2", children: "No messages yet" }), _jsx(Text, { size: "p2", children: "Send a message now to start meeting chat with other participants" })] })), _jsx(Button, { v2: true, round: true, size: "small", startIcon: _jsx(ChevronDownFilledIcon, { width: 20 }), type: "neutral-inverted", className: cn("scroll-down-btn", { hide: !isTouched }), onClick: () => setIsTouched(false) })] }));
};
