import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal, PositionType } from "@ting/ting-ui-components";
import { CloseOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import "./bottomModal.scss";
const cn = classNameFactory("bottom-modal");
export const BottomModal = props => {
    const { isOpen, onClose, children } = props;
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, position: PositionType.BOTTOM, containerClassName: cn(""), children: [children, _jsx(Button, { v2: true, type: "neutral", size: "small", icon: _jsx(CloseOutlinedIcon, { width: "20px", height: "20px" }), className: cn("close-btn"), onClick: onClose })] }));
};
