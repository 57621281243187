import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseCrossIcon, HrLine } from "@ting/ting-ui-components";
import { ChevronLeftOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { HeaderSizes } from "./Header.types";
import { classNameFactory } from "@src/utils/dom";
import { useMobile } from "@src/hooks/breakpoints";
import "./Header.scss";
const cn = classNameFactory("header");
export const Header = ({ children, size = HeaderSizes.medium, onClose, onBack, paragraph, filled = false, isModal = false, className = "", marginSize, }) => {
    const isMobile = useMobile();
    if (!marginSize) {
        marginSize = size;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("section", { className: `${cn("")} ${isModal && cn("is-modal", { [marginSize]: true })} ${className}`.trim(), children: [_jsxs("div", { className: cn("title-line", { [size]: true }), children: [onBack && _jsx(ChevronLeftOutlinedIcon, { onClick: onBack, className: cn("back-icon", { [size]: true }) }), _jsx("p", { className: cn("title", { [size]: true, mobile: isMobile }), children: children }), onClose && _jsx(CloseCrossIcon, { onClick: onClose, className: cn("close-icon", { [size]: true, filled }) })] }), paragraph && _jsx("p", { className: cn("paragraph", { [size]: true, back: Boolean(onBack) }), children: paragraph })] }), isModal && _jsx(HrLine, { className: cn("hr-line") })] }));
};
