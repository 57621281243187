import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SourceFrame } from "@src/components/Studio/Screen/components/SourceFrame";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSources } from "@src/selectors/studio";
import { SourceType } from "@src/domain/Studio/types";
export const SourceFrameList = () => {
    const sources = useSubscribe(selectStudioActiveSources());
    if (sources && Object.keys(sources)?.length) {
        return (_jsx(_Fragment, { children: Object.entries(sources).map(([id, source]) => {
                if (source.sourceType === SourceType.VISUAL && !source.hidden) {
                    return _jsx(SourceFrame, { source: source }, id);
                }
                return null;
            }) }));
    }
    return null;
};
