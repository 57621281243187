import { nanoid } from "nanoid";
import { MessageType } from "@ting/ting-ui-components";
import { state } from "@src/state";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
import { DCMessageType } from "@src/domain/RTCConnection";
import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
import { selectRoomSelfPeer, selectUser } from "@src/selectors";
import { chatSettings } from "@src/configs/chat";
import { selectRepliedMessage } from "@src/selectors/chat";
export function setChatOpen(isOpen) {
    state.meeting.chat.setChatOpen(isOpen);
}
export function setChatToggle(toggle) {
    state.meeting.chat.setChatToggle(toggle);
}
export function setChatActiveTabIndex(index) {
    state.meeting.chat.setActiveTabIndex(index);
}
export function addChatMessage(message) {
    state.meeting.chat.addChatMessage(message);
}
export function addUnreadMessage(message) {
    state.meeting.chat.addUnreadMessage(message);
}
export function setRecievedMessages(msgs) {
    state.meeting.chat.setRecievedMessages(msgs);
}
export function setUnreadMessages(msgs) {
    state.meeting.chat.setUnreadMessages(msgs);
}
export function resetChat() {
    state.meeting.chat.clearMessages();
}
export function setRepliedMessage(msg) {
    state.meeting.chat.setRepliedMessage(msg);
}
export function removeRepliedMessage() {
    state.meeting.chat.removeRepliedMessage();
}
export function sendChatMessage(payload) {
    return bindDependencies(async function (RTCTransport) {
        const peer = selectRoomSelfPeer().get();
        const finalMessage = new DCMessage({
            from: peer,
            to: "all",
            payload,
            type: DCMessageType.CHAT,
            id: payload.id,
        });
        RTCTransport.sendMessage(finalMessage);
        addChatMessage(payload);
    }, [TYPES.RTCConnectionService]);
}
export function sendTextChatMessage(content) {
    const peer = selectRoomSelfPeer().get();
    const user = selectUser().get();
    const repliedMessage = selectRepliedMessage().get();
    const chatUser = {
        displayName: peer?.name || user?.account.displayName || "Guest",
        userName: peer?.name || user?.account.name || "Guest",
        avatarUrl: peer?.avatarURL || (user?.account.avatar && `${user.account.avatar?.path}`),
        corepassId: peer?.corepassId || user?.corepassId || peer.peerId || chatSettings.guestCorePassID,
    };
    const newMessage = {
        type: MessageType.CHAT,
        id: nanoid(),
        content,
        time: new Date().toISOString(),
        from: chatUser,
        reactions: [],
        reply: repliedMessage,
        viewer: chatUser,
    };
    sendChatMessage(newMessage);
    removeRepliedMessage();
}
