const valuesMap = new Map();
export class MemoryStorage {
    getItem(key) {
        const stringKey = key;
        if (valuesMap.has(key)) {
            return valuesMap.get(stringKey);
        }
        return null;
    }
    getObject(key) {
        const value = this.getItem(key);
        return value && JSON.parse(value);
    }
    setItem(key, val) {
        return valuesMap.set(key, val);
    }
    setObject(key, value) {
        this.setItem(key, JSON.stringify(value));
    }
    removeItem(key) {
        return valuesMap.delete(key);
    }
    clear() {
        valuesMap.clear();
    }
    key(i) {
        if (arguments.length === 0) {
            throw new TypeError('Failed to execute "key" on "Storage": 1 argument required, but only 0 present.');
        }
        return Array.from(valuesMap.keys())[i];
    }
    get length() {
        return valuesMap.size;
    }
}
export function proxify(instance) {
    return new Proxy(instance, {
        set(_obj, prop, value) {
            if (Object.prototype.hasOwnProperty.call(MemoryStorage, prop)) {
                instance[prop] = value;
            }
            else {
                instance.setItem(prop, value);
            }
            return true;
        },
        get(_target, name) {
            if (Object.prototype.hasOwnProperty.call(MemoryStorage, name)) {
                return instance[name];
            }
            if (valuesMap.has(name)) {
                return instance.getItem(name);
            }
            return null;
        },
    });
}
