import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { SourcePreview } from "../";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSource } from "@src/selectors/studio";
import { classNameFactory } from "@src/utils/dom";
import { SourceKind } from "@src/domain/Studio/types";
import { SwitchDeviceSelect } from "@src/components/Studio/Sources/components/SourceFileds/SwitchDeviceSelect";
import { SwitchFileSelect } from "@src/components/Studio/Sources/components/SourceFileds/SwitchFileSelect";
import { SwitchScreenSelect } from "@src/components/Studio/Sources/components/SourceFileds/SwitchScreenSelect";
import { switchStudioSource } from "@src/controller";
import "./sourceFields.scss";
const cn = classNameFactory("source-field");
export const SourceSwitchDeviceField = ({ source, setSelectedSource, showSwitcher, showPreview, }) => {
    const { t } = useTranslation(["common", "studio"]);
    const activeSource = useSubscribe(selectStudioActiveSource());
    if (source || activeSource) {
        const selectedSource = source || activeSource;
        const onChange = setSelectedSource || switchStudioSource;
        const SourceSwitcher = () => {
            switch (selectedSource.sourceKind) {
                case SourceKind.AUDIO_INPUT:
                case SourceKind.VIDEO_INPUT:
                    return _jsx(SwitchDeviceSelect, { source: selectedSource, setSelectedSource: onChange });
                case SourceKind.DISPLAY_CAPTURE:
                case SourceKind.WINDOW_CAPTURE:
                case SourceKind.BROWSER_CAPTURE:
                    return _jsx(SwitchScreenSelect, { source: selectedSource, setSelectedSource: onChange });
                case SourceKind.IMAGE_FILE:
                case SourceKind.VIDEO_FILE:
                case SourceKind.AUDIO_FILE:
                    return _jsx(SwitchFileSelect, { source: selectedSource, setSelectedSource: onChange });
                default:
                    return null;
            }
        };
        return (_jsxs("div", { className: cn(""), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", className: cn("label"), children: showSwitcher ? t("device") : t("preview") }), _jsxs("div", { className: `${cn("input")}`, children: [showSwitcher && _jsx(SourceSwitcher, {}), showPreview &&
                            ![SourceKind.AUDIO_INPUT, SourceKind.AUDIO_OUTPUT, SourceKind.AUDIO_FILE].includes(selectedSource.sourceKind) && (_jsx("div", { className: cn("device-preview"), children: _jsx(SourcePreview, { source: source }) }))] })] }));
    }
    return null;
};
SourceSwitchDeviceField.defaultProps = {
    showPreview: true,
    showSwitcher: true,
};
