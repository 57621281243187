import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Modal, Tab, Tabs } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { SoundOutlinedIcon, VideoOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { Header, HeaderSizes } from "../Header";
import { AudioController } from "./AudioController";
import { EffectsController } from "./EffectsController";
import { VideoController } from "./VideoController";
//TODO: move to uikit
import { VisualEffectsIcon } from "./VisualEffectsIcon";
import { classNameFactory } from "@src/utils/dom";
import "./AudioVideoSettings.scss";
const cn = classNameFactory("audio-video-settings");
export const AudioVideoSettings = props => {
    const { open = false, onClose } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const { t } = useTranslation(["common", "room"]);
    return (_jsxs(Modal, { isOpen: open, hideCloseButton: true, className: cn(""), children: [_jsx(Header, { onClose: onClose, filled: true, isModal: true, marginSize: HeaderSizes.big, children: t("room:audio-video-settings") }), _jsxs("div", { className: cn("container"), children: [_jsx("div", { className: cn("side-tabs"), children: _jsxs(Tabs, { value: tabIndex, onChange: idx => setTabIndex(idx), variant: "vertical", children: [_jsxs(Tab, { className: cn("tab"), children: [_jsx(SoundOutlinedIcon, { width: "24px", height: "24px" }), t("audio")] }), _jsxs(Tab, { className: cn("tab"), children: [_jsx(VideoOutlinedIcon, { width: "24px", height: "24px" }), t("video")] }), _jsxs(Tab, { className: cn("tab"), children: [_jsx(VisualEffectsIcon, {}), t("effects")] })] }) }), _jsxs("div", { className: cn("content"), children: [tabIndex === 0 && _jsx(AudioController, {}), tabIndex === 1 && _jsx(VideoController, {}), tabIndex === 2 && _jsx(EffectsController, {})] })] })] }));
};
