import logger from "loglevel";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
import { Protocols, } from "@src/services/PeerCommunicator";
import { selectCorepassId } from "@src/selectors";
export function fetchCompactRoomInfo(hostPeerId) {
    return bindDependencies(async function (peerCommunicator) {
        const corepassId = selectCorepassId().peek();
        const req = { corepassId };
        try {
            return await peerCommunicator.send(hostPeerId, req, Protocols.compactRoomInfo);
        }
        catch (e) {
            logger.info(e);
            return;
        }
    }, [TYPES.PeerCommunicator]);
}
