import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@ting/ting-ui-components";
import { AddOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useTranslation } from "react-i18next";
import { classNameFactory } from "@src/utils/dom";
import { setCreateScenesModal } from "@src/controller";
import "./emptyScene.scss";
const cn = classNameFactory("empty-scene");
export const EmptyScene = () => {
    const { t } = useTranslation("studio");
    return (_jsx("div", { className: cn(""), children: _jsx(Button, { v2: true, size: "small", type: "brand", disabled: false, icon: _jsx(AddOutlinedIcon, { height: "20px", width: "20px" }), onClick: () => {
                setCreateScenesModal(true);
            }, children: t("addNewScene") }) }));
};
