import { jsx as _jsx } from "react/jsx-runtime";
import { Route, Switch } from "react-router";
import { routes } from "./routes";
export const Router = () => {
    const pageRoutes = routes.map(({ layout: Layout, routes, name }) => {
        const layoutPaths = routes.map(route => route.path);
        return (_jsx(Route, { path: layoutPaths, exact: true, children: _jsx(Layout, { children: routes.map(({ title, path, component }) => (_jsx(Route, { path: path, component: component, exact: true }, title))) }) }, name));
    });
    return _jsx(Switch, { children: pageRoutes });
};
