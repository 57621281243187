import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Modal } from "@ting/ting-ui-components";
import { Header, HeaderSizes } from "@src/components/Header";
import { ModalMeetHeader } from "@src/components/ModalMeetHeader";
import { PollBody } from "@src/components/Poll/PollBody";
import { createPoll, sendPollAnswer } from "@src/controller/poll";
import { classNameFactory } from "@src/utils/dom";
import "./pollModal.scss";
const cn = classNameFactory("poll-modal");
export const PollModal = props => {
    const { isOpen, onClose } = props;
    const { t } = useTranslation("common");
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, fullscreen: true, hideCloseButton: true, containerClassName: cn(""), children: [_jsx(ModalMeetHeader, {}), _jsx(Header, { onClose: onClose, filled: true, marginSize: HeaderSizes.small, children: t("polls") }), _jsx(PollBody, { onCreate: createPoll, onAnswer: sendPollAnswer })] }));
};
