import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Text } from "@ting/ting-ui-components";
import { ChevronLeftOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { StreamFrame } from "./StreamFrame";
import { useLobby } from "./Lobby.hook";
import { LobbyInfo } from "./LobbyInfo";
import { Header, HeaderSizes } from "@src/components/Header";
import { classNameFactory } from "@src/utils/dom";
import { FullScreenLoading } from "@src/components/FullScreenLoading";
import "./lobby.scss";
const cn = classNameFactory("lobby");
export const Lobby = () => {
    const { t } = useTranslation(["common", "room"]);
    const { isJoinMode, isInitializing } = useLobby();
    const history = useHistory();
    if (isInitializing)
        return _jsx(FullScreenLoading, {});
    return (_jsx("main", { className: cn(""), children: _jsxs("div", { className: cn("content"), children: [_jsx(Header, { size: HeaderSizes.big, className: cn("small-screen-header"), children: isJoinMode ? t("room:join-a-meeting") : t("common:instant-meeting") }), _jsxs("div", { className: cn("stream-content"), children: [_jsxs("button", { className: cn("back-sec"), onClick: history.goBack, children: [_jsx(ChevronLeftOutlinedIcon, { width: 20, height: 20 }), _jsx(Text, { size: "body-medium", fontWeight: "semiBold", children: t("common:go-back") })] }), _jsx(StreamFrame, {})] }), _jsx(LobbyInfo, {})] }) }));
};
