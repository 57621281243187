import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "./google/protobuf/any";
import { Code } from "./code";
// @generated message type with reflection information, may provide speed optimized methods
class Status$Type extends MessageType {
    constructor() {
        super("Status", [
            { no: 1, name: "code", kind: "enum", T: () => ["Code", Code] },
            { no: 2, name: "message", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "details", kind: "message", T: () => Any },
        ]);
    }
    create(value) {
        const message = globalThis.Object.create(this.messagePrototype);
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Code code */ 1:
                    message.code = reader.int32();
                    break;
                case /* optional string message */ 2:
                    message.message = reader.string();
                    break;
                case /* optional google.protobuf.Any details */ 3:
                    message.details = Any.internalBinaryRead(reader, reader.uint32(), options, message.details);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* Code code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* optional string message = 2; */
        if (message.message !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* optional google.protobuf.Any details = 3; */
        if (message.details)
            Any.internalBinaryWrite(message.details, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Status
 */
export const Status = new Status$Type();
