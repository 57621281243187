import { batch } from "@legendapp/state";
export class Room {
    constructor({ slug, type, locked, mode, security, lobbyEnabled, title, entryState, publicId, status, createdAt, }) {
        Object.defineProperty(this, "mode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selfPeerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "peers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "slug", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "security", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lobbyEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "locked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "entryState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "publicId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "joinedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.title = title;
        this.type = type;
        this.mode = mode;
        this.locked = locked;
        this.slug = slug;
        this.security = security;
        this.lobbyEnabled = lobbyEnabled;
        this.entryState = entryState;
        this.publicId = publicId;
        this.peers = {};
        this.status = status;
        this.createdAt = createdAt;
        this.joinedAt = new Date();
    }
    changeTitle(newName) {
        this.title.set(newName);
    }
    changeMode(newMode) {
        this.mode.set(newMode);
    }
    setSelfPeer(newPeer) {
        this.selfPeerId.set(newPeer.peerId);
        this.addPeer(newPeer);
    }
    changeType(newType) {
        this.type.set(newType);
    }
    changeSecurity(newSecurity) {
        this.security.set(newSecurity);
    }
    changeLocked(newLocked) {
        this.locked.set(newLocked);
    }
    changeLobbyEnabled(newLobbyEnabled) {
        this.lobbyEnabled.set(newLobbyEnabled);
    }
    changeEntryState(newEntryState) {
        this.entryState.set(newEntryState);
    }
    addPeer(newPeer) {
        this.peers.assign({ [newPeer.peerId]: newPeer });
    }
    removePeer(peerId) {
        this.peers[peerId].delete();
    }
    changeSlug(newSlug) {
        this.slug.set(newSlug);
    }
    setPublicId(publicId) {
        this.publicId.set(publicId);
    }
    changeRoomStatus(status) {
        this.status.set(status);
    }
    setCreatedAt(createdAt) {
        this.createdAt.set(createdAt);
    }
    updateWith(info) {
        batch(() => {
            this.publicId.set(info.publicId);
            this.type.set(info.type);
        });
    }
}
