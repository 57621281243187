export const groupFilesBySender = (files) => {
    const groupedFiles = [];
    if (files.length === 0) {
        return groupedFiles;
    }
    let currentGroup = {
        id: `${files[0].id}-group`,
        sender: files[0].from.displayName,
        files: [files[0]],
    };
    for (let i = 1; i < files.length; i++) {
        const file = files[i];
        if (file.from.displayName === currentGroup.sender) {
            currentGroup.files.push(file);
        }
        else {
            groupedFiles.push(currentGroup);
            currentGroup = {
                id: `${file.id}-group`,
                sender: file.from.displayName,
                files: [file],
            };
        }
    }
    groupedFiles.push(currentGroup);
    return groupedFiles;
};
