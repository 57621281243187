import classNames from "classnames";
import { isObject } from "./validators";
const prefix = "stages";
function generateEnhancedModifiers(baseClassName, modifiers) {
    const enhancedModifiers = {};
    Object.entries(modifiers).forEach(([key, value]) => {
        enhancedModifiers[`${baseClassName}--${key}`] = value;
    });
    return enhancedModifiers;
}
export function classNameFactory(blockClassName) {
    return function classNameBuilder(elementClassName, modifiers = {}) {
        let baseClassName = "";
        let updatedModifiers = {};
        if (isObject(elementClassName) || !elementClassName) {
            baseClassName = `${prefix}-${blockClassName}`;
            updatedModifiers = generateEnhancedModifiers(baseClassName, elementClassName);
        }
        else {
            baseClassName = `${prefix}-${blockClassName}__${elementClassName}`;
            updatedModifiers = generateEnhancedModifiers(baseClassName, modifiers);
        }
        return classNames(baseClassName, updatedModifiers);
    };
}
