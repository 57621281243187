export async function isOnline() {
    if (!window.navigator.onLine)
        return false;
    const url = new URL(window.location.origin);
    url.searchParams.set("rand", Date.now().toString());
    try {
        const response = await fetch(url.toString(), { method: "HEAD" });
        return response.ok;
    }
    catch {
        return false;
    }
}
