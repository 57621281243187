import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { SelectBox } from "@ting/ting-ui-components";
import { SourceIcon } from "../";
import { LocalStream } from "@src/services/localStream";
export const SwitchDeviceSelect = ({ source, setSelectedSource }) => {
    const [loading, setLoading] = useState(true);
    const [mediaList, setMediaList] = useState([]);
    const [options, setOptions] = useState([]);
    useEffect(() => {
        async function getDeviceList() {
            const mediaList = await LocalStream.getMediaList();
            setLoading(false);
            setMediaList(mediaList);
            setOptions(mediaList
                .filter((media) => media.sourceKind === source.sourceKind)
                .map((media) => ({
                icon: _jsx(SourceIcon, { kind: media.sourceKind, width: 20, height: 20 }),
                label: media.label,
                value: media.label,
            })));
        }
        if (!mediaList.length)
            void getDeviceList();
    }, [mediaList, source.sourceKind]);
    if (source) {
        const value = source && {
            icon: _jsx(SourceIcon, { kind: source.sourceKind, width: 20, height: 20 }),
            label: source.sourceLabel,
            value: source.sourceLabel,
        };
        return (_jsx(SelectBox, { size: "small", value: value, options: options, isLoading: loading, onChange: async (option) => {
                const change = mediaList.find((media) => media.label === option.label);
                const newSource = await change.getStream();
                setSelectedSource(newSource);
            } }));
    }
    return null;
};
