export default {
    video: {
        width: { ideal: 960 },
        height: { ideal: 540 },
        frameRate: 30,
        videoAspectRatio: 1.7777777778,
    },
    audio: {
        echoCancellation: true,
        noiseSuppression: true,
    },
    display: {
        audio: true,
        video: {
            height: 1080,
        },
    },
};
