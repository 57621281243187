import { observable } from "@legendapp/state";
import { persistObservable } from "@legendapp/state/persist";
import { DCMessageBuilder } from "./helpers";
import { defaultSelfStreamSettings } from "@src/configs/StreamSettings";
import { selectMeetingUser, selectRoomSelfPeer, selectSelfScreenStream, selectStreamSettings } from "@src/selectors";
import { SelfStream } from "@src/domain/SelfStream/SelfStream";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
function initUserMediaController(selfStreamService) {
    return async function () {
        const selfStreamSettingsObservable = observable(defaultSelfStreamSettings);
        // TODO: maybe there's a better solution?
        persistObservable(selfStreamSettingsObservable, { local: "SelfStreamSettings" });
        const selfStream = new SelfStream();
        selectMeetingUser().selfStream.set(selfStream);
        selectMeetingUser().selfStreamSettings.set(selfStreamSettingsObservable.peek());
        // const selfPeerObservable = selectRoomSelfPeer();
        //selfPeerObservable?.setSelfStream(selfStream);
        //selfPeerObservable?.setPeerStreamSetting(selfStreamSettingsObservable.peek());
        persistObservable(selectStreamSettings(), {
            local: {
                name: "SelfStreamSettings",
                adjustData: {
                    load: data => ({ ...data, isScreenOn: false }),
                },
            },
        });
        await selfStreamService.initUserMedia();
    };
}
export const initUserMedia = bindDependencies(initUserMediaController, [TYPES.SelfStreamService]);
export function toggleCamOn(enabled) {
    return bindDependencies(async function (selfStreamService) {
        await selfStreamService.toggleCamStatus(enabled);
        const peer = selectRoomSelfPeer().peek();
        const { isMicOn } = selectStreamSettings().peek();
        if (!peer)
            return isMicOn;
        await sendPeerStreamStatusMessage();
        return selectStreamSettings().isCamOn.peek();
    }, [TYPES.SelfStreamService]);
}
export function toggleMicOn(enabled) {
    return bindDependencies(async function (selfStreamService) {
        await selfStreamService.toggleMicStatus(enabled);
        const peer = selectRoomSelfPeer().peek();
        const { isMicOn } = selectStreamSettings().peek();
        if (!peer)
            return isMicOn;
        await sendPeerStreamStatusMessage();
        return selectStreamSettings().isMicOn.peek();
    }, [TYPES.SelfStreamService]);
}
export function sendPeerStreamStatusMessage() {
    return bindDependencies(async function (rtcConnectionService) {
        const peer = selectRoomSelfPeer().peek();
        const peerStreamStatusMessage = DCMessageBuilder.BuildPeerStatusMessage(peer);
        rtcConnectionService.sendMessage(peerStreamStatusMessage);
    }, [TYPES.RTCConnectionService]);
}
function toggleScreenOnController(roomService, selfStreamService) {
    return async function (enabled) {
        await selfStreamService.toggleScreenStatus(enabled);
        const selfScreenStreamObservable = selectSelfScreenStream().peek();
        const stream = selfScreenStreamObservable?.stream;
        if (!(stream instanceof MediaStream))
            return enabled;
        if (enabled)
            roomService.addScreenTracks(stream);
        else
            roomService.removeScreenTracks(stream);
        return enabled;
    };
}
export const toggleScreenOn = bindDependencies(toggleScreenOnController, [TYPES.RoomService, TYPES.SelfStreamService]);
function changeInputDeviceController(selfStreamService) {
    return async function (device) {
        return await selfStreamService.changeInputDevice(device);
    };
}
export const changeInputDevice = bindDependencies(changeInputDeviceController, [TYPES.SelfStreamService]);
function toggleEchoCancellationController(selfStreamService) {
    return async function (enabled) {
        return await selfStreamService.toggleEchoCancellationStatus(enabled);
    };
}
export const toggleEchoCancellation = bindDependencies(toggleEchoCancellationController, [TYPES.SelfStreamService]);
function toggleAutoGainControlContrller(selfStreamService) {
    return async function (enabled) {
        return await selfStreamService.toggleAutoGainControlStatus(enabled);
    };
}
export const toggleAutoGainControl = bindDependencies(toggleAutoGainControlContrller, [TYPES.SelfStreamService]);
function toggleNoiseSuppressionController(selfStreamService) {
    return async function (enabled) {
        return await selfStreamService.toggleNoiseSuppressionStatus(enabled);
    };
}
export const toggleNoiseSuppression = bindDependencies(toggleNoiseSuppressionController, [TYPES.SelfStreamService]);
function updateVolumeController(selfStreamService) {
    return async function (volume) {
        return await selfStreamService.updateVolume(volume);
    };
}
export const updateVolume = bindDependencies(updateVolumeController, [TYPES.SelfStreamService]);
function toggleBlurBackgroundController(selfStreamService) {
    return async function (enabled) {
        return await selfStreamService.toggleBlurBackgroundStatus(enabled);
    };
}
export const toggleBlurBackground = bindDependencies(toggleBlurBackgroundController, [TYPES.SelfStreamService]);
function handleVideoResolutionController(selfStreamService) {
    return async function (resolution) {
        return await selfStreamService.handleVideoResolution(resolution);
    };
}
export const handleVideoResolution = bindDependencies(handleVideoResolutionController, [TYPES.SelfStreamService]);
function handleScreenResolutionController(selfStreamService) {
    return async function (resolution) {
        return await selfStreamService.handleScreenResolution(resolution);
    };
}
export const handleScreenResolution = bindDependencies(handleScreenResolutionController, [TYPES.SelfStreamService]);
