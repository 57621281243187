const IS_DEV = process.env.NODE_ENV === "development";
const GOOGLE_CLIENT_ID = process.env.VITE_GOOGLE_CLIENTID;
const GOOGLE_API_KEY = process.env.VITE_GOOGLE_API_KEY;
const TING_SITE = process.env.VITE_TING_SITE;
const TING_HOST = process.env.VITE_TING_HOST;
const TING_STREAM = process.env.VITE_TING_STREAM;
const MEETING_API = process.env.VITE_MEETING_API;
const OAUTH_DOMAIN = process.env.VITE_OAUTH_DOMAIN;
const OAUTH_CLIENT = process.env.VITE_OAUTH_CLIENT;
const FILE_SERVER_URL = process.env.VITE_FILE_SERVER_URL;
const STUDIO_RESTORE_LOCAL_NAME = process.env.VITE_STUDIO_RESTORE_LOCAL_NAME;
const SENTRY_DNS = process.env.VITE_SENTRY_DNS;
export const ENV = {
    IS_DEV,
    GOOGLE_CLIENT_ID,
    GOOGLE_API_KEY,
    TING_SITE,
    TING_HOST,
    TING_STREAM,
    MEETING_API,
    OAUTH_DOMAIN,
    OAUTH_CLIENT,
    FILE_SERVER_URL,
    STUDIO_RESTORE_LOCAL_NAME,
    SENTRY_DNS,
};
