import axios from "axios";
import { history } from "@src/services/history";
import { TingLocalStorage, StorageKeys } from "@src/services/storage";
import { state } from "@src/state";
import { routePaths } from "@src/routes/routes";
import { slugify } from "@src/utils/slugify";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
export function handleStartLogin() {
    return bindDependencies(async function (authorizer) {
        state.auth.inProgress.set(true);
        const location = history.location;
        TingLocalStorage.setObject(StorageKeys.RedirectPath, location);
        authorizer.makeAuthorizationRequest();
    }, [TYPES.Authorizer]);
}
export function handleLoginCallback() {
    return bindDependencies(async function (authorizer) {
        try {
            state.auth.inProgress.set(true);
            await authorizer.signinCallback();
            const didGetSelf = await handleGetSelf();
            if (!didGetSelf)
                return;
            state.auth.inProgress.set(false);
            const redirectPath = TingLocalStorage.getObject(StorageKeys.RedirectPath);
            TingLocalStorage.removeItem(StorageKeys.RedirectPath);
            if (redirectPath)
                history.push(redirectPath);
        }
        catch (err) {
            await handleLogOut();
        }
    }, [TYPES.Authorizer]);
}
export async function handleSilentLogin() {
    try {
        state.auth.inProgress.set(true);
        // set user to show in view until we are sure user is logged in
        const storedUser = TingLocalStorage.getObject(StorageKeys.TingUser);
        if (storedUser)
            state.auth.user.set(storedUser);
        const didGetSelf = await handleGetSelf();
        if (!didGetSelf)
            return;
        state.auth.inProgress.set(false);
    }
    catch (e) {
        await handleLogOut();
    }
}
export function handleGetSelf() {
    return bindDependencies(async function (tingApiService) {
        try {
            const user = await tingApiService.getMe();
            TingLocalStorage.setObject(StorageKeys.TingUser, user);
            state.auth.user.set(user);
            return true;
        }
        catch (err) {
            if (axios.isAxiosError(err) && err.response.status === 404) {
                TingLocalStorage.removeItem(StorageKeys.TingUser);
                state.auth.user.set(undefined);
                state.auth.shouldRegister.set(true);
                history.push(routePaths.HOME());
            }
            else {
                await handleLogOut();
            }
        }
        return false;
    }, [TYPES.TingApiService]);
}
export function handleSignUp(payload, avatar, channelImage) {
    return bindDependencies(async function (tingApiService) {
        const channelHandle = slugify(payload.channelName, "_");
        await tingApiService.signUp({ ...payload, channelHandle });
        await Promise.all([
            ...(avatar ? [tingApiService.uploadUserAvatar(avatar)] : []),
            ...(channelImage ? [tingApiService.uploadChannelAvatar(channelHandle, channelImage)] : []),
        ]);
        state.auth.shouldRegister.set(false);
        await handleGetSelf();
    }, [TYPES.TingApiService]);
}
export function handleLogOut() {
    return bindDependencies(async function (authorizer) {
        await authorizer.signout();
        TingLocalStorage.removeItem(StorageKeys.TingUser);
        state.auth.user.set(undefined);
        state.auth.inProgress.set(false);
    }, [TYPES.Authorizer]);
}
