import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, UserAvatar } from "@ting/ting-ui-components";
import { MenuOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { handleStartLogin } from "@src/controller/auth";
import { selectLoginInProgress, selectUser } from "@src/selectors";
import { useBuildMobile } from "@src/hooks/breakpoints";
import { routePaths } from "@src/routes/routes";
import StageHero from "@src/assets/stages-hero-icon.svg";
import "./header.scss";
const cn = classNameFactory("home-layout-header");
export const Header = props => {
    const { className = "", ...rest } = props;
    const user = useSubscribe(selectUser());
    const loginInProgress = useSubscribe(selectLoginInProgress());
    const isBuildMobile = useBuildMobile();
    const onCreateButtonClick = () => handleStartLogin();
    return (_jsxs("header", { className: `${cn("")} ${className}`.trim(), ...rest, children: [_jsx(Link, { to: routePaths.HOME(), children: _jsx("img", { src: StageHero, alt: "Ting Hero", className: cn("hero") }) }), _jsxs("div", { className: cn("actions"), children: [!isBuildMobile && (_jsxs(Fragment, { children: [user && (_jsx(Button, { v2: true, size: "medium", type: "neutral", onClick: onCreateButtonClick, startIcon: _jsx(UserAvatar, { image: user.account.avatar?.path, alt: user.account.displayName, userName: user.username, coreID: user.corepassId, size: "xxs" }), children: user.account.displayName })), !user && (_jsxs(Fragment, { children: [_jsx(Button, { v2: true, size: "medium", type: "neutral", onClick: onCreateButtonClick, className: cn("create-btn"), children: "Create a Ting Account" }), _jsx(Button, { v2: true, size: "medium", type: "neutral", variant: "secondary", onClick: onCreateButtonClick, isLoading: loginInProgress, disabled: loginInProgress, children: "Log In" })] }))] })), isBuildMobile && (_jsxs(Fragment, { children: [user && (_jsx(Fragment, { children: _jsx(UserAvatar, { image: user.account.avatar?.path, alt: user.account.displayName, userName: user.username, coreID: user.corepassId, size: "xs", className: cn("avatar") }) })), _jsx(Button, { v2: true, size: "extraSmall", type: "neutral", variant: "ghost", onClick: onCreateButtonClick, className: cn("menu-btn"), icon: _jsx(MenuOutlinedIcon, { width: "24px", height: "24px" }) })] }))] })] }));
};
