import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, Tabs, Badge } from "@ting/ting-ui-components";
import { AccountOutlinedIcon, ChatOutlinedIcon, FilesOutlinedIcon, PollsOutlinedIcon, } from "@ting/ting-ui-components/dist/icons";
import { PollFrame } from "../Poll/PollFrame";
import { ChatFrame } from "./ChatFrame/ChatFrame";
import { classNameFactory } from "@src/utils/dom";
import { PanelController } from "@src/components/Studio";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectChat } from "@src/selectors/chat";
import { setChatActiveTabIndex, setChatOpen } from "@src/controller";
import { useNumberOfPeers } from "@src/hooks/useNumberOfPeers";
import { PeerList } from "@src/components/PeerList";
import { createPoll, sendPollAnswer } from "@src/controller/poll";
import { FilesFrame } from "@src/components/Files/FilesFrame";
import "./chat.scss";
const cn = classNameFactory("chat");
export const Chat = () => {
    const chatState = useSubscribe(selectChat());
    const numberOfPeers = useNumberOfPeers();
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("header"), children: [_jsx(PanelController, { title: "Meeting", titleSize: "large", rightActions: {
                            close: () => {
                                setChatOpen(false);
                            },
                        } }), _jsxs(Tabs, { value: chatState.activeTabIndex, onChange: setChatActiveTabIndex, variant: "secondary", children: [_jsx(Tab, { className: cn("tab"), children: _jsx(ChatOutlinedIcon, { width: "20px", height: "20px" }) }), _jsx(Tab, { className: cn("tab"), children: _jsx(PollsOutlinedIcon, { width: "20px", height: "20px" }) }), _jsx(Tab, { className: cn("tab"), children: _jsx(FilesOutlinedIcon, { width: "20px", height: "20px" }) }), _jsxs(Tab, { className: cn("tab"), children: [_jsx(AccountOutlinedIcon, { width: "20px", height: "20px" }), numberOfPeers && _jsx(Badge, { badgeContent: numberOfPeers, color: "grey", className: cn("indicator") })] })] })] }), _jsxs("div", { className: cn("content"), children: [chatState.activeTabIndex === 0 && _jsx(ChatFrame, {}), chatState.activeTabIndex === 1 && _jsx(PollFrame, { onCreate: createPoll, onAnswer: sendPollAnswer }), chatState.activeTabIndex === 2 && _jsx(FilesFrame, {}), chatState.activeTabIndex === 3 && _jsx(PeerList, {})] })] }));
};
