var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject, injectable } from "inversify";
import { HttpClient } from "../HttpClient/HttpClient";
import configs from "@src/configs";
import { TYPES } from "@src/ioc.type";
let RoomsApiService = class RoomsApiService {
    constructor(authorizerService) {
        Object.defineProperty(this, "roomsAxiosInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.roomsAxiosInstance = new HttpClient(configs.app.roomsAPI, authorizerService);
    }
    async getRoom(roomId) {
        return this.roomsAxiosInstance.get(`/room/${roomId}`);
    }
    async createRoom(data) {
        return this.roomsAxiosInstance.post(`/room`, data);
    }
    async updateRoom(roomId, data) {
        return this.roomsAxiosInstance.patch(`/room/${roomId}`, data);
    }
    async joinRoom(roomId, peerId) {
        return this.roomsAxiosInstance.post(`/room/${roomId}/join`, { peerId });
    }
    async endRoom(roomId) {
        return this.roomsAxiosInstance.post(`/room/${roomId}/end`);
    }
};
RoomsApiService = __decorate([
    injectable(),
    __param(0, inject(TYPES.Authorizer)),
    __metadata("design:paramtypes", [Object])
], RoomsApiService);
export { RoomsApiService };
