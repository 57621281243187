export class AnalyzerFilter {
    constructor(audioContext) {
        Object.defineProperty(this, "audioContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "analyser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.audioContext = audioContext;
    }
    apply(source) {
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 256;
        this.audioData = new Uint8Array(this.analyser.frequencyBinCount);
        source.connect(this.analyser);
        return this.analyser;
    }
    getAudioVolumeInDecibels() {
        this.analyser.getByteFrequencyData(this.audioData);
        // Calculate the average amplitude of the audio signal
        let sum = 0;
        this.audioData.forEach((value) => {
            sum += value;
        });
        const averageAmplitude = sum / this.analyser.frequencyBinCount;
        // Convert the average amplitude to dB
        const val = Math.floor(20 * Math.log10(averageAmplitude / 255));
        return val === -Infinity ? 0 : val;
    }
    clear() {
        this.analyser.disconnect();
    }
}
