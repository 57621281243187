import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import logger from "loglevel";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Text, TextField } from "@ting/ting-ui-components";
import { createRoomToJoin } from "@src/controller";
import { routePaths } from "@src/routes/routes";
import { classNameFactory } from "@src/utils/dom";
import { Header, HeaderSizes } from "@src/components/Header";
import { useBuildMobile } from "@src/hooks/breakpoints";
import "./joinRoom.scss";
const cn = classNameFactory("join-room");
export const JoinRoom = () => {
    const history = useHistory();
    const { t } = useTranslation(["common", "room"]);
    const [meetId, setMeetId] = useState("");
    const isBuildMobile = useBuildMobile();
    const isSubmitDisabled = Boolean(meetId?.length === 0);
    const onMeetIdChange = useCallback((e) => {
        setMeetId(e.target.value);
    }, []);
    const onSubmit = async () => {
        try {
            await createRoomToJoin(meetId);
            history.push(routePaths.LOBBY(meetId));
        }
        catch (e) {
            // TODO: error handling
            logger.error(e);
        }
    };
    return (_jsxs("div", { className: cn(""), children: [!isBuildMobile && (_jsx(Text, { size: "h2", fontWeight: "semiBold", children: t("room:join-a-meeting") })), isBuildMobile && (_jsx(Header, { size: HeaderSizes.medium, isModal: true, children: t("room:join-a-meeting") })), _jsxs("div", { className: cn("container"), children: [_jsx(TextField, { v2: true, label: t("room:enter-meeting-id"), size: "large", className: cn("input"), onChange: onMeetIdChange }), _jsx(Button, { v2: true, size: "big", type: "brand", className: cn("submit"), disabled: isSubmitDisabled, onClick: onSubmit, children: t("common:continue") })] })] }));
};
