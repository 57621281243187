import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, Checkbox, Modal, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { Header, HeaderSizes } from "@src/components/Header";
import { classNameFactory } from "@src/utils/dom";
import "./warnModal.scss";
const cn = classNameFactory("warn-modal");
export const WarnModal = ({ title, caption, icon, isOpen, onClose }) => {
    const { t } = useTranslation("common");
    if (!isOpen)
        return null;
    return (_jsxs(Modal, { isOpen: true, onClose: onClose, shouldCloseOnEsc: true, shouldCloseOnOverlayClick: true, hideCloseButton: true, className: cn(""), containerClassName: cn("container"), children: [_jsxs(Header, { filled: true, size: HeaderSizes.small, onClose: onClose, children: [icon && icon, title] }), _jsxs("div", { className: cn("content"), children: [caption && (_jsx(Text, { size: "body-medium", type: "sub", children: caption })), _jsxs("div", { className: cn("actions"), children: [_jsx("div", { className: cn("dont-show"), children: _jsx(Checkbox, { name: "dontShow", size: "small", disabled: true, children: _jsx(Text, { size: "body-medium", fontWeight: "semiBold", children: "Don\u2019t show again" }) }) }), _jsx(Button, { v2: true, type: "neutral", variant: "primary", onClick: onClose, children: t("got-it") })] })] })] }));
};
