import { useEffect, useRef, useState } from "react";
export function useInit(initializer) {
    const [isRunning, setIsRunning] = useState(true);
    const isCalledRef = useRef(false);
    useEffect(() => {
        async function runner() {
            if (!isCalledRef.current) {
                isCalledRef.current = true;
                await initializer();
                setIsRunning(false);
            }
        }
        runner();
    }, [initializer]);
    return isRunning;
}
