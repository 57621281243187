import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Text, TextField } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { SlideDown } from "@src/components/SlideDown";
import { classNameFactory } from "@src/utils/dom";
import { PanelController } from "@src/components/Studio/PanelController";
import { SourceType } from "@src/domain/Studio/types";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSource } from "@src/selectors/studio";
import { updateSourceFlip, updateSourcePosition, updateSourceSize } from "@src/controller";
import { StudioMerger } from "@src/services/studioService";
import "./transformSlideDown.scss";
const cn = classNameFactory("transform-slide");
export const TransformSlideDown = () => {
    const { t } = useTranslation("studio");
    const source = useSubscribe(selectStudioActiveSource());
    const { id, width, height, ratio, positionX, positionY, flipX, flipY } = source;
    const [sourceAspectRatioLink, setSourceAspectRatioLink] = useState(true);
    const handleSourceSizeChange = (type, size, linked) => {
        const { canvas } = StudioMerger.instance;
        const { offsetWidth: canvasW, offsetHeight: canvasH } = canvas;
        const [pixelW, pixelH] = type === "width"
            ? [(size * canvasW) / 100, (height * canvasH) / 100]
            : [(width * canvasW) / 100, (size * canvasH) / 100];
        if (linked) {
            const frameW = type === "width" ? (100 / canvasW) * pixelW : (100 / canvasW) * (pixelH * ratio);
            const frameH = type === "height" ? (100 / canvasH) * pixelH : (100 / canvasH) * (pixelW / ratio);
            updateSourceSize(id, Math.round(frameW), Math.round(frameH));
        }
        else if (type === "width") {
            updateSourceSize(id, size, height);
        }
        else if (type === "height") {
            updateSourceSize(id, width, size);
        }
    };
    return (_jsx(SlideDown, { title: t("transform"), content: _jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("size"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("size") }), _jsxs("div", { className: cn("group"), children: [_jsxs("div", { className: cn("input-group"), children: [_jsx(TextField, { v2: true, type: "number", name: "url", size: "small", state: "normal", value: width, onChange: event => {
                                                const { value } = event.target;
                                                const size = +value < 0 ? 0 : +value > 100 ? 100 : +value;
                                                handleSourceSizeChange("width", Math.round(size), sourceAspectRatioLink);
                                            } }), _jsx(Text, { type: "sub", size: "body-small", fontWeight: "semiBold", children: t("width") })] }), _jsx(PanelController, { leftActions: {
                                        linkSourceAspectRatioToggle: {
                                            toggled: true,
                                            sourceType: SourceType.VISUAL,
                                            action: toggle => {
                                                setSourceAspectRatioLink(toggle);
                                                if (toggle) {
                                                    handleSourceSizeChange("width", +width, toggle);
                                                }
                                            },
                                        },
                                    } }), _jsxs("div", { className: cn("input-group"), children: [_jsx(TextField, { v2: true, type: "number", name: "url", size: "small", state: "normal", value: height, onChange: event => {
                                                handleSourceSizeChange("height", +event.target.value, sourceAspectRatioLink);
                                            } }), _jsx(Text, { type: "sub", size: "body-small", fontWeight: "semiBold", children: t("height") })] })] })] }), _jsxs("div", { className: cn("position"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("position") }), _jsxs("div", { className: cn("group"), children: [_jsxs("div", { className: cn("input-group"), children: [_jsx(TextField, { v2: true, type: "number", name: "url", size: "small", state: "normal", value: positionX, onChange: event => {
                                                updateSourcePosition(id, +event.target.value, positionY);
                                            } }), _jsx(Text, { type: "sub", size: "body-small", fontWeight: "semiBold", children: "X" })] }), _jsxs("div", { className: cn("input-group"), children: [_jsx(TextField, { v2: true, type: "number", name: "url", size: "small", state: "normal", value: positionY, onChange: event => {
                                                updateSourcePosition(id, positionX, +event.target.value);
                                            } }), _jsx(Text, { type: "sub", size: "body-small", fontWeight: "semiBold", children: "Y" })] })] })] }), _jsxs("div", { className: cn("rotate"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("rotate") }), _jsxs("div", { className: cn("group"), children: [_jsx(PanelController, { leftActions: {
                                        rotateSource: () => {
                                            // console.log("rotateSource");
                                        },
                                    } }), _jsxs("div", { className: cn("input-group"), children: [_jsx(TextField, { v2: true, type: "number", value: "value", name: "url", size: "small", state: "normal" }), _jsx(Text, { type: "sub", size: "body-small", fontWeight: "semiBold", children: t("degree") })] })] })] }), _jsxs("div", { className: cn("flip"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("flip") }), _jsx("div", { className: cn("group"), children: _jsx(PanelController, { leftActions: {
                                    flipSourceVertical: () => {
                                        updateSourceFlip(source.id, !flipX, flipY);
                                    },
                                    flipSourceHorizontal: () => {
                                        updateSourceFlip(source.id, flipX, !flipY);
                                    },
                                } }) })] })] }) }));
};
