import { Peer } from ".";
export class SelfPeer extends Peer {
    constructor(args) {
        super(args);
        Object.defineProperty(this, "selfStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selfScreenStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    setSelfStream(ss) {
        this.selfStream.set(ss);
        this.peerStream.set(ss.stream);
    }
    setSelfScreenStream(ss) {
        this.selfScreenStream.set(ss);
        this.screenStream.set(ss.stream);
    }
    setPeerStream() {
        throw new Error("you should use setSelfStream instead");
    }
    setPeerStreamSetting(settings) {
        this.peerStreamSettings.set(settings);
    }
}
