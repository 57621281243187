import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronRightFilledIcon, InfoOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { Text } from "@ting/ting-ui-components";
import { NameAuthenticationModal } from "../NameAuthenticationModal";
import { DisplayNameOption } from "../DisplayNameOption";
import { classNameFactory } from "@src/utils/dom";
import { SelectField } from "@src/components/SelectField";
import "./displayNameSelect.scss";
const cn = classNameFactory("display-name-select");
export const DisplayNameSelect = props => {
    const { value, onChange, className = "", name, options } = props;
    const { t } = useTranslation("room");
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const openAuthModal = () => setIsOpenAuthModal(true);
    const closeAuthModal = () => setIsOpenAuthModal(false);
    const onChangeHandler = (option) => {
        if (option.value === "verifiedName") {
            openAuthModal();
        }
        else {
            onChange(option.value);
        }
    };
    const selectOptions = [
        {
            label: (_jsx(DisplayNameOption, { title: t("verified-name"), rightElement: _jsxs("div", { className: cn("auth-needed"), children: [_jsx(Text, { size: "links-big", children: t("authentication-needed") }), _jsx(ChevronRightFilledIcon, { width: "24px", height: "24px" })] }) })),
            value: "verifiedName",
        },
        {
            label: _jsx(DisplayNameOption, { title: t("ting-profile"), value: options.tingName }),
            value: "tingName",
        },
        {
            label: _jsx(DisplayNameOption, { title: t("core-id"), value: options.coreId }),
            value: "coreId",
        },
    ];
    return (_jsxs("div", { className: cn(""), children: [_jsx(SelectField, { size: "large", label: t("display-name"), name: name, className: className, options: selectOptions, value: selectOptions.find(op => op.value === value), defaultValue: selectOptions[2], onChange: onChangeHandler }), value === "coreId" && (_jsxs("div", { className: cn("caption"), children: [_jsx(InfoOutlinedIcon, { width: "20px", height: "20px" }), _jsx(Text, { size: "body-medium", type: "sub", children: t("core-id-privacy") })] })), isOpenAuthModal && _jsx(NameAuthenticationModal, { isOpen: isOpenAuthModal, onClose: closeAuthModal })] }));
};
