import { ENV } from "@src/env";
const TYPES = {
    PeerCommunicator: Symbol.for("PeerCommunicator"),
    RoomService: Symbol.for("RoomService"),
    RoomsApiService: Symbol.for("RoomsApiService"),
    PeerService: Symbol.for("PeerService"),
    RTCConnectionService: Symbol.for("RTCConnectionService"),
    SelfStreamService: Symbol.for("SelfStreamService"),
    Calender: Symbol.for("Calender"),
    CalenderUtils: Symbol.for("CalenderUtils"),
    Authorizer: Symbol.for("Authorizer"),
    TingApiService: Symbol.for("TingApiService"),
    ChatService: Symbol.for("ChatService"),
    CandidateService: Symbol.for("CandidateService"),
};
export { TYPES };
if (ENV.IS_DEV)
    window.TYPES = TYPES;
