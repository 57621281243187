import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import c from "classnames";
import { Text } from "@ting/ting-ui-components";
import { MicOffFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import "./participantName.scss";
const cn = classNameFactory("participant-name");
export const ParticipantName = props => {
    const { name, muted, className } = props;
    return (_jsxs("div", { className: c(cn(""), className), children: [_jsx(Text, { size: "body-medium", fontWeight: "medium", children: name }), muted && (_jsx("div", { className: cn("mute-icon"), children: _jsx(MicOffFilledIcon, { width: 16, height: 16 }) }))] }));
};
