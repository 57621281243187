/* eslint-disable @typescript-eslint/no-explicit-any */
export class SessionDescriptionMeta {
    constructor() {
        Object.defineProperty(this, "sessionDescription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "meta", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
}
