import { nanoid } from "nanoid";
import { MessageType } from "@ting/ting-ui-components";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
import { DCMessageType } from "@src/domain/RTCConnection";
import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
import { selectRoomSelfPeer, selectUser } from "@src/selectors";
import { chatSettings } from "@src/configs/chat";
import { selectRepliedMessage } from "@src/selectors/chat";
import { addChatMessage } from "@src/controller";
export function sendFile(payload, to) {
    return bindDependencies(async function (RTCTransport) {
        const peer = selectRoomSelfPeer().get();
        const finalMessage = new DCMessage({
            from: peer,
            to,
            payload,
            type: DCMessageType.FILE,
            id: payload.id,
        });
        RTCTransport.sendMessage(finalMessage);
        addChatMessage(payload);
    }, [TYPES.RTCConnectionService]);
}
export function sendFileChat(content, to = "all") {
    const peer = selectRoomSelfPeer().get();
    const user = selectUser().get();
    const repliedMessage = selectRepliedMessage().get();
    const chatUser = {
        displayName: peer?.name || user?.account.displayName || "Guest",
        userName: peer?.name || user?.account.name || "Guest",
        avatarUrl: peer?.avatarURL || (user?.account.avatar && `${user.account.avatar?.path}`),
        corepassId: peer?.corepassId || user?.corepassId || peer.peerId || chatSettings.guestCorePassID,
    };
    const newMessage = {
        type: MessageType.FILE,
        id: nanoid(),
        content,
        time: new Date().toISOString(),
        from: chatUser,
        reactions: [],
        reply: repliedMessage,
        viewer: chatUser,
    };
    sendFile(newMessage, to);
}
