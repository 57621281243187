import { PeerRoles } from "@src/domain/Peer";
import { state } from "@src/state";
export const selectMeeting = () => state.meeting;
export const selectRoom = () => state.meeting.room;
export const selectMeetingUser = () => state.meetingUser;
export const selectRoomType = () => selectRoom().type;
export const selectRoomMode = () => selectRoom().mode;
export const selectRoomSlug = () => selectRoom().slug;
export const selectRoomPeers = () => selectRoom().peers;
export const selectRoomPeer = (peerId) => selectRoomPeers()[peerId];
export const selectHostPeer = () => Object.values(selectRoomPeers()).find(peer => peer.role.peek() === PeerRoles.SUPER_NODE);
export const selectRoomPeerStream = (peerId) => selectRoomPeer(peerId).peerStream;
export const selectRoomTitle = () => selectRoom().title;
export const selectRoomPublicId = () => selectRoom().publicId;
export const selectRoomHostPeer = () => selectRoomPeers()[selectRoomPublicId().get()];
export const selectRoomSelfPeerId = () => selectRoom().selfPeerId;
export const selectRoomSelfPeer = () => selectRoom().peers[selectRoomSelfPeerId().peek()];
export const selectRoomJoinTime = () => selectRoom().joinedAt;
export const selectRoomCreatedTime = () => selectRoom().createdAt;
export const selectSelfName = () => selectRoomSelfPeer().name;
export const selectSelfPeerRole = () => selectRoomSelfPeer().role;
export const selectStreamSettings = () => selectMeetingUser().selfStreamSettings;
export const selectSelfStream = () => selectMeetingUser().selfStream;
export const selectSelfScreenStream = () => selectRoomSelfPeer().selfScreenStream;
export const selectSelfVideoTrack = () => selectSelfStream()?.videoTrack;
export const selectSelfAudioTrack = () => selectSelfStream()?.audioTrack;
export const selectVideoInputError = () => selectStreamSettings().videoInputError;
export const selectAudioInputError = () => selectStreamSettings()?.audioInputError;
export const selectIsCamOn = () => selectStreamSettings()?.isCamOn;
export const selectIsMicOn = () => selectStreamSettings()?.isMicOn;
export const selectIsScreenOn = () => selectStreamSettings()?.isScreenOn;
export const selectRoomUISettings = () => state.roomUISettings;
export const selectShowInviteModal = () => selectRoomUISettings()?.showInviteModal;
export const selectIsBlurBackground = () => selectStreamSettings()?.isBlurBackground;
export const selectAnalyzerFilter = () => selectMeetingUser()?.analyzerFilter;
