import { jsx as _jsx } from "react/jsx-runtime";
import { MicOutlinedIcon, MusicOutlinedIcon, PictureOutlinedIcon, SoundOutlinedIcon, TvOutlinedIcon, VideoCountOutlinedIcon, VideoOutlinedIcon, WebOutlinedIcon, WindowOutlinedIcon, } from "@ting/ting-ui-components/dist/icons";
import { SourceKind } from "@src/domain/Studio/types";
export const SourceIcon = ({ kind, width, height }) => {
    switch (kind) {
        case SourceKind.AUDIO_INPUT:
            return _jsx(MicOutlinedIcon, { width: width, height: height });
        case SourceKind.AUDIO_OUTPUT:
            return _jsx(SoundOutlinedIcon, { width: width, height: height });
        case SourceKind.VIDEO_INPUT:
        case SourceKind.VIDEO_OUTPUT:
            return _jsx(VideoOutlinedIcon, { width: width, height: height });
        case SourceKind.IMAGE_FILE:
            return _jsx(PictureOutlinedIcon, { width: width, height: height });
        case SourceKind.VIDEO_FILE:
            return _jsx(VideoCountOutlinedIcon, { width: width, height: height });
        case SourceKind.AUDIO_FILE:
            return _jsx(MusicOutlinedIcon, { width: width, height: height });
        case SourceKind.DISPLAY_CAPTURE:
            return _jsx(TvOutlinedIcon, { width: width, height: height });
        case SourceKind.WINDOW_CAPTURE:
            return _jsx(WindowOutlinedIcon, { width: width, height: height });
        case SourceKind.BROWSER_CAPTURE:
            return _jsx(WebOutlinedIcon, { width: width, height: height });
        default:
            return null;
    }
};
SourceIcon.defaultProps = {
    width: 24,
    height: 24,
};
