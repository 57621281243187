export class VolumeFilter {
    constructor(audioContext, volume) {
        Object.defineProperty(this, "volume", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "gainSource", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.audioContext = audioContext;
        this.volume = volume;
    }
    apply(source) {
        this.gainSource = this.audioContext.createGain();
        this.updateVolume(this.volume);
        source.connect(this.gainSource);
        return this.gainSource;
    }
    scaleValue(value) {
        return 2 * (value / 100) - 1;
    }
    updateVolume(volume) {
        this.volume = volume;
        this.gainSource.gain.setValueAtTime(this.scaleValue(volume), this.audioContext.currentTime);
    }
    clear() {
        this.gainSource.disconnect();
    }
}
