export class Poll {
    constructor() {
        Object.defineProperty(this, "polls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activePoll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activePollAnswers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activePollTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "timerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.polls = [];
        this.activePoll = null;
        this.activePollTime = 0;
    }
    clearPoll() {
        this.polls = [];
        this.activePoll = null;
        this.activePollTime = 0;
        clearInterval(this.timerId);
    }
    createPoll(poll) {
        this.activePoll.set(poll);
        this.polls.push({ ...poll, userSelectedOptions: [], answers: [] });
        this.setupActiveTimeTimer();
        this.activePollTime.set(poll.timeToAnswer / 1000);
    }
    addPollMessage(poll) {
        this.activePoll.set(poll);
    }
    setActivePollTime(time) {
        this.activePollTime.set(time);
    }
    setupActiveTimeTimer() {
        if (this.activePoll?.timeToAnswer.get() !== Number.POSITIVE_INFINITY)
            this.timerId.set(setInterval(() => {
                if (this.activePollTime.get() - 1 < 0) {
                    this.activePollTime.set(0);
                    this.activePoll.set(null);
                    clearInterval(this.timerId.get());
                    return;
                }
                this.activePollTime.set(this.activePollTime.get() - 1);
            }, 1000));
    }
}
