import { addDays, addMonths, format } from "date-fns";
import { DaysOfWeek } from "@src/services/Calendar/enums";
function formatMeetingDate(date, monthlyFormat = false) {
    const tomorrow = addDays(new Date(), 1);
    if (date.getDate() === tomorrow.getDate() &&
        date.getMonth() === tomorrow.getMonth() &&
        date.getFullYear() === tomorrow.getFullYear()) {
        return "tomorrow";
    }
    else {
        return format(date, monthlyFormat ? "MMM dd" : "EEE MMM dd");
    }
}
export function getNextWeeklyMeetingDate(selectedDays, currentDate) {
    currentDate = currentDate instanceof Date ? currentDate : new Date(currentDate);
    const dayNames = Object.values(DaysOfWeek);
    const nextMeetingDate = new Date(currentDate);
    nextMeetingDate.setDate(nextMeetingDate.getDate() + 1);
    while (!selectedDays.includes(dayNames[nextMeetingDate.getDay()])) {
        nextMeetingDate.setDate(nextMeetingDate.getDate() + 1);
    }
    return formatMeetingDate(nextMeetingDate);
}
export function getNextMonthlyMeetingDate(meetingDay, currentDate) {
    currentDate = currentDate instanceof Date ? currentDate : new Date(currentDate);
    let nextMeetingDate = new Date(currentDate);
    nextMeetingDate.setDate(meetingDay);
    // If the meeting day has already passed in the current month, move to the next month
    if (nextMeetingDate <= currentDate) {
        nextMeetingDate = addMonths(nextMeetingDate, 1);
    }
    return formatMeetingDate(nextMeetingDate, true);
}
