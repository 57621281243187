import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Text, TextField } from "@ting/ting-ui-components";
import { CloseOutlinedIcon, SearchOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useEffect, useState } from "react";
import { useTransition } from "react-transition-state";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomPeers } from "@src/selectors";
import { ProfileRow } from "@src/components/ProfileRow";
import "./SelectParticipants.scss";
const cn = classNameFactory("select-participants");
export const SelectParticipants = ({ handleClose, onSelect, isOpen }) => {
    const peers = useSubscribe(selectRoomPeers(), true);
    const [selectParticipantsModalState, toggleSelectParticipantsModalState] = useTransition({ timeout: 500 });
    const [searchQuery, setSearchQuery] = useState("");
    const onSearchChange = (e) => {
        const text = e.currentTarget.value;
        setSearchQuery(text);
    };
    const handleSelectParticipant = (peer) => {
        onSelect(peer);
    };
    const handleSelectParticipantKeyDown = (peer, event) => {
        if (event.key === "Enter") {
            handleSelectParticipant(peer);
        }
    };
    useEffect(() => {
        toggleSelectParticipantsModalState(isOpen);
    }, [isOpen]);
    return (_jsx("div", { className: `${cn("")} ${selectParticipantsModalState.status}`, children: _jsxs("div", { className: `${cn("wrapper")} ${selectParticipantsModalState.status}`, children: [_jsxs("header", { className: cn("header"), children: [_jsx(Text, { size: "h4", fontWeight: "semiBold", children: "Send File" }), _jsx(Button, { v2: true, icon: _jsx(CloseOutlinedIcon, { height: "20px", width: "20px" }), size: "small", type: "neutral", onClick: handleClose })] }), _jsxs("div", { className: cn("content"), children: [_jsx(TextField, { v2: true, placeholder: "Search...", value: searchQuery, onChange: onSearchChange, size: "medium", icon: _jsx(SearchOutlinedIcon, { width: "24px", height: "24px" }), className: cn("search") }), _jsx("div", { className: cn("peers"), children: Object.values(peers)
                                .filter(peer => (searchQuery ? peer.name.search(searchQuery) !== -1 : true))
                                .map((peer, index) => (_jsx("div", { role: "button", tabIndex: index, onClick: () => handleSelectParticipant(peer), onKeyDown: event => handleSelectParticipantKeyDown(peer, event), children: _jsx(ProfileRow, { peerId: peer.peerId, className: cn("profile-item"), showActions: false, showCaption: false }) }, peer.peerId))) })] })] }) }));
};
