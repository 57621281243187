import { useEffect, useRef } from "react";
const fftSize = 256;
export const useAudioTrackSampler = (track, enabled, animate, reset) => {
    const contextRef = useRef();
    const analyzerRef = useRef();
    const timeout = useRef();
    const dataRef = useRef(new Uint8Array(fftSize / 2));
    const createAudioContext = () => {
        contextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        analyzerRef.current = contextRef.current.createAnalyser();
        analyzerRef.current.fftSize = fftSize;
    };
    const addTrackToContext = () => {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(track);
        const source = contextRef.current.createMediaStreamSource(mediaStream);
        source.connect(analyzerRef.current);
    };
    useEffect(() => {
        const isContextNotExist = !contextRef.current || contextRef.current.state === "closed";
        if (track && isContextNotExist) {
            createAudioContext();
            addTrackToContext();
        }
        if (track && !isContextNotExist)
            addTrackToContext();
        return () => {
            analyzerRef.current?.disconnect();
            if (contextRef.current?.state !== "closed") {
                contextRef.current?.close();
            }
        };
    }, [track]);
    const getStreamData = () => {
        if (analyzerRef.current)
            analyzerRef.current.getByteFrequencyData(dataRef.current);
        return dataRef.current;
    };
    const visualize = () => {
        const data = getStreamData();
        animate(data);
        timeout.current = setTimeout(visualize, 50);
    };
    useEffect(() => {
        if (enabled)
            timeout.current = setTimeout(visualize, 50);
        if (!enabled && timeout.current) {
            clearTimeout(timeout.current);
            reset?.();
        }
        return () => {
            clearTimeout(timeout.current);
        };
    }, [enabled]);
};
