import { state } from "@src/state";
export const selectChat = () => state.meeting.chat;
export const selectIsChatOpen = () => state.meeting.chat.isOpen;
export const selectIsChatToggle = () => state.meeting.chat.isToggle;
export const selectActiveTabIndex = () => state.meeting.chat.activeTabIndex;
export const selectChatMessages = () => state.meeting.chat.messages;
export const selectUnreadMessages = () => state.meeting.chat.unReadMessages;
export const selectRecievedMessages = () => state.meeting.chat.recievedMessages;
export const selectRepliedMessage = () => state.meeting.chat.repliedMessage;
export const selectMessageIdToScroll = () => state.meeting.chat.messageIdToScroll;
