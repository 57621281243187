import { IMediaTrackType } from "./types";
export class MetaDataStore {
    constructor() {
        Object.defineProperty(this, "metaList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
    }
    add(meta) {
        const found = this.metaList.filter(m => m.peerId === meta.peerId && m.type === meta.type && m.streamId === meta.streamId);
        if (found.length === 0)
            this.metaList.push(meta);
    }
    find(streamIdTrackIdValidator, kind) {
        if (typeof streamIdTrackIdValidator === "function") {
            return this.metaList.find(streamIdTrackIdValidator);
        }
        if (typeof streamIdTrackIdValidator === "string" && typeof kind === "string") {
            return this.metaList.find(m => m.streamId === streamIdTrackIdValidator && m.kind === kind);
        }
        if (kind === undefined && typeof streamIdTrackIdValidator === "string") {
            return this.metaList.find(m => m.trackId === streamIdTrackIdValidator);
        }
        throw new Error("unrecognized Input");
    }
    getByPeer(peerId, includeForwardedMetas) {
        const metasByPeer = this.metaList.filter(m => m.peerId === peerId);
        if (!includeForwardedMetas)
            return metasByPeer;
        const forwardedTypes = [
            IMediaTrackType.ForwardedAudio,
            IMediaTrackType.ForwardedVideo,
            IMediaTrackType.ForwardedScreenAudio,
            IMediaTrackType.ForwardedScreenVideo,
        ];
        const forwardedMetas = this.metaList.filter(m => m.peerId !== peerId && m.type in forwardedTypes);
        return [...metasByPeer, ...forwardedMetas];
    }
    removeByPeer(peerId) {
        this.metaList = this.metaList.filter(m => m.peerId !== peerId);
    }
    clean() {
        this.metaList = [];
    }
}
