import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, PositionType, Text, Button } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TimerOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { Header, HeaderSizes } from "@src/components/Header";
import { useBuildMobile } from "@src/hooks/breakpoints";
import { classNameFactory } from "@src/utils/dom";
import { routePaths } from "@src/routes/routes";
import "./ScheduleMeetingMessageModal.scss";
const cn = classNameFactory("schedule-meeting-message-modal");
export const ScheduleMeetingMessageModal = ({ open = false, onClose, roomId, }) => {
    const { t } = useTranslation("room");
    const isMobile = useBuildMobile();
    const history = useHistory();
    return (_jsxs(Modal, { position: isMobile ? PositionType.BOTTOM : PositionType.CENTER, isOpen: open, hideCloseButton: true, className: cn(""), children: [_jsx(Header, { onClose: onClose, filled: true, marginSize: HeaderSizes.big }), _jsxs("div", { className: cn("content"), children: [_jsx("div", { className: cn("icon"), children: _jsx(TimerOutlinedIcon, { width: 40, height: 40 }) }), _jsxs("div", { className: cn("message-wrapper"), children: [_jsx(Text, { size: "h3", fontWeight: "semiBold", children: t("scheduled-meeting-creation") }), _jsx(Text, { size: "p2", className: cn("description"), children: t("enter-meeting-now") })] }), _jsxs("div", { className: cn("action-wrapper"), children: [_jsx(Button, { v2: true, type: "brand", size: "big", onClick: () => history.push(routePaths.LOBBY(roomId)), children: t("enter-now") }), _jsx(Button, { v2: true, type: "neutral", size: "big", onClick: () => history.replace("/"), children: t("go-to-homepage") })] })] })] }));
};
