import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Modal, Text, useSnackBar, Button } from "@ting/ting-ui-components";
import { CloseFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { slugify } from "@src/utils/slugify";
import { handleLogOut, handleSignUp } from "@src/controller/auth";
import { useMobile } from "@src/hooks/breakpoints";
import { classNameFactory } from "@src/utils/dom";
import "./SignUpModal.scss";
const cn = classNameFactory("sign-up-modal");
export const SignUpModal = props => {
    const { open, onClose } = props;
    const [values, setValues] = useState({
        profileName: "",
        userName: "",
        termsChecked: false,
        channelName: "",
        channelHandle: "",
        avatarFile: null,
        channelAvatar: null,
    });
    const [fieldErrors, setFieldErrors] = useState({});
    const [step, stepHelpers] = useState(1);
    const isMobile = useMobile();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const showSnackBar = useSnackBar();
    useEffect(() => {
        if (errors.general || errors.userName || errors.profileName || errors.channelName || errors.channelHandle) {
            const jumpToFirstStep = errors.general || errors.userName || errors.profileName;
            if (jumpToFirstStep) {
                setValues({
                    ...values,
                    channelName: "",
                    channelHandle: "",
                });
            }
            if (jumpToFirstStep && errors.general) {
                showSnackBar({ message: errors.general });
            }
            else {
                setFieldErrors(errors);
            }
            stepHelpers(jumpToFirstStep ? 1 : 2);
            setErrors({});
        }
    }, [errors, stepHelpers, step, values, showSnackBar]);
    const closeHandler = async () => {
        if (open && step !== 3) {
            await handleLogOut();
            onClose();
        }
        else {
            finishLogin();
        }
    };
    const finishLogin = () => {
        onClose();
    };
    // TODO: change this code to a backend solution without condition on error messages
    const handleSignUpErrors = (error) => {
        const errorMessage = error?.response?.data?.error;
        const errorFields = error?.response?.data?.fields;
        if (errorMessage && errorFields) {
            if (errorFields.includes("body.channel.name")) {
                setErrors({ channelHandle: errorMessage });
                return;
            }
            if (errorFields.includes("body.username") || errorFields.includes("body.corepassId")) {
                setErrors({ userName: errorMessage });
                return;
            }
            setErrors({ general: errorMessage });
            return;
        }
    };
    const submitUserInfo = (newValues) => {
        const { profileName } = { ...newValues };
        const userName = slugify(profileName, "_");
        setValues({ ...values, ...newValues, profileName, userName });
        stepHelpers(step + 1);
    };
    const submitChannelInfo = (newValues) => {
        setIsSubmitting(true);
        const { avatarFile, channelAvatar, ...rest } = { ...values, ...newValues };
        handleSignUp({ ...rest }, avatarFile, channelAvatar)
            .then(() => {
            setIsSubmitting(false);
            finishLogin();
        })
            .catch(error => {
            handleSignUpErrors(error);
            setIsSubmitting(false);
        });
    };
    const handleBackClick = (newValues) => {
        const { channelAvatar, channelName } = newValues;
        const channelHandle = slugify(channelName, "_");
        setValues({ ...values, channelAvatar, channelHandle, channelName });
        stepHelpers(step - 1);
    };
    const TITLES = {
        1: "Create profile",
        2: "Create channel",
    };
    return (_jsx(Modal, { isOpen: open, onClose: closeHandler, fullscreen: isMobile, shouldCloseOnOverlayClick: false, hideCloseButton: true, children: _jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("header"), children: [_jsxs("div", { className: cn("header-title"), children: [_jsx(Text, { size: isMobile ? "h3" : "h2", fontWeight: "semiBold", children: TITLES[step] }), !isMobile && (_jsx(Button, { v2: true, variant: "primary", size: "medium", type: "neutral", startIcon: _jsx(CloseFilledIcon, { width: 20, height: 20 }), onClick: closeHandler }))] }), _jsxs("div", { className: cn("header-subtitle"), children: [_jsx(Text, { size: "p2", type: "sub", children: `Let’s start with creating your` }), _jsx(Text, { size: "p2", children: "Ting Profile and Channel" })] })] }), _jsxs("div", { className: cn("content"), children: [_jsx("div", { hidden: step === 2, className: cn("step-1", { hidden: step === 2 }), children: _jsx(Step1, { initialValues: values, errors: fieldErrors, onStep1Submit: submitUserInfo, onCancelClick: closeHandler, onAvatarChange: avatar => Promise.resolve(setValues({ ...values, avatarFile: avatar })) }) }), _jsx("div", { hidden: step === 1, className: cn("step-2", { hidden: step === 1 }), children: _jsx(Step2, { initialValues: values, isSubmitting: isSubmitting, errors: fieldErrors, onBackClick: handleBackClick, onStep2Submit: submitChannelInfo, onChannelAvatarChange: avatar => Promise.resolve(setValues({ ...values, channelAvatar: avatar })) }) })] })] }) }));
};
