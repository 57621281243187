import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HorizontalOrderOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useTranslation } from "react-i18next";
import { PanelController } from "../PanelController";
import { Panel } from "../Panel";
import { CreateSceneModal, EmptyScene, Scene } from "./components";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectIsCreateScenesModalOpen, selectStudioScenes } from "@src/selectors/studio";
import { createNewScene, setCreateScenesModal } from "@src/controller";
export const Scenes = () => {
    const { t } = useTranslation("studio");
    const isCreateSceneModalOpen = useSubscribe(selectIsCreateScenesModalOpen());
    const scenes = useSubscribe(selectStudioScenes());
    return (_jsxs(Panel, { header: _jsx(PanelController, { title: t("scenes"), titleSize: "small", icon: _jsx(HorizontalOrderOutlinedIcon, { height: "24px", width: "24px" }), rightActions: {
                // export: () => {// console.log("export scene action"); },
                add: () => {
                    setCreateScenesModal(!isCreateSceneModalOpen);
                },
            } }), children: [scenes && Object.keys(scenes).length ? (Object.values(scenes).map((scene) => _jsx(Scene, { scene: scene }, scene.id))) : (_jsx(EmptyScene, {})), _jsx(CreateSceneModal, { isOpen: isCreateSceneModalOpen, onCreate: (name) => {
                    createNewScene(name);
                    setCreateScenesModal(false);
                }, onClose: () => {
                    setCreateScenesModal(false);
                } })] }));
};
