import { Studio } from "./Studio/Studio";
import { Chat } from "./Chat/Chat";
import { Poll } from "./Chat/Poll";
export class Meeting {
    constructor() {
        Object.defineProperty(this, "room", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "poll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "studio", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.chat = new Chat(true, true);
        this.liveChat = new Chat(true, false);
        this.poll = new Poll();
        this.studio = new Studio();
    }
    setRoom(room) {
        this.room.set(room);
    }
}
Object.defineProperty(Meeting, "instance", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: new Meeting()
});
