import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Title, callbackIcons, toggleIcons, CallbackAction, SourceToggleAction } from "./components";
import { classNameFactory } from "@src/utils/dom";
import "./panelController.scss";
const cn = classNameFactory("panel-controller");
export const PanelController = ({ title, titleSize, icon, direction, leftActions, rightActions, className, }) => {
    const renderActions = (actions) => {
        return Object.keys(actions).map(name => {
            switch (name) {
                case "separator":
                    return _jsx("div", { className: cn("separator") }, "separator");
                case "fullscreenToggle":
                case "scenesToggle":
                case "sourcesToggle":
                case "mixerToggle":
                case "hideSourceToggle":
                case "lockSourceToggle":
                case "linkSourceAspectRatioToggle": {
                    const toggleAction = actions[name];
                    return (_jsx(SourceToggleAction, { sourceType: toggleAction?.sourceType, icon: toggleIcons[name].active, toggleIcon: toggleIcons[name].inactive, toggled: toggleAction?.toggled, action: toggleAction?.action, toggleByVariant: toggleAction?.toggleByVariant, disabled: toggleAction?.disabled }, name));
                }
                default: {
                    const action = actions[name];
                    return (_jsx(CallbackAction, { action: action, icon: callbackIcons[name] }, name));
                }
            }
        });
    };
    return (_jsxs("div", { className: `${cn({
            [direction]: true,
        })} ${className}`.trim(), children: [(title || leftActions) && (_jsx("div", { className: cn("cell"), children: title ? _jsx(Title, { title: title, size: titleSize, icon: icon }) : leftActions && renderActions(leftActions) })), rightActions && _jsx("div", { className: cn("cell"), children: renderActions(rightActions) })] }));
};
PanelController.defaultProps = {
    titleSize: "medium",
    direction: "row",
};
