import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "@ting/ting-ui-components";
import { StepOne, StepTwo } from "./components";
import { classNameFactory } from "@src/utils/dom";
import { Header } from "@src/components/Header";
import { studioStartStream } from "@src/controller";
import configs from "@src/configs";
import { StepActions } from "@src/components/ControlBar/StreamController/components/StartStreamModal/components/StepActions";
import "./startStreamModal.scss";
const cn = classNameFactory("stream-form");
export const StartStreamModal = ({ onClose }) => {
    const { t } = useTranslation(["common", "studio"]);
    const [step, setStep] = useState(1);
    const [streamUrl, setStreamUrl] = useState(null);
    const formikRef = useRef(null);
    const handleSubmit = (values, { setSubmitting, setFieldError, }) => {
        // Request live stream data
        studioStartStream(values)
            .then(stream => {
            setStep(3);
            setStreamUrl(`${configs.app.tingSite}/watch/${stream.video.uuid}`);
        })
            .catch(error => {
            const fieldErrors = error?.response?.data?.errors;
            if (Object.keys(fieldErrors || {}).length) {
                Object.entries(fieldErrors).map(([key, value]) => {
                    setFieldError(key, value.msg);
                });
            }
        })
            .finally(() => {
            setSubmitting(false);
        });
    };
    return (_jsxs(Modal, { isOpen: true, onClose: onClose, shouldCloseOnEsc: true, shouldCloseOnOverlayClick: true, hideCloseButton: true, children: [_jsx(Header, { isModal: true, filled: true, onClose: onClose, children: t("studio:setUpStream") }), _jsx("div", { className: cn(""), children: step === 3 && streamUrl ? (_jsx("div", { className: cn("watch-stream"), children: _jsx("a", { href: streamUrl, target: "_blank", rel: "noreferrer", className: "visitUrl", children: _jsx(Button, { v2: true, type: "brand", variant: "primary", size: "medium", className: cn("watch"), children: t("watch-stream") }) }) })) : (_jsx(Formik, { innerRef: formikRef, onSubmit: handleSubmit, validationSchema: Yup.object().shape({
                        title: Yup.string().min(2, "Too Short!").max(150, "Too Long!").required("Stream needs to have a title!"),
                        category: Yup.object().required("Select stream category!"),
                        channelId: Yup.object().required("Choose your channel!"),
                    }), initialValues: {
                        title: "",
                        language: null,
                        category: null,
                        tags: [],
                        channelId: null,
                        playlist: null,
                        privacy: null,
                        thumbnailFile: null,
                    }, children: _jsxs(Form, { children: [step === 1 && (_jsxs("div", { className: cn("step-1"), children: [_jsx(StepOne, {}), _jsx(StepActions, { step: step, setStep: setStep, onClose: onClose })] })), step === 2 && (_jsxs("div", { className: cn("step-2"), children: [_jsx(StepTwo, {}), _jsx(StepActions, { step: step, setStep: setStep, onClose: onClose })] }))] }) })) })] }));
};
