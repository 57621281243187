import axios from "axios";
export class HttpClient {
    constructor(baseURL, authorizerService) {
        Object.defineProperty(this, "axiosInstance", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_authorizerService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "previousRequestInterceptor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "previousResponseInterceptor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        this._authorizerService = authorizerService;
        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                "Content-Type": "application/json",
            },
        });
        // You can add any default configurations or interceptors here
        this.setupInterceptors();
    }
    setupInterceptors() {
        if (this.previousRequestInterceptor) {
            this.axiosInstance.interceptors.request.eject(this.previousRequestInterceptor);
        }
        this.previousRequestInterceptor = this.axiosInstance.interceptors.request.use(config => {
            if (this._authorizerService.getAccessToken()) {
                config.headers.Authorization = `Bearer ${this._authorizerService.getAccessToken()}`;
            }
            return config;
        });
        // Set a response interceptor in case of 401 errors that might be related to token expiration
        // Try to refresh the token and retry the request. if it keeps failing on retry attempt, give up and
        // throw the error back to the requester
        if (this.previousResponseInterceptor) {
            this.axiosInstance.interceptors.response.eject(this.previousResponseInterceptor);
        }
        this.previousResponseInterceptor = this.axiosInstance.interceptors.response.use(response => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, async (error) => {
            return new Promise((resolve, reject) => {
                const originalRequest = error.config;
                // If the request failed because of a network issue
                if (!error.response) {
                    reject(error);
                }
                else if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    this._authorizerService
                        .makeRefreshTokenRequest()
                        .then(() => {
                        originalRequest.headers.Authorization = `Bearer ${this._authorizerService.getAccessToken()}`;
                        resolve(this.axiosInstance(originalRequest));
                    })
                        .catch(() => {
                        reject(error);
                    });
                }
                else {
                    reject(error);
                }
            });
        });
    }
    async get(url, config) {
        try {
            const response = await this.axiosInstance.get(url, config);
            return response.data;
        }
        catch (error) {
            throw this.handleAxiosError(error);
        }
    }
    async post(url, data, config) {
        try {
            const response = await this.axiosInstance.post(url, data, config);
            return response.data;
        }
        catch (error) {
            throw this.handleAxiosError(error);
        }
    }
    async postForm(url, data, config) {
        try {
            const response = await this.axiosInstance.postForm(url, data, config);
            return response.data;
        }
        catch (error) {
            throw this.handleAxiosError(error);
        }
    }
    async patch(url, data) {
        try {
            const response = await this.axiosInstance.patch(url, data);
            return response.data;
        }
        catch (error) {
            throw this.handleAxiosError(error);
        }
    }
    handleAxiosError(error) {
        throw error;
    }
}
