import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ChevronDownOutlinedIcon, ChevronUpOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { Text } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import "./slideDown.scss";
const cn = classNameFactory("slide-down");
export const SlideDown = ({ title, content, isOpen }) => {
    const [open, setOpen] = useState(isOpen);
    return (_jsxs("div", { className: cn(""), children: [_jsxs("button", { className: cn("handler"), onClick: () => {
                    setOpen(!open);
                }, children: [_jsx(Text, { size: "small", fontWeight: "bold", className: cn("title"), children: title }), open ? (_jsx(ChevronUpOutlinedIcon, { width: "20px", height: "20px" })) : (_jsx(ChevronDownOutlinedIcon, { width: "20px", height: "20px" }))] }), open && _jsx("div", { className: cn("content"), children: content })] }));
};
SlideDown.defaultProps = {
    isOpen: false,
};
