import EventEmitter from "events";
import logger from "loglevel";
import { SessionDescriptionMeta } from "./SessionDescriptionMeta";
import { MetaDataStore } from "./MetaDataStore";
import { MediaTrackMetaData } from "./MediaTrackMetaData";
import { IMediaTrackType, PeerConnectionEvents, DCMessageType } from "./types";
import { RTCConnection, RTCConnectionEvents, RTCConnectionState, } from "@src/services/RTCConnection";
import { PeerRoles } from "@src/domain/Peer";
import { Protocols } from "@src/services/PeerCommunicator";
import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
import { MissingTrackService } from "@src/services/MissingTrackService/MissingTrackService";
export class RTCConnectionManager extends EventEmitter {
    constructor(peerCommunicator, selfPeer) {
        super();
        Object.defineProperty(this, "_selfPeer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_peerCommunicator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pcMap", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "metaStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // Peer Stream
        Object.defineProperty(this, "sendingStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sendingAudioTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sendingVideoTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // Peer Screen Stream
        Object.defineProperty(this, "sendingScreenStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sendingScreenAudioTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sendingScreenVideoTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "missingTrackService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._peerCommunicator = peerCommunicator;
        this._selfPeer = selfPeer;
        this.metaStore = new MetaDataStore();
        this.handleReceivedSignal = this.handleReceivedSignal.bind(this);
        this._peerCommunicator.on(Protocols.rtcEvent, this.handleReceivedSignal);
        this.missingTrackService = new MissingTrackService(this);
    }
    addConnectionTo(peer) {
        const newPc = new RTCConnection(peer, { isInitiator: this._selfPeer.role === PeerRoles.SUPER_NODE });
        this.pcMap.set(peer.peerId, newPc);
        newPc.on(RTCConnectionEvents.SIGNAL, this.handleSendingSignal.bind(this));
        newPc.on(RTCConnectionEvents.TRACK, this.handleReceivedTrack.bind(this));
        newPc.on(RTCConnectionEvents.DC_MESSAGE, DCMessage => {
            this.handleDCMessage(DCMessage);
        });
        newPc.on(RTCConnectionEvents.CONNECTION_STATE, this.handleConnectionStateChange.bind(this));
        //TODO: remove pc on destroy
        this.addTracksToNewPc(newPc);
    }
    removeConnectionFrom(peer) {
        const pc = this.pcMap.get(peer.peerId);
        this.pcMap.delete(peer.peerId);
        try {
            pc?.close();
        }
        catch (e) {
            logger.debug("can't close pc", e);
        }
    }
    handleDCMessage(dcMessage) {
        if (dcMessage.to !== "all" && !dcMessage.to.includes(this._selfPeer.peerId))
            return;
        if (dcMessage.type === DCMessageType.PING) {
            const pongMessage = new DCMessage({
                from: {
                    peerId: this._selfPeer.peerId,
                    name: this._selfPeer.name,
                },
                to: [dcMessage.from.peerId],
                type: DCMessageType.PONG,
                payload: null,
            });
            this.sendMessage(pongMessage);
        }
        else {
            this.emit(PeerConnectionEvents.ReceivedDCMessage, dcMessage);
        }
    }
    // we assume that the stream we are sending to the other side
    // has at most one audioTrack and one videoTrack
    setSendingStream(stream) {
        this.sendingStream = stream;
        const audioTrack = stream.getAudioTracks()[0];
        if (audioTrack) {
            this.addOrReplaceTrackToPCs(audioTrack, stream);
        }
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack) {
            this.addOrReplaceTrackToPCs(videoTrack, stream);
        }
        stream.onaddtrack = ({ track }) => {
            if (stream !== this.sendingStream)
                return;
            this.addOrReplaceTrackToPCs(track, stream);
        };
    }
    sendMessage(message) {
        for (const [, pc] of this.pcMap) {
            pc.sendDCMessage(message);
        }
    }
    removeTrackFromPCs(track, stream) {
        if (track.kind === "audio") {
            this.sendingAudioTrack = null;
        }
        else if (track.kind === "video") {
            this.sendingVideoTrack = null;
        }
        for (const peerConnection of this.pcMap.values()) {
            peerConnection.replaceTrack(track, null, stream);
        }
    }
    removeScreenTracksFromPCs(stream) {
        for (const peerConnection of this.pcMap.values()) {
            stream.getTracks().forEach((track) => {
                peerConnection.replaceTrack(track, null, stream);
            });
        }
    }
    addOrReplaceTrackToPCs(track, stream) {
        if (track.kind === "audio" && this.sendingAudioTrack == null) {
            this.addTrackToPCs(track, stream);
            this.sendingAudioTrack = track;
        }
        else if (track.kind === "audio" && track != this.sendingAudioTrack) {
            this.replaceTrackOnPCs(track, stream);
            this.sendingAudioTrack = track;
        }
        else if (track.kind === "video" && this.sendingVideoTrack == null) {
            this.addTrackToPCs(track, stream);
            this.sendingVideoTrack = track;
        }
        else if (track.kind === "video" && track != this.sendingVideoTrack) {
            this.replaceTrackOnPCs(track, stream);
            this.sendingVideoTrack = track;
        }
    }
    replaceTrackOnPCs(track, stream) {
        const oldTrack = track.kind === "audio" ? this.sendingAudioTrack : this.sendingVideoTrack;
        for (const peerConnection of this.pcMap.values()) {
            peerConnection.replaceTrack(oldTrack, track, stream);
        }
    }
    addTrackToPCs(track, stream) {
        const type = track.kind === "audio" ? IMediaTrackType.Audio : IMediaTrackType.Video;
        this.metaStore.add(MediaTrackMetaData.buildFromTrack(track, stream, this._selfPeer.peerId, type));
        for (const peerConnection of this.pcMap.values()) {
            peerConnection.addTrack(track, stream);
        }
    }
    addScreenTracksToPCs(stream) {
        const tracks = stream?.getTracks() || [];
        const addScreenTrackPCs = (track, stream) => {
            const type = track.kind === "audio" ? IMediaTrackType.ScreenAudio : IMediaTrackType.ScreenVideo;
            this.metaStore.add(MediaTrackMetaData.buildFromTrack(track, stream, this._selfPeer.peerId, type));
            for (const peerConnection of this.pcMap.values()) {
                peerConnection.addTrack(track, stream);
            }
        };
        const replaceScreenTrackOnPCs = (oldTrack, track, stream) => {
            for (const peerConnection of this.pcMap.values()) {
                try {
                    peerConnection.replaceTrack(oldTrack, track, stream);
                }
                catch {
                    peerConnection.addTrack(track, stream);
                }
            }
        };
        if (tracks.length) {
            tracks.forEach((track) => {
                // Replace ScreenAudio/ScreenVideo Track
                if (track.kind === "video" && this.sendingScreenVideoTrack instanceof MediaStreamTrack) {
                    replaceScreenTrackOnPCs(this.sendingScreenVideoTrack, track, this.sendingScreenStream);
                }
                else if (track.kind === "audio" && this.sendingScreenAudioTrack instanceof MediaStreamTrack) {
                    replaceScreenTrackOnPCs(this.sendingScreenAudioTrack, track, this.sendingScreenStream);
                }
                else if (this.sendingScreenStream instanceof MediaStream) {
                    addScreenTrackPCs(track, this.sendingScreenStream);
                }
                else {
                    addScreenTrackPCs(track, stream); // Add ScreenAudio/ScreenVideo Track
                    this.sendingScreenStream = stream;
                }
                if (track.kind === "audio")
                    this.sendingScreenAudioTrack = track;
                if (track.kind === "video")
                    this.sendingScreenVideoTrack = track;
            });
        }
    }
    addTracksToNewPc(pc) {
        if (this.sendingAudioTrack)
            pc.addTrack(this.sendingAudioTrack, this.sendingStream);
        if (this.sendingVideoTrack)
            pc.addTrack(this.sendingVideoTrack, this.sendingStream);
        if (this.sendingScreenAudioTrack)
            pc.addTrack(this.sendingScreenAudioTrack, this.sendingScreenStream);
        if (this.sendingScreenVideoTrack)
            pc.addTrack(this.sendingScreenVideoTrack, this.sendingScreenStream);
    }
    getMetasForSendingTracks() {
        return this.metaStore.getByPeer(this._selfPeer.peerId, false);
    }
    async handleSendingSignal({ to: peer, data }) {
        const sessionDescMeta = new SessionDescriptionMeta();
        sessionDescMeta.sessionDescription = data;
        if (data) {
            sessionDescMeta.meta = this.getMetasForSendingTracks();
        }
        logger.debug("!======= sendingSignalDataMessage =========!", sessionDescMeta);
        try {
            const rtcReq = {
                payload: JSON.stringify(sessionDescMeta),
            };
            await this._peerCommunicator.send(peer.peerId, rtcReq, Protocols.rtcEvent);
        }
        catch (error) {
            logger.debug('can"t send signal through peer communicator', error);
        }
    }
    handleReceivedSignal(remotePeerId, rtcEventRequest) {
        if (!rtcEventRequest)
            return { isFine: false };
        const descriptionMeta = JSON.parse(rtcEventRequest.payload);
        logger.debug("!======= handleSignalDataMessage =========!", descriptionMeta);
        const { sessionDescription, meta } = descriptionMeta;
        // TODO: add multiple to metaStore
        if (meta) {
            for (const incomingMeta of meta) {
                this.metaStore.add(incomingMeta);
            }
        }
        logger.info("!======= CALL SIGNAL WITH =========!", sessionDescription);
        try {
            this.pcMap.get(remotePeerId).signal(sessionDescription);
            return {
                isFine: true,
            };
        }
        catch (e) {
            // Sentry.captureException(e);
            logger.debug("can't handle signal data message, peer is either destroyed or session description is null", e, sessionDescription);
            return {
                isFine: false,
            };
        }
    }
    handleReceivedTrack({ track, stream }) {
        try {
            const meta = this.metaStore.find(stream.id, track.kind);
            if (!meta)
                throw new Error("received a track without it's corresponding meta");
            logger.info(`!======= Received a Track=========! from: ${meta.peerId}`);
            this.emit(PeerConnectionEvents.ReceivedTrack, { peerId: meta.peerId, trackType: meta.type, track, stream });
        }
        catch (e) {
            logger.error(e);
        }
    }
    handleConnectionStateChange(connStateEvent) {
        try {
            const { connectionState, to } = connStateEvent;
            if (connectionState === RTCConnectionState.FAILED) {
                // TODO: try to rejoin meeting
                this.emit(PeerConnectionEvents.ConnectionFailed, to);
            }
        }
        catch (e) {
            logger.error(e);
        }
    }
    getPeerConnectionState(remotePeerId) {
        return this.pcMap.get(remotePeerId).connState;
    }
    close() {
        this._peerCommunicator.off(Protocols.rtcEvent, this.handleReceivedSignal);
        this.removeAllListeners();
        this.missingTrackService.stop();
        logger.debug("close connection called");
        for (const pc of this.pcMap.values()) {
            pc.close();
        }
        this.pcMap = new Map();
        this.metaStore.clean();
        this.sendingStream = null;
        this.sendingAudioTrack = null;
        this.sendingVideoTrack = null;
        this.sendingScreenStream = null;
        this.sendingScreenAudioTrack = null;
        this.sendingScreenVideoTrack = null;
    }
}
