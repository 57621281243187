import { MessageType } from "@ting/ting-ui-components";
import { addPollAnswer } from "../poll";
import { addLiveChatMessage, resetLiveChat } from "../live-chat/chat";
import { state } from "@src/state";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
import { selectUser } from "@src/selectors";
import { LiveChatWebTorrentUtil } from "@src/services/WebtorrentService";
export function studioStartStream({ title, channelId, category, privacy, thumbnailFile }) {
    return bindDependencies(async function (tingApiService) {
        return new Promise((resolve, reject) => {
            tingApiService
                .startStream({
                title,
                channelId,
                category,
                privacy,
                thumbnailFile,
            })
                .then(videoStream => {
                state.meeting.studio.stream.startStream(videoStream); // Start Live Recorder
                setupLiveChat(videoStream.video.uuid);
                resolve(videoStream);
            })
                .catch(error => {
                reject(error);
            });
        });
    }, [TYPES.TingApiService]);
}
export function studioStopStream() {
    state.meeting.studio.stream.stopStream();
    LiveChatWebTorrentUtil.Instance.dispose();
    resetLiveChat();
}
export function studioRequestStopStream(uid) {
    return bindDependencies(async function (tingApiService) {
        return tingApiService.stopStream(uid);
    }, [TYPES.TingApiService]);
}
export function studioSendStreamChunk(uid, data) {
    return bindDependencies(async function (tingApiService) {
        return tingApiService.sendStreamChunk(uid, data);
    }, [TYPES.TingApiService]);
}
export function getStreamInputDataApi(input, cancel) {
    return bindDependencies(async function (tingApiService) {
        if (input === "languages")
            return tingApiService.getLanguages({ cancel }).then(res => {
                const data = [];
                Object.keys(res).forEach(key => {
                    data.push({ value: key, label: res[key] });
                });
                return data;
            });
        if (input === "categories")
            return tingApiService.getCategories({ cancel }).then(res => {
                const data = [];
                Object.keys(res).forEach(key => {
                    data.push({ value: key, label: res[Number(key)] });
                });
                return data;
            });
        if (input === "privacies")
            return tingApiService.getPrivacies({ cancel }).then(res => {
                const data = [];
                Object.keys(res).forEach(key => {
                    data.push({ value: key, label: res[Number(key)] });
                });
                return data;
            });
        if (input === "playlists")
            return tingApiService.getVideoPlayLists({ cancel }).then(res => {
                return res.data.map(playlist => ({
                    label: playlist.displayName,
                    value: playlist.id,
                }));
            });
        if (input === "channels") {
            const tingUser = selectUser().peek();
            return tingApiService
                .getVideoChannels({ tingUser, cancel })
                .then(res => res.data.map(channel => ({ label: channel.displayName, value: channel.id })));
        }
    }, [TYPES.TingApiService]);
}
export function setupLiveChat(uuid) {
    return bindDependencies(async function (tingApiService, chatService) {
        return tingApiService.getVideoDetails({ uuid }).then(function (video) {
            LiveChatWebTorrentUtil.Instance.initialize({
                torrentOrMagnet: video.files[0].torrentUrl,
                onNewMessageReceived(msg) {
                    if (msg?.type === MessageType.POLL_ANSWER) {
                        addPollAnswer(msg);
                    }
                    else {
                        chatService.makeMessageSound();
                        addLiveChatMessage(msg);
                    }
                },
            });
        });
    }, [TYPES.TingApiService, TYPES.ChatService]);
}
