import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, TextField } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { SlideDown } from "@src/components/SlideDown";
import { classNameFactory } from "@src/utils/dom";
import { PanelController } from "@src/components/Studio/PanelController";
import "./layerSlideDown.scss";
const cn = classNameFactory("layer-slide");
export const LayerSlideDown = () => {
    const { t } = useTranslation("studio");
    return (_jsx(SlideDown, { title: t("layer"), content: _jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("transparency"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("transparency") }), _jsxs("div", { className: cn("group"), children: [_jsx(PanelController, { leftActions: {
                                        sourceTransparency: () => {
                                            // console.log("sourceTransparency");
                                        },
                                    } }), _jsxs("div", { className: cn("input-group"), children: [_jsx(TextField, { v2: true, type: "number", value: 100, name: "url", size: "small", state: "normal" }), _jsx(Text, { type: "sub", size: "body-small", fontWeight: "semiBold", children: "%" })] })] })] }), _jsxs("div", { className: cn("blending"), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("blendingMode") }), _jsx("div", { className: cn("group"), children: _jsx(Text, { type: "sub", size: "body-small", fontWeight: "semiBold", children: t("multiply") }) })] })] }) }));
};
