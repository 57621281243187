import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { SourceKind } from "@src/domain/Studio/types";
import { classNameFactory } from "@src/utils/dom";
import "./sourcePreview.scss";
const cn = classNameFactory("source-preview");
const VideoFrame = ({ stream, className }) => {
    const videoRef = useRef(null);
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.srcObject = stream instanceof MediaStream ? stream : null;
        }
    }, [stream]);
    return _jsx("video", { ref: videoRef, muted: true, autoPlay: true, style: { maxWidth: "100%" }, className: className });
};
export const SourcePreview = ({ source }) => {
    if (!source)
        return null;
    switch (source.sourceKind) {
        case SourceKind.VIDEO_INPUT:
        case SourceKind.VIDEO_OUTPUT:
            return _jsx(VideoFrame, { stream: source.source, className: cn("camera") });
        case SourceKind.DISPLAY_CAPTURE:
        case SourceKind.WINDOW_CAPTURE:
        case SourceKind.BROWSER_CAPTURE:
            return _jsx(VideoFrame, { stream: source.source, className: cn("screen") });
        case SourceKind.IMAGE_FILE:
            return _jsx("img", { src: source.source.src, alt: source.sourceLabel, className: cn("image") });
        case SourceKind.VIDEO_FILE:
            return _jsx("video", { src: source.source.src, autoPlay: true, loop: true, muted: true, className: cn("video") });
        // case SourceKind.AUDIO_FILE:
        //   return <audio src={(source.source as HTMLAudioElement).src} autoPlay loop />;
        // case SourceKind.AUDIO_INPUT:
        //   return <SourceAudioVolumeMeterField source={source} />;
        default:
            return null;
    }
};
