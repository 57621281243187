import logger from "loglevel";
import { RTCConnectionManager } from "./RTCConnectionManager";
import { DCMessageType, IMediaTrackType, PeerConnectionEvents } from "./types";
import { MediaTrackMetaData } from "./MediaTrackMetaData";
import { RTCConnectionState } from "@src/services/RTCConnection/types";
import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
export class SuperNodeRTCConnectionManager extends RTCConnectionManager {
    constructor(...args) {
        super(...args);
        Object.defineProperty(this, "forwardedTrackList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "pongNotReceived", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        this.pingAllConnection();
    }
    handleReceivedTrack({ sender, track, stream, }) {
        try {
            super.handleReceivedTrack({ sender, track, stream });
            this.forwardTrackToOtherPeers(sender, track, stream);
        }
        catch (e) {
            logger.error(e);
        }
    }
    getMetasForSendingTracks() {
        return this.metaStore.getByPeer(this._selfPeer.peerId, true);
    }
    forwardTrackToOtherPeers(sender, track, stream) {
        // Retrieve the exact track metadata from previously received metadata along with Offer/Answer
        // Note: this meta is guaranteed to be received previously because the other peer has to send it
        // along with his SDP
        const trackMetadata = this.metaStore.find(stream.id, track.kind);
        const receivedTrack = track;
        let forwardType;
        if (trackMetadata.type === IMediaTrackType.ScreenAudio) {
            forwardType = IMediaTrackType.ForwardedScreenAudio;
        }
        else if (trackMetadata.type === IMediaTrackType.ScreenVideo) {
            forwardType = IMediaTrackType.ForwardedScreenVideo;
        }
        else {
            forwardType = track.kind === "audio" ? IMediaTrackType.ForwardedAudio : IMediaTrackType.ForwardedVideo;
        }
        // add to the forwarded track list
        this.forwardedTrackList.push({ track, stream });
        // forward the recently received track to other peer connections we have
        let addedMetaFlag = false;
        for (const [peerId, peerConnection] of this.pcMap) {
            if (peerId === sender.peerId)
                continue;
            // Ignore if this is the same peer that we just received his tracks
            logger.info(`Adding ${trackMetadata.type}/${track.kind} track of peer ${sender.name}/${sender.peerId} => to peer ${peerConnection.remotePeer.name}/${peerId}`);
            peerConnection.addTrack(receivedTrack, stream);
            if (!addedMetaFlag) {
                this.metaStore.add(MediaTrackMetaData.buildFromTrack(track, stream, peerId, forwardType));
                addedMetaFlag = true;
            }
        }
        // remove the forwarded track from other peers once the track has ended
        receivedTrack.onended = () => {
            for (const [peerId, peerConnection] of this.pcMap) {
                if (peerId !== sender.peerId && peerConnection.connState !== RTCConnectionState.FAILED) {
                    logger.info(`Removing ${trackMetadata.type}/${track.kind} track of peer: ${sender.name}/${sender.peerId} => from peer ${peerConnection.remotePeer.name}/${peerId}`);
                    peerConnection.removeTrack(track, stream);
                    this.forwardedTrackList = this.forwardedTrackList.filter(({ track }) => track.id !== receivedTrack.id);
                }
            }
        };
    }
    handleDCMessage(dcMessage) {
        if (dcMessage.to === "all") {
            for (const [peerId, pc] of this.pcMap.entries()) {
                if (peerId !== dcMessage.from.peerId) {
                    pc.sendDCMessage(dcMessage);
                }
            }
        }
        if (Array.isArray(dcMessage.to)) {
            for (const recipient of dcMessage.to) {
                const rpc = this.pcMap.get(recipient);
                if (rpc && recipient !== dcMessage.from.peerId && recipient !== this._selfPeer.peerId) {
                    rpc.sendDCMessage(dcMessage);
                }
            }
        }
        if (dcMessage.to !== "all" && !dcMessage.to.includes(this._selfPeer.peerId))
            return;
        if (dcMessage.type === DCMessageType.PONG) {
            this.pongNotReceived.set(dcMessage.from.peerId, 0);
        }
        else {
            this.emit(PeerConnectionEvents.ReceivedDCMessage, dcMessage);
        }
    }
    addTracksToNewPc(pc) {
        if (this.sendingAudioTrack) {
            pc.addTrack(this.sendingAudioTrack, this.sendingStream);
        }
        if (this.sendingVideoTrack) {
            pc.addTrack(this.sendingVideoTrack, this.sendingStream);
        }
        if (this.sendingScreenStream) {
            this.sendingScreenStream.getTracks().forEach((track) => {
                pc.addTrack(track, this.sendingScreenStream);
            });
        }
        // forward already sent tracks to the new pc
        for (const { track, stream } of this.forwardedTrackList) {
            pc.addTrack(track, stream);
        }
    }
    sendMessage(message) {
        for (const [peerId, pc] of this.pcMap) {
            if (message.to === "all" || message.to.includes(peerId)) {
                pc.sendDCMessage(message);
            }
        }
    }
    pingAllConnection() {
        this.pongNotReceived.forEach((pong, peerId) => {
            if (pong >= 3)
                this.kickPeer(peerId);
        });
        if (this._selfPeer) {
            const pingMessage = new DCMessage({
                from: {
                    peerId: this._selfPeer.peerId,
                    name: this._selfPeer.name,
                },
                to: "all",
                type: DCMessageType.PING,
                payload: null,
            });
            for (const [peerId] of this.pcMap) {
                const prevState = this.pongNotReceived.get(peerId) || 0;
                this.pongNotReceived.set(peerId, prevState + 1);
            }
            this.sendMessage(pingMessage);
        }
        setTimeout(() => {
            this.pingAllConnection();
        }, 10000);
    }
    kickPeer(peerId) {
        const kickMessage = new DCMessage({
            from: { peerId: this._selfPeer.peerId, name: this._selfPeer.name },
            to: "all",
            type: DCMessageType.PEER_KICK,
            payload: { peerId: peerId },
        });
        this.sendMessage(kickMessage);
        /* The message that is sent to others is not executed in the supernode itself.
          Here, using this line of code, we emitted the message to the supernode itself
         */
        this.emit(PeerConnectionEvents.ReceivedDCMessage, kickMessage);
    }
    handleConnectionStateChange(connStateEvent) {
        const { connectionState, to } = connStateEvent;
        if (connectionState === RTCConnectionState.FAILED) {
            this.kickPeer(to.peerId);
        }
    }
}
