import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { Button, RPopover, Text } from "@ting/ting-ui-components";
import { AddOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { SelectParticipants } from "@src/components/Files/SelectParticipants";
import { LiveChatWebTorrentUtil } from "@src/services/WebtorrentService";
import { sendFileChat } from "@src/controller";
import { classNameFactory } from "@src/utils/dom";
import "./FilesFooter.scss";
const cn = classNameFactory("files-footer");
export const FilesFooter = () => {
    const [isSelectParticipantsOpen, setIsSelectParticipantsOpen] = useState(false);
    const fileInputRef = useRef();
    const selectedPeerId = useRef();
    const handleFileChange = (event) => {
        const input = event.target;
        if (!input || !input.files || input.files.length !== 1) {
            return;
        }
        const selectedFile = input.files[0];
        LiveChatWebTorrentUtil.Instance.seedFile(selectedFile).then(fileContent => {
            sendFileChat(fileContent, selectedPeerId.current ? [selectedPeerId.current] : "all");
            selectedPeerId.current = null;
        });
    };
    const handleOpenSelectParticipantsModal = () => {
        selectedPeerId.current = null;
        setIsSelectParticipantsOpen(true);
    };
    const handleOpenFileDialog = () => {
        fileInputRef?.current?.click();
    };
    const handleOnParticipantSelect = (selectedParticipant) => {
        selectedPeerId.current = selectedParticipant.peerId;
        setIsSelectParticipantsOpen(false);
        handleOpenFileDialog();
    };
    return (_jsxs(_Fragment, { children: [_jsx(SelectParticipants, { handleClose: () => setIsSelectParticipantsOpen(false), onSelect: handleOnParticipantSelect, isOpen: isSelectParticipantsOpen }), _jsx(RPopover, { trigger: _jsx("div", { children: _jsx(Button, { className: cn("add-file-btn"), v2: true, variant: "primary", type: "brand", round: true, size: "big", startIcon: _jsx(AddOutlinedIcon, { width: 24, height: 24 }) }) }), contentClassname: cn("add-file-dropdown"), content: _jsx("div", { children: _jsxs("ul", { className: cn("add-file-dropdown-items"), children: [_jsx("li", { children: _jsx("button", { onClick: handleOpenFileDialog, children: _jsx(Text, { size: "body-medium", children: "Send file to everyone" }) }) }), _jsx("li", { children: _jsx("button", { onClick: handleOpenSelectParticipantsModal, children: _jsx(Text, { size: "body-medium", children: "Send file to specific participant" }) }) })] }) }) }), _jsx("input", { type: "file", className: cn("file-input"), ref: fileInputRef, onChange: handleFileChange })] }));
};
