import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, SelectBox, Text } from "@ting/ting-ui-components";
import { TeamOutlinedIcon, TimerOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import { SignUpModal } from "@src/components/SignUpModal";
import { CreateRoomModal } from "@src/components/CreateRoomModal";
import { BottomModal } from "@src/components/BottomModal";
import { ListRow } from "@src/components/ListRow";
import { selectShouldRegister } from "@src/selectors";
import { useBuildMobile } from "@src/hooks/breakpoints";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { Header } from "@src/layout/HomeLayout/Header";
import { routePaths } from "@src/routes/routes";
import { RoomType } from "@src/domain/Room";
import "./home.scss";
const cn = classNameFactory("home");
export const Home = () => {
    const [openSignUpModal, setOpenSignUpModal] = useState(false);
    const [openCreateRoomModal, setOpenCreateRoomModal] = useState(false);
    const [startMeetingModal, setStartMeetingModal] = useState(false);
    const [meetingType, setMeetingType] = useState(RoomType.instant);
    const shouldRegister = useSubscribe(selectShouldRegister());
    const isBuildMobile = useBuildMobile();
    const history = useHistory();
    useEffect(() => {
        if (shouldRegister)
            setOpenSignUpModal(true);
    }, [shouldRegister]);
    const closeStartMeetingModal = () => setStartMeetingModal(false);
    const onJoinButtonClick = () => history.push(routePaths.JOIN_ROOM());
    return (_jsxs("div", { className: cn("wrapper"), children: [_jsx(Header, { className: cn("header") }), _jsxs("div", { className: cn(""), children: [_jsx("div", { className: cn("meeting"), children: _jsxs("div", { className: cn("meeting-container"), children: [_jsxs("h1", { className: cn("title"), children: ["The", " ", _jsxs("strong", { className: cn("title-hero", { orange: true }), children: ["most secure,", !isBuildMobile && (_jsx("svg", { width: "332", height: "20", fill: "none", className: cn("meeting-border"), children: _jsx("path", { stroke: "#FFAE4D", strokeLinecap: "round", strokeWidth: "2", d: "M1 19C52.297 8.287 190.112-8.86 331 8.25" }) }))] }), _jsx("br", {}), "decentralized video", _jsx("br", {}), "meeting platform."] }), _jsx(Text, { type: "sub", size: isBuildMobile ? "p2" : "h4", fontWeight: "medium", className: cn("caption"), children: "Meet anyone, anywhere in the world.\nA truly borderless peer-to-peer\ncommunication platform." }), _jsxs("div", { className: cn("actions"), children: [!isBuildMobile && (_jsx(SelectBox, { options: [
                                                { label: "Instant Meeting", value: "1" },
                                                { label: "Schedule a Meeting", value: "2" },
                                            ], value: { label: "Start a meeting", value: "0" }, size: "large", className: cn("start-meeting"), onChange: value => {
                                                const _value = value.value;
                                                setOpenCreateRoomModal(true);
                                                if (_value === "1") {
                                                    setMeetingType(RoomType.instant);
                                                }
                                                else if (_value === "2") {
                                                    setMeetingType(RoomType.scheduled);
                                                }
                                            } })), isBuildMobile && (_jsx(Button, { v2: true, type: "brand", size: "big", onClick: () => setStartMeetingModal(true), children: "Start a meeting" })), _jsx(Button, { v2: true, type: "neutral", size: "big", onClick: onJoinButtonClick, children: "Join a meeting" })] })] }) }), !isBuildMobile && (_jsx("div", { className: cn("studio"), children: _jsxs("div", { className: cn("studio-container"), children: [_jsxs("div", { className: cn("title"), children: ["Enter your", _jsxs("strong", { className: cn("title-hero"), children: ["Studio", _jsx("svg", { width: "197", height: "16", fill: "none", className: cn("studio-border"), children: _jsx("path", { stroke: "#fff", strokeLinecap: "round", strokeWidth: "2", d: "M1 15C32.654 2.146 118.555-4.797 196 7.204" }) })] }), _jsx("br", {}), "and start streaming", _jsx("br", {}), "now!"] }), _jsx(Text, { type: "sub", size: "h4", fontWeight: "medium", className: cn("caption"), children: "Meet anyone, anywhere in the world.\nA truly borderless peer-to-peer\ncommunication platform." }), _jsx("div", { className: cn("actions"), children: _jsx(Button, { v2: true, type: "brand", size: "big", children: "Enter Studio" }) })] }) })), _jsx(SignUpModal, { open: openSignUpModal, onClose: () => setOpenSignUpModal(false) }), _jsx(CreateRoomModal, { open: openCreateRoomModal, onClose: () => setOpenCreateRoomModal(false), type: meetingType }), isBuildMobile && (_jsxs(BottomModal, { isOpen: startMeetingModal, onClose: closeStartMeetingModal, children: [_jsx(ListRow, { title: "Instant Meeting", onClick: () => {
                                    closeStartMeetingModal();
                                    setOpenCreateRoomModal(true);
                                }, icon: TeamOutlinedIcon }), _jsx(ListRow, { title: "Scheduled Meeting", icon: TimerOutlinedIcon })] }))] })] }));
};
