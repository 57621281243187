import { jsx as _jsx } from "react/jsx-runtime";
import { AddOutlinedIcon, AdjustOutlinedIcon, AlignHorizontalCenterIcon, AlignHorizontalLeftIcon, AlignHorizontalRightIcon, AlignVerticalBottomIcon, AlignVerticalCenterIcon, AlignVerticalTopIcon, BringForwardIcon, BringToFrontIcon, ChannelsOutlinedIcon, CloseOutlinedIcon, CopyOutlinedIcon, DownloadOutlinedIcon, FillCanvasIcon, FlipHorizontalOutlinedIcon, FlipVerticalOutlinedIcon, FreeTransformIcon, FullscreenExitOutlinedIcon, FullscreenOutlinedIcon, HiddenOutlinedIcon, HorizontalOrderOutlinedIcon, LinkFilledIcon, LinkOutlinedIcon, LockOutlinedIcon, RedoIcon, Rotate90Icon, SendBackwardIcon, SendToBackIcon, SettingsOutlinedIcon, StretchToCanvasIcon, TrashOutlinedIcon, UndoIcon, UnlockOutlinedIcon, VisibleOutlinedIcon, } from "@ting/ting-ui-components/dist/icons";
import { EyeIcon } from "@ting/ting-ui-components";
export const callbackIcons = {
    add: _jsx(AddOutlinedIcon, { height: "20px", width: "20px" }),
    close: _jsx(CloseOutlinedIcon, { height: "20px", width: "20px" }),
    redo: _jsx(RedoIcon, { height: "20px", width: "20px" }),
    undo: _jsx(UndoIcon, { height: "20px", width: "20px" }),
    export: _jsx(DownloadOutlinedIcon, { height: "20px", width: "20px" }),
    settings: _jsx(SettingsOutlinedIcon, { height: "20px", width: "20px" }),
    delete: _jsx(TrashOutlinedIcon, { width: "20px", height: "20px" }),
    duplicate: _jsx(CopyOutlinedIcon, { width: "20px", height: "20px" }),
    objectPropertiesToggle: _jsx(FreeTransformIcon, { height: "20px", width: "20px" }),
    alignHorizontalLeft: _jsx(AlignHorizontalLeftIcon, { height: "20px", width: "20px" }),
    alignHorizontalRight: _jsx(AlignHorizontalRightIcon, { height: "20px", width: "20px" }),
    alignHorizontalCenter: _jsx(AlignHorizontalCenterIcon, { height: "20px", width: "20px" }),
    alignVerticalTop: _jsx(AlignVerticalTopIcon, { height: "20px", width: "20px" }),
    alignVerticalBottom: _jsx(AlignVerticalBottomIcon, { height: "20px", width: "20px" }),
    alignVerticalCenter: _jsx(AlignVerticalCenterIcon, { height: "20px", width: "20px" }),
    bringToFront: _jsx(BringToFrontIcon, { height: "20px", width: "20px" }),
    bringForward: _jsx(BringForwardIcon, { height: "20px", width: "20px" }),
    sendToBack: _jsx(SendToBackIcon, { height: "20px", width: "20px" }),
    sendBackward: _jsx(SendBackwardIcon, { height: "20px", width: "20px" }),
    fillCanvas: _jsx(FillCanvasIcon, { height: "20px", width: "20px" }),
    stretchToCanvas: _jsx(StretchToCanvasIcon, { height: "20px", width: "20px" }),
    rotateSource: _jsx(Rotate90Icon, { height: "20px", width: "20px" }),
    flipSourceHorizontal: _jsx(FlipHorizontalOutlinedIcon, { height: "20px", width: "20px" }),
    flipSourceVertical: _jsx(FlipVerticalOutlinedIcon, { height: "20px", width: "20px" }),
    sourceTransparency: _jsx(EyeIcon, { height: "20px", width: "20px" }),
};
export const toggleIcons = {
    fullscreenToggle: {
        active: _jsx(FullscreenOutlinedIcon, { height: "20px", width: "20px" }),
        inactive: _jsx(FullscreenExitOutlinedIcon, { height: "20px", width: "20px" }),
    },
    scenesToggle: {
        active: _jsx(HorizontalOrderOutlinedIcon, { height: "20px", width: "20px" }),
        inactive: _jsx(HorizontalOrderOutlinedIcon, { height: "20px", width: "20px" }),
    },
    sourcesToggle: {
        active: _jsx(ChannelsOutlinedIcon, { height: "20px", width: "20px" }),
        inactive: _jsx(ChannelsOutlinedIcon, { height: "20px", width: "20px" }),
    },
    mixerToggle: {
        active: _jsx(AdjustOutlinedIcon, { height: "20px", width: "20px" }),
        inactive: _jsx(AdjustOutlinedIcon, { height: "20px", width: "20px" }),
    },
    hideSourceToggle: {
        active: _jsx(VisibleOutlinedIcon, { height: "20px", width: "20px" }),
        inactive: _jsx(HiddenOutlinedIcon, { height: "20px", width: "20px" }),
    },
    lockSourceToggle: {
        active: _jsx(LockOutlinedIcon, { width: "20px", height: "20px" }),
        inactive: _jsx(UnlockOutlinedIcon, { width: "20px", height: "20px" }),
    },
    linkSourceAspectRatioToggle: {
        active: _jsx(LinkOutlinedIcon, { height: "20px", width: "20px" }),
        inactive: _jsx(LinkFilledIcon, { height: "20px", width: "20px" }),
    },
};
