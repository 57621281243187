import { ENV } from "@src/env";
export default {
    tingSite: ENV.TING_SITE,
    tingAPI: ENV.TING_HOST,
    tingStreamAPI: ENV.TING_STREAM,
    roomsAPI: ENV.MEETING_API,
    tingHost: ENV.TING_HOST,
    oauthDomain: ENV.OAUTH_DOMAIN,
    oauthClient: ENV.OAUTH_CLIENT,
    fileServer: ENV.FILE_SERVER_URL || "",
    studioRestoreLocalName: ENV.STUDIO_RESTORE_LOCAL_NAME || "",
    sentryDNS: ENV.SENTRY_DNS,
    scheduleLobbyPollingInterval: 5000,
    reOpenConnectionInterval: 3000,
};
