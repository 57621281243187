var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from "inversify";
import configs from "@src/configs";
let Calendar = class Calendar {
    constructor() {
        Object.defineProperty(this, "tokenClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGisLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isClientLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    async retrieveAndLoadGoogleScripts() {
        return new Promise(resolve => {
            const scriptGoogle = document.createElement("script");
            const scriptGapi = document.createElement("script");
            scriptGoogle.src = configs.google.scriptSrcGoogle;
            scriptGoogle.async = true;
            scriptGoogle.defer = true;
            scriptGapi.src = configs.google.scriptSrcGapi;
            scriptGapi.async = true;
            scriptGapi.defer = true;
            document.body.appendChild(scriptGapi);
            document.body.appendChild(scriptGoogle);
            scriptGapi.onload = () => {
                gapi.load("client", () => this.initGapiClient(resolve));
            };
            scriptGoogle.onload = () => {
                this.gisLoaded(resolve);
            };
        });
    }
    gisLoaded(resolve) {
        this.tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: configs.google.clientId,
            scope: configs.google.calendarScopes,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            callback: () => { },
        });
        this.isGisLoaded = true;
        if (this.isClientLoaded)
            resolve();
    }
    initGapiClient(resolve) {
        gapi.client
            .init({
            apiKey: configs.google.apiKey,
            discoveryDocs: [configs.google.calendarDiscoveryDoc],
            // hosted_domain: this.config.hosted_domain,
        })
            .then(() => {
            this.isClientLoaded = true;
            if (this.isGisLoaded)
                resolve();
        })
            .catch(() => {
            // logger.error(e);
        });
    }
    async requestToken() {
        return new Promise((resolve, reject) => {
            this.tokenClient.callback = resp => {
                if (resp.error !== undefined) {
                    reject(resp.error);
                }
                resolve(resp);
            };
            this.tokenClient.error_callback = err => {
                reject(err.message);
            };
            if (gapi.client.getToken() === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                this.tokenClient.requestAccessToken({ prompt: "consent" });
            }
            else {
                // Skip display of account chooser and consent dialog for an existing session.
                this.tokenClient.requestAccessToken({ prompt: "" });
            }
        });
    }
    get isLoaded() {
        return this.isGisLoaded && this.isClientLoaded;
    }
    async createNewEvent(newEvent) {
        if (!this.isLoaded)
            await this.retrieveAndLoadGoogleScripts();
        await this.requestToken();
        const req = gapi.client.calendar.events.insert({ calendarId: "primary", sendUpdates: "all", resource: newEvent });
        return req.then(response => response.result);
    }
};
Calendar = __decorate([
    injectable()
], Calendar);
export { Calendar };
