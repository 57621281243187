import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Text, ToggleButton } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { classNameFactory } from "@src/utils/dom";
import { SourceKind } from "@src/domain/Studio/types";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSource } from "@src/selectors/studio";
import "./sourceFields.scss";
const cn = classNameFactory("source-field");
export const SourceShowCursorField = () => {
    const { t } = useTranslation("studio");
    const source = useSubscribe(selectStudioActiveSource());
    const [cursorActive, setCursorActive] = useState(false);
    if (source &&
        (source.sourceKind === SourceKind.DISPLAY_CAPTURE ||
            source.sourceKind === SourceKind.WINDOW_CAPTURE ||
            source.sourceKind === SourceKind.BROWSER_CAPTURE)) {
        return (_jsxs("div", { className: cn(""), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", children: t("showCursor") }), _jsx(ToggleButton, { value: cursorActive, onClick: enable => {
                        setCursorActive(enable);
                    } })] }));
    }
    return null;
};
