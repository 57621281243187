import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { classNameFactory } from "@src/utils/dom";
import { CamController, MicController, SettingsController } from "@src/components/ControllerButtons/";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectSelfStream, selectIsCamOn, selectSelfVideoTrack } from "@src/selectors";
import { CamOffFrame } from "@src/components/CamOffFrame";
import { useBuildMobile } from "@src/hooks/breakpoints";
import "./StreamFrame.scss";
const cn = classNameFactory("stream-frame");
export const StreamFrame = () => {
    const isMobile = useBuildMobile();
    const selfStream = useSubscribe(selectSelfStream());
    const selfVideoTrack = useSubscribe(selectSelfVideoTrack());
    const isCamOn = useSubscribe(selectIsCamOn());
    const videoElementRef = useRef(null);
    const rootRef = useRef(null);
    const [rootHeight, setRootHeight] = useState();
    useEffect(() => {
        if (selfVideoTrack)
            videoElementRef.current.srcObject = selfStream.stream;
    }, [selfStream, selfVideoTrack]);
    useLayoutEffect(() => {
        function handleHeight() {
            const { width } = rootRef.current.getBoundingClientRect();
            setRootHeight(Math.round((width * 9) / 16));
        }
        handleHeight();
        window.addEventListener("resize", handleHeight);
        return () => {
            window.removeEventListener("resize", handleHeight);
        };
    }, []);
    return (_jsxs("div", { className: cn(""), ref: rootRef, style: { height: rootHeight || 0 }, children: [_jsx("video", { muted: true, autoPlay: true, ref: videoElementRef, className: cn("video-element", { hide: !isCamOn }) }), !isCamOn && _jsx(CamOffFrame, {}), !isMobile && (_jsxs("div", { className: cn("setting-row"), children: [_jsx(CamController, {}), _jsx(MicController, {}), _jsx(SettingsController, {})] }))] }));
};
