let ls;
let ss;
try {
    // checking browser support for localStorage and sessionStore
    const t = "t";
    localStorage.setItem(t, t);
    localStorage.removeItem(t);
    sessionStorage.setItem(t, t);
    sessionStorage.removeItem(t);
    Storage.prototype.setObject = function (key, value) {
        this.setItem(key, JSON.stringify(value));
    };
    Storage.prototype.getObject = function (key) {
        const value = this.getItem(key);
        return value && JSON.parse(value);
    };
    ls = localStorage;
    ss = sessionStorage;
}
catch (e) {
    ls = null;
    ss = null;
}
export const improvedLocalStorage = ls;
export const improvedSessionStorage = ss;
