import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { Button } from "@ting/ting-ui-components";
import { FullscreenOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { isFullScreen, toggleFullScreen } from "@src/utils/requestFullscreen";
export const FullscreenController = () => {
    const [isFull, setIsFull] = useState(isFullScreen());
    const onFullscreenClick = () => {
        toggleFullScreen();
        setIsFull(!isFull);
    };
    return (_jsx(Button, { v2: true, round: true, icon: _jsx(FullscreenOutlinedIcon, { height: "20px", width: "20px" }), size: "medium", type: isFull ? "button" : "neutral", variant: isFull ? "primary" : "ghost", onClick: onFullscreenClick }));
};
