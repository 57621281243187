export class AudioFilterChain {
    constructor(filters) {
        Object.defineProperty(this, "filters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.filters = filters;
    }
    processChain(source) {
        let currentNode = source;
        for (const filter of this.filters) {
            currentNode = filter.apply(currentNode);
        }
        return currentNode;
    }
    getFilterByType(type) {
        return this.filters.find(filter => filter instanceof type);
    }
    clear() {
        for (const filter of this.filters) {
            filter.clear();
        }
    }
}
