import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { SelectBox, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { CameraFrame } from "../CameraFrame";
import { resolutionsToCheck } from "./constants";
import { classNameFactory } from "@src/utils/dom";
import { ToggleWithContent } from "@src/components/ToggleWithContent";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStreamSettings } from "@src/selectors";
import { LocalStream } from "@src/services/localStream";
import { SourceKind } from "@src/domain/Studio/types";
import { changeInputDevice, handleScreenResolution, handleVideoResolution } from "@src/controller/userMedia";
import "./VideoController.scss";
const cn = classNameFactory("video-controller");
export const VideoController = () => {
    const { t } = useTranslation(["common", "room"]);
    const [loading, setLoading] = useState(false);
    const [videoInputOptions, setVideoInputOptions] = useState([]);
    const [maxSelectedCameraResolution, setMaxSelectedCameraResolution] = useState();
    const [availableCameraResolution, setAvailableCameraResolution] = useState();
    const [availableScreenResolution, setAvailableScreenResolution] = useState();
    const streamSettings = useSubscribe(selectStreamSettings());
    const { videoInput, videoInputResolution, screenInputResolution } = streamSettings;
    useEffect(() => {
        async function getVideo() {
            setLoading(true);
            const devices = await LocalStream.devices(SourceKind.VIDEO_INPUT);
            const videoInputOptions = devices
                .filter(device => device.label)
                .map(device => ({ value: device, label: device.label }));
            setVideoInputOptions(videoInputOptions);
            setLoading(false);
        }
        navigator.mediaDevices.addEventListener("devicechange", getVideo);
        getVideo();
        return () => {
            navigator.mediaDevices.removeEventListener("devicechange", getVideo);
        };
    }, []);
    useEffect(() => {
        LocalStream.getMediaTrack("video", { deviceId: { exact: videoInput?.deviceId } }).then(stream => {
            const videoTracks = stream?.getVideoTracks();
            if (stream && videoTracks?.length) {
                const streamSettings = videoTracks[0]?.getSettings();
                setMaxSelectedCameraResolution({
                    width: streamSettings.width,
                    height: streamSettings.height,
                });
            }
        });
    }, [videoInput]);
    useEffect(() => {
        if (maxSelectedCameraResolution) {
            setAvailableCameraResolution(resolutionsToCheck
                .filter(x => x.width <= maxSelectedCameraResolution.width && x.height <= maxSelectedCameraResolution.height)
                .map(x => ({ label: `${x.width}*${x.height}`, value: x })));
        }
    }, [maxSelectedCameraResolution]);
    useEffect(() => {
        if (window.screen) {
            setAvailableScreenResolution(resolutionsToCheck
                .filter(x => x.width <= window.screen.width && x.height <= window.screen.height)
                .map(x => ({ label: `${x.width}*${x.height}`, value: x })));
        }
    }, []);
    const videoResolutionValue = videoInputResolution && {
        label: `${videoInputResolution.width}*${videoInputResolution.height}`,
        value: videoInputResolution,
    };
    const screenResolutionValue = screenInputResolution && {
        label: `${screenInputResolution.width}*${screenInputResolution.height}`,
        value: screenInputResolution,
    };
    const notVideoInputFound = videoInputOptions.length === 0;
    const cameraValue = !notVideoInputFound && {
        label: videoInput?.label,
        value: videoInput,
    };
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("section"), children: [_jsx(Text, { size: "h4", fontWeight: "semiBold", children: t("room:camera-settings") }), _jsxs("div", { className: cn("camera-wrapper"), children: [_jsx(SelectBox, { label: t("camera"), size: "large", onChange: opt => changeInputDevice(opt.value), value: cameraValue, placeholder: !loading && notVideoInputFound && t("room:no-camera-found"), isDisabled: loading || notVideoInputFound, isLoading: loading, options: videoInputOptions }), _jsx("div", { className: cn("video-frame"), children: _jsx(CameraFrame, {}) })] })] }), _jsxs("div", { className: cn("section"), children: [_jsx(Text, { size: "h4", fontWeight: "semiBold", children: t("room:advanced-video-settings") }), _jsx(SelectBox, { label: t("room:send-resolution"), size: "large", value: videoResolutionValue, options: availableCameraResolution, onChange: opt => handleVideoResolution(opt.value) }), _jsx(ToggleWithContent, { label: t("room:auto-resolution"), caption: t("room:auto-resolution-caption") }), _jsx(SelectBox, { label: t("room:screen-sharing-resolution"), value: screenResolutionValue, size: "large", options: availableScreenResolution, onChange: opt => handleScreenResolution(opt.value) })] })] }));
};
