import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import c from "classnames";
import { Button, UserAvatar, Text } from "@ting/ting-ui-components";
import { MicOffOutlinedIcon, MicOnOutlinedIcon, MoreVerticalFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import { selectMeetingUser, selectRoomPeer } from "@src/selectors";
import { PeerRoles } from "@src/domain/Peer";
import { useSubscribe } from "@src/hooks/useSubscribe";
import VerifyIcon from "@src/assets/verify.svg";
import "./profileRow.scss";
const cn = classNameFactory("profile-row");
const getProfileRowCaption = (role) => {
    switch (role) {
        case PeerRoles.SUPER_NODE:
            return "Host";
        case PeerRoles.CANDIDATE:
            return "Moderator";
        default:
            return "";
    }
};
export const ProfileRow = props => {
    const { peerId, className, showCaption = true, showActions = true } = props;
    const peer = useSubscribe(selectRoomPeer(peerId));
    // TODO: remove this when reactive state fixed
    useSubscribe(selectMeetingUser());
    const isMicOn = peer.peerStreamSettings.isMicOn;
    const caption = getProfileRowCaption(peer.role);
    const verified = false;
    const micStatusIcon = isMicOn ? (_jsx(MicOnOutlinedIcon, { width: "20px", height: "20px" })) : (_jsx(MicOffOutlinedIcon, { width: "20px", height: "20px" }));
    return (_jsxs("div", { className: c(cn(""), className), children: [_jsx(UserAvatar, { image: peer.avatarURL, userName: peer.name, size: "lg" }), _jsxs("div", { className: cn("content"), children: [_jsxs("div", { className: cn("name-container"), children: [_jsx(Text, { size: "body-large", fontWeight: "medium", className: cn("name"), children: peer.name }), verified && _jsx("img", { src: VerifyIcon, alt: "verify icon" })] }), showCaption && caption && (_jsx(Text, { size: "body-medium", fontWeight: "regular", className: cn("caption"), children: caption }))] }), showActions && (_jsxs("div", { className: cn("actions"), children: [_jsx(Button, { v2: true, type: isMicOn ? "neutral" : "danger", round: true, variant: "ghost", size: "small", icon: micStatusIcon }), _jsx(Button, { v2: true, type: "neutral", variant: "ghost", disabled: true, size: "small", icon: _jsx(MoreVerticalFilledIcon, { width: "20px", height: "20px" }) })] }))] }));
};
