import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Text, UserAvatar, Button } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { PermissionModal } from "@src/components/PermissionModal";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectAudioInputError, selectMeetingUser, selectUser, selectVideoInputError } from "@src/selectors";
import { toggleCamOn, toggleMicOn } from "@src/controller/userMedia";
import { MediaInputErrors } from "@src/domain/SelfStream/types";
import "./CamOffFrame.scss";
const cn = classNameFactory("cam-off-frame");
export const CamOffFrame = ({ peerId }) => {
    // TODO: this should be based on peerId and Tinguser that are not yet implemented
    const selfUser = useSubscribe(selectUser());
    const meetingUser = useSubscribe(selectMeetingUser());
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation("room");
    const videoInputError = useSubscribe(selectVideoInputError());
    const audioInputError = useSubscribe(selectAudioInputError());
    const [permissionState, setPermissionState] = useState({ camera: false, microphone: false });
    let avatar;
    if (!peerId && selfUser) {
        avatar = {
            image: selfUser.account.avatar?.path,
            alt: selfUser.account.displayName,
            userName: selfUser.username,
            coreID: selfUser.corepassId,
        };
    }
    else {
        // throw new Error("Not Yet Implemented");
        avatar = {
            userName: meetingUser?.displayName || "-",
        };
    }
    const avatarBlink = !selfUser && !meetingUser?.displayName;
    useEffect(() => {
        async function checkPermission(name) {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                const res = await navigator.permissions.query({ name });
                setPermissionState(prev => ({ ...prev, [name]: res.state !== "granted" }));
            }
            catch (error) {
                setPermissionState(prev => ({ ...prev, [name]: false }));
            }
        }
        checkPermission("camera");
        checkPermission("microphone");
    }, []);
    const handleCamMicOn = async () => {
        if (videoInputError === MediaInputErrors.NotAllowedError || audioInputError === MediaInputErrors.NotAllowedError) {
            setOpen(true);
        }
        setIsLoading(true);
        await toggleCamOn(true);
        await toggleMicOn(true);
        setIsLoading(false);
    };
    const handleCamOn = async () => {
        if (videoInputError === MediaInputErrors.NotAllowedError) {
            setOpen(true);
        }
        setIsLoading(true);
        await toggleCamOn(true);
        setIsLoading(false);
    };
    const permissionMessgae = permissionState.camera && permissionState.microphone ? (_jsxs("div", { className: cn("permission-wrapper"), children: [_jsx(Text, { size: "p1", children: t("access-camera-mic") }), _jsx("div", { children: _jsx(Button, { disabled: isLoading, v2: true, type: "neutral", variant: "primary", onClick: handleCamMicOn, children: t("enable-mic-camera") }) })] })) : permissionState.camera ? (_jsxs("div", { className: cn("permission-wrapper"), children: [_jsx(Text, { size: "p1", children: t("access-camera") }), _jsx("div", { children: _jsx(Button, { disabled: isLoading, v2: true, type: "neutral", variant: "primary", onClick: handleCamOn, children: t("enable-camera") }) })] })) : null;
    // TODO: add waves for when the user is speaking
    return (_jsxs("div", { className: cn(""), children: [_jsx(UserAvatar, { ...avatar, size: "xxl", className: cn("avatar", { blink: avatarBlink }) }), permissionMessgae, _jsx(PermissionModal, { camera: !!videoInputError, mic: !!audioInputError, open: isOpen, onClose: () => setOpen(false) })] }));
};
