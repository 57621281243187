import { useEffect, useRef } from "react";
export function useInterval(callback, delay) {
    const callbackRef = useRef();
    // Update the callback function with the current render callback that has access to the latest props and state
    useEffect(() => {
        callbackRef.current = callback;
    });
    useEffect(() => {
        if (!delay) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => { };
        }
        const interval = setInterval(() => {
            callbackRef.current && callbackRef.current();
        }, delay);
        return () => clearInterval(interval);
    }, [delay]);
}
