import { jsx as _jsx } from "react/jsx-runtime";
import { FormSelectField } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import "./selectField.scss";
const cn = classNameFactory("select-field");
export const SelectField = props => {
    const { name, options, value, defaultValue, onChange, label, size, className = "" } = props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onChangeHandler = (name, option) => onChange(option);
    return (_jsx(FormSelectField, { size: size, label: label, name: name, className: `${cn("")} ${className}`.trim(), options: options, value: value, defaultValue: defaultValue, setFieldValue: onChangeHandler }));
};
