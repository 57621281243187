var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { AuthorizationNotifier, AuthorizationRequest, AuthorizationServiceConfiguration, RedirectRequestHandler, GRANT_TYPE_AUTHORIZATION_CODE, GRANT_TYPE_REFRESH_TOKEN, TokenRequest, BaseTokenRequestHandler, EndSessionResponse, TokenResponse, FetchRequestor, AuthorizationResponse, RevokeTokenRequest, } from "@ting/app-auth";
import logger from "loglevel";
import { injectable } from "inversify";
import { QueryStringUtils } from "./QueryStringUtils";
import configs from "@src/configs";
import { StorageKeys, TingLocalStorage } from "@src/services/storage";
// TODO: setup loglevel
const { origin } = window.location;
const settings = {
    // URL to the authentication server (including realm)
    authority: configs.app.oauthDomain,
    // The name of the client in Keycloak setup for this service
    client_id: configs.app.oauthClient,
    // Where to redirect the user to after successful authentication
    redirect_uri: `${origin}${configs.auth.loginCallbackPath}`,
    // "openid" tells the server that this client uses oidc for authentication
    scope: "offline",
    token_type_hint: "refresh_token",
};
let Authorizer = class Authorizer {
    constructor() {
        Object.defineProperty(this, "notifier", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "authorizationHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tokenHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // state
        Object.defineProperty(this, "configuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "request", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "code", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tokenResponse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "requestor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "handleTokenResponse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (response) => {
                this.tokenResponse = response;
                TingLocalStorage.setObject(StorageKeys.tokenResponse, response.toJson());
                // unset code, so we can do refresh token exchanges subsequently
                this.code = undefined;
            }
        });
        this.notifier = new AuthorizationNotifier();
        this.authorizationHandler = new RedirectRequestHandler(undefined, new QueryStringUtils());
        this.requestor = new FetchRequestor();
        this.tokenHandler = new BaseTokenRequestHandler(this.requestor);
        // set notifier to deliver responses
        this.authorizationHandler.setAuthorizationNotifier(this.notifier);
        // set a listener to listen for authorization responses
        this.notifier.setAuthorizationListener((request, response) => {
            if (response && response instanceof AuthorizationResponse && request instanceof AuthorizationRequest) {
                this.request = request;
                this.code = response.code;
            }
            if (response && response instanceof EndSessionResponse) {
                TingLocalStorage.removeItem(StorageKeys.tokenResponse);
                this.tokenResponse = null;
            }
        });
        // retrieve the tokenResponse from localStorage in case it's valid or refreshToken is still usable
        const jsonTokenResponse = TingLocalStorage.getObject(StorageKeys.tokenResponse);
        if (jsonTokenResponse) {
            this.tokenResponse = new TokenResponse(jsonTokenResponse);
        }
    }
    get accessToken() {
        return this.tokenResponse?.accessToken;
    }
    getAccessToken() {
        return this.tokenResponse?.accessToken;
    }
    fetchServiceConfiguration() {
        return AuthorizationServiceConfiguration.fetchFromIssuer(settings.authority, this.requestor)
            .then(response => {
            this.configuration = response;
        })
            .catch(err => {
            logger.info("fetchServiceConfiguration", err);
        });
    }
    async makeTokenRequestFromCode() {
        if (!this.configuration || !this.code) {
            throw new Error("login_required");
        }
        let extras;
        if (this.request && this.request.internal) {
            extras = {};
            extras.code_verifier = this.request.internal.code_verifier;
        }
        // use the code to make the token request.
        const request = new TokenRequest({
            client_id: settings.client_id,
            redirect_uri: settings.redirect_uri,
            grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
            code: this.code,
            refresh_token: undefined,
            extras,
        });
        try {
            const tokenResponse = await this.tokenHandler.performTokenRequest(this.configuration, request);
            this.handleTokenResponse(tokenResponse);
        }
        catch (error) {
            logger.info("makeTokenRequestFromCode()", error);
        }
    }
    checkForAuthorizationResponse() {
        return this.authorizationHandler.completeAuthorizationRequestIfPossible();
    }
    async makeAuthorizationRequest() {
        if (!this.configuration) {
            await this.fetchServiceConfiguration();
        }
        const request = new AuthorizationRequest({
            client_id: settings.client_id,
            redirect_uri: settings.redirect_uri,
            scope: settings.scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            extras: { prompt: "consent", access_type: "offline" },
        });
        this.authorizationHandler.performAuthorizationRequest(this.configuration, request);
    }
    async makeRefreshTokenRequest() {
        if (!this.configuration) {
            await this.fetchServiceConfiguration();
        }
        if (!this.tokenResponse) {
            throw new Error("login_required");
        }
        // use the token response to make a request for an access token
        const request = new TokenRequest({
            client_id: settings.client_id,
            redirect_uri: settings.redirect_uri,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            refresh_token: this.tokenResponse.refreshToken,
        });
        try {
            const tokenResponse = await this.tokenHandler.performTokenRequest(this.configuration, request);
            this.handleTokenResponse(tokenResponse);
        }
        catch (error) {
            throw new Error("login_required");
        }
    }
    async signinCallback() {
        if (!this.configuration) {
            await this.fetchServiceConfiguration();
        }
        await this.checkForAuthorizationResponse();
        return this.makeTokenRequestFromCode();
    }
    async signout() {
        if (!this.configuration) {
            await this.fetchServiceConfiguration();
        }
        const request = new RevokeTokenRequest({
            token: this.tokenResponse?.accessToken,
            token_type_hint: settings.token_type_hint,
            client_id: settings.client_id,
        });
        await this.tokenHandler.performRevokeTokenRequest(this.configuration, request);
        TingLocalStorage.removeItem(StorageKeys.tokenResponse);
        this.tokenResponse = null;
    }
};
Authorizer = __decorate([
    injectable(),
    __metadata("design:paramtypes", [])
], Authorizer);
export { Authorizer };
