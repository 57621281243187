import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { HandFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectMeetingUser, selectRoomPeer } from "@src/selectors";
import { PeerAvatar } from "@src/components/PeerAvatar";
import { useAudioTrackSampler } from "@src/hooks/useAudioContext";
import { ParticipantName } from "@src/components/ParticipantName";
import "./peerFrame.scss";
const cn = classNameFactory("peer-frame");
const speakingClassName = cn("speaking-state", { active: true }).split(" ")?.[1];
export const PeerFrame = ({ peerId }) => {
    const videoElementRef = useRef(null);
    const frameBorderRef = useRef(null);
    const { name, raisedHandAt: raisingHand, peerStream, peerStreamSettings: { isCamOn, isMicOn }, } = useSubscribe(selectRoomPeer(peerId));
    // TODO: remove this when reactive state fixed
    useSubscribe(selectMeetingUser());
    const frameBorderAnimate = (data) => {
        const range = 64;
        const sensitivity = 5;
        const to = Math.floor(range + 20);
        const from = Math.ceil(to - range);
        const segment = data.slice(from, to);
        const sum = segment.reduce((a, b) => a + b);
        const average = sum / segment.length;
        const height = sensitivity * average;
        if (height > 5) {
            frameBorderRef.current?.classList.add(speakingClassName);
        }
        else {
            frameBorderRef.current?.classList.remove(speakingClassName);
        }
    };
    const resetAnimatedFrame = () => {
        frameBorderRef.current?.classList.remove(speakingClassName);
    };
    useAudioTrackSampler(peerStream?.getAudioTracks()?.[0], isMicOn, frameBorderAnimate, resetAnimatedFrame);
    useEffect(() => {
        if (isCamOn && peerStream && videoElementRef.current) {
            videoElementRef.current.srcObject = peerStream;
        }
    }, [isCamOn, peerStream]);
    return (_jsxs("div", { className: cn({ width: true }), children: [!isCamOn && _jsx(PeerAvatar, { peerId: peerId }), isCamOn && _jsx("video", { muted: true, autoPlay: true, ref: videoElementRef, className: cn("video-element", { hide: !isCamOn }) }), _jsx(ParticipantName, { name: name, muted: !isMicOn, className: cn("name") }), raisingHand && (_jsx("div", { className: cn("raise-hand-indicator"), children: _jsx(HandFilledIcon, { width: "16px", height: "16px" }) })), _jsx("div", { className: cn("speaking-state"), ref: frameBorderRef })] }));
};
