export class StudioMixer {
    constructor(isOpen = true) {
        Object.defineProperty(this, "isPanelOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isPanelOpen = isOpen;
    }
    setPanelOpen(isOpen) {
        this.isPanelOpen.set(isOpen);
    }
}
