import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Text } from "@ting/ting-ui-components";
import { NoSoundOutlinedIcon, SettingsOutlinedIcon, SoundOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { AudioVolumeMeter } from "@src/components/AudioVolumeMeter";
import { classNameFactory } from "@src/utils/dom";
import { SourceKind } from "@src/domain/Studio/types";
import { SourceIcon } from "@src/components/Studio/Sources/components/SourceIcon";
import { muteSource } from "@src/controller";
import "./audioSource.scss";
const cn = classNameFactory("audio-source");
export const AudioSource = ({ source, onVolumeChanged }) => {
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("name"), title: source.sourceName, children: [_jsx(SourceIcon, { kind: SourceKind.AUDIO_INPUT, width: 20, height: 20 }), _jsx(Text, { size: "body-medium", className: cn("name-text"), children: source.sourceName })] }), _jsxs("div", { className: cn("content"), children: [_jsx("div", { className: cn("bars"), children: _jsx(AudioVolumeMeter, { type: "STUDIO", streamId: source.id, volume: source.volume, disabled: source.mute, onVolumeChanged: onVolumeChanged }) }), _jsxs("div", { className: cn("actions"), children: [_jsx(Button, { v2: true, icon: source.mute ? (_jsx(NoSoundOutlinedIcon, { height: "20px", width: "20px" })) : (_jsx(SoundOutlinedIcon, { height: "20px", width: "20px" })), size: "small", type: "neutral", variant: "ghost", onClick: () => {
                                    muteSource(source.id, !source.mute);
                                } }), _jsx(Button, { v2: true, icon: _jsx(SettingsOutlinedIcon, { height: "20px", width: "20px" }), size: "small", type: "neutral", variant: "ghost" })] })] })] }));
};
