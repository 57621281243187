import { jsx as _jsx } from "react/jsx-runtime";
import { createPortal } from "react-dom";
import { Toast } from "./Toast";
import "./ToasterPortal.scss";
let toasterPortalRoot = document.querySelector(".toaster-portal-root");
if (!toasterPortalRoot) {
    toasterPortalRoot = document.createElement("div");
    toasterPortalRoot.setAttribute("class", "toaster-portal-root");
    document.body?.appendChild(toasterPortalRoot);
}
export const ToasterPortal = ({ toasts, position }) => createPortal(_jsx("ul", { className: `toaster-portal ${position}`, children: toasts.map(({ id, type, size, icon, message, className, onClose }) => (_jsx("li", { children: _jsx(Toast, { id: id, type: type, size: size, message: message, icon: icon, className: className, close: close, onClose: onClose }) }, id))) }), toasterPortalRoot);
