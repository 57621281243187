import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from "react";
import { BlockOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectIsCamOn, selectSelfStream, selectSelfVideoTrack } from "@src/selectors";
import "./CameraFrame.scss";
const cn = classNameFactory("camera-frame");
export const CameraFrame = () => {
    const selfStream = useSubscribe(selectSelfStream());
    const selfVideoTrack = useSubscribe(selectSelfVideoTrack());
    const isCamOn = useSubscribe(selectIsCamOn());
    const videoElementRef = useRef(null);
    useEffect(() => {
        if (selfVideoTrack)
            videoElementRef.current.srcObject = selfStream.stream;
    }, [selfStream, selfVideoTrack]);
    return (_jsxs("div", { className: cn(""), children: [_jsx("video", { muted: true, autoPlay: true, ref: videoElementRef, className: cn("video-element", { hide: !isCamOn }) }), !isCamOn && (_jsx("div", { className: cn("video-off"), children: _jsx(BlockOutlinedIcon, { width: 40, height: 40 }) }))] }));
};
