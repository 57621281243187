import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyOutlinedIcon, ShareOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { Button, Modal, PositionType, Text } from "@ting/ting-ui-components";
import { useTransition } from "react-transition-state";
import { useParams } from "react-router-dom";
import { AnimatableCheckMark } from "./AnimatableCheckMark";
import { Header, HeaderSizes } from "@src/components/Header";
import { classNameFactory } from "@src/utils/dom";
import { useBuildMobile } from "@src/hooks/breakpoints";
import { selectRoomHostPeer, selectRoomTitle } from "@src/selectors";
import { useSubscribe } from "@src/hooks/useSubscribe";
import "./InviteParticipant.scss";
const cn = classNameFactory("invite-participant");
const COPIED_CLEAR_TIME = 2 * 1000; // 2 seconds
export const InviteParticipant = props => {
    const { onClose, open = false } = props;
    const [inviteParticipantState, toggleInviteParticipantState] = useTransition({
        timeout: 300,
        preEnter: true,
        initialEntered: open,
    });
    const [copied, setCopied] = useState(false);
    const [roomIdCopied, setRoomIdCopied] = useState(false);
    const { t } = useTranslation("room");
    const isMobile = useBuildMobile();
    const { roomId } = useParams();
    const meetName = useSubscribe(selectRoomTitle());
    const hostPeer = useSubscribe(selectRoomHostPeer());
    useEffect(() => {
        toggleInviteParticipantState(open);
    }, [open]);
    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, COPIED_CLEAR_TIME);
        }
    }, [copied]);
    useEffect(() => {
        if (roomIdCopied) {
            setTimeout(() => {
                setRoomIdCopied(false);
            }, COPIED_CLEAR_TIME);
        }
    }, [roomIdCopied]);
    const inviteLink = `${
    // isMobile ? import.meta.env.VITE_MOBILE_BASE_URL : import.meta.env.VITE_DESKTOP_BASE_URL
    window.location.origin}/room/${roomId}`;
    const copyToClipboard = async (text) => {
        await navigator.clipboard.writeText(text);
    };
    const content = (_jsxs("div", { className: cn("content"), children: [_jsx(Header, { onClose: onClose, filled: true, size: HeaderSizes.small, children: t("active-meeting") }), _jsxs("div", { children: [_jsx(Text, { className: cn("label"), size: "p2", fontWeight: "regular", children: t("core-pass-invite-participants", { names: "XY" }) }), _jsxs("div", { className: cn("invite"), children: [_jsxs("div", { className: cn("meeting-link", { copied: copied }), children: [_jsx(Text, { size: "p2", children: copied ? t("meeting-info-copied-text") : inviteLink }), copied ? (_jsx(AnimatableCheckMark, {})) : (_jsx(Button, { v2: true, onClick: () => {
                                            copyToClipboard(inviteLink).then(() => setCopied(true));
                                        }, type: "neutral", variant: "ghost", startIcon: _jsx(CopyOutlinedIcon, { width: 24, height: 24 }), size: "extraSmall" }))] }), isMobile && _jsx(Button, { v2: true, type: "neutral", size: "big", startIcon: _jsx(ShareOutlinedIcon, { width: 20, height: 20 }) })] })] }), _jsxs("div", { className: cn("meeting-info"), children: [_jsxs("section", { className: cn("meeting-info-item"), children: [_jsx(Text, { type: "sub", size: "p2", children: t("meeting-info-name") }), _jsx(Text, { type: "main", size: "p2", children: meetName })] }), _jsxs("section", { className: cn("meeting-info-item"), children: [_jsx(Text, { type: "sub", size: "p2", children: t("meeting-info-host") }), _jsx(Text, { type: "main", size: "p2", children: hostPeer?.name || "" })] }), _jsxs("section", { className: cn("meeting-info-item"), children: [_jsx(Text, { type: "sub", size: "p2", children: t("meeting-info-id") }), _jsxs("div", { className: cn("meeting-info-action"), children: [_jsx(Text, { type: "main", size: "p2", children: roomId }), roomIdCopied ? (_jsx("span", { children: _jsx(AnimatableCheckMark, {}) })) : (_jsx(Button, { v2: true, onClick: () => copyToClipboard(roomId).then(() => setRoomIdCopied(true)), type: "neutral", variant: "ghost", startIcon: _jsx(CopyOutlinedIcon, { width: 24, height: 24 }), size: "extraSmall" }))] })] })] }), _jsxs("div", { className: cn("footer"), children: [_jsx(Button, { v2: true, type: "brand", size: "big", children: t("invite-participants") }), _jsx(Button, { v2: true, type: "neutral", size: "big", children: t("meeting-info") })] })] }));
    if (isMobile) {
        return (_jsx(Modal, { position: PositionType.BOTTOM, isOpen: open, hideCloseButton: true, shouldCloseOnEsc: false, shouldCloseOnOverlayClick: false, children: content }));
    }
    return (_jsx("div", { "data-testid": "wrapper", className: `${cn("wrapper")} ${inviteParticipantState.status}`, children: content }));
};
