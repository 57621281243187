export const resolutionsToCheck = [
    { width: 160, height: 120 },
    { width: 320, height: 180 },
    { width: 320, height: 240 },
    { width: 640, height: 360 },
    { width: 640, height: 480 },
    { width: 768, height: 576 },
    { width: 1024, height: 576 },
    { width: 1280, height: 720 },
    { width: 1280, height: 768 },
    { width: 1280, height: 800 },
    { width: 1280, height: 900 },
    { width: 1280, height: 1000 },
    { width: 1920, height: 1080 },
    { width: 1920, height: 1200 },
    { width: 2560, height: 1440 },
    { width: 3840, height: 2160 },
    { width: 4096, height: 2160 },
];
