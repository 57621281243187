import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, PositionType, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { VideoOutlinedIcon, MicOnOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { Header, HeaderSizes } from "../Header";
import { ToggleIcon } from "./ToggleIcon";
import { classNameFactory } from "@src/utils/dom";
import { useMobile } from "@src/hooks/breakpoints";
import "./PermissionModal.scss";
const cn = classNameFactory("permission-modal");
const getTranslationKey = (camera, mic) => {
    let titleKey;
    let descriptionKey;
    if (camera && mic) {
        titleKey = "permission-camera-mic-title";
        descriptionKey = "permission-description";
    }
    else if (camera) {
        titleKey = "permission-camera-title";
        descriptionKey = "permission-camera-description";
    }
    else {
        titleKey = "permission-mic-title";
        descriptionKey = "permission-mic-description";
    }
    return {
        title: titleKey,
        description: descriptionKey,
    };
};
export const PermissionModal = props => {
    const { open = false, onClose, camera, mic } = props;
    const isMobile = useMobile();
    const { t } = useTranslation("room");
    if (!camera && !mic)
        return;
    const keys = getTranslationKey(camera, mic);
    return (_jsxs(Modal, { position: isMobile ? PositionType.BOTTOM : PositionType.CENTER, isOpen: open, hideCloseButton: true, className: cn(""), children: [_jsx(Header, { onClose: onClose, filled: true, marginSize: HeaderSizes.big }), _jsxs("div", { className: cn("content"), "data-testid": "permission-modal", "aria-labelledby": "pm-title", "aria-describedby": "pm-description", children: [_jsxs("div", { className: cn("devices"), children: [camera && (_jsxs("span", { "data-testid": "device-camera", className: cn("device-icon"), children: [_jsx(VideoOutlinedIcon, { width: "40px", height: "40px" }), _jsx(ToggleIcon, {})] })), mic && (_jsxs("span", { "data-testid": "device-mic", className: cn("device-icon"), children: [_jsx(MicOnOutlinedIcon, { width: "40px", height: "40px" }), _jsx(ToggleIcon, {})] }))] }), _jsx(Text, { id: "pm-title", className: cn("title"), size: "h3", fontWeight: "semiBold", children: t(keys.title) }), _jsx(Text, { id: "pm-description", className: cn("description"), size: "p2", fontWeight: "regular", children: `${t(keys.description)} ${t("grant-necessary-permissions")}` })] })] }));
};
