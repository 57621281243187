import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddSourceContext } from "../../Context";
import { EmptyState } from "../EmptyState";
import { PeerSourceInput } from "../PeerSourceInput";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomPeers } from "@src/selectors";
import { PeerRoles } from "@src/domain/Peer/types";
import "./peerList.scss";
const cn = classNameFactory("modal-source-list");
export const PeerList = () => {
    const { t } = useTranslation("studio");
    const peers = useSubscribe(selectRoomPeers());
    const [source, setSource] = useState(null);
    const { setSelected } = useContext(AddSourceContext);
    useEffect(() => {
        setSelected({ type: "remote", source });
    }, [source, setSelected]);
    if (peers && Object.keys(peers)?.length) {
        return (_jsx("div", { className: cn(""), children: Object.values(peers).map((peer) => {
                if (peer.role === PeerRoles.SUPER_NODE)
                    return null;
                return (_jsx(PeerSourceInput, { peer: peer, active: source?.peerId === peer?.peerId, onClick: () => {
                        setSource(peer);
                    } }, peer.peerId));
            }) }));
    }
    return _jsx(EmptyState, { title: t("There is no participant online on meeting") });
};
