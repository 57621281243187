import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldError, FormTagsComponent, SelectBox, TextField } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { classNameFactory } from "@src/utils/dom";
import { useGetStreamInputData } from "@src/hooks/useGetStreamInputData";
const cn = classNameFactory("stream-form");
export const StepOne = () => {
    const { t } = useTranslation("studio");
    const { values, setFieldValue, setFieldTouched } = useFormikContext();
    const { title, language, category, tags } = values;
    const { data: languagesData } = useGetStreamInputData("languages");
    const { data: categoryData } = useGetStreamInputData("categories");
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: cn("input-group"), children: [_jsx(TextField, { v2: true, name: "title", maxLength: 150, label: t("studio:stream.title"), value: title, placeholder: t("studio:stream.title"), onChange: event => {
                            void setFieldValue("title", event.target.value);
                        } }), _jsx(FieldError, { name: "title" })] }), _jsxs("div", { className: cn("input-group"), children: [_jsx(SelectBox, { name: "language", isClearable: true, isSearchable: true, value: language, label: t("stream.language"), onChange: value => {
                            void setFieldValue("language", value);
                        }, placeholder: t("stream.language"), className: "select-input", options: languagesData || [] }), _jsx(FieldError, { name: "language" })] }), _jsxs("div", { className: cn("input-group"), children: [_jsx(SelectBox, { name: "category", isClearable: true, isSearchable: true, value: category, label: t("stream.category"), onChange: value => {
                            void setFieldValue("category", value);
                        }, placeholder: t("stream.category"), className: "select-input", options: categoryData || [] }), _jsx(FieldError, { name: "category" })] }), _jsxs("div", { className: cn("input-group"), children: [_jsx(FormTagsComponent, { allowNew: true, name: "tags", value: tags, label: t("stream.tagsUp10"), setFieldValue: setFieldValue, setFieldTouched: setFieldTouched }), _jsx(FieldError, { name: "tags" })] })] }));
};
