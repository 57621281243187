import { nanoid } from "nanoid";
// TODO: Improve generic type format
export class DCMessage {
    constructor(data) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "from", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "to", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // Date of sending message
        Object.defineProperty(this, "sendDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // Date of receive message by user
        Object.defineProperty(this, "receiveDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "payload", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.from = data.from;
        this.to = data.to;
        this.sendDate = data.sendDate ?? new Date();
        this.id = data.id ?? nanoid();
        this.type = data.type;
        this.payload = data.payload;
    }
    static serialize(message) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return JSON.stringify(message, (_, value) => {
            if (value instanceof Map) {
                return {
                    dataType: "Map",
                    value: Array.from(value.entries()),
                };
            }
            return value;
        });
    }
    static deserialize(messageStr) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const objectMessage = JSON.parse(messageStr, (_, value) => {
            if (typeof value === "object" && value !== null) {
                if (value.dataType === "Map") {
                    return new Map(value.value);
                }
            }
            return value;
        });
        return new DCMessage(objectMessage);
    }
}
