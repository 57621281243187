import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, FormTextField } from "@ting/ting-ui-components";
import { DeliveredOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { NameRules } from "./NameRules";
import { useMobile } from "@src/hooks/breakpoints";
import UploadAvatar from "@src/components/UploadAvatar";
const usernameRegex = /^[a-z0-9._]{1,50}$/;
const step1ValidationSchema = Yup.object().shape({
    profileName: Yup.string()
        .min(4, "Too Short!")
        .max(50, "Too Long!")
        .matches(usernameRegex, "The username should be composed of numbers, lowercase letters and _")
        .required("* Required"),
});
export const Step1 = props => {
    const { initialValues, errors, onStep1Submit, onAvatarChange, onCancelClick } = props;
    const formikRef = useRef(null);
    const [valid, setIsValid] = useState(undefined);
    const isMobile = useMobile();
    useEffect(() => {
        const formik = formikRef.current;
        if (!formik) {
            return;
        }
        Object.entries(errors).forEach(([field, error]) => {
            formik.setFieldError(field, error);
            formik.setFieldTouched(field, true, false);
            setIsValid(false);
        });
    }, [errors]);
    const handleProfileNameChanged = (e) => {
        const formik = formikRef.current;
        if (!formik) {
            return;
        }
        const value = e.target.value;
        if (value.length >= 4 && value.length <= 50 && usernameRegex.test(value)) {
            setIsValid(true);
        }
        else {
            setIsValid(false);
        }
        formik.handleChange(e);
    };
    const handleFormSubmit = (formValues) => {
        const { profileName } = formValues;
        onStep1Submit({ profileName });
    };
    const TextFieldProp = valid !== undefined
        ? {
            state: valid ? "success" : "error",
        }
        : {};
    return (_jsx(Formik, { innerRef: formikRef, initialValues: initialValues, onSubmit: handleFormSubmit, validationSchema: step1ValidationSchema, children: ({ handleSubmit, errors }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: "upload-profile", children: _jsx(UploadAvatar, { coreId: "profile-avatar", onAvatarChanged: onAvatarChange, hasButton: true }) }), _jsx(FormTextField, { v2: true, className: "upload-profile__field", name: "profileName", label: "Profile name", placeholder: "Your public profile name", onChange: handleProfileNameChanged, size: "large", stateText: (errors?.userName || errors?.profileName), endIcon: valid && _jsx(DeliveredOutlinedIcon, { className: "success-check-icon" }), ...TextFieldProp }), _jsx(NameRules, {}), _jsxs("div", { className: "action", children: [_jsx(Button, { v2: true, variant: "primary", type: isMobile ? "brand" : "neutral", className: "continue-button", size: isMobile ? "big" : "medium", onClick: handleSubmit, children: "Next" }), isMobile && (_jsx(Button, { v2: true, variant: "primary", type: "neutral", size: "big", onClick: onCancelClick, children: "Cancel" }))] })] })) }));
};
