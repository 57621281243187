export class StudioScreen {
    constructor(fullscreen = false) {
        Object.defineProperty(this, "isPanelFullScreen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isPanelFullScreen = fullscreen;
    }
    setPanelFullscreen(isOpen) {
        this.isPanelFullScreen.set(isOpen);
    }
}
