export var StorageKeys;
(function (StorageKeys) {
    StorageKeys["RoomSlugPreFix"] = "ting_room_";
    StorageKeys["GuestID"] = "ting_guest_id";
    StorageKeys["RoomSlug"] = "ting_room_slug";
    StorageKeys["StudioTour"] = "ting_studio_tour";
    StorageKeys["TingUser"] = "ting_platform_user";
    StorageKeys["MeetingSetting"] = "meeting_setting";
    StorageKeys["RedirectPath"] = "redirect_path";
    StorageKeys["tokenResponse"] = "token_response";
})(StorageKeys || (StorageKeys = {}));
