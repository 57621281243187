import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import common from "./locales/en/common.json";
import room from "./locales/en/room.json";
import studio from "./locales/en/studio.json";
export const defaultNS = "common";
export const resources = {
    en: {
        common,
        room,
        studio,
    },
};
// eslint-disable-next-line import/no-named-as-default-member
export const i18n = i18next.createInstance();
i18n.use(initReactI18next).init({
    lng: "en",
    ns: ["common", "room", "studio"],
    defaultNS,
    resources,
});
