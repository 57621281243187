import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Text, Tooltip, UserAvatar } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { InfoOutlinedIcon, LoadingFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import "./roomInfoBox.scss";
const cn = classNameFactory("lobby-schedule-info-box");
export const RoomInfoBox = ({ compactRoomInfo, isLoading }) => {
    const { t } = useTranslation("room");
    let mainText, subText, IconElement;
    if (isLoading) {
        mainText = "checking-meeting-status";
        subText = "just-a-moment";
        IconElement = (_jsx(WaitingIcon, { animated: true, children: _jsx(LoadingFilledIcon, { width: 20, height: 20 }) }));
    }
    else if (!compactRoomInfo) {
        mainText = "lobby-meeting-has-not-started";
        subText = "waiting-for-host";
        IconElement = (_jsx(WaitingIcon, { children: _jsx(InfoOutlinedIcon, { width: 20, height: 20 }) }));
    }
    else {
        const noOfPeers = compactRoomInfo.compactPeers.length;
        mainText = "meeting-already-started";
        subText = noOfPeers === 1 ? "host-joined" : "host-and-others-joined";
        IconElement = _jsx(PeerAvatars, { peers: compactRoomInfo.compactPeers });
    }
    return (_jsxs("div", { className: cn({ "fade-in": !isLoading }), children: [IconElement, _jsxs("span", { children: [_jsx(Text, { size: "p2", fontWeight: "semiBold", children: t(mainText) }), _jsx(Text, { size: "p2", fontWeight: "regular", type: "sub", children: t(subText) })] })] }));
};
const WaitingIcon = ({ animated = false, children }) => {
    return _jsx("div", { className: cn("icon", { animated }), children: children });
};
const PeerAvatar = ({ peer }) => {
    const [target, setTarget] = useState(null);
    return (_jsxs("span", { className: cn("avatar-container"), ref: el => {
            setTarget(el);
        }, children: [_jsx(UserAvatar, { size: "lg", image: peer.avatarURL, userName: peer.name }), peer?.name && (_jsx(Tooltip, { placement: "top", target: target, arrow: true, className: cn("tool-tip"), children: _jsx(Text, { children: peer.name }) }))] }));
};
const PeerAvatars = ({ peers }) => {
    return (_jsxs("div", { className: cn("avatars"), children: [peers.slice(0, 3).map(p => (_jsx(PeerAvatar, { peer: p }, p.peerId))), peers.length > 3 && (_jsx("span", { className: cn("avatar-container"), children: _jsx("div", { className: cn("more-icon"), children: _jsx(Text, { size: "body-medium", fontWeight: "semiBold", children: `+${peers.length - 3}` }) }) }, "more"))] }));
};
