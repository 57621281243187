import { state } from "@src/state";
export function setScenesOpen(isOpen) {
    state.meeting.studio.scenes.setPanelOpen(isOpen);
}
export function setCreateScenesModal(isOpen) {
    state.meeting.studio.scenes.setCreateSceneOpen(isOpen);
}
export function createNewScene(name) {
    state.meeting.studio.scenes.create(name);
}
export function activateScene(id) {
    state.meeting.studio.scenes.activate(id);
}
export function duplicateScene(sceneId) {
    state.meeting.studio.scenes.duplicate(sceneId);
}
export function renameScene(sceneId, name) {
    state.meeting.studio.scenes.rename(sceneId, name);
}
export function assignColor(sceneId, color) {
    state.meeting.studio.scenes.assignColor(sceneId, color);
}
export function deleteScene(sceneId) {
    state.meeting.studio.scenes.remove(sceneId);
}
