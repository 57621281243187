import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleArrowRightIcon, ThreeDotsIcon } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import { SceneColor } from "@src/domain/Studio/types";
import { activateScene, assignColor, deleteScene, duplicateScene, renameScene } from "@src/controller";
import { DropDown } from "@src/components/DropDown";
import { RenameModal } from "@src/components/Studio/RenameModal/RenameModal";
import "./scene.scss";
const cn = classNameFactory("scene");
export const Scene = ({ scene }) => {
    const { t } = useTranslation(["common", "studio"]);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: cn({ active: scene.active, open: isMenuOpen }), children: [_jsxs("button", { type: "button", className: cn("handler"), onClick: () => activateScene(scene.id), title: scene.name, children: [_jsx("span", { className: cn(`color`), style: { backgroundColor: scene.color } }), _jsx("span", { className: cn("name"), children: scene.name })] }), _jsx("div", { className: cn("actions"), children: _jsx(DropDown, { direction: "bottom-left", toggleIcon: _jsx(ThreeDotsIcon, { height: "18px", width: "18px", className: cn("toggle-icon") }), onOpen: open => {
                                setIsMenuOpen(open);
                            }, items: [
                                {
                                    label: t("studio:rename"),
                                    onClick: () => {
                                        setIsRenameModalOpen(true);
                                    },
                                },
                                {
                                    label: t("studio:duplicate"),
                                    onClick: () => duplicateScene(scene.id),
                                },
                                {
                                    label: t("studio:sceneAssignColor"),
                                    endIcon: _jsx(SimpleArrowRightIcon, { height: "24px", width: "24px" }),
                                    subMenuClass: cn("color-list"),
                                    subItemClass: cn("color-item"),
                                    divider: true,
                                    submenu: Object.keys(SceneColor).map((color) => {
                                        return {
                                            id: color,
                                            content: (_jsx("div", { className: cn("color-content"), style: { backgroundColor: SceneColor[color] } })),
                                            onClick: () => {
                                                assignColor(scene.id, SceneColor[color]);
                                            },
                                        };
                                    }),
                                },
                                {
                                    label: t("studio:filters"),
                                },
                                {
                                    label: t("studio:sceneExport"),
                                },
                                {
                                    label: t("delete"),
                                    type: "danger",
                                    divider: true,
                                    onClick: () => {
                                        deleteScene(scene.id);
                                    },
                                },
                            ] }) })] }), _jsx(RenameModal, { name: scene.name, rename: "scene", isOpen: isRenameModalOpen, onRename: name => {
                    renameScene(scene.id, name);
                    setIsRenameModalOpen(false);
                }, onClose: () => setIsRenameModalOpen(false) })] }));
};
