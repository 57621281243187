import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, FormTextField } from "@ting/ting-ui-components";
import { DeliveredOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { NameRules } from "./NameRules";
import { useMobile } from "@src/hooks/breakpoints";
import UploadAvatar from "@src/components/UploadAvatar";
const channelRegex = /^[A-Za-z0-9\-_.:]{4,120}$/;
const step1ValidationSchema = Yup.object().shape({
    channelName: Yup.string()
        .min(4, "Too Short!")
        .max(120, "Too Long!")
        .matches(channelRegex, "The username should be composed of numbers, lowercase letters and _")
        .required("* Required"),
});
export const Step2 = props => {
    const { initialValues, isSubmitting, errors, onBackClick, onStep2Submit, onChannelAvatarChange } = props;
    const formikRef = useRef(null);
    const [valid, setIsValid] = useState(undefined);
    const isMobile = useMobile();
    useEffect(() => {
        const formik = formikRef.current;
        if (!formik) {
            return;
        }
        Object.entries(errors).forEach(([field, error]) => {
            formik.setFieldError(field, error);
            formik.setFieldTouched(field, true, false);
            setIsValid(false);
        });
    }, [errors]);
    const handleChannelNameChanged = (event) => {
        const formik = formikRef.current;
        if (!formik) {
            return;
        }
        const value = event.target.value;
        if (value.length >= 4 && value.length <= 50 && channelRegex.test(value)) {
            setIsValid(true);
        }
        else {
            setIsValid(false);
        }
        formik.handleChange(event);
    };
    const handleFormSubmit = (formValues) => {
        const { channelName } = formValues;
        onStep2Submit({ channelName });
    };
    const TextFieldProp = valid !== undefined
        ? {
            state: valid ? "success" : "error",
        }
        : {};
    // Set error message for channel handle if we got the error from server
    return (_jsx(Formik, { innerRef: formikRef, initialValues: initialValues, onSubmit: handleFormSubmit, validationSchema: step1ValidationSchema, children: ({ handleSubmit, values, errors }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: "upload-profile", children: _jsx(UploadAvatar, { coreId: "profile-avatar", onAvatarChanged: onChannelAvatarChange, hasButton: true }) }), _jsx(FormTextField, { v2: true, className: "upload-profile__field", name: "channelName", size: "large", label: "Channel name", placeholder: "Your channel name", onChange: handleChannelNameChanged, stateText: (errors?.channelHandle || errors?.channelName), endIcon: valid && _jsx(DeliveredOutlinedIcon, { className: "success-check-icon" }), ...TextFieldProp }), _jsx(NameRules, {}), _jsxs("div", { className: "action", children: [_jsx(Button, { v2: true, variant: "primary", type: "neutral", theme: "secondary", className: "back-button", onClick: () => onBackClick(values), disabled: isSubmitting, size: isMobile ? "big" : "medium", children: "Back" }), _jsx(Button, { v2: true, variant: "primary", type: "brand", className: "complete-button", onClick: handleSubmit, disabled: isSubmitting, size: isMobile ? "big" : "medium", children: "Create" })] })] })) }));
};
