import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Modal } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { AddSourceContext } from "../../Context";
import { SelectSourceRemoteTabList } from "../SelectSourceRemoteTabList";
import { Header, HeaderSizes } from "@src/components/Header";
import { BackwardAction, ForwardAction, SelectSourceLocalTabList, SelectSourceTabList, } from "@src/components/Studio/Sources/components/AddSourceModal/components";
import { classNameFactory } from "@src/utils/dom";
import "./container.scss";
const cn = classNameFactory("add-source");
export const AddSourceContainer = () => {
    const { t } = useTranslation(["common", "studio"]);
    const { step, setStep, isOpen, onClose, selected, selectedSource } = useContext(AddSourceContext);
    const getTitle = () => {
        if (step === 1 || !selected)
            return t("studio:addNewSource");
        if (selected.type === "remote")
            return selected.source.name;
        return selected.source.label;
    };
    return (_jsxs(Modal, { isOpen: isOpen, onClose: onClose, shouldCloseOnEsc: true, shouldCloseOnOverlayClick: true, hideCloseButton: true, className: cn(""), children: [_jsx(Header, { isModal: true, filled: true, size: HeaderSizes.big, onBack: step === 2 ? () => setStep(1) : null, onClose: onClose, children: getTitle() }), isOpen && (_jsxs("div", { className: cn("content"), children: [_jsxs("div", { className: cn("container"), children: [step === 1 && _jsx(SelectSourceTabList, {}), step === 2 && selectedSource.type === "local" && _jsx(SelectSourceLocalTabList, {}), step === 2 && selectedSource.type === "remote" && _jsx(SelectSourceRemoteTabList, {})] }), _jsxs("div", { className: cn("actions"), children: [_jsx(BackwardAction, {}), _jsx(ForwardAction, {})] })] }))] }));
};
