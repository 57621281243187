export var IMediaTrackType;
(function (IMediaTrackType) {
    IMediaTrackType[IMediaTrackType["Audio"] = 0] = "Audio";
    IMediaTrackType[IMediaTrackType["Video"] = 1] = "Video";
    IMediaTrackType[IMediaTrackType["ScreenAudio"] = 2] = "ScreenAudio";
    IMediaTrackType[IMediaTrackType["ScreenVideo"] = 3] = "ScreenVideo";
    IMediaTrackType[IMediaTrackType["ForwardedAudio"] = 4] = "ForwardedAudio";
    IMediaTrackType[IMediaTrackType["ForwardedVideo"] = 5] = "ForwardedVideo";
    IMediaTrackType[IMediaTrackType["ForwardedScreenAudio"] = 6] = "ForwardedScreenAudio";
    IMediaTrackType[IMediaTrackType["ForwardedScreenVideo"] = 7] = "ForwardedScreenVideo";
})(IMediaTrackType || (IMediaTrackType = {}));
