import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ChannelsOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useTranslation } from "react-i18next";
import { PanelController } from "../PanelController";
import { Panel } from "../Panel";
import { AddSourceModal, Source } from "./components";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSources, selectStudioScenes } from "@src/selectors/studio";
import { useToaster } from "@src/components/Toaster";
export const Sources = () => {
    const { t } = useTranslation("studio");
    const scenes = useSubscribe(selectStudioScenes());
    const [isAddSourceModalOpen, setAddSourceModalOpen] = useState(false);
    const showToast = useToaster();
    const sources = useSubscribe(selectStudioActiveSources());
    const handleAddSource = () => {
        if (Object.keys(scenes).length) {
            setAddSourceModalOpen(true);
        }
        else {
            showToast({ type: "danger", message: t("warning.needToCreateScene") });
        }
    };
    return (_jsxs(Panel, { header: _jsx(PanelController, { title: t("sources"), titleSize: "small", icon: _jsx(ChannelsOutlinedIcon, { height: "24px", width: "24px" }), rightActions: {
                add: handleAddSource,
            } }), children: [sources && Object.entries(sources).map(([id, source]) => _jsx(Source, { source: source }, id)), isAddSourceModalOpen && (_jsx(AddSourceModal, { isOpen: true, onClose: () => {
                    setAddSourceModalOpen(false);
                } }))] }));
};
