import { nanoid } from "nanoid";
import { MessageType } from "@ting/ting-ui-components";
import { state } from "@src/state";
import { selectRoomSelfPeer, selectUser } from "@src/selectors";
import { selectActivePoll } from "@src/selectors/poll";
import { chatSettings } from "@src/configs/chat";
import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
import { DCMessageType } from "@src/domain/RTCConnection";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
import { LiveChatWebTorrentUtil } from "@src/services/WebtorrentService";
export function setActivePoll(poll) {
    state.meeting.poll.createPoll(poll);
}
export function addPollAnswer(pollAnswer, user) {
    const poll = state.meeting.poll.polls.find(p => p.id.get() === pollAnswer.content.pollId);
    if (!poll)
        return;
    const pollAnswers = poll?.answers.get() || [];
    const submittedUserAnswerIdx = pollAnswers.findIndex(a => {
        return a.from.corepassId === pollAnswer.from.corepassId;
    });
    let newPollAnswers = [];
    let userSelectedOptions = [];
    if (submittedUserAnswerIdx !== -1) {
        newPollAnswers = [
            ...pollAnswers.slice(0, submittedUserAnswerIdx),
            pollAnswer,
            ...pollAnswers.slice(submittedUserAnswerIdx + 1),
        ];
    }
    else {
        newPollAnswers = pollAnswers.concat(pollAnswer);
    }
    if (user && pollAnswer.from.corepassId === user.corepassId) {
        userSelectedOptions = pollAnswer.content.answerIds;
    }
    poll.answers.set(newPollAnswers);
    poll.userSelectedOptions.set(userSelectedOptions);
}
export function createPoll(poll) {
    return bindDependencies(async function (RTCTransport) {
        const peer = selectRoomSelfPeer().get();
        const newPollMessage = new DCMessage({
            from: peer,
            to: "all",
            payload: poll,
            type: DCMessageType.POLL_QUESTION,
            id: poll.id,
        });
        RTCTransport.sendMessage(newPollMessage);
        setActivePoll(poll);
        LiveChatWebTorrentUtil.Instance.sendNewLiveChatMessage(poll);
    }, [TYPES.RTCConnectionService]);
}
export function sendPollAnswer(selectedIds) {
    return bindDependencies(async function (RTCTransport) {
        const peer = selectRoomSelfPeer().get();
        const user = selectUser().get();
        const activePoll = selectActivePoll().get();
        const from = {
            displayName: peer.name || user?.account.displayName,
            userName: peer.peerId || user?.username || chatSettings.guestCorePassID,
            avatarUrl: peer.avatarURL || user?.account.avatar
                ? `${user?.account.avatar?.path}`
                : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png",
            corepassId: peer.corepassId || user?.corepassId || peer.peerId || chatSettings.guestCorePassID,
        };
        const pollAnswer = {
            id: nanoid(),
            type: MessageType.POLL_ANSWER,
            from,
            content: {
                pollId: activePoll.id,
                answerIds: selectedIds,
            },
            time: new Date().toISOString(),
            viewer: from,
        };
        const newPollAnswerMessage = new DCMessage({
            from: selectRoomSelfPeer().get(),
            to: "all",
            payload: pollAnswer,
            type: DCMessageType.POLL_ANSWER,
            id: pollAnswer.id,
        });
        RTCTransport.sendMessage(newPollAnswerMessage);
        addPollAnswer(pollAnswer, user);
        LiveChatWebTorrentUtil.Instance.sendNewLiveChatMessage(pollAnswer);
    }, [TYPES.RTCConnectionService]);
}
