import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRouteMatch } from "react-router-dom";
import { classNameFactory } from "@src/utils/dom";
import { useBuildMobile } from "@src/hooks/breakpoints";
import { Navigator } from "@src/components/ControlBar/Navigator";
import { MeetingController } from "@src/components/ControlBar/MeetingController";
import { StudioController } from "@src/components/ControlBar/StudioController";
import { StreamController } from "@src/components/ControlBar/StreamController/StreamController";
import "./controlBar.scss";
const cn = classNameFactory("bottom-navigation");
export const ControlBar = () => {
    const isMobile = useBuildMobile();
    const isStudioRoute = useRouteMatch({
        path: "/room/:roomId/studio",
        strict: true,
        sensitive: true,
    });
    return (_jsxs("div", { className: cn(""), children: [!isMobile && _jsx(Navigator, {}), isStudioRoute?.isExact ? _jsx(StudioController, {}) : _jsx(MeetingController, {}), !isMobile && _jsx(StreamController, {})] }));
};
