import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@ting/ting-ui-components";
import { EndCallFilledIcon, MoreHorizontalFilledIcon, RecordFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { MoreInfo } from "../MoreInfo";
import { TimerController } from "../TimerController";
import { InviteParticipant } from "@src/components/InviteParticipant";
import { classNameFactory } from "@src/utils/dom";
import { Separator } from "@src/components/ControlBar/Separator/Separator";
import { CamController, MicController, SettingsController, HandController, ScreenController, FullscreenController, } from "@src/components/ControllerButtons";
import { selectShowInviteModal } from "@src/selectors";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { leaveRoom } from "@src/controller";
import "./meetingController.scss";
const cn = classNameFactory("meeting-controller");
export const MeetingController = () => {
    const showInviteModal = useSubscribe(selectShowInviteModal());
    const toggleInviteModal = () => {
        const v = selectShowInviteModal();
        v.set(!v.peek());
    };
    const closeInviteModal = () => {
        const v = selectShowInviteModal();
        v.set(false);
    };
    const handleEndButtonClick = () => {
        leaveRoom();
    };
    return (_jsxs("div", { className: cn(""), children: [_jsx(MicController, {}), _jsx(CamController, {}), _jsx(SettingsController, {}), _jsx(Separator, {}), _jsx(HandController, {}), _jsx(ScreenController, {}), _jsx(FullscreenController, {}), _jsx(Separator, {}), _jsx(Button, { v2: true, round: true, icon: _jsx(RecordFilledIcon, { height: "20px", width: "20px" }), size: "medium", type: "neutral", variant: "ghost" }), _jsx(Button, { v2: true, round: true, icon: _jsx(MoreHorizontalFilledIcon, { height: "20px", width: "20px" }), size: "medium", type: "neutral", variant: "ghost" }), _jsx(MoreInfo, { onClick: toggleInviteModal }), _jsx(Button, { v2: true, round: true, startIcon: _jsx(EndCallFilledIcon, { height: "20px", width: "20px" }), size: "medium", type: "danger", onClick: handleEndButtonClick }), _jsx(Separator, {}), _jsx(TimerController, {}), _jsx(InviteParticipant, { open: showInviteModal, onClose: closeInviteModal })] }));
};
