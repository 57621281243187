import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { Text, TextField } from "@ting/ting-ui-components";
import { HandFilledIcon, SearchOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useTranslation } from "react-i18next";
import { selectRoomPeers } from "@src/selectors";
import { ProfileRow } from "@src/components/ProfileRow";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import "./peerList.scss";
const cn = classNameFactory("peer-list");
export const PeerList = () => {
    const { t } = useTranslation(["room"]);
    const peers = useSubscribe(selectRoomPeers(), true);
    const [searchQuery, setSearchQuery] = useState("");
    const onSearchChange = (e) => {
        const text = e.currentTarget.value;
        setSearchQuery(text);
    };
    const raisedHandPeers = Object.values(peers)
        .filter(peer => peer.raisedHandAt)
        .sort((a, b) => a.raisedHandAt - b.raisedHandAt);
    return (_jsxs("div", { className: cn(""), children: [_jsx(Text, { size: "p1", fontWeight: "semiBold", className: cn("title"), children: t("participants") }), _jsx(TextField, { v2: true, placeholder: "Search...", value: searchQuery, onChange: onSearchChange, size: "medium", icon: _jsx(SearchOutlinedIcon, { width: "24px", height: "24px" }), className: cn("search") }), _jsxs("div", { className: cn("content"), children: [Boolean(raisedHandPeers?.length) && (_jsxs(Fragment, { children: [_jsxs("div", { className: cn("raise-hand-subtitle"), children: [_jsx(Text, { size: "body-medium", fontWeight: "semiBold", children: t("raised-hand") }), _jsx(HandFilledIcon, { width: "20px", height: "20px" })] }), _jsx("div", { className: cn("peers"), children: raisedHandPeers
                                    .filter(peer => (searchQuery ? peer.name.search(searchQuery) !== -1 : true))
                                    .map(peer => (_jsx(ProfileRow, { peerId: peer.peerId, className: cn("row") }, peer.peerId))) })] })), _jsx(Text, { size: "body-medium", fontWeight: "semiBold", className: cn("subtitle"), children: t("call-participants") }), _jsx("div", { className: cn("peers"), children: Object.values(peers)
                            .filter(peer => (searchQuery ? peer.name.search(searchQuery) !== -1 : true))
                            .filter(peer => !peer.raisedHandAt)
                            .map(peer => (_jsx(ProfileRow, { peerId: peer.peerId, className: cn("row") }, peer.peerId))) })] })] }));
};
