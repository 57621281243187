import { jsx as _jsx } from "react/jsx-runtime";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useRef } from "react";
import useClickOutside from "@src/hooks/useClickOutside";
import { classNameFactory } from "@src/utils/dom";
import "./EmojiPicker.scss";
const cn = classNameFactory("chat-emoji-picker");
export const EmojiPicker = ({ handlePick, togglePicker }) => {
    const pickerWrapperRef = useRef(null);
    useClickOutside(pickerWrapperRef, togglePicker);
    return (_jsx("div", { className: cn(""), ref: pickerWrapperRef, children: _jsx(Picker, { dynamicWidth: true, data: data, onEmojiSelect: (emoji) => {
                handlePick(emoji.native);
            }, theme: "dark", perLine: 9, previewPosition: "none" }) }));
};
