import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldError, SelectBox } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { classNameFactory } from "@src/utils/dom";
import { StreamThumbnail } from "@src/components/ControlBar/StreamController/components/StartStreamModal/components/StreamThumbnail";
import { useGetStreamInputData } from "@src/hooks/useGetStreamInputData";
const cn = classNameFactory("stream-form");
export const StepTwo = () => {
    const { t } = useTranslation("studio");
    const { values, setFieldValue } = useFormikContext();
    const { channelId, playlist, privacy, thumbnailFile } = values;
    const { data: channelData } = useGetStreamInputData("channels");
    const { data: playlistData } = useGetStreamInputData("playlists");
    const { data: privacyData } = useGetStreamInputData("privacies");
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: cn("input-group"), children: [_jsx(SelectBox, { name: "channelId", isClearable: true, isSearchable: true, value: channelId, label: t("stream.channel"), onChange: value => {
                            void setFieldValue("channelId", value);
                        }, placeholder: t("stream.channel"), className: "select-input", options: channelData || [] }), _jsx(FieldError, { name: "channelId" })] }), _jsxs("div", { className: cn("input-group"), children: [_jsx(SelectBox, { name: "playlist", isClearable: true, isSearchable: true, value: playlist, onChange: value => {
                            void setFieldValue("playlist", value);
                        }, label: t("stream.playlist"), placeholder: t("stream.playlist"), className: "select-input", options: playlistData || [] }), _jsx(FieldError, { name: "playlist" })] }), _jsxs("div", { className: cn("input-group"), children: [_jsx(SelectBox, { name: "privacy", isClearable: true, isSearchable: true, value: privacy, label: t("stream.privacy"), onChange: value => {
                            void setFieldValue("privacy", value);
                        }, placeholder: t("stream.privacy"), className: "select-input", options: privacyData || [] }), _jsx(FieldError, { name: "privacy" })] }), _jsx(StreamThumbnail, { secondaryThumbnailFile: thumbnailFile, onChange: value => {
                    void setFieldValue("thumbnailFile", value);
                } })] }));
};
