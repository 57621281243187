import { StudioMerger } from "@src/services/studioService/studioMerger";
import configs from "@src/configs";
import { studioRequestStopStream, studioSendStreamChunk } from "@src/controller";
export class StudioLive {
    constructor() {
        Object.defineProperty(this, "mediaRecorder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "uid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chunks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "uploadedChunks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chunkNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "recording", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "recorderInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "retryCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    start(uid) {
        try {
            this.uid = uid;
            this.retryCount = 0;
            this.chunks = [];
            this.uploadedChunks = [];
            this.mediaRecorder = new MediaRecorder(StudioMerger.instance.result, { mimeType: "video/webm;codecs=h264;" });
            this.mediaRecorder.addEventListener("dataavailable", (event) => {
                this.chunkNumber += 1;
                const currentTarget = event.currentTarget;
                // if (event.data.size === 0) console.warn("LiveRecorder recorded 0 data", event.data);
                if (event.data.size > 0 && currentTarget.state === "recording") {
                    this.chunks.push(event.data);
                    if (!this.recording) {
                        this.recording = true;
                        this.uploadNextChunk();
                    }
                }
            });
            // this.mediaRecorder.addEventListener("error", error => {
            //   console.error("LiveRecorder Error:", error);
            // });
            this.mediaRecorder.start();
            this.recorderInterval = setInterval(() => {
                if (this.mediaRecorder.state === "recording") {
                    this.mediaRecorder.requestData();
                }
                else if (this.recording) {
                    // console.error(`LiveRecorder stopped! recorder state changed to ${this.mediaRecorder.state}.`);
                    clearInterval(this.recorderInterval);
                }
            }, 2000);
        }
        catch {
            // console.error("LiveRecorder, Something happened while recording live stream", err);
            clearInterval(this.recorderInterval);
        }
    }
    stop() {
        clearInterval(this.recorderInterval);
        if (this.mediaRecorder?.state !== "inactive")
            this.mediaRecorder.stop();
        this.recording = false;
    }
    uploadNextChunk() {
        if (this.chunks?.length) {
            const startTime = new Date().getTime();
            const nextChunk = this.chunks.shift();
            this.retryCount += 1;
            studioSendStreamChunk(this.uid, { webm: nextChunk })
                .then(() => {
                this.retryCount = 0;
                this.calculateUploadSpeed(startTime, new Date().getTime()); // nextChunk
            })
                .catch(() => {
                if (this.retryCount < 5) {
                    this.chunks.unshift(nextChunk);
                    this.uploadNextChunk();
                    // console.error("LiveRecorder failed to upload chunk, trying to send it again", err);
                }
            });
        }
        else if (this.recording && this.mediaRecorder.state === "recording") {
            setTimeout(() => {
                this.uploadNextChunk();
            }, 1000);
        }
        else {
            // Set merger output back to defaults
            StudioMerger.instance.setOutputSize(configs.studio.defaultOutput.width, configs.studio.defaultOutput.height);
            void studioRequestStopStream(this.uid);
        }
    }
    calculateUploadSpeed(startTime, endTime) {
        const duration = endTime - startTime;
        // const bitsLoaded = chunk.size * 8;
        // const bps = +(bitsLoaded / (duration / 1000)).toFixed(2);
        // const kbps = +(bps / 1000).toFixed(2);
        // const mbps = +(kbps / 1000).toFixed(2);
        const keys = Object.keys(configs.studio.screenOutputs);
        const obsCurrentResolution = configs.studio.defaultOutput.height;
        const current = keys.indexOf(obsCurrentResolution.toString());
        let obsResolution = obsCurrentResolution;
        if (duration < 500 && keys.length > current + 1)
            obsResolution = Number(keys[current + 1]);
        if (duration > 1500 && current - 1 > -1)
            obsResolution = Number(keys[current - 1]);
        const output = configs.studio.screenOutputs[obsResolution];
        StudioMerger.instance.setOutputSize(output.width, output.height);
        // console.log("Size (mb)", (chunk.size / 1000 / 1000).toFixed(2), "Speed (mb)", mbps, "Duration (ms)", duration, "Resolution", output.height, "Queue", this.chunks.length);
        this.uploadNextChunk();
    }
}
Object.defineProperty(StudioLive, "instance", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: new StudioLive()
});
