// TODO: this is probably not a very good place for this
export var DCMessageType;
(function (DCMessageType) {
    DCMessageType["PING"] = "PING";
    DCMessageType["PONG"] = "PONG";
    DCMessageType["CHAT"] = "CHAT";
    DCMessageType["POLL"] = "POLL";
    DCMessageType["FILE"] = "FILE";
    DCMessageType["POLL_QUESTION"] = "POLL_QUESTION";
    DCMessageType["POLL_ANSWER"] = "POLL_ANSWER";
    DCMessageType["POLL_RESULT"] = "POLL_RESULT";
    DCMessageType["PEER_JOINED"] = "PEER_JOINED";
    DCMessageType["PEER_LEFT"] = "PEER_LEFT";
    DCMessageType["PEER_KICK"] = "PEER_KICK";
    DCMessageType["CALL_ENDED"] = "CALL_ENDED";
    DCMessageType["RISING_HAND"] = "RISING_HAND";
    DCMessageType["STOP_RISING_HAND"] = "STOP_RISING_HAND";
    DCMessageType["PEER_STREAM_STATUS"] = "PEER_STREAM_STATUS";
    DCMessageType["SELECT_CANDIDATE"] = "SELECT_CANDIDATE";
    DCMessageType["CONNECTION_FAILED"] = "CONNECTION_FAILED";
    DCMessageType["PEER_DISCONNECT_STATUS"] = "PEER_DISCONNECT_STATUS";
    DCMessageType["PEER_SCREEN_SHARE_STATUS"] = "PEER_SCREEN_SHARE_STATUS";
    DCMessageType["PEER_PERMISSION_STATUS"] = "PEER_PERMISSION_STATUS";
    DCMessageType["ROOM_EVENT"] = "ROOM_EVENT";
    DCMessageType["MISSING_TRACK"] = "MISSING_TRACK";
    DCMessageType["POSSIBLE_MISSING_META"] = "POSSIBLE_MISSING_META";
})(DCMessageType || (DCMessageType = {}));
