export function isFullScreen() {
    const doc = document;
    return !!(doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.msFullscreenElement);
}
export function requestFullScreen(element) {
    if (element.requestFullscreen)
        return element.requestFullscreen();
    if (element.msRequestFullscreen)
        return element.msRequestFullscreen();
    if (element.webkitRequestFullscreen)
        return element.webkitRequestFullscreen();
    if (element.mozRequestFullScreen)
        return element.mozRequestFullScreen();
}
export function exitFullScreen(doc) {
    if (doc.exitFullscreen)
        return doc.exitFullscreen();
    if (doc.msExitFullscreen)
        return doc.msExitFullscreen();
    if (doc.webkitExitFullscreen)
        return doc.webkitExitFullscreen();
    if (doc.mozCancelFullScreen)
        return doc.mozCancelFullScreen();
}
export function toggleFullScreen() {
    if (isFullScreen()) {
        exitFullScreen(document);
    }
    else {
        requestFullScreen(document.documentElement);
    }
}
