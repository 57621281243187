import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button, ThreeDotsIcon, Text } from "@ting/ting-ui-components";
import { PollBody } from "../PollBody";
import { classNameFactory } from "@src/utils/dom";
import "./PollFrame.scss";
const cn = classNameFactory("poll-frame");
export const PollFrame = ({ onCreate, onAnswer }) => {
    const { t } = useTranslation("common");
    return (_jsxs("div", { className: cn(""), children: [_jsxs("header", { className: cn("header"), children: [_jsx(Text, { size: "p1", fontWeight: "semiBold", children: t("polls") }), _jsx(Button, { v2: true, icon: _jsx(ThreeDotsIcon, { width: 18, height: 18 }), size: "small", type: "neutral", variant: "primary" })] }), _jsx(PollBody, { onAnswer: onAnswer, onCreate: onCreate })] }));
};
