export var Protocols;
(function (Protocols) {
    Protocols["join"] = "meeting/join/1.0.0";
    Protocols["rtcEvent"] = "meeting/rtcEvent/1.0.0";
    Protocols["compactRoomInfo"] = "meeting/compactRoomInfo/1.0.0";
    Protocols["supernodeChange"] = "meeting/supernodeChange/1.0.0";
})(Protocols || (Protocols = {}));
export var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus[ConnectionStatus["CONNECTING"] = 0] = "CONNECTING";
    ConnectionStatus[ConnectionStatus["CONNECTED"] = 1] = "CONNECTED";
    ConnectionStatus[ConnectionStatus["FAILED"] = 2] = "FAILED";
    ConnectionStatus[ConnectionStatus["NOT_CONNECTED"] = 3] = "NOT_CONNECTED";
})(ConnectionStatus || (ConnectionStatus = {}));
export const CONNECTION_STATUS_EVENT_NAME = "connection-status";
export var OtherEvents;
(function (OtherEvents) {
    OtherEvents["connectionStatus"] = "connection-status";
})(OtherEvents || (OtherEvents = {}));
export const PeerCommunicatorEvents = { ...Protocols, ...OtherEvents };
