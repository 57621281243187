var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import logger from "loglevel";
import { inject, injectable } from "inversify";
import { Protocols, } from "@src/services/PeerCommunicator";
import { TYPES } from "@src/ioc.type";
import { DCMessageType, PeerConnectionEvents, } from "@src/domain/RTCConnection";
import { selectRoom, selectRoomHostPeer, selectRoomPeer, selectRoomPeers, selectRoomPublicId, selectRoomSelfPeer, selectRoomSlug, selectSelfStream, } from "@src/selectors";
import { PeerRoles } from "@src/domain/Peer";
import { isOnline } from "@src/utils/isOnline";
import { RTCConnectionState } from "@src/services/RTCConnection";
import { DCMessageBuilder } from "@src/controller/helpers";
import configs from "@src/configs";
let CandidateService = class CandidateService {
    constructor(rtcConnectionService, peerCommunicator, peerService, roomsApiService) {
        Object.defineProperty(this, "_peerCommunicator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rtcConnectionService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_peerService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_roomsApiService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_reOpenTimeOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._peerCommunicator = peerCommunicator;
        this._rtcConnectionService = rtcConnectionService;
        this._peerService = peerService;
        this._roomsApiService = roomsApiService;
        this.init();
    }
    init() {
        const peers$ = selectRoomPeers();
        peers$.onChange(({ value: peers }) => {
            const selfPeer = this._peerService.getSelfPeer();
            if (!peers || !selfPeer)
                return;
            const candidatePeer = Object.values(peers).find(peer => peer.role === PeerRoles.CANDIDATE);
            if (!candidatePeer && selfPeer.role === PeerRoles.SUPER_NODE)
                this.notifyPeersCandidate();
        });
        this._peerCommunicator.on(Protocols.supernodeChange, this.handleChangeSupernode.bind(this));
        this._rtcConnectionService.on(PeerConnectionEvents.ConnectionFailed, peer => {
            const $peer = selectRoomPeer(peer.peerId);
            if ($peer.peek())
                this.handleReOpenConnection();
        });
        this._rtcConnectionService.on(PeerConnectionEvents.ReceivedDCMessage, (message) => {
            switch (message.type) {
                case DCMessageType.SELECT_CANDIDATE: {
                    const { peerId } = message.payload;
                    const selfPeer = this._peerService.getSelfPeer();
                    selectRoomPeer(peerId).changeRole(PeerRoles.CANDIDATE);
                    if (selfPeer.peerId !== peerId)
                        this._peerCommunicator.ping(peerId).catch(e => logger.warn("cannot ping candidate peer", e));
                    break;
                }
                case DCMessageType.PEER_LEFT: {
                    const { peerId } = message.payload;
                    const leftPeerRole = selectRoomPeer(peerId).role.get();
                    const selfPeer = this._peerService.getSelfPeer();
                    if (leftPeerRole === PeerRoles.SUPER_NODE && selfPeer.role === PeerRoles.CANDIDATE)
                        this.handleLoseSupernode();
                    break;
                }
            }
        });
    }
    selectCandidateNode() {
        const peersArray = this._peerService.getRoomPeersArray();
        if (peersArray.length < 2)
            return;
        const selectedPeer = peersArray.filter(peer => peer.role !== PeerRoles.SUPER_NODE)[0];
        selectRoomPeer(selectedPeer.peerId).changeRole(PeerRoles.CANDIDATE);
        return selectedPeer;
    }
    getCandidateNode() {
        const peers = this._peerService.getRoomPeersArray();
        return peers.find(peer => peer.role === PeerRoles.CANDIDATE);
    }
    notifyPeersCandidate(peerId) {
        const selfPeer = this._peerService.getSelfPeer();
        let candidatePeer = this.getCandidateNode();
        if (!candidatePeer)
            candidatePeer = this.selectCandidateNode();
        if (candidatePeer) {
            const candidateNodeMessage = DCMessageBuilder.BuildCandidaSelectMessage(selfPeer, candidatePeer, peerId);
            this._rtcConnectionService.sendMessage(candidateNodeMessage);
        }
    }
    async publishNewSupernode() {
        logger.info("~ publish new super node ~");
        const peers = this._peerService.getRoomPeersArray();
        const selfPeer = this._peerService.getSelfPeer();
        const roomSlug = selectRoomSlug().get();
        const changeSupernodeMessage = {
            peerId: selfPeer.peerId,
        };
        peers
            .filter(peer => peer.role !== PeerRoles.SUPER_NODE)
            .forEach(peer => {
            this._peerCommunicator.send(peer.peerId, changeSupernodeMessage, Protocols.supernodeChange);
        });
        this._roomsApiService.updateRoom(roomSlug, {
            publicId: selfPeer.peerId,
        });
    }
    async handleLoseSupernode() {
        logger.info("~ start losing supernode logic ~");
        const $room = selectRoom();
        const selfPeer = this._peerService.getSelfPeer();
        const selfStream = selectSelfStream().peek();
        selectRoomSelfPeer().changeRole(PeerRoles.SUPER_NODE);
        $room.setPublicId(selfPeer.peerId);
        this._rtcConnectionService.reinitiate();
        this._rtcConnectionService.setSendingStream(selfStream.stream);
        await this.publishNewSupernode();
        const peers = this._peerService.getRoomPeersArray();
        peers
            .filter(peer => peer.role !== PeerRoles.SUPER_NODE)
            .forEach(peer => {
            this._rtcConnectionService.addConnectionTo(peer);
        });
        this.notifyPeersCandidate();
    }
    removeSupernodeFromRoom() {
        const lastSupernodePeerId = selectRoomHostPeer().peerId.get();
        if (lastSupernodePeerId)
            this._peerService.removePeer(lastSupernodePeerId);
    }
    handleChangeSupernode(peerId) {
        this.removeSupernodeFromRoom();
        const $room = selectRoom();
        const $newSupernode = selectRoomPeer(peerId);
        $newSupernode.changeRole(PeerRoles.SUPER_NODE);
        $room.setPublicId(peerId);
        this._rtcConnectionService.addConnectionTo($newSupernode.get());
        clearTimeout(this._reOpenTimeOut);
        return {
            isFine: true,
        };
    }
    async handleReOpenConnection(numberOfRetries = 0) {
        const selfPeerRole = this._peerService.getSelfPeer().role;
        const roomSlug = selectRoomSlug().get();
        const supernodePeerId = selectRoomPublicId().get();
        const pcConnState = this._rtcConnectionService.getPeerConnectionState(supernodePeerId);
        const isUserOnline = await isOnline();
        if (isUserOnline) {
            // check connection maybe connected
            if (pcConnState === RTCConnectionState.FAILED) {
                try {
                    const room = await this._roomsApiService.getRoom(roomSlug);
                    // not sure about this condition
                    if (room.publicId === supernodePeerId) {
                        await this._peerCommunicator.ping(room.publicId);
                        // TODO: successfully ping supernode, needs to rejoin
                    }
                    return;
                }
                catch (e) {
                    logger.warn("host is not available", e);
                    if (numberOfRetries > 0 && selfPeerRole === PeerRoles.CANDIDATE) {
                        this._peerService.removePeer(supernodePeerId);
                        this.handleLoseSupernode();
                        return;
                    }
                }
            }
            else if (pcConnState === RTCConnectionState.CONNECTED) {
                return;
            }
        }
        else {
            logger.warn("network not available");
        }
        this._reOpenTimeOut = setTimeout(() => {
            this.handleReOpenConnection(numberOfRetries + 1);
        }, configs.app.reOpenConnectionInterval);
    }
};
CandidateService = __decorate([
    injectable(),
    __param(0, inject(TYPES.RTCConnectionService)),
    __param(1, inject(TYPES.PeerCommunicator)),
    __param(2, inject(TYPES.PeerService)),
    __param(3, inject(TYPES.RoomsApiService)),
    __metadata("design:paramtypes", [Object, Object, Object, Object])
], CandidateService);
export { CandidateService };
