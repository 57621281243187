import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text, TextField } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { classNameFactory } from "@src/utils/dom";
import "./sourceFields.scss";
const cn = classNameFactory("source-field");
export const SourceNameField = ({ source, setSource }) => {
    const { t } = useTranslation("studio");
    return (_jsxs("div", { className: cn(""), children: [_jsx(Text, { size: "body-small", fontWeight: "semiBold", className: cn("label"), children: t("sourceName") }), _jsx("div", { className: `${cn("input")} ${cn("source-name")}`, children: _jsx(TextField, { v2: true, type: "string", name: "source-name", size: "small", state: "normal", value: source.sourceName, onChange: event => setSource({ ...source, sourceName: event.target.value }) }) })] }));
};
