import messageSrc from "@src/assets/audio/message-song.mp3";
import peerJoinedSrc from "@src/assets/audio/peer-joined-song.wav";
export var AudioType;
(function (AudioType) {
    AudioType[AudioType["Message"] = 0] = "Message";
    AudioType[AudioType["PeerJoined"] = 1] = "PeerJoined";
})(AudioType || (AudioType = {}));
const messageSong = new Audio(messageSrc);
messageSong.volume = 0.5;
const peerJoinedSong = new Audio(peerJoinedSrc);
peerJoinedSong.volume = 0.2;
export const playAudioNotification = (type) => {
    switch (type) {
        case AudioType.Message:
            messageSong.play();
            break;
        case AudioType.PeerJoined:
            peerJoinedSong.play();
            break;
        default:
            messageSong.play();
    }
};
