import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Tag, Text, UserAvatar } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import { SourceKind } from "@src/domain/Studio/types";
import { SourceIcon } from "@src/components/Studio/Sources/components/SourceIcon";
import "./peerSourceInput.scss";
const cn = classNameFactory("modal-peer-input");
export const PeerSourceInput = ({ peer, active, onClick }) => {
    const audioTrack = peer.peerStreamSettings.isMicOn && peer.peerStream?.getAudioTracks();
    const videoTrack = peer.peerStreamSettings.isCamOn && peer.peerStream?.getVideoTracks();
    const screenTrack = peer.peerStreamSettings.isScreenOn && peer.screenStream?.getVideoTracks();
    const noSourceAvailable = !audioTrack?.length && !videoTrack?.length && !screenTrack?.length;
    return (_jsxs(Button, { v2: true, size: "small", type: "neutral", variant: "ghost", className: cn({ selected: active }), onClick: onClick, disabled: noSourceAvailable, children: [_jsxs("div", { className: cn("icon"), children: [_jsx(UserAvatar, { image: peer.avatarURL, size: "sm", userName: peer.name, alt: peer.name }), _jsxs(Tag, { size: "medium", type: "default", rounded: "big", fill: false, className: cn("active-sources"), children: [audioTrack?.length > 0 && _jsx(SourceIcon, { kind: SourceKind.AUDIO_INPUT, width: 16, height: 16 }), videoTrack?.length > 0 && _jsx(SourceIcon, { kind: SourceKind.VIDEO_INPUT, width: 16, height: 16 }), screenTrack?.length > 0 && _jsx(SourceIcon, { kind: SourceKind.DISPLAY_CAPTURE, width: 16, height: 16 }), noSourceAvailable && "not available"] })] }), _jsx("div", { className: cn("label"), children: _jsx(Text, { type: "main", size: "body-large", fontWeight: "bold", children: peer.name }) })] }));
};
