/* eslint-disable no-undef */
import { ENV } from "@src/env";
export default {
    scriptSrcGoogle: "https://accounts.google.com/gsi/client",
    scriptSrcGapi: "https://apis.google.com/js/api.js",
    calendarDiscoveryDoc: "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    calendarScopes: "https://www.googleapis.com/auth/calendar",
    clientId: ENV.GOOGLE_CLIENT_ID,
    apiKey: ENV.GOOGLE_API_KEY,
};
