import { jsx as _jsx } from "react/jsx-runtime";
import { ChatMessage, MessageType, FileItem } from "@ting/ting-ui-components";
import { forwardRef, useState } from "react";
import { sendChatMessage, setRepliedMessage } from "@src/controller";
import { LiveChatWebTorrentUtil } from "@src/services/WebtorrentService";
import { state } from "@src/state";
export const ChatMessageItem = forwardRef(({ msg, isFile, isGrouped }, ref) => {
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const handleDownloadStatusChange = (status) => {
        setIsDownloadLoading(status === "started");
    };
    return (_jsx("div", { ref: ref, "data-id": msg.id, children: isFile ? (_jsx(FileItem, { record: msg, onDownload: record => LiveChatWebTorrentUtil.Instance.downloadTorrentFile(record.content, handleDownloadStatusChange), onShowInMeetingChat: () => {
                state.meeting.chat.setActiveTabIndex(0);
                state.meeting.chat.setMessageIdToScroll(msg.id);
            }, isDownloadLoading: isDownloadLoading, showSender: !isGrouped })) : (_jsx(ChatMessage, { record: msg, onCopy: () => undefined, onHide: () => sendChatMessage({
                type: MessageType.HIDE_MESSAGE,
                messageId: msg.id,
                time: new Date().toISOString(),
                viewer: msg.viewer,
            }), onReact: newRecord => sendChatMessage({
                type: MessageType.REACTION,
                messageId: msg.id,
                reactions: newRecord.reactions,
                time: new Date().toISOString(),
                viewer: msg.viewer,
            }), onReply: () => {
                setRepliedMessage(msg);
            }, onDownload: record => LiveChatWebTorrentUtil.Instance.downloadTorrentFile(record.content, handleDownloadStatusChange), isDownloadLoading: isDownloadLoading })) }));
});
