import { nanoid } from "nanoid";
import { SceneColor } from "./types";
import configs from "@src/configs";
import { selectStudioSources } from "@src/selectors/studio";
import { deleteStudioSource } from "@src/controller";
import { StudioMerger } from "@src/services/studioService";
export class StudioScenes {
    constructor() {
        Object.defineProperty(this, "isPanelOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isCreateSceneModalOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeSceneId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "list", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isPanelOpen = true;
        this.isCreateSceneModalOpen = false;
        this.activeSceneId = null;
        this.list = {};
    }
    setPanelOpen(isOpen) {
        this.isPanelOpen.set(isOpen);
    }
    setCreateSceneOpen(isOpen) {
        this.isCreateSceneModalOpen.set(isOpen);
    }
    activate(sceneId) {
        const sourceList = selectStudioSources();
        const activeSceneId = this.activeSceneId.peek();
        // Remove prev active scene sources from canvas
        if (activeSceneId) {
            const sources = sourceList[activeSceneId]?.peek();
            if (sources) {
                Object.keys(sources).map((sourceId) => StudioMerger.instance.removeStream(sourceId));
            }
        }
        // Add new activated scene sources to canvas
        const activatedSceneSources = sourceList[sceneId]?.peek();
        if (activatedSceneSources) {
            Object.keys(activatedSceneSources).map((sourceId) => {
                StudioMerger.instance.addSource(activatedSceneSources[sourceId]);
            });
        }
        // Set new scene.id active
        this.activeSceneId.set(sceneId);
        // Update each scene in list active prop
        Object.keys(this.list.peek()).forEach((id) => {
            this.list[id].assign({ active: id === sceneId });
        });
    }
    create(data) {
        let scene;
        const isNewScene = typeof data === "string";
        if (isNewScene) {
            const { width, height, fps } = configs.studio.defaultOutput;
            scene = {
                id: nanoid(),
                name: data,
                width,
                height,
                ratio: width / height,
                active: false,
                color: SceneColor.Aqua,
                output: {
                    width,
                    height,
                    fps,
                },
            };
        }
        else
            scene = data;
        this.list[scene.id].set(scene);
        if (isNewScene)
            this.activate(scene.id);
    }
    remove(sceneId) {
        const sourceList = selectStudioSources();
        const sources = sourceList[sceneId]?.peek();
        // Delete scene all sources
        if (sources) {
            Object.keys(sources).map((sourceId) => deleteStudioSource(sourceId, sceneId));
        }
        // Delete scene object itself
        this.list[sceneId].delete();
    }
    rename(sceneId, name) {
        this.list[sceneId].assign({ name });
    }
    duplicate(sceneId) {
        const scene = this.list[sceneId].peek();
        const id = nanoid();
        this.list[id].set({
            ...scene,
            id,
            name: `${scene.name} (copy)`,
        });
        this.activate(id);
    }
    assignColor(sceneId, color) {
        this.list[sceneId].assign({ color });
    }
}
