import { state } from "@src/state";
export function setLiveChatOpen(isOpen) {
    state.meeting.studio.stream.liveChat.setChatOpen(isOpen);
}
export function setLiveChatToggle(isOpen) {
    state.meeting.studio.stream.liveChat.setChatToggle(isOpen);
}
export function setLiveChatActiveTabIndex(index) {
    state.meeting.studio.stream.liveChat.setActiveTabIndex(index);
}
export function addLiveChatMessage(message) {
    state.meeting.studio.stream.liveChat.addChatMessage(message);
}
export function resetLiveChat() {
    state.meeting.studio.stream.liveChat.clearMessages();
}
