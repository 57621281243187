import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from "@ting/ting-ui-components";
import { selectRoomCreatedTime } from "@src/selectors";
import { useNewTimer } from "@src/hooks/useNewTimer";
import { useSubscribe } from "@src/hooks/useSubscribe";
export const TimerController = () => {
    const defaultDate = useSubscribe(selectRoomCreatedTime());
    const { hours, minutes, seconds } = useNewTimer({ defaultDate });
    const sec = seconds.toString().padStart(2, "0");
    const min = minutes.toString().padStart(2, "0");
    const h = hours.toString().padStart(2, "0");
    return (_jsx("div", { style: { minWidth: 70 }, children: _jsx(Text, { size: "body-medium", children: `${h}:${min}:${sec}` }) }));
};
