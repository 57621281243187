import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { AddSourceContext } from "../../Context";
import { SourceNameField, SourceSwitchDeviceField } from "@studio/Sources";
import { classNameFactory } from "@src/utils/dom";
import "./addNewSource.scss";
const cn = classNameFactory("new-source");
export const AddNewSource = () => {
    const { selectedSource, setSelectedSource } = useContext(AddSourceContext);
    return (_jsxs("div", { className: cn(""), children: [_jsx(SourceNameField, { source: selectedSource.source, setSource: source => {
                    setSelectedSource({ type: "local", source });
                } }), _jsx(SourceSwitchDeviceField, { source: selectedSource.source, setSelectedSource: source => {
                    setSelectedSource({ type: "local", source });
                } })] }));
};
