import { useState } from "react";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectUser } from "@src/selectors";
export const useDisplayNameOptions = () => {
    const user = useSubscribe(selectUser());
    const coreId = user?.corepassId.slice(7);
    const tingName = user?.account.displayName;
    // TODO handle loading saved verifiedName
    const [verifiedName, setVerifiedName] = useState("");
    return [{ verifiedName, tingName, coreId }, setVerifiedName];
};
