import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectMeetingUser, selectRoomPeer, selectRoomSelfPeer } from "@src/selectors";
import { ParticipantName } from "@src/components/ParticipantName";
import { debounce } from "@src/utils/helpers/debounce";
import "./peerScreen.scss";
const cn = classNameFactory("peer-screen-frame");
export const PeerScreen = ({ peerId }) => {
    const { t } = useTranslation("room");
    const wrapperRef = useRef(null);
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [videoWidth, setVideoWidth] = useState(0);
    const { name, screenStream, peerStreamSettings: { isScreenOn, isMicOn }, } = useSubscribe(selectRoomPeer(peerId));
    const selfPeer = useSubscribe(selectRoomSelfPeer());
    // TODO: remove this when reactive state fixed
    useSubscribe(selectMeetingUser());
    useEffect(() => {
        if (isScreenOn && screenStream) {
            const handleScreenSizeChange = (data) => {
                if (data.type === "video") {
                    const ratio = data.videoWidth / data.videoHeight;
                    setVideoWidth(data.wrapperWidth / ratio > data.wrapperHeight ? data.wrapperHeight * ratio : data.wrapperWidth);
                }
                if (data.type === "wrapper") {
                    if (!data.rect?.width && !videoRef.current)
                        return;
                    const { videoWidth, videoHeight } = videoRef.current || {};
                    const { width, height } = data.rect || { width: videoWidth, height: videoHeight };
                    const ratio = videoWidth / videoHeight;
                    setVideoWidth(width / ratio > height ? height * ratio : width);
                }
            };
            const screenObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    debounce(() => handleScreenSizeChange({ type: "wrapper", rect: entry.contentRect }), 100)();
                }
            });
            const handleVideoSizeChange = () => {
                handleScreenSizeChange({
                    type: "video",
                    videoWidth: videoRef.current.clientWidth,
                    videoHeight: videoRef.current.clientHeight,
                    wrapperWidth: wrapperRef.current.clientWidth,
                    wrapperHeight: wrapperRef.current.clientHeight,
                });
            };
            // Set video tag source
            const videoTag = videoRef.current;
            videoTag.srcObject = screenStream;
            videoTag.onloadedmetadata = () => {
                setLoading(false);
                screenObserver.observe(wrapperRef.current);
            };
            videoTag.addEventListener("resize", handleVideoSizeChange);
            return () => {
                videoTag.removeEventListener("resize", handleVideoSizeChange);
                screenObserver.disconnect();
            };
        }
    }, [isScreenOn, screenStream]);
    useEffect(() => {
        // Set screen audio if screen-share has audio track
        const [audioTrack] = screenStream.getAudioTracks();
        if (audioTrack && audioRef.current)
            audioRef.current.srcObject = screenStream;
    }, [screenStream]);
    return (_jsxs("div", { ref: wrapperRef, className: cn(""), children: [loading && _jsx(Loading, { className: cn("loading") }), _jsxs("div", { className: cn("inner"), style: { width: videoWidth }, children: [_jsx(ParticipantName, { name: t("screen-frame-name", { name }), muted: !isMicOn, className: cn("name") }), _jsx("video", { ref: videoRef, muted: true, autoPlay: true, className: cn("video-element") }), selfPeer.peerId !== peerId && _jsx("audio", { ref: audioRef, autoPlay: true })] })] }));
};
