export var RTCConnectionState;
(function (RTCConnectionState) {
    RTCConnectionState["INITIALIZED"] = "INITIALIZED";
    RTCConnectionState["CONNECTING"] = "CONNECTING";
    RTCConnectionState["CONNECTED"] = "CONNECTED";
    RTCConnectionState["DISCONNECTED"] = "DISCONNECTED";
    RTCConnectionState["FAILED"] = "FAILED";
    RTCConnectionState["CLOSED"] = "CLOSED";
    RTCConnectionState["RECONNECTING"] = "RECONNECTING";
})(RTCConnectionState || (RTCConnectionState = {}));
