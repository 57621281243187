export class SelfScreenStream {
    constructor() {
        Object.defineProperty(this, "stream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new MediaStream()
        });
        Object.defineProperty(this, "videoTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    addScreenTracks(stream) {
        for (const track of stream.getTracks()) {
            this.stream.peek().addTrack(track);
            if (track.kind === "video")
                this.videoTrack.set(track);
            if (track.kind === "audio")
                this.audioTrack.set(track);
        }
    }
    removeScreenTracks() {
        for (const track of this.stream.peek().getTracks()) {
            this.stream.peek().removeTrack(track);
            track.stop();
        }
        this.videoTrack.set(null);
        this.audioTrack.set(null);
    }
}
