import { handleSilentLogin, handleLoginCallback } from "./auth";
import configs from "@src/configs";
import { bindDependencies } from "@src/ioc";
import { TYPES } from "@src/ioc.type";
import { selectRoomUISettings } from "@src/selectors";
import { PeerCommunicatorEvents, } from "@src/services/PeerCommunicator";
function handleAuth() {
    if (location.pathname === configs.auth.loginCallbackPath) {
        handleLoginCallback();
    }
    else {
        handleSilentLogin();
    }
}
function handleInitPeerCommunicator() {
    return bindDependencies(function (peerCommunicator) {
        peerCommunicator.init();
        peerCommunicator.on(PeerCommunicatorEvents.connectionStatus, (connectionStatus) => {
            selectRoomUISettings().p2pConnectionStatus.set(connectionStatus);
        });
    }, [TYPES.PeerCommunicator]);
}
export function handleInit() {
    handleAuth();
    handleInitPeerCommunicator();
}
