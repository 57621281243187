export var DaysOfWeek;
(function (DaysOfWeek) {
    DaysOfWeek["Mon"] = "Mon";
    DaysOfWeek["Tue"] = "Tue";
    DaysOfWeek["Wed"] = "Wed";
    DaysOfWeek["Thu"] = "Thu";
    DaysOfWeek["Fri"] = "Fri";
    DaysOfWeek["Sat"] = "Sat";
    DaysOfWeek["Sun"] = "Sun";
})(DaysOfWeek || (DaysOfWeek = {}));
export var Recurrences;
(function (Recurrences) {
    Recurrences["none"] = "None";
    Recurrences["daily"] = "Daily";
    Recurrences["weekly"] = "Weekly";
    Recurrences["monthly"] = "Monthly";
    Recurrences["yearly"] = "Yearly";
})(Recurrences || (Recurrences = {}));
