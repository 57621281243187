import { Protocols } from "./types";
import { ChangeSupernodeRequest, ChangeSupernodeResponse, CompactRoomInfoRequest, CompactRoomInfoResponse, JoinRoomRequest, JoinRoomResponse, RTCEventRequest, RTCEventResponse, } from "./messages";
export const buffToArray = (msg) => {
    const res = new Uint8Array(msg.length);
    for (let i = 0; i < msg.length; i += 1) {
        // eslint-disable-next-line no-underscore-dangle
        res[i] = msg._bufs[0][i];
    }
    return res;
};
export const getRequestFrom = (protocol) => {
    switch (protocol) {
        case Protocols.join:
            return JoinRoomRequest;
        case Protocols.rtcEvent:
            return RTCEventRequest;
        case Protocols.compactRoomInfo:
            return CompactRoomInfoRequest;
        case Protocols.supernodeChange:
            return ChangeSupernodeRequest;
    }
};
export const getResponseFrom = (protocol) => {
    let result;
    switch (protocol) {
        case Protocols.join:
            result = JoinRoomResponse;
            break;
        case Protocols.rtcEvent:
            result = RTCEventResponse;
            break;
        case Protocols.compactRoomInfo:
            result = CompactRoomInfoResponse;
            break;
        case Protocols.supernodeChange:
            result = ChangeSupernodeResponse;
            break;
    }
    return result;
};
