import { jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Button } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { AddSourceContext } from "../../Context";
export const BackwardAction = () => {
    const { t } = useTranslation("common");
    const { step, setStep, onClose, selectedSource, setSelectedSource } = useContext(AddSourceContext);
    return (_jsxs(Button, { v2: true, type: "neutral", variant: "secondary", size: "small", onClick: () => {
            if (step === 1)
                onClose();
            else {
                setStep(step - 1);
                if (selectedSource.type === "local" && selectedSource.source.source instanceof MediaStream) {
                    selectedSource.source.source.getTracks().forEach(track => {
                        track.enabled = false;
                        track.stop();
                    });
                    setSelectedSource(null);
                }
            }
        }, children: [step === 1 && t("cancel"), step === 2 && t("back")] }));
};
