import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from "react";
import { UserAvatar } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import { selectRoomPeer } from "@src/selectors";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { useAudioTrackSampler } from "@src/hooks/useAudioContext";
import "./peerAvatar.scss";
const range = 64;
const sensitivity = 4;
const cn = classNameFactory("participant-avatar");
const activeClassName = "active";
const highActiveClassName = "active--high";
export const PeerAvatar = props => {
    const { peerId } = props;
    const visualizerRef = useRef();
    const pulseRef = useRef();
    const resetAnimatedPulse = useCallback(() => {
        pulseRef.current?.classList?.remove(activeClassName);
        visualizerRef.current?.classList?.remove(activeClassName, highActiveClassName);
    }, []);
    const animatePulse = useCallback((data) => {
        const to = Math.floor(range + 20);
        const from = Math.ceil(to - range);
        const segment = data.slice(from, to);
        const sum = segment.reduce((a, b) => a + b);
        const average = sum / segment.length;
        const height = sensitivity * average;
        if (height > 30) {
            visualizerRef.current?.classList.add(activeClassName, highActiveClassName);
        }
        else if (height > 15) {
            visualizerRef.current?.classList.remove(highActiveClassName);
            visualizerRef.current?.classList.add(activeClassName);
        }
        else {
            visualizerRef.current?.classList.remove(activeClassName, highActiveClassName);
        }
        if (height > 10) {
            pulseRef.current?.classList.add(activeClassName);
        }
        else {
            pulseRef.current?.classList.remove(activeClassName);
        }
    }, []);
    const peer = useSubscribe(selectRoomPeer(peerId));
    const isMicOn = peer.peerStreamSettings.isMicOn;
    useAudioTrackSampler(peer?.peerStream?.getAudioTracks()?.[0], isMicOn, animatePulse, resetAnimatedPulse);
    return (_jsxs("div", { className: cn(""), children: [_jsx("span", { ref: visualizerRef, className: cn("visualizer") }), _jsx(UserAvatar, { size: "xxl", image: peer.avatarURL, userName: peer.name }), _jsx("div", { ref: pulseRef, className: cn("pulse") })] }));
};
