import { improvedSessionStorage } from "./ImprovedStorage";
import { proxify, MemoryStorage } from "./memoryStorage";
let TingSessionStorage;
if (improvedSessionStorage) {
    TingSessionStorage = improvedSessionStorage;
}
else {
    const instanceSessionStorage = new MemoryStorage();
    TingSessionStorage = proxify(instanceSessionStorage);
}
export { TingSessionStorage };
