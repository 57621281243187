export class MediaTrackMetaData {
    constructor() {
        Object.defineProperty(this, "peerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mid", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "trackId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "streamId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "kind", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    static buildFromTransceiver(transceiver, stream, peerId, type) {
        const instance = new MediaTrackMetaData();
        instance.mid = transceiver.mid;
        instance.peerId = peerId;
        instance.trackId = transceiver.sender.track.id;
        instance.streamId = stream.id;
        instance.kind = transceiver.sender.track.kind;
        instance.type = type;
        return instance;
    }
    static buildFromTrack(track, stream, peerId, type) {
        const instance = new MediaTrackMetaData();
        instance.mid = null;
        instance.peerId = peerId;
        instance.trackId = track.id;
        instance.streamId = stream.id;
        instance.kind = track.kind;
        instance.type = type;
        return instance;
    }
}
