import configs from "@src/configs";
import { LocalStream } from "@src/services/localStream";
import { activateScene, addStudioSource, createNewScene, mapStudioSource } from "@src/controller";
import { SourceKind, SourceType, } from "@src/domain/Studio/types";
import { StudioMixer, StudioScenes, StudioScreen, StudioSources, StudioStream } from "@src/domain/Studio";
export class Studio {
    constructor(data) {
        Object.defineProperty(this, "screen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "scenes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sources", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mixer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "savedState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.screen = new StudioScreen();
        this.scenes = new StudioScenes();
        this.sources = new StudioSources();
        this.mixer = new StudioMixer();
        this.stream = new StudioStream();
        if (data)
            this.savedState = data;
    }
    restoreStudio() {
        const studio = this.savedState?.peek();
        if (!studio)
            return;
        if (studio?.screen)
            this.restoreScreen(studio.screen);
        if (studio?.mixer)
            this.restoreMixer(studio.mixer);
        if (studio?.scenes)
            this.restoreScenes(studio.scenes);
        if (studio?.sources)
            this.restoreSources(studio.sources);
        this.savedState.set(null);
    }
    restoreScreen(screen) {
        Object.keys(this.screen.peek()).forEach(k => {
            const key = k;
            if (Object.keys(screen).includes(key)) {
                this.screen[key].set(screen[key]);
            }
        });
    }
    restoreMixer(mixer) {
        Object.keys(this.mixer.peek()).forEach(k => {
            const key = k;
            if (Object.keys(mixer).includes(key)) {
                this.mixer[key].set(mixer[key]);
            }
        });
    }
    restoreScenes(scenes) {
        const defaultScenes = this.scenes.peek();
        const scenesList = scenes?.list ? Object.keys(scenes.list) : [];
        // Add default keys
        Object.keys(defaultScenes).forEach(k => {
            const key = k;
            if (key !== "list" && Object.keys(scenes).includes(key)) {
                this.scenes[key].set(scenes[key]);
            }
        });
        // Add scene list
        scenesList.map((sceneId) => {
            createNewScene(scenes.list[sceneId]);
        });
        activateScene(this.scenes.activeSceneId.peek());
    }
    restoreSources(sources) {
        const defaultSources = this.sources.peek();
        const sceneList = sources?.list ? Object.keys(sources.list) : [];
        // Add default keys
        Object.keys(defaultSources).forEach(k => {
            const key = k;
            if (key !== "list" && Object.keys(sources).includes(key)) {
                this.sources[key].set(sources[key]);
            }
        });
        // Add source list
        sceneList.map((sceneId) => {
            if (!Object.keys(this.scenes.list.peek()).includes(sceneId)) {
                delete sources.list[sceneId];
                return;
            }
            Object.values(sources.list[sceneId]).map(async (source) => {
                let stream = null;
                let isRetrieval = true;
                // If it's a remote source (participants video/audio/screen source) skip and delete the source from storage
                if (source.isRemote) {
                    delete sources.list[sceneId][source.id];
                    return;
                }
                if (source.sourceKind === SourceKind.AUDIO_INPUT || source.sourceKind === SourceKind.VIDEO_INPUT) {
                    const type = ["audio", "video"][+(source.sourceType === SourceType.VISUAL)];
                    isRetrieval = false;
                    stream = await LocalStream.getMediaTrack(type, {
                        deviceId: source.settings.deviceId,
                    });
                }
                addStudioSource({
                    ...source,
                    isRetrieval,
                    source: stream,
                }, sceneId // Add to sceneId
                );
            });
        });
        // After all sources has been restored, Now it's time to activate scene id
        // setTimeout(() => {
        //   activateScene(this.scenes.activeSceneId.peek());
        // }, 0);
    }
    async mapRetrievalSource(source) {
        let stream = null;
        switch (source.sourceKind) {
            // Display Captures
            case SourceKind.DISPLAY_CAPTURE:
                stream = await LocalStream.getDisplay(configs.studio.getScreenCaptureConstraint("monitor"));
                break;
            case SourceKind.WINDOW_CAPTURE:
                stream = await LocalStream.getDisplay(configs.studio.getScreenCaptureConstraint("window"));
                break;
            case SourceKind.BROWSER_CAPTURE:
                stream = await LocalStream.getDisplay(configs.studio.getScreenCaptureConstraint("browser"));
                break;
            // Files Loaders
            case SourceKind.IMAGE_FILE:
                stream = (await LocalStream.loadFile(SourceKind.IMAGE_FILE)).source;
                break;
            case SourceKind.VIDEO_FILE:
                stream = (await LocalStream.loadFile(SourceKind.VIDEO_FILE)).source;
                break;
            case SourceKind.AUDIO_FILE:
                stream = (await LocalStream.loadFile(SourceKind.AUDIO_FILE)).source;
                break;
            default:
                break;
        }
        mapStudioSource({
            ...source,
            source: stream,
        });
    }
}
