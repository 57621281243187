import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { SourceInput } from "../SourceInput";
import { AddSourceContext } from "../../Context";
import { classNameFactory } from "@src/utils/dom";
import "./sourceList.scss";
const cn = classNameFactory("modal-source-list");
export const SourceList = ({ list }) => {
    const [select, setSelect] = useState(null);
    const { setSelected } = useContext(AddSourceContext);
    useEffect(() => {
        setSelected({ type: "local", source: select });
    }, [select, setSelected]);
    if (list?.length) {
        return (_jsx("div", { className: cn(""), children: list.map(item => {
                return (_jsx(SourceInput, { label: item.label, caption: item.caption, sourceKind: item.sourceKind, active: item.id === select?.id, onClick: () => {
                        setSelect(item);
                    } }, item.id));
            }) }));
    }
    return null;
};
