import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ting/ting-ui-components";
import { AddSourceContext } from "../../Context";
import { SourceNameField, SourceSwitchDeviceField } from "@src/components/Studio/Sources/components/SourceFileds";
import { SourceKind, SourceType } from "@src/domain/Studio/types";
import { classNameFactory } from "@src/utils/dom";
import { CheckboxDropDown } from "@src/components/Studio/Sources/components/AddSourceModal/components/CheckboxDropDown";
import { getStreamDimensions } from "@src/services/localStream";
import configs from "@src/configs";
const cn = classNameFactory("peer-source");
const getPeerStream = async (type, peer) => {
    const mediaStream = type === "Screen" ? peer.screenStream?.clone() : new MediaStream();
    if (type === "Video" && peer.peerStream?.getVideoTracks().length) {
        mediaStream.addTrack(peer.peerStream.clone().getVideoTracks()[0]);
    }
    if (type === "Mic" && peer.peerStream?.getAudioTracks().length) {
        mediaStream.addTrack(peer.peerStream.clone().getAudioTracks()[0]);
    }
    if (!(mediaStream instanceof MediaStream) ||
        !mediaStream.getTracks().length ||
        (type === "Video" && (!peer.peerStreamSettings.isCamOn || !mediaStream.getVideoTracks().length)) ||
        (type === "Mic" && (!peer.peerStreamSettings.isMicOn || !mediaStream.getAudioTracks().length)) ||
        (type === "Screen" && (!peer.peerStreamSettings.isScreenOn || !mediaStream.getVideoTracks().length))) {
        return false;
    }
    const source = {
        id: mediaStream.id,
        isRemote: true,
        sourceName: `${peer.name}'s ${type}`,
        sourceLabel: peer.name,
        isRetrieval: false,
        source: mediaStream,
    };
    switch (type) {
        case "Mic": {
            return { ...source, mute: false, sourceType: SourceType.SOUND, sourceKind: SourceKind.AUDIO_INPUT };
        }
        case "Video": {
            const { width, height, ratio, sourceWidth, sourceHeight } = await getStreamDimensions(mediaStream);
            return {
                ...source,
                lock: false,
                hidden: false,
                positionX: configs.studio.source.defaultPositionX,
                positionY: configs.studio.source.defaultPositionY,
                width,
                height,
                ratio,
                sourceWidth,
                sourceHeight,
                flipX: true,
                flipY: false,
                sourceType: SourceType.VISUAL,
                sourceKind: SourceKind.VIDEO_INPUT,
            };
        }
        case "Screen": {
            const { width, height, ratio, sourceWidth, sourceHeight } = await getStreamDimensions(mediaStream);
            return {
                ...source,
                lock: false,
                hidden: false,
                positionX: configs.studio.source.defaultPositionX,
                positionY: configs.studio.source.defaultPositionY,
                width,
                height,
                ratio,
                sourceWidth,
                sourceHeight,
                sourceType: SourceType.VISUAL,
                sourceKind: SourceKind.VIDEO_INPUT, // todo need to add SCREEN_SHARE
            };
        }
    }
};
const getAllSourcesStream = async (peer) => {
    const videoSource = await getPeerStream("Video", peer);
    const audioSource = await getPeerStream("Mic", peer);
    const screenSource = await getPeerStream("Screen", peer);
    return { videoSource, audioSource, screenSource };
};
const VideoSource = ({ source }) => {
    const { selectedSource, setSelectedSource } = useContext(AddSourceContext);
    const { audio, screen, source: peer } = selectedSource;
    const [videoSource, setVideoSource] = useState(source);
    const [videoSelected, setVideoSelected] = useState(true);
    return (_jsx(CheckboxDropDown, { name: "camera", peer: peer, checked: videoSelected, disabled: !source, onChange: active => {
            setVideoSelected(active);
            setSelectedSource({
                type: "remote",
                source: peer,
                video: active && videoSource,
                audio,
                screen,
            });
        }, children: videoSource && (_jsxs(_Fragment, { children: [_jsx(SourceNameField, { source: videoSource, setSource: source => {
                        setVideoSource(source);
                        setSelectedSource({
                            type: "remote",
                            source: peer,
                            video: source,
                            audio,
                            screen,
                        });
                    } }), _jsx(SourceSwitchDeviceField, { source: videoSource, showSwitcher: false })] })) }));
};
const AudioSource = ({ source }) => {
    const { selectedSource, setSelectedSource } = useContext(AddSourceContext);
    const { video, screen, source: peer } = selectedSource;
    const [micSource, setMicSource] = useState(source);
    const [audioSelected, setAudioSelected] = useState(true);
    return (_jsx(CheckboxDropDown, { name: "mic", peer: peer, checked: audioSelected, disabled: !micSource, onChange: active => {
            setAudioSelected(active);
            setSelectedSource({
                type: "remote",
                source: peer,
                audio: active && micSource,
                video,
                screen,
            });
        }, children: micSource && (_jsx(_Fragment, { children: _jsx(SourceNameField, { source: micSource, setSource: source => {
                    setMicSource(source);
                    setSelectedSource({
                        type: "remote",
                        source: peer,
                        audio: source,
                        video,
                        screen,
                    });
                } }) })) }));
};
const ScreenSource = ({ source }) => {
    const { selectedSource, setSelectedSource } = useContext(AddSourceContext);
    const { video, audio, source: peer } = selectedSource;
    const [screenSource, setScreenSource] = useState(source);
    const [screenSelected, setScreenSelected] = useState(true);
    return (_jsx(CheckboxDropDown, { name: "screen", peer: peer, checked: screenSelected, disabled: !screenSource, onChange: active => {
            setScreenSelected(active);
            setSelectedSource({
                type: "remote",
                source: peer,
                video,
                audio,
                screen: active && screenSource,
            });
        }, children: screenSource && (_jsxs(_Fragment, { children: [_jsx(SourceNameField, { source: screenSource, setSource: source => {
                        setScreenSource(source);
                        setSelectedSource({
                            type: "remote",
                            source: peer,
                            video,
                            audio,
                            screen: source,
                        });
                    } }), _jsx(SourceSwitchDeviceField, { source: screenSource, showSwitcher: false })] })) }));
};
export const AddPeerSource = () => {
    const { selectedSource, setSelectedSource } = useContext(AddSourceContext);
    const { source: peer } = selectedSource;
    const [loading, setLoading] = useState(true);
    const [sources, setSources] = useState({
        videoSource: false,
        audioSource: false,
        screenSource: false,
    });
    useEffect(() => {
        getAllSourcesStream(peer).then(receivedSources => {
            setLoading(false);
            setSources(receivedSources);
            setSelectedSource({
                type: "remote",
                source: peer,
                video: receivedSources.videoSource,
                audio: receivedSources.audioSource,
                screen: receivedSources.screenSource,
            });
        });
    }, [peer, setSelectedSource]);
    if (loading) {
        return (_jsx("div", { className: cn(""), children: _jsx(Loading, {}) }));
    }
    return (_jsxs("div", { className: cn(""), children: [_jsx(VideoSource, { source: sources.videoSource }), _jsx(AudioSource, { source: sources.audioSource }), _jsx(ScreenSource, { source: sources.screenSource })] }));
};
