import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, TextField } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { handleDisplayLoad } from "@src/services/localStream";
import { classNameFactory } from "@src/utils/dom";
const cn = classNameFactory("source-field");
export const SwitchScreenSelect = ({ source, setSelectedSource }) => {
    const { t } = useTranslation("common");
    const changeSource = async () => {
        const newSource = await handleDisplayLoad("monitor", source.sourceKind);
        setSelectedSource(newSource);
    };
    return (_jsxs("div", { className: cn("file-selector"), children: [_jsx(TextField, { v2: true, type: "text", name: "file_name", size: "small", state: "normal", value: source.sourceLabel, onClick: changeSource }), _jsx(Button, { v2: true, type: "neutral", theme: "primary", size: "small", onClick: changeSource, children: t("browse") })] }));
};
