export var SourceKind;
(function (SourceKind) {
    SourceKind["AUDIO_OUTPUT"] = "audiooutput";
    SourceKind["AUDIO_INPUT"] = "audioinput";
    SourceKind["VIDEO_OUTPUT"] = "videooutput";
    SourceKind["VIDEO_INPUT"] = "videoinput";
    SourceKind["DISPLAY_CAPTURE"] = "displaycapture";
    SourceKind["WINDOW_CAPTURE"] = "windowcapture";
    SourceKind["BROWSER_CAPTURE"] = "browsercapture";
    SourceKind["VIDEO_FILE"] = "videofile";
    SourceKind["AUDIO_FILE"] = "audiofile";
    SourceKind["IMAGE_FILE"] = "imagefile";
})(SourceKind || (SourceKind = {}));
