import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Tab, Tabs, UserAvatar } from "@ting/ting-ui-components";
import { NotificationsOutlinedIcon, ObsFilledIcon, SettingsOutlinedIcon, TeamOutlinedIcon, } from "@ting/ting-ui-components/dist/icons";
import { classNameFactory } from "@src/utils/dom";
import { setChatOpen, setChatToggle } from "@src/controller";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRoomSelfPeer } from "@src/selectors";
import { selectIsChatOpen, selectIsChatToggle } from "@src/selectors/chat";
import { selectIsLiveChatOpen, selectIsLiveChatToggle } from "@src/selectors/live-chat";
import { setLiveChatOpen, setLiveChatToggle } from "@src/controller/live-chat/chat";
import { useUnreadMessagesCount } from "@src/hooks/useUnreadMessagesCount";
import "./sideNavigation.scss";
const cn = classNameFactory("side-navigation");
export const SideNavigation = () => {
    const isChatOpen = useSubscribe(selectIsChatOpen());
    const isChatToggle = useSubscribe(selectIsChatToggle());
    const isLiveChatOpen = useSubscribe(selectIsLiveChatOpen());
    const isLiveChatToggle = useSubscribe(selectIsLiveChatToggle());
    const selfPeer = useSubscribe(selectRoomSelfPeer());
    const [tabIndex, setTabIndex] = useState(0);
    const unReadMessagesCount = useUnreadMessagesCount();
    return (_jsx("div", { className: cn(""), children: _jsx("div", { className: cn("tabs", { "not-active": !isChatOpen && !isLiveChatOpen }), children: _jsxs(Tabs, { value: tabIndex, onChange: idx => {
                    switch (idx) {
                        case 0: {
                            const toggle = isChatOpen ? !isChatToggle : !isChatOpen;
                            setChatOpen(toggle);
                            setChatToggle(toggle);
                            setLiveChatToggle(false);
                            break;
                        }
                        case 1: {
                            const toggle = isLiveChatOpen ? !isLiveChatToggle : !isLiveChatOpen;
                            setLiveChatOpen(toggle);
                            setChatToggle(false);
                            setLiveChatToggle(toggle);
                            break;
                        }
                    }
                    setTabIndex(idx);
                }, variant: "vertical", children: [_jsx(Tab, { badgeContent: unReadMessagesCount ? String(unReadMessagesCount) : undefined, children: _jsx(TeamOutlinedIcon, { width: "24px", height: "24px" }) }), _jsx(Tab, { children: _jsx(ObsFilledIcon, { width: "24px", height: "24px" }) }), _jsx(Tab, { children: _jsx(NotificationsOutlinedIcon, { width: "24px", height: "24px" }) }), _jsx(Tab, { className: cn("tabs__separator"), children: _jsx(SettingsOutlinedIcon, { width: "24px", height: "24px" }) }), _jsx(Tab, { children: _jsx(UserAvatar, { size: "xs", image: selfPeer?.avatarURL, userName: selfPeer?.name }) })] }) }) }));
};
