import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SelectBox, Text } from "@ting/ting-ui-components";
import { useState } from "react";
import { FilesBody } from "../FilesBody";
import { classNameFactory } from "@src/utils/dom";
import { FilesSortValuesEnum } from "@src/components/Files/types";
import "@src/components/Chat/ChatFrame/ChatFrame.scss";
import "./FilesFrame.scss";
const cn = classNameFactory("chat-frame");
const cnFilesFrame = classNameFactory("files-frame");
const SORT_OPTIONS = [
    {
        label: "Newest first",
        value: FilesSortValuesEnum.newest,
    },
    {
        label: "Group by Sender",
        value: FilesSortValuesEnum.group,
    },
];
export const FilesFrame = () => {
    const [selectedSort, setSelectedSort] = useState(SORT_OPTIONS[0]);
    return (_jsxs("div", { className: cn(""), children: [_jsx("header", { className: cn("header"), children: _jsx(Text, { size: "p1", fontWeight: "semiBold", children: "Files" }) }), _jsxs("div", { className: cnFilesFrame("filters"), children: [_jsx(Text, { size: "body-medium", fontWeight: "regular", children: "Sort by:" }), _jsx(SelectBox, { className: cnFilesFrame("sort-selectbox"), value: selectedSort, options: SORT_OPTIONS, onChange: selectedOption => {
                            setSelectedSort(selectedOption);
                        }, size: "small" })] }), _jsx(FilesBody, { sortType: selectedSort.value })] }));
};
