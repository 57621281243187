import logger from "loglevel";
import SimplePeer from "@ting/simple-peer";
import { RTCConnectionEvents, RTCConnectionState } from "./types";
import { IRTCConnectionEventEmitter } from "./RTCConnectionEventEmitter";
import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
import configs from "@src/configs";
export class RTCConnection extends IRTCConnectionEventEmitter {
    constructor(remotePeer, args) {
        const { isInitiator } = args;
        super();
        Object.defineProperty(this, "npc", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "connState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pendingDCMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "remotePeer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.connState = RTCConnectionState.INITIALIZED;
        this.remotePeer = remotePeer;
        this.pendingDCMessages = [];
        this.openRTCPeerConnection(isInitiator ?? false);
    }
    openRTCPeerConnection(isInitiator) {
        this.connState = RTCConnectionState.CONNECTING;
        this.emit(RTCConnectionEvents.CONNECTION_STATE, { to: this.remotePeer, connectionState: this.connState });
        this.npc = new SimplePeer({
            initiator: isInitiator,
            config: {
                iceServers: configs.webrtc.iceServers,
            },
            // TODO move these const numbers?
            reconnectRetryCount: 4,
            reconnectSleepTime: 3000,
            encodings: {
                checkBitRateOnStats: true,
                preserveVideoHeight: 720,
                preserveScreenHeight: 1080,
            },
        });
        // eslint-disable-next-line no-underscore-dangle
        this.npc._debug = logger.debug;
        // TODO: setup stats-store
        this.npc.on("connect", () => {
            this.connState = RTCConnectionState.CONNECTED;
            this.emit(RTCConnectionEvents.CONNECTION_STATE, { to: this.remotePeer, connectionState: this.connState });
            // eslint-disable-next-line
            logger.info("!===== Simple Peer Connect =====!", this.npc._pc.connectionState);
            while (this.pendingDCMessages.length > 0) {
                const pendingMessage = this.pendingDCMessages.shift();
                this.sendDCMessage(pendingMessage);
            }
        });
        this.npc.on("signal", (data) => {
            this.emit(RTCConnectionEvents.SIGNAL, { to: this.remotePeer, data });
        });
        this.npc.on("stream", stream => logger.info("!===== Simple Peer Stream =====!", stream));
        this.npc.on("track", (track, stream) => {
            this.emit(RTCConnectionEvents.TRACK, { sender: this.remotePeer, track, stream });
        });
        this.npc.on("data", data => {
            const payload = DCMessage.deserialize(data);
            logger.info(`!===== Simple Peer Data (${payload.type}) =====!`, payload);
            this.emit(RTCConnectionEvents.DC_MESSAGE, payload);
        });
        this.npc.on("reconnected", () => {
            this.connState = RTCConnectionState.CONNECTED;
            this.emit(RTCConnectionEvents.CONNECTION_STATE, { to: this.remotePeer, connectionState: this.connState });
            // TODO: this doesn't look right!! we send connected and disconnected?
            // this.emitter({
            //   type: PCEventTypes.Disconnected,
            //   payload: {
            //     isDisconnected: false,
            //     peerId: this.peerId,
            //   },
            // });
        });
        this.npc.on("disconnected", () => {
            this.connState = RTCConnectionState.DISCONNECTED;
            logger.info("!===== Simple Peer Disconnected =====!");
            logger.info({
                isDisconnected: true,
                peerId: this.remotePeer.peerId,
            });
            this.emit(RTCConnectionEvents.CONNECTION_STATE, {
                connectionState: this.connState,
                to: this.remotePeer,
            });
        });
        this.npc.on("reconnecting_peer", () => {
            logger.log("~~~ Reconnecting event raised ~~~");
            this.emit(RTCConnectionEvents.CONNECTION_STATE, {
                connectionState: RTCConnectionState.RECONNECTING,
                to: this.remotePeer,
            });
        });
        this.npc.on("error", () => {
            this.connState = RTCConnectionState.FAILED;
            this.emit(RTCConnectionEvents.CONNECTION_STATE, { to: this.remotePeer, connectionState: this.connState });
        });
        this.npc.on("close", () => {
            this.connState = RTCConnectionState.FAILED;
            logger.info("!===== Simple Peer Closed =====!");
            this.emit(RTCConnectionEvents.CONNECTION_STATE, { to: this.remotePeer, connectionState: this.connState });
        });
        // TODO: clear Transceivers
    }
    sendDCMessage(message) {
        try {
            this.npc.send(DCMessage.serialize(message));
        }
        catch {
            logger.info(`Queuing message with type ${message.type} as dc is not ready`);
            this.pendingDCMessages.push(message);
        }
    }
    addTrack(track, stream) {
        if (track) {
            this.npc.addTrack(track, stream);
        }
        else {
            logger.error("track is not inctanceof MediaStreamTrack", track);
        }
    }
    replaceTrack(oldTrack, newTrack, stream) {
        this.npc.replaceTrack(oldTrack, newTrack, stream);
    }
    removeTrack(track, stream) {
        this.npc.removeTrack(track, stream);
    }
    signal(sessionDescription) {
        this.npc.signal(sessionDescription);
    }
    close() {
        logger.debug("close connection called");
        if (this.npc?.destroyed !== undefined && !this.npc?.destroyed) {
            this.npc.removeAllListeners("close");
            this.npc.destroy();
        }
        else {
            logger.info(`PeerConnection to ${(this.remotePeer.name, this.remotePeer.peerId)} is already closed`);
        }
    }
    get remoteTracks() {
        return this?.npc?.remoteTracks ?? [];
    }
}
