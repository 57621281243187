import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button } from "@ting/ting-ui-components";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectStudioActiveSource } from "@src/selectors/studio";
export const SourceToggleAction = ({ action, sourceType, toggled, icon, toggleIcon, toggleByVariant, disabled, }) => {
    const activeSource = useSubscribe(selectStudioActiveSource());
    const [toggle, setToggle] = useState(toggled);
    useEffect(() => {
        setToggle(toggled);
    }, [toggled]);
    if (sourceType && sourceType !== activeSource.sourceType)
        return null;
    return (_jsx(Button, { v2: true, icon: toggleIcon && toggle ? toggleIcon : icon, size: "small", type: "neutral", variant: toggleByVariant && toggle ? "ghost" : "primary", disabled: disabled, onClick: () => {
            if (typeof action === "function")
                action(!toggle);
            setToggle(!toggle);
        } }));
};
SourceToggleAction.defaultProps = {
    sourceType: null,
    toggled: false,
    toggleByVariant: false,
    toggleIcon: null,
};
