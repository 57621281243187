import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ModalTabs } from "../ModalTabs";
import { AddExistingSource, AddNewSource } from "../AddSource";
export const SelectSourceLocalTabList = () => {
    const { t } = useTranslation(["common", "studio"]);
    return (_jsx(ModalTabs, { list: [
            {
                label: t("studio:addNewSource"),
                content: _jsx(AddNewSource, {}),
            },
            {
                label: t("studio:addExistingSource"),
                content: _jsx(AddExistingSource, {}),
            },
        ] }));
};
