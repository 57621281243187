import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { getAllTimezones } from "countries-and-timezones";
import { FormSelectField, DatePicker } from "@ting/ting-ui-components";
import { ChevronDownFilledIcon } from "@ting/ting-ui-components/dist/icons";
import { RecurringMeeting } from "../RecurringMeeting";
import { Header, HeaderSizes } from "@src/components/Header";
import { classNameFactory } from "@src/utils/dom";
import { addHoursToTime } from "@src/utils/date";
import "./DateTimeMeeting.scss";
const cn = classNameFactory("date-time-meeting");
export const DateTimeMeeting = () => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation(["common", "room"]);
    const timezones = useMemo(() => {
        const options = [];
        const allTimezones = getAllTimezones();
        for (const [key, value] of Object.entries(allTimezones)) {
            options.push({
                label: `${value.name} - UTC${value.utcOffsetStr}`,
                value: key,
            });
        }
        return options;
    }, []);
    return (_jsxs(Fragment, { children: [_jsx(Header, { size: HeaderSizes.small, children: t("room:set-date-time") }), _jsxs("div", { className: cn("wrapper"), children: [_jsxs("div", { className: cn("date-time"), children: [_jsx(DatePicker, { label: t("room:starts-at") + ":", selected: values.startDate, name: "startDate", minDate: new Date(), onChange: (date) => setFieldValue("startDate", date), calendarIcon: _jsx(ChevronDownFilledIcon, { width: "24px", height: "24px" }) }), _jsx(DatePicker, { name: "startTime", showTimeSelect: true, showTimeSelectOnly: true, timeIntervals: 15, timeCaption: "Time", dateFormat: "h:mm aa", selected: values.startTime, onChange: (date) => {
                                    setFieldValue("startTime", date);
                                    setFieldValue("endTime", addHoursToTime(date, 1));
                                }, placeholderText: "HH:MM", calendarIcon: _jsx(ChevronDownFilledIcon, { width: "24px", height: "24px" }) })] }), _jsxs("div", { className: cn("date-time"), children: [_jsx(DatePicker, { label: t("room:ends-at") + ":", name: "endDate", minDate: new Date(), selected: values.endDate, onChange: (date) => setFieldValue("endDate", date), calendarIcon: _jsx(ChevronDownFilledIcon, { width: "24px", height: "24px" }) }), _jsx(DatePicker, { name: "endTime", showTimeSelect: true, showTimeSelectOnly: true, timeIntervals: 15, timeCaption: "Time", dateFormat: "h:mm aa", selected: values.endTime, onChange: (date) => setFieldValue("endTime", date), placeholderText: "HH:MM", calendarIcon: _jsx(ChevronDownFilledIcon, { width: "24px", height: "24px" }) })] }), _jsx(FormSelectField, { size: "large", label: t("room:time-zone"), name: "timezone", options: timezones, value: values.timezone, className: cn("input"), setFieldValue: (name, value) => setFieldValue(name, value.value) }), _jsx(RecurringMeeting, {})] })] }));
};
