import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Text } from "@ting/ting-ui-components";
import { classNameFactory } from "@src/utils/dom";
import { useBuildMobile } from "@src/hooks/breakpoints";
import "./displayNameOption.scss";
const cn = classNameFactory("display-name-option");
export const DisplayNameOption = props => {
    const { title, value, rightElement } = props;
    const isBuildMobile = useBuildMobile();
    const fontWeight = isBuildMobile ? "medium" : "regular";
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("content"), children: [_jsx(Text, { size: "p2", fontWeight: fontWeight, className: cn("title"), children: title }), value && (_jsxs(Fragment, { children: [_jsxs(Text, { size: "p2", fontWeight: fontWeight, className: cn("separator"), children: [" ", "-", " "] }), _jsx(Text, { size: "p2", fontWeight: fontWeight, className: cn("value"), children: value })] }))] }), rightElement] }));
};
