import { jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@ting/ting-ui-components";
import { AddSourceContext } from "../../Context";
import { addStudioSource } from "@src/controller";
import { useToaster } from "@src/components/Toaster";
export const ForwardAction = () => {
    const { t } = useTranslation(["common", "studio"]);
    const { step, selected, selectedSource, setStep, setSelectedSource, onClose } = useContext(AddSourceContext);
    const showToast = useToaster();
    return (_jsxs(Button, { v2: true, type: "brand", variant: "primary", size: "small", disabled: (step === 1 && !selected?.source) ||
            (step === 2 && !selectedSource) ||
            (step === 2 &&
                selectedSource.type === "remote" &&
                !selectedSource?.audio &&
                !selectedSource.video &&
                !selectedSource.screen), onClick: async () => {
            if (!selected)
                return;
            if (step === 1 && selected.type === "local") {
                try {
                    const source = await selected.source.getStream();
                    setSelectedSource({ type: "local", source });
                    setStep(step + 1);
                }
                catch (err) {
                    showToast({ type: "danger", message: t("room:access-camera-mic") });
                    onClose();
                }
            }
            if (step === 1 && selected.type === "remote" && selected.source) {
                setSelectedSource(selected);
                setStep(step + 1);
            }
            if (step === 2 && selectedSource?.type === "local") {
                addStudioSource(selectedSource.source);
                onClose();
            }
            if (step === 2 && selectedSource?.type === "remote") {
                if (selectedSource.audio)
                    addStudioSource(selectedSource.audio);
                if (selectedSource.video)
                    addStudioSource(selectedSource.video);
                if (selectedSource.screen)
                    addStudioSource(selectedSource.screen);
                onClose();
            }
        }, children: [step === 1 && t("continue"), step === 2 && t("studio:addSource")] }));
};
