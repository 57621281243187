import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import classNames from "classnames";
import { Button, Loading, UserAvatar, useSnackBar } from "@ting/ting-ui-components";
import configs from "@src/configs";
import { CropImage } from "@src/components/CropImage";
import { readFile } from "@src/utils/helpers/cropImage";
import validateImageUpload, { ImageValidationResult } from "@src/utils/helpers/validateImageUpload";
import { classNameFactory } from "@src/utils/dom";
import "./UploadAvatar.scss";
const cn = classNameFactory("upload-avatar");
const UploadAvatar = props => {
    const inputRef = useRef(null);
    const imageDataUrl = useRef(null);
    const { path, alt, className = "", size = "xl", userName, onAvatarChanged, coreId, hasButton } = props;
    const showSnackBar = useSnackBar();
    const [selectedImage, setSelectedImage] = useState(null);
    const [isCropImageOpen, setIsCropImageOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onUpdateImage = (file) => {
        setSelectedImage(imageDataUrl.current);
        setIsLoading(true);
        onAvatarChanged(file)
            .then(() => {
            setIsLoading(false);
            // queryClient.invalidateQueries([UserService.getUserInfo.name]);
        })
            .catch(() => setSelectedImage(null))
            .finally(() => setIsLoading(false));
        setIsCropImageOpen(false);
    };
    const handleInputOpen = (e) => {
        if (inputRef) {
            inputRef.current.click(e);
        }
    };
    const handleChange = async (e) => {
        if (e.target.files.length) {
            switch (validateImageUpload(e.target.files[0], configs.images.maxUserAvatarSize)) {
                case ImageValidationResult.VALID:
                    imageDataUrl.current = await readFile(e.target.files[0]);
                    setIsCropImageOpen(true);
                    break;
                case ImageValidationResult.INVALID_SIZE:
                    showSnackBar({
                        message: `This file is too large, maximum size is ${Math.floor(configs.images.maxUserAvatarSize / 1024 / 1024)} Megabytes`,
                    });
                    imageDataUrl.current = null;
                    break;
                case ImageValidationResult.INVALID_FORMAT:
                    showSnackBar({
                        message: "Please, make sure it is of the following type : jpg, png, jpeg",
                    });
                    imageDataUrl.current = null;
                    break;
                default:
                    onAvatarChanged(e.target.files[0]);
                    break;
            }
        }
    };
    const imgSrc = selectedImage || (path && `${configs.app.fileServer}${path}`);
    return (_jsxs(_Fragment, { children: [_jsx("input", { type: "file", accept: "image/*", ref: inputRef, hidden: true, onChange: handleChange }), _jsxs("div", { className: cn(""), children: [_jsxs("div", { onClick: handleInputOpen, onKeyDown: handleInputOpen, role: "button", tabIndex: 0, className: classNames("container", className), children: [isLoading && _jsx(Loading, {}), _jsx(UserAvatar, { image: imgSrc, alt: alt, size: size, userName: userName, coreID: coreId })] }), hasButton && (_jsx(Button, { v2: true, variant: "secondary", type: "neutral", size: "medium", onClick: handleInputOpen, children: "Upload Picture" }))] }), _jsx(CropImage, { image: imageDataUrl.current, onSave: onUpdateImage, isOpen: isCropImageOpen, onClose: () => setIsCropImageOpen(false), cropShape: "round" })] }));
};
export default UploadAvatar;
