import { useEffect } from "react";
import logger from "loglevel";
import { useHistory, useParams } from "react-router-dom";
import { RoomStatus } from "@src/domain/Room";
import { selectRoom, selectSelfStream } from "@src/selectors";
import { createRoomToJoin } from "@src/controller";
import { useInit } from "@src/routes/Room/useInit";
import { routePaths } from "@src/routes/routes";
import { initUserMedia } from "@src/controller/userMedia";
import { useSubscribe } from "@src/hooks/useSubscribe";
export const useLobby = () => {
    const history = useHistory();
    const { roomId } = useParams();
    const selfStream = useSubscribe(selectSelfStream());
    const room = useSubscribe(selectRoom(), true);
    const isJoinMode = room && room.status === RoomStatus.joining;
    const isInitializing = useInit(async () => {
        try {
            const room = selectRoom().peek();
            if (room)
                return;
            await createRoomToJoin(roomId);
        }
        catch (e) {
            // TODO: error handling
            logger.error(e);
            history.push(routePaths.HOME());
        }
    });
    useEffect(() => {
        if (!selfStream && !isInitializing)
            initUserMedia();
    }, [selfStream, isInitializing]);
    return {
        isJoinMode,
        isInitializing,
    };
};
