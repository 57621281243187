import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import c from "classnames";
import { useHistory } from "react-router-dom";
import { Button, HrLine, Modal, Tag } from "@ting/ting-ui-components";
import { CrownOutlinedIcon, FingerprintOutlinedIcon, TeamOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useTranslation } from "react-i18next";
import { Header, HeaderSizes } from "../Header";
import { CreateRoomCard } from "./CreateRoomCard";
import { useBuildMobile } from "@src/hooks/breakpoints";
import { classNameFactory } from "@src/utils/dom";
import { RoomSecurity, RoomType } from "@src/domain/Room";
import { routePaths } from "@src/routes/routes";
import "./createRoomModal.scss";
const cn = classNameFactory("create-room-modal");
export const CreateRoomModal = props => {
    const { open, onClose, type } = props;
    const { t } = useTranslation(["common", "room"]);
    const history = useHistory();
    const isMobile = useBuildMobile();
    const [security, setSecurity] = useState(RoomSecurity.public);
    const closeHandler = () => {
        onClose();
    };
    const setSecurityHandler = (security) => {
        setSecurity(security);
    };
    // TODO: handle security
    const nextHandler = async (security) => {
        // eslint-disable-next-line no-console
        console.log(security);
        history.push(routePaths.CREATE_ROOM(type === RoomType.scheduled ? type : undefined));
    };
    return (_jsx(Modal, { className: c(cn("modal-container"), "withMobileHeader"), isOpen: open, onClose: closeHandler, fullscreen: isMobile, hideCloseButton: true, children: _jsxs("div", { className: cn(""), children: [_jsx(Header, { isModal: true, size: isMobile ? HeaderSizes.small : HeaderSizes.big, filled: true, onClose: !isMobile && closeHandler, className: cn("modal-header"), children: t("room:secure-room-needed") }), _jsxs("div", { className: cn("content"), children: [_jsx(CreateRoomCard, { selected: security === RoomSecurity.secure, onClick: () => setSecurityHandler(RoomSecurity.secure), title: t("room:select-secure-room-title"), description: t("room:select-secure-room-description"), icon: _jsx(FingerprintOutlinedIcon, { width: 24, height: 24 }), tags: [
                                _jsx(Tag, { size: "medium", rounded: "small", type: "corepass", children: t("kyc-only") }, "first"),
                                _jsx(Tag, { size: "medium", type: "brand", rounded: "small", startIcon: _jsx(CrownOutlinedIcon, { height: 10 }), children: t("premium") }, "second"),
                            ] }), _jsx(CreateRoomCard, { selected: security === RoomSecurity.public, onClick: () => setSecurityHandler(RoomSecurity.public), title: t("room:select-public-room-title"), description: t("room:select-public-room-description"), icon: _jsx(TeamOutlinedIcon, { width: 24, height: 24 }) })] }), !isMobile && _jsx(HrLine, { className: cn("hr-line") }), _jsxs("div", { className: "action", children: [_jsx(Button, { v2: true, variant: "secondary", type: "neutral", theme: "secondary", className: "back-button", disabled: false, onClick: closeHandler, size: isMobile ? "big" : "medium", children: t("cancel") }), _jsx(Button, { v2: true, variant: "primary", type: "brand", className: "complete-button", onClick: () => nextHandler(security), disabled: false, size: isMobile ? "big" : "medium", children: t("Continue") })] })] }) }));
};
